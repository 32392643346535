// https://gist.github.com/leocristofani/98312e61807db8f32e720c9f97a186e5
import React from 'react';
import SearchableSelect from 'components/shared/SearchableSelect';
import { getTranslatedString } from 'helpers/i18n';

SearchableSelectRedux.defaultProps = {
  multi: false,
  className: '',
};

export default function SearchableSelectRedux({
  input,
  options,
  className,
  allowNone,
  placeholder,
  grouped,
  variant,
  label,
  disabled,
  isMulti,
  showErrorsInline,
  meta: { touched, error },
}) {
  const { name, value, onChange, onFocus } = input;

  if (allowNone) {
    options = [
      {
        label: getTranslatedString('landlordPortal.searchableSelectRedux.none'),
        value: 'None',
        type: '',
      },
      ...options,
    ];
  }

  if (grouped) {
    const groupings = [...new Set(options.map(option => option.type).sort())];
    let groupedOptions = [
      {
        label: placeholder || getTranslatedString('landlordPortal.searchableSelectRedux.select'),
        options: [
          {
            label: getTranslatedString('landlordPortal.searchableSelectRedux.none'),
            value: null,
            type: '',
          },
        ],
      },
    ];

    groupings.forEach(grouping => {
      groupedOptions.push({
        label: grouping,
        options: options.filter(option => option.type === grouping),
      });
    });

    options = groupedOptions;
  }

  const showError = showErrorsInline || touched;

  return (
    <SearchableSelect
      valueKey="value"
      label={label}
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      onBlur={event => event.preventDefault()}
      onFocus={onFocus}
      className={className}
      placeholder={placeholder}
      variant={variant}
      isDisabled={disabled}
      isMulti={isMulti}
      error={!!(showError && error)}
      helperText={showError && error}
    />
  );
}
