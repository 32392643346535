export default function reducer(
  state = {
    showSuccessfulVerificationMessage: false,
    showLoader: false,
  },
  action
) {
  switch (action.type) {
    case 'DISPLAY_LANDLORD_REFERENCE_VERIFICATION': {
      return {
        ...state,
        landlord_reference_verification: action.landlord_reference_verification,
        showLoader: false,
        showSuccessfulVerificationMessage: false,
      };
    }
    case 'DISPLAY_LOADER': {
      return {
        ...state,
        showLoader: action.display,
        personal_reference_verification: null,
        landlord_reference_verification: null,
        showSuccessfulVerificationMessage: false,
      };
    }
    case 'DISPLAY_SUCCESSFUL_VERIFICATION_MESSAGE': {
      return {
        ...state,
        showSuccessfulVerificationMessage: true,
        personal_reference_verification: null,
        landlord_reference_verification: null,
        showLoader: false,
      };
    }
    case 'DISPLAY_PERSONAL_REFERENCE_VERIFICATION': {
      return {
        ...state,
        personal_reference_verification: action.personal_reference_verification,
        showSuccessfulVerificationMessage: false,
        showLoader: false,
      };
    }
    default:
      return state;
  }
}
