import * as types from './types';

const initialState = {
  selectedBatch: {},
  searchResultCount: 0,
  batches: [],
  batchStats: {
    draft: 0,
    scheduled: 0,
    processing: 0,
    processed: 0,
  },
  batchPayments: [],
};

const serviceRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_BATCHES:
      return {
        ...state,
        searchResultCount: action.payload.searchResultCount,
        batches: [...state.batches.slice(0, action.payload.offset), ...action.payload.batches],
      };
    case types.STORE_BATCH_PAYMENTS:
      return {
        ...state,
        searchResultCount: action.payload.searchResultCount,
        batchPayments: [
          ...state.batchPayments.slice(0, action.payload.offset),
          ...action.payload.batchPayments,
        ],
      };
    case types.STORE_BATCH:
      return {
        ...state,
        selectedBatch: action.payload,
      };
    case types.STORE_BATCH_STATS:
      return {
        ...state,
        batchStats: action.payload.stats,
      };
    default:
      return state;
  }
};

export default serviceRequestsReducer;
