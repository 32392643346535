export default function reducer(
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
  },
  action
) {
  switch (action.type) {
    case 'DROPZONE_MULTI_FILE::CLOSE_LIGHT_BOX': {
      return { ...state, lightboxIsOpen: false, currentImage: 0 };
    }
    case 'DROPZONE_MULTI_FILE::OPEN_LIGHT_BOX': {
      return {
        ...state,
        lightboxIsOpen: true,
        currentImage: action.currentImage,
      };
    }
    case 'DROPZONE_MULTI_FILE::SET_LIGHT_BOX_IMAGE': {
      return { ...state, currentImage: action.currentImage };
    }
    default:
      return state;
  }
}
