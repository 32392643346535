import { useLayoutEffect, useState } from 'react';
import { css } from 'styled-components';

export const sizes = {
  mobileSmall: 405,
  mobile: 749,
  desktop: 750,
  ipadMax: 768,
  ipadMin: 769,
  loginSmallMax: 900,
  loginLargeMin: 901,
  largeDesktop: 1023,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  if (label === 'desktop') {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes['largeDesktop']}px) and (min-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  } else if (label === 'largeDesktop') {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  } else if (label === 'loginSmallMax') {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  } else if (label === 'loginLargeMin') {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  } else if (label === 'ipadMax') {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  } else if (label === 'ipadMin') {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  }

  return acc;
}, {});

/**
 * React Hook that returns the width and height of the browser window
 * @returns {Array} [width, height]
 * @example
 * const [width, height] = useWindowSize();
 * const isMobile = width <= sizes.mobile;
 *
 * return <MyComponent isMobile={isMobile} />;
 */
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
