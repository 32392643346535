export default function reducer(
  state = {
    address: {},
    companyPreferences: {},
  },
  action
) {
  switch (action.type) {
    case 'COMPANY::SAVE_BILLING_DETAILS': {
      return { ...state, billingDetails: action.billingDetails };
    }
    case 'COMPANY::SAVE_PREFERENCES': {
      return { ...state, companyPreferences: action.preferences };
    }
    case 'COMPANY::SAVE_ADDRESS': {
      return { ...state, address: action.address };
    }
    case 'COMPANY::SAVE_COMPANY': {
      return { ...state, company: action.company };
    }
    default:
      return state;
  }
}
