import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localize } from 'react-localize-redux';

class TenantResidentVerbiageHelper extends Component {
  render() {
    let textTransform = 'inherit';
    if (this.props.capitalize) {
      textTransform = 'capitalize';
    } else if (this.props.uppercase) {
      textTransform = 'uppercase';
    }

    return (
      <span style={{ textTransform: textTransform }}>
        {this._renderTenantVerbiage()}
        {this._renderResidentVerbiage()}
        {this._renderTenantVerbiageSentence()}
        {this._renderResidentVerbiageSentence()}
      </span>
    );
  }

  _renderTenantVerbiageSentence = () => {
    if (!this.props.translatedTenant) return;
    if (!this.props.translatedResident) return;
    if (this.props.propertyTypes !== 'commercial') return;

    return this.props.translatedTenant;
  };

  _renderResidentVerbiageSentence = () => {
    if (!this.props.translatedTenant) return;
    if (!this.props.translatedResident) return;
    if (this.props.propertyTypes === 'commercial') return;

    return this.props.translatedResident;
  };

  _renderTenantVerbiage = () => {
    if (this.props.translatedTenant) return;
    if (this.props.translatedResident) return;
    if (this.props.propertyTypes !== 'commercial') return;

    return this.props.translate('tenant');
  };

  _renderResidentVerbiage = () => {
    if (this.props.translatedTenant) return;
    if (this.props.translatedResident) return;
    if (this.props.propertyTypes === 'commercial') return;

    return this.props.translate('resident');
  };
}

TenantResidentVerbiageHelper = connect(store => ({
  propertyTypes: store.userTypes.propertyTypes,
}))(TenantResidentVerbiageHelper);

export default localize()(TenantResidentVerbiageHelper);
