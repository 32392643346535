import React, { Component } from 'react';

import Dialog from 'material-ui/Dialog';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import SignatureCanvas from 'react-signature-canvas';
import { FaEdit as PenIcon } from 'react-icons/fa';

import trimCanvas from 'trim-canvas';

import * as ByesignHelper from './ByesignHelper';
import { localize } from 'react-localize-redux';

class SignaturePadDialog extends Component {
  componentDidMount = () => {
    window.addEventListener(
      'orientationchange',
      () => {
        this.closePad();
      },
      false
    );
  };

  clearPad = () => {
    if (this.props.showPreviousSignatureImage) {
      this.props.hidePreviousSignatureImage();
    } else if (!this.props.toggleSignatureTextMode) {
      this.sigPad.clear();
    }

    if (this.props.toggleSignatureTextMode) {
      this.clearSignatureTextCanvas();
      this.props.updateSignatureTextValue('');
    }

    this.props.enableSignButton(false);
  };

  clearSignatureTextCanvas = () => {
    setTimeout(() => {
      const canvas = this.signatureTextCanvas;
      if (canvas) {
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      }
    }, 0);
  };

  isCanvasEmpty = (canvas) => {
    if (!canvas) return false;

    let blank = document.createElement('canvas');
    blank.width = canvas.width;
    blank.height = canvas.height;

    return canvas.toDataURL() === blank.toDataURL();
  };

  onChangeSignatureTextField = (value, textYPosition) => {
    this.props.updateSignatureTextValue(value);
    this.props.updateSignatureTextCanvas(value, textYPosition, this.props.isTextbox);
    this.props.enableSignButton(!!value && !!value.trim());
  };

  trim = () => {
    let url;
    if (this.props.showPreviousSignatureImage) {
      url = this.previousSignatureImage.src;
      if (!url) return;
    } else if (this.props.toggleSignatureTextMode) {
      if (this.isCanvasEmpty(this.signatureTextCanvas)) return;
      url = trimCanvas(this.signatureTextCanvas).toDataURL('image/png');
    } else {
      if (this.isCanvasEmpty(this.sigPad.getCanvas())) return;
      url = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    }
    this.props.captureSignature(this.props.signatureId, url);
    this.props.closePad();
    this.props.updateToggleSignatureTextMode(true);
    this.props.updateSignatureTextValue('');
    this.props.goToNextSignature();
  };

  closePad = () => {
    this.props.closePad();
    this.props.updateToggleSignatureTextMode(true);
    this.props.updateSignatureTextValue('');
    this.props.enableSignButton(false);
  };

  render() {
    const signatureDialogActions = [
      <FlatButton
        label={this.props.translate('close')}
        primary={true}
        style={{ color: ByesignHelper.style.grayColor }}
        onClick={this.closePad}
      />,
      <FlatButton
        label={this.props.isTextbox ? this.props.translate('save') : this.props.translate('sign')}
        type="button"
        primary={true}
        backgroundColor={this.props.signButtonEnabled ? this.props.colorScheme : 'grey'}
        style={{ color: 'white' }}
        onClick={this.trim}
        disabled={!this.props.signButtonEnabled}
      />,
    ];

    let dialogTitle = this.props.isPadTypeSignature
      ? this.props.translate('eSignature')
      : this.props.translate('eInitials');

    dialogTitle = this.props.isTextbox ? this.props.translate('textInput') : dialogTitle;

    return (
      <Dialog
        title={
          <div>
            <span>{dialogTitle}</span>
            <span style={{ float: 'right', width: '122px', marginTop: '-6px' }}>
              {!this.props.isTextbox && !this.props.showPreviousSignatureImage && (
                <Toggle
                  toggled={!this.props.toggleSignatureTextMode}
                  label={
                    <span>
                      <PenIcon
                        style={{
                          marginRight: '4px',
                          fontSize: '24px',
                          color: ByesignHelper.style.grayColor,
                        }}
                      />
                      {this.props.translate('toggleLabelText')}
                    </span>
                  }
                  labelPosition="right"
                  style={{ marginTop: '10px', color: this.props.colorScheme }}
                  labelStyle={{ color: ByesignHelper.style.grayColor, fontSize: '16px' }}
                  onToggle={(e, value) => {
                    let validSignerFirstName = ByesignHelper.isValidSignerFirstName(
                      this.props.currentSigner
                    );
                    this.props.enableSignButton(!value && validSignerFirstName);
                    this.props.updateToggleSignatureTextMode(!value);
                    const names = ByesignHelper.retrieveSignerNameText(
                      this.props.currentSigner,
                      this.props.isPadTypeSignature,
                      this.props.isTextbox
                    );
                    this.props.updateSignatureTextValue(names);
                    this.props.updateSignatureTextCanvas(
                      names,
                      this.props.signatureTextYPosition,
                      this.props.isTextbox
                    );
                  }}
                />
              )}
            </span>
          </div>
        }
        open={this.props.showPad}
        modal={false}
        actions={signatureDialogActions}
        titleStyle={this.props.titleContentStyle}
        bodyStyle={ByesignHelper.style.bodyContentStyle}
        contentStyle={ByesignHelper.style.customContentStyle}
        autoDetectWindowHeight={!this.props.isLandscape}
        repositionOnUpdate={false}
        style={{ zIndex: '3000', padding: '0px' }}
      >
        {ByesignHelper.showSignPadInstructions(
          this.props.showPreviousSignatureImage,
          this.props.isPadTypeSignature,
          this.props.toggleSignatureTextMode,
          this.props.translate,
          this.props.isTextbox
        )}
        <div style={{ width: '100%' }}>
          <div className={this.props.isPadTypeSignature ? '' : 'inner-canvas-div'}>
            {this.props.showPreviousSignatureImage && (
              <div style={ByesignHelper.style.previousSignatureImageStyle}>
                <img
                  alt=""
                  crossOrigin="anonymous"
                  ref={(ref) => {
                    this.props.previousSignatureImageRef(ref);
                    this.previousSignatureImage = ref;
                  }}
                />
              </div>
            )}
            {!this.props.showPreviousSignatureImage &&
              this.props.toggleSignatureTextMode === false && (
                <SignatureCanvas
                  penColor="black"
                  ref={(ref) => {
                    this.sigPad = ref;
                  }}
                  onEnd={() => {
                    this.props.enableSignButton(true);
                  }}
                  canvasProps={{
                    width: this.props.canvas_width,
                    height: this.props.isLandscape ? 100 : 200,
                    className: 'signature-canvas',
                  }}
                />
              )}
            {!this.props.showPreviousSignatureImage &&
              this.props.toggleSignatureTextMode === true &&
              !this.props.isTextbox && (
                <div>
                  <canvas
                    ref={(ref) => {
                      this.props.signatureTextCanvasRef(ref);
                      this.signatureTextCanvas = ref;
                    }}
                    width={this.props.canvas_width}
                    height={this.props.isLandscape || this.props.isSmallMobileDevice ? 70 : 100}
                    onClick={() => this.signatureTextField.focus()}
                    style={{
                      border: '1px solid #e6e6e6',
                    }}
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      marginTop: this.props.isLandscape ? '-18px' : '0px',
                    }}
                  >
                    <TextField
                      ref={(ref) => {
                        this.signatureTextField = ref;
                      }}
                      name={'signatureTextField'}
                      type={'text'}
                      hintText={this.props.translate('clickStartTyping')}
                      value={this.props.signatureTextValue}
                      style={{ textAlign: 'center' }}
                      inputStyle={{ color: 'grey' }}
                      fullWidth
                      maxLength={
                        this.props.isPadTypeSignature
                          ? ByesignHelper.constants.SIGNATURE_TEXT_LENGTH
                          : ByesignHelper.constants.INITIALS_TEXT_LENGTH
                      }
                      onChange={(evt) =>
                        this.onChangeSignatureTextField(
                          evt.target.value,
                          this.props.signatureTextYPosition
                        )
                      }
                    />
                  </div>
                </div>
              )}

            {!this.props.showPreviousSignatureImage && this.props.isTextbox && (
              <div>
                <canvas
                  ref={(ref) => {
                    this.props.signatureTextCanvasRef(ref);
                    this.signatureTextCanvas = ref;
                  }}
                  width={this.props.canvas_width}
                  height={40}
                  onClick={() => this.signatureTextField.focus()}
                  style={{
                    border: '1px solid #e6e6e6',
                    display: 'none',
                  }}
                />
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    marginTop: this.props.isLandscape ? '-18px' : '0px',
                  }}
                >
                  <TextField
                    ref={(ref) => {
                      this.signatureTextField = ref;
                    }}
                    name={'signatureTextField'}
                    type={'text'}
                    hintText={this.props.translate('clickStartTyping')}
                    value={this.props.signatureTextValue}
                    style={{ textAlign: 'center' }}
                    inputStyle={{ color: 'grey' }}
                    fullWidth
                    maxLength={35}
                    onChange={(evt) => this.onChangeSignatureTextField(evt.target.value, 20)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <FlatButton
          label={this.props.translate('clear')}
          primary={false}
          style={{ color: ByesignHelper.style.grayColor }}
          onClick={this.clearPad}
        />
      </Dialog>
    );
  }
}

export default localize()(SignaturePadDialog);
