import Auth from 'j-toker';
import { browserHistory } from 'react-router';

import { apiGet, apiPut, apiPost } from 'helpers/ApiHelper';
import * as notifications from './notificationActions';
import constants from 'config/constants';

export function setUserAsLead(response = {}) {
  return {
    type: 'USER_TYPES::SET_USER_AS_LEAD',
    hasActivePad: response.has_active_pad,
    accentColor: response.accent_color,
    activeLeaseHasAmenities: response.active_lease_has_amenities,
    moveIn: response.move_in,
    moveInDate: response.move_in_date,
    mustAcceptTerms: response.must_accept_terms,
    landlordName: response.landlord_name,
    showHelp: response.show_help,
    landlordId: response.landlord_id,
    propertyTypes: response.property_types,
    verbiages: response.verbiages,
    landlordLogoUrl: response.landlord_logo_url,
    landlordLogoThumbUrl: response.landlord_logo_thumb_url,
    imageMaxSize: response.image_max_size,
    helpDocuments: response.help_documents,
    privacyStatementLink: response.privacy_statement_link,
    privacyStatementUpdatedAt: response.privacy_statement_updated_at,
    termsFileName: response.terms_file_name,
    showPoweredByYuhu: response.show_powered_by_yuhu,
    heroImage: response.hero_image,
    featureSet: response.feature_set,
    defaultLanguages: response.default_languages,
    countryCodes: response.country_codes,
    greetingNameType: response.greeting_name_type,
    landlordCountry: response.landlord_country,
  };
}

export function setUserAsLandlord(response = {}) {
  return {
    type: 'USER_TYPES::SET_USER_AS_LANDLORD',
    admin: response.admin,
    isYuhu: response.is_yuhu,
    showAvailability: response.show_availability,
    permissions: response.permission,
    allowManualBooking: response.allow_manual_agent_bookings,
    active: response.active,
    stripeConnected: response.stripe_connected,
    googleConnected: response.google_connected,
    azureConnected: response.azure_connected,
    accountingEnabled: response.accounting_enabled,
    maintenanceEnabled: response.maintenance_enabled,
    multiFamilyEnabled: response.multi_family_enabled,
    isThirdPartyManager: response.is_third_party_manager,
    accentColor: response.accent_color,
    passwordExpirationDate: response.password_expiration_date,
    enableAllAgentsTwoFactorAuth: response.enable_all_agents_two_factor_auth,
    email: response.email,
    id: response.id,
    createdSuccess: response.createdSuccess,
    noticeTemplateManager: response.notice_template_manager,
    provider: response.provider,
    showHelp: response.show_help,
    showMessaging: response.show_messaging,
    showTenantAdditionalOccupant: response.show_tenant_additional_occupant,
    showTenantDocuments: response.show_tenant_documents,
    showTenantLedger: response.show_tenant_ledger,
    showTenantPetDetails: response.show_tenant_pet_details,
    landlordId: response.landlord_id,
    landlordName: response.landlord_name,
    landlordSSOEnabled: response.landlord_sso_enabled,
    uuid: response.landlord_uuid,
    propertyTypes: response.property_types,
    yardiEnabled: response.yardi_enabled,
    verbiages: response.verbiages,
    sso_providers: response.sso_providers,
    imageMaxSize: response.image_max_size,
    helpDocuments: response.help_documents,
    showPoweredByYuhu: response.show_powered_by_yuhu,
    heroImage: response.hero_image,
    featureSet: response.feature_set,
    canOnlySendByNoticeTemplate: response.can_only_send_by_notice_template,
    canAddAmenities: response.can_add_amenities,
    canApprovePurchaseOrder: response.can_approve_purchase_order,
    defaultLanguages: response.default_languages,
    countryCodes: response.country_codes,
    canAssignAndScheduleTickets: response.can_assign_and_schedule_tickets,
    canLogTravelAndWorkTimes: response.can_log_travel_and_work_times,
    canChangeTicketStatus: response.can_change_ticket_status,
    canApproveLeases: response.can_approve_leases,
    canApproveApplication: response.can_approve_application,
    canReviewApplication: response.can_review_application,
    paymentProvider: response.payment_provider,
    landlordCountry: response.landlord_country,
    globalFilter: response.global_filter,
    leadManagingLink: response.lead_managing_link,
    landlordErp: response.landlord_erp,
    isApproverPlus: response.is_approver_plus,
  };
}

export function setUserAsMaintenance(response = {}) {
  return {
    type: 'USER_TYPES::SET_USER_AS_MAINTENANCE',
    id: response.id,
    active: response.active,
    accentColor: response.accent_color,
    readOnly: response.read_only,
    createdSuccess: response.createdSuccess,
    claimTickets: response.claim_tickets,
    assignTickets: response.assign_tickets,
    landlordId: response.landlord_id,
    propertyTypes: response.property_types,
    admin: response.admin,
    verbiages: response.verbiages,
    landlordSSOEnabled: response.landlord_sso_enabled,
    imageMaxSize: response.image_max_size,
    helpDocuments: response.help_documents,
    showPoweredByYuhu: response.show_powered_by_yuhu,
    heroImage: response.hero_image,
    featureSet: response.feature_set,
    defaultLanguages: response.default_languages,
    countryCodes: response.country_codes,
    landlordCountry: response.landlord_country,
    canAssignTicketsMaint: response.assign_tickets,
  };
}

export function getUserAvailability() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/user/availability`)
        .done((response) => {
          dispatch(saveAvailability(response));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

function saveAvailability(availability) {
  return {
    type: 'USER_DETAILS::SAVE_AVAILABLITY',
    availability,
  };
}

export function getUserPreferences() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/user/preferences`)
        .done((response) => {
          dispatch(saveAddress(response));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

function saveAddress(userPreferences) {
  return {
    type: 'USER_DETAILS::SAVE_PREFERENCES',
    userPreferences,
  };
}

export function getUserNotificationPreferences() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/leads/notification_preferences`)
        .done((response) => {
          dispatch({
            type: 'USER_DETAILS::SAVE_NOTIFICATION_PREFERENCES',
            notificationPreferences: response.notification_preferences,
            noticeByEmail: response.notice_by_email,
            digitalLegalDocuments: response.digital_legal_documents,
          });
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updateUserAvailability(userType, userId, formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/user/${userType}/${userId}/availability`, formData)
        .done((response) => {
          dispatch(notifications.showSuccess('availabilitySuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updateUserNotificationPreferences(formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + '/leads/notification_preferences', formData)
        .done((response) => {
          dispatch(getUserNotificationPreferences());
          dispatch(hideInitialLeadDialog());
          dispatch(notifications.showSuccess('notificationPreferencesSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updateUserPreferences(formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + '/user/preferences', formData)
        .done((response) => {
          dispatch(getUserPreferences());
          dispatch(hideInitialLeadDialog());
          dispatch(notifications.showSuccess('preferencesSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function setLandlordDetails(details = {}) {
  const {
    id,
    accentColor,
    landlordLogoUrl,
    landlordLogoThumbUrl,
    propertyTypes,
    landlordName,
    landlordSSOEnabled,
    uuid,
    verbiages,
    showPoweredByYuhu,
    heroImageUrl,
    featureSet,
    defaultLanguages,
    countryCodes,
    greetingNameType,
    googleTagId,
    landlordCountry,
  } = details;
  return {
    landlordName,
    type: 'USER_TYPES::SET_LANDLORD_DETAILS',
    landlordId: id,
    accentColor,
    landlordLogoUrl,
    landlordSSOEnabled,
    uuid,
    propertyTypes,
    landlordLogoThumbUrl,
    sso_providers: details.sso_providers,
    verbiages,
    showPoweredByYuhu,
    heroImageUrl,
    featureSet,
    defaultLanguages,
    countryCodes,
    greetingNameType,
    googleTagId,
    landlordCountry,
  };
}

export function setLandlordPropertyTypes(propertyTypes) {
  return {
    type: 'USER_TYPES::SET_PROPERTY_TYPE',
    propertyTypes,
  };
}

export function setGoogleTagId(googleTagId) {
  return {
    type: 'USER_TYPES::SET_GOOGLE_TAG_ID',
    googleTagId,
  };
}

export function setAccentColor(accentColor) {
  return {
    type: 'USER_TYPES::SET_ACCENT_COLOR',
    accentColor,
  };
}

export function setLandlordLogo(landlord_logo_url, landlord_logo_thumb_url) {
  return {
    type: 'USER_TYPES::SET_LANDLORD_LOGO',
    landlordLogoUrl: landlord_logo_url,
    landlordLogoThumbUrl: landlord_logo_thumb_url,
  };
}

export function setDefaultLanguages(default_languages) {
  return {
    type: 'USER_TYPES::SET_DEFAULT_LANGUAGES',
    defaultLanguages: default_languages,
  };
}

export function hideExpiringPasswordDialog() {
  return {
    type: 'USER_TYPES::HIDE_EXPIRING_PASSWORD_DIALOG',
  };
}

export function hideInitialLeadDialog() {
  return {
    type: 'USER_TYPES::HIDE_INITIAL_LEAD_DIALOG',
  };
}

export function getLandlordDetails() {
  if (constants.SUBDOMAIN === window.DEFAULT_ENV_SUBDOMAIN || constants.SUBDOMAIN === 'app') {
    return { type: 'USER_TYPES::LOADED_APP_SUBDOMAIN' };
  }

  return (dispatch) =>
    dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/domains/${constants.SUBDOMAIN}`)
        .done((response) => {
          const {
            name,
            accent_color,
            logo_url,
            id,
            landlord_sso_enabled,
            uuid,
            property_types,
            logo_thumb_url,
            sso_providers,
            verbiages,
            show_powered_by_yuhu,
            hero_image_url,
            feature_set,
            landlord_subdomain,
            is_property_subdomain,
            default_languages,
            country_codes,
            greeting_name_type,
            google_tag_id,
            landlord_country,
          } = response.landlord_details;

          // If user in an agent, and they are going to a property specific subdomain
          const isAgent = browserHistory.getCurrentLocation().pathname.startsWith('/companies');
          if (isAgent && is_property_subdomain && constants.SUBDOMAIN !== landlord_subdomain) {
            window.location.replace(
              `${window.location.protocol}//${landlord_subdomain}.${window.CLIENT_URL}/companies`
            );
          }

          return dispatch(
            setLandlordDetails({
              landlordName: name,
              id,
              accentColor: accent_color,
              landlordLogoUrl: logo_url,
              landlordSSOEnabled: landlord_sso_enabled,
              uuid,
              propertyTypes: property_types,
              landlordLogoThumbUrl: logo_thumb_url,
              sso_providers: sso_providers,
              verbiages: verbiages,
              showPoweredByYuhu: show_powered_by_yuhu,
              heroImageUrl: hero_image_url,
              featureSet: feature_set,
              defaultLanguages: default_languages,
              countryCodes: country_codes,
              greetingNameType: greeting_name_type,
              googleTagId: google_tag_id,
              landlordCountry: landlord_country,
            })
          );
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
          if (error && error.status === 404) {
            // We were not able to verify a landlord with this subdomain so we should
            // redirect the user to a 404 page
            window.location.replace(
              `${window.location.protocol}//${window.CLIENT_URL}/notfound-${constants.SUBDOMAIN}`
            );
          }
        }),
    });
}

export function setGlobalFilter(portfolioId) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/users/global_filter/${portfolioId}`)
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyUpdated'));
          window.location.reload();
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}
