export default function reducer(
  state = {
    documents: null,
    document: null,
    statistics: null,
    logos: null,
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_API_DASHBOARD::SHOW_DOCUMENTS': {
      return { ...state, documents: action.documents, statistics: action.statistics };
    }
    case 'LANDLORD_API_DASHBOARD::SHOW_DOCUMENT_INFORMATION': {
      return { ...state, document: action.document };
    }

    case 'LANDLORD_API_DASHBOARD::SHOW_LOGOS': {
      return { ...state, logos: action.logos };
    }
    default:
      return state;
  }
}
