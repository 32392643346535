import { FETCH_PROMOTION } from './actionTypes';

const initialState = {
  isFetching: false,
  promotion: {},
};

function doReceivePromotion(state, action) {
  const promotionData = action.payload.promotion && action.payload.promotion.data.attributes;
  return { ...state, isFetching: false, promotion: promotionData };
}

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROMOTION}_PENDING`:
      return { ...state, isFetching: true };
    case `${FETCH_PROMOTION}_REJECTED`:
      return { ...state, isFetching: false };
    case `${FETCH_PROMOTION}_FULFILLED`:
      return doReceivePromotion(state, action);

    default:
      return state;
  }
};

export default promotionsReducer;
