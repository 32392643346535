export default function reducer(
  state = {
    isOn: false,
    time: 0,
    showSaveJob: false,
    showJobOptions: false,
    showContractorDialog: false,
  },
  action
) {
  switch (action.type) {
    case 'START_TIMER': {
      return {
        ...state,
        isOn: true,
        interval: 1,
        showSaveJob: true,
      };
    }
    case 'SET_START_TIME': {
      return {
        ...state,
        time: action.time,
        offset: Date.now(),
      };
    }
    case 'REQUEST_CONTRACTOR': {
      return {
        ...state,
        isOn: false,
        time: state.time,
        showContractorDialog: true,
      };
    }
    case 'CLOSE_CONTRACTOR_REQUEST': {
      return {
        ...state,
        isOn: false,
        time: state.time,
        interval: null,
        showContractorDialog: false,
      };
    }
    case 'STOP_OR_PAUSE_JOB': {
      return {
        ...state,
        isOn: false,
        time: state.time,
        showJobOptions: true,
      };
    }
    case 'CLOSE_JOB_DIALOG': {
      return {
        ...state,
        isOn: false,
        time: state.time,
        interval: null,
        showJobOptions: false,
      };
    }
    case 'COMPLETE_JOB': {
      return {
        isOn: false,
        time: 0,
        interval: null,
        showJobOptions: false,
        showSaveJob: false,
      };
    }
    case 'STOP_TIMER': {
      return {
        ...state,
        isOn: false,
        time: state.time,
        interval: null,
      };
    }
    case 'TICK': {
      return {
        ...state,
        time: state.time + (action.time - state.offset),
        offset: action.time,
        interval: 1,
      };
    }
    case 'CLEAR': {
      return {
        time: 0,
        isOn: false,
        interval: null,
        showSaveJob: false,
      };
    }
    default:
      return state;
  }
}
