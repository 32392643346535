export default function reducer(
  state = {
    showCotenantsForm: false,
    showOtherOccupantsForm: false,
    isStudent: false,
    previousOccupation: false,
    isSending: false,
    landlordReference: false,
    showProofOfIncomeUpload: false,
    showIdentificationUpload: false,
    applicationAvailable: true,
    uploadedFileErrorsArray: [],
    uploadError: false,
    appFeePaymentOpen: false,
    createdApplicationID: null,
    tenant_application: {},
    block_instances: 0
  },
  action
) {
  switch (action.type) {
    case 'STORE_APPLICATION_ID': {
      return { ...state, createdApplicationID: action.id };
    }
    case 'TOGGLE_APPLICATION_FEE_PAYMENT_MODAL': {
      return { ...state, appFeePaymentOpen: action.isOpen };
    }
    case 'SHOW_COTENANTS_FORM': {
      return { ...state, showCotenantsForm: true };
    }
    case 'HIDE_COTENANTS_FORM': {
      return { ...state, showCotenantsForm: false };
    }
    case 'SHOW_PETS_FORM': {
      return { ...state, showPetsForm: true };
    }
    case 'HIDE_PETS_FORM': {
      return { ...state, showPetsForm: false };
    }
    case 'SHOW_VEHICLES_FORM': {
      return { ...state, showVehiclesForm: true };
    }
    case 'HIDE_VEHICLES_FORM': {
      return { ...state, showVehiclesForm: false };
    }
    case 'SHOW_OTHER_OCCUPANTS_FORM': {
      return { ...state, showOtherOccupantsForm: true };
    }
    case 'HIDE_OTHER_OCCUPANTS_FORM': {
      return { ...state, showOtherOccupantsForm: false };
    }
    case 'SHOW_STUDENT_FORM': {
      return { ...state, isStudent: true };
    }
    case 'SHOW_OCCUPATION_FORM': {
      return { ...state, isStudent: false };
    }
    case 'SHOW_PREVIOUS_OCCUPATION_FORM': {
      return { ...state, previousOccupation: true };
    }
    case 'HIDE_PREVIOUS_OCCUPATION_FORM': {
      return { ...state, previousOccupation: false };
    }
    case 'SHOW_LANDLORD_REFERENCE_FORM': {
      return { ...state, landlordReference: true };
    }
    case 'HIDE_LANDLORD_REFERENCE_FORM': {
      return { ...state, landlordReference: false };
    }
    case 'SHOW_APPLICATION_SENDING': {
      return { ...state, isSending: true };
    }
    case 'SHOW_APPLICATION_DONE_SENDING': {
      return { ...state, isSending: false };
    }
    case 'POPULATE_TENANT_APPLICATION': {
      return {
        ...state,
        tenantApplication: action.tenantApplication,
        applicationAvailable: true,
      };
    }
    case 'APPLICATION_NO_LONGER_AVAILABLE': {
      return { ...state, applicationAvailable: action.applicationAvailable };
    }
    case 'SHOW_PROOF_OF_INCOME_UPLOAD': {
      return { ...state, showProofOfIncomeUpload: true };
    }
    case 'SHOW_IDENTIFICATION_UPLOAD': {
      return { ...state, showIdentificationUpload: true };
    }
    case 'UPLOAD_ERRORS': {
      return {
        ...state,
        uploadError: true,
        uploadedFileErrorsArray: action.uploadedFileErrorsArray,
      };
    }
    case 'TENANT_APPLICATION':
      return { ...state, tenant_application: action.tenant_application };
    case 'BLOCK_INSTANCES':
      return { ...state, block_instances: action.block_instances };
    default:
      return state;
  }
}
