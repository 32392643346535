import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Select, DatePicker, Dropdown } from 'antd';

import ConfigLoader from './components/ConfigLoader';
const Home = React.lazy(() => import('./Home'));

/*
  Set default values for all dropdown-like components:
  The `getPopupContainer` prop allows us to position the menu or panel element in another part of the document.
  By default each one of these components renders the panel inside `<body>`, but if we have nested scrolled areas, we need to:
    1. Render the dropdown's panel closer to the trigger (the button that opens the panel) and inside the scrolling component.
    2. Set `position: relative` to the scrolling component.
  The custom `getPopupContainer` function below tries to catch all the common scenarios in our application's UI:
    * It tries to render on the parent element of the trigger (the most common case).
    * Fallbacks to the default value, which is `<body />`.
  For more information, see:
    * API Documentation: https://4x.ant.design/components/select/#API
    * Example provided by Ant: https://codesandbox.io/s/4j168r7jw0
*/
const getPopupContainer = (node) => node.parentElement || document.body;

Select.defaultProps ||= {};
Select.defaultProps.getPopupContainer = getPopupContainer;
DatePicker.defaultProps ||= {};
DatePicker.defaultProps.getPopupContainer = getPopupContainer;
Dropdown.defaultProps ||= {};
Dropdown.defaultProps.getPopupContainer = getPopupContainer;

class App extends Component {
  render() {
    return (
      <ConfigLoader
        onLoad={() => {
          return (
            <Suspense fallback={<div></div>}>
              <Home />
            </Suspense>
          );
        }}
      />
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
