export default function reducer(
  state = {
    payments: [],
    deposits: [],
    tenants: [],
    csv_url: null,
    paymentInfoDialogIsOpen: false,
    paymentData: { historic_payments: [], payment_details: [], tenants: [] },
    interestPeriods: [],
    depositDetails: {
      historic_payments: [],
      payment_details: [],
      tenants: [],
      interest_errors: [],
    },
    fetchingPayments: false,
    paymentsCount: {},
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_PAYMENTS::SHOW_INTEREST_CALCULATION_RANGES': {
      return { ...state, interestPeriods: action.interestPeriods };
    }
    case 'LANDLORD_RENT_ROLL::FETCH_PAYMENTS_PENDING': {
      return { ...state, fetchingPayments: true };
    }
    case 'LANDLORD_PAYMENTS::SHOW_PAYMENTS': {
      if (state.paymentsLastFetched > action.timestamp) return state;
      return {
        ...state,
        payments: [...state.payments.slice(0, action.offset), ...action.payments],
        paymentsLastFetched: action.timestamp,
        fetchingPayments: false,
        offset: action.offset,
      };
    }
    case 'LANDLORD_PAYMENTS::SAVE_PAYMENT_STATS': {
      return { ...state, paymentsCount: action.payload };
    }
    case 'LANDLORD_PAYMENTS::SHOW_DEPOSITS': {
      return { ...state, deposits: action.deposits, csv_url: action.csv_url };
    }
    case 'LANDLORD_PAYMENTS::SHOW_PAYMENTS_INFO_DIALOG': {
      return {
        ...state,
        paymentInfoDialogIsOpen: action.isOpen,
        paymentData: action.paymentDetails,
      };
    }
    case 'LANDLORD_PAYMENTS::SAVE_PAYMENT_DETAILS': {
      return {
        ...state,
        depositDetails: action.depositDetails,
      };
    }
    case 'LANDLORD_PAYMENTS::GET_TENANT_LIST': {
      return { ...state, tenants: action.tenants };
    }
    default:
      return state;
  }
}
