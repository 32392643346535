import { useState, useEffect } from 'react';
import { load } from './config';

const ConfigLoader = ({ loading, onLoad }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [deployConfig, setConfig] = useState(null);

  useEffect(() => {
    load().then((newDeployConfig) => {
      setIsLoaded(true);
      setConfig(newDeployConfig);
    });
  }, []);

  if (!isLoaded) {
    return loading ? loading() : null;
  }

  return onLoad(deployConfig);
};

export default ConfigLoader;
