const BASE_ACTION_TYPE = 'LANDLORD::MAINTENANCE::PROJECTS';

export const FETCH_PROJECT = `${BASE_ACTION_TYPE}::FETCH_PROJECT`;
export const UPDATE_PROJECT = `${BASE_ACTION_TYPE}::UPDATE_PROJECT`;
export const FETCH_ALL_PROJECTS = `${BASE_ACTION_TYPE}::FETCH_ALL_PROJECTS`;
export const FILTER_PROJECTS = `${BASE_ACTION_TYPE}::FILTER_PROJECTS`;
export const FETCH_PROJECT_STATS = `${BASE_ACTION_TYPE}::FETCH_PROJECTS_STATS`;
export const SHOW_FILTERED_PROJECTS = `${BASE_ACTION_TYPE}::SHOW_FILTERED_PROJECTS`;
export const CLEAR_SELECTED_PROJECT = `${BASE_ACTION_TYPE}::CLEAR_SELECTED_PROJECT`;

export const FETCH_PROJECT_FILTERED_PURCHASE_ORDERS = `${BASE_ACTION_TYPE}::FETCH_PROJECT_FILTERED_PURCHASE_ORDERS`;
export const STORE_PROJECT_PURCHASE_ORDERS = `${BASE_ACTION_TYPE}::STORE_PROJECT_PURCHASE_ORDERS`;
export const LINK_PURCHASE_ORDER_TO_PROJECT = `${BASE_ACTION_TYPE}::LINK_PURCHASE_ORDER_TO_PROJECT`;
export const UNLINK_PURCHASE_ORDER_FROM_PROJECT = `${BASE_ACTION_TYPE}::UNLINK_PURCHASE_ORDER_FROM_PROJECT`;

export const FETCH_FILTERED_TICKETS = `${BASE_ACTION_TYPE}::FETCH_FILTERED_TICKETS`;
export const SHOW_FILTERED_TICKETS = `${BASE_ACTION_TYPE}::SHOW_FILTERED_TICKETS`;
export const LINK_TICKET_TO_PROJECT = `${BASE_ACTION_TYPE}::LINK_TICKET_TO_PROJECT`;
export const UNLINK_TICKET_FROM_PROJECT = `${BASE_ACTION_TYPE}::UNLINK_TICKET_FROM_PROJECT`;
export const REMOVE_UNLINKED_TICKET_FROM_PROJECT = `${BASE_ACTION_TYPE}::REMOVE_UNLINKED_TICKET_FROM_PROJECT`;
