export default function reducer(
  state = {
    stepIndex: 0,
    property_name: 'loading',
    document_id: null,
    signer_id: null,
    application_id: null,
  },
  action
) {
  switch (action.type) {
    case 'GET_APPLICATION_STATUS_FULFILLED': {
      const appStatus = Array.isArray(action.payload) ? action.payload[0] : action.payload;
      return {
        ...state,
        ...appStatus,
      };
    }
    default:
      return state;
  }
}
