export default function reducer(
  state = {
    notices: [],
    noticeDetails: {},
    delivery_report: [],
    notice_templates: [],
    noticesCounts: {},
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_ANNOUNCEMENTS::SHOW_ANNOUNCEMENTS': {
      return {
        ...state,
        notices: [...state.notices.slice(0, action.offset), ...action.notices],
      };
    }
    case 'LANDLORD_NOTICES::SHOW_DELIVERY_REPORTS': {
      return { ...state, delivery_report: action.delivery_report };
    }
    case 'LANDLORD_NOTICES::SAVE_NOTICE_DETAILS': {
      return { ...state, noticeDetails: action.noticeDetails };
    }
    case 'LANDLORD_NOTICES::SHOW_NOTICE_TEMPLATES': {
      return { ...state, notice_templates: action.notice_templates };
    }
    case 'LANDLORD_NOTICES::SAVE_NOTICES_STATS': {
      return { ...state, noticesCounts: action.payload };
    }
    default:
      return state;
  }
}
