import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Paper from 'material-ui/Paper';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { MdAddAPhoto as AddAPhoto } from 'react-icons/md';
import { RenderError } from 'components/shared/RenderError';
import { colors } from 'helpers/ColorHelper';
import { getTranslatedString } from 'helpers/i18n';
import styled from 'styled-components';
import { media } from 'helpers/MediaHelper';

const StyledFileSizeErrorContainer = styled.div`
  padding: 0px 60px;
  color: ${colors.RED};
  ${media.mobile`
    padding: 0px;
  `};
`;

export default class ReduxDropzone extends Component {
  state = { showFileSizeError: false, showFileLimitError: false };

  callOnChange = (newResult) => {
    if (this.props.fileChanged) {
      this.props.fileChanged(newResult);
    }
  };

  deleteFile = () => {
    this.props.input.onChange(null);
    this.callOnChange('removed');
  };

  showFiles = (files) => {
    if (this.props.dontShowFiles || !files) return;

    return (
      <ul style={{ padding: '20px 0 0 20px', textAlign: 'center' }}>
        {Array.isArray(files) &&
          files.map((file, i) => (
            <li key={i}>
              {file.name}
              {!this.props.cannotModify && (
                <button type="button" className="link-button" onClick={this.deleteFile}>
                  <DeleteIcon size={22} />
                </button>
              )}
            </li>
          ))}
        {!Array.isArray(files) && this.props.viewFileLink && (
          <li>
            <a href={files} target="_blank" rel="noopener noreferrer">
              {getTranslatedString('dropzone.viewFile')}
            </a>
            {!this.props.cannotModify && (
              <button type="button" className="link-button" onClick={this.deleteFile}>
                <DeleteIcon size={22} />
              </button>
            )}
          </li>
        )}
      </ul>
    );
  };

  render() {
    const { input } = this.props;
    const files = input.value;

    let backgroundImage = files;
    if (Array.isArray(files) && files.length > 0) {
      backgroundImage = files[0].preview;
    }

    const styles = {
      avatar: {
        width: this.props.size || '100%',
        height: this.props.size || '250px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url("${backgroundImage}")`,
        boxShadow: 'None',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'column',
      },
      photoIcon: {
        fontSize: '34px',
        display: 'block',
        color: colors.ACCENT_GRAY,
        opacity: '0',
        margin: 'auto',
      },
    };

    let dropzoneStyle = this.props.style || {};

    let innerComponent;
    if (!this.props.children) {
      innerComponent = (
        <Paper circle={this.props.circle} style={styles.avatar} zDepth={1}>
          <AddAPhoto id="photo-icon" style={styles.photoIcon} />
          {this.props.labelText && !backgroundImage && <p>{this.props.labelText}</p>}
        </Paper>
      );
    } else {
      innerComponent = this.props.children;
    }

    const maxFileSize = this.props.maxFileSize ? this.props.maxFileSize : 20000000;

    return (
      <div>
        <Dropzone
          accept={this.props.fileValidation}
          name={this.props.name}
          onDrop={(filesToUpload, e) => {
            this.setState({ showFileSizeError: false });
            this.setState({ showFileLimitError: false });
            input.onChange(filesToUpload);
            this.callOnChange(filesToUpload);
          }}
          id="react-dropzone"
          style={dropzoneStyle}
          maxSize={maxFileSize}
          maxNumberOfFilesUpload={this.props.maxNumberOfFilesUpload}
          onDropRejected={(files) => {
            if (files.length > this.props.maxNumberOfFilesUpload) {
              this.setState({ showFileLimitError: true });
              return;
            }
            files.forEach((file) => {
              if (file.size >= maxFileSize) {
                this.setState({ showFileSizeError: true });
              }
            });
          }}
          disabled={this.props.disabled}
          multiple={this.props.maxNumberOfFilesUpload ? false : true}
        >
          {innerComponent}
        </Dropzone>

        {this.showFiles(files)}
        <RenderError meta={this.props.meta} hide={this.state.showFileSizeError} />
        {this._renderFileSizeError(this.props.fileSizeErrorMessage, this.props.fileSizeErrorIcon)}
        {this._renderFileLimitError(this.props.fileSizeLimitMessage)}
      </div>
    );
  }

  _renderFileLimitError = (fileLimitErrorMessage) => {
    if (!this.state.showFileLimitError) return null;

    const uploadedFileLimit = this.props.uploadedFileLimit;

    return (
      <StyledFileSizeErrorContainer>
        <p>
          {fileLimitErrorMessage
            ? fileLimitErrorMessage
            : getTranslatedString('dropzone.tooManyFiles', {
                uploadedFileLimit: uploadedFileLimit,
              })}
        </p>
      </StyledFileSizeErrorContainer>
    );
  };

  _renderFileSizeError = (fileSizeErrorMessage, fileSizeErrorIcon) => {
    if (!this.state.showFileSizeError) return null;

    const maxFileSize = this.props.maxFileSize ? this.props.maxFileSize / 1000000 : 20;
    return (
      <StyledFileSizeErrorContainer>
        {fileSizeErrorIcon ? (
          <img alt="" src={fileSizeErrorIcon} style={{ marginBottom: '10px' }} />
        ) : null}
        <p>
          {fileSizeErrorMessage
            ? fileSizeErrorMessage
            : getTranslatedString('dropzone.fileIsTooLarge', {
                maxFileSize: maxFileSize,
              })}
        </p>
      </StyledFileSizeErrorContainer>
    );
  };
}
