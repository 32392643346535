import Auth from 'j-toker';
import { apiPut, apiPost } from '../helpers/ApiHelper';
import * as notifications from './notificationActions';

export function toggleAuthenticationDialog(isOpen = true) {
  return {
    type: 'AUTHENTICATION::TOGGLE_LOGIN_DIALOG',
    showLoginForm: false,
    showLoginDialog: isOpen,
  };
}

export function logUserOut(options) {
  localStorage.removeItem('authHeaders');
  localStorage.removeItem('currentConfigName');

  let actionType = 'AUTHENTICATION::LOG_USER_OUT';
  let showPage = false;
  let showLoginForm = true;
  let showLoginDialog = false;

  if (options && options.showDialog) {
    actionType = 'AUTHENTICATION::DIALOG_LOG_OUT';
    showPage = true;
    showLoginForm = false;
    showLoginDialog = true;
  }
  return {
    type: actionType,
    showPage,
    showLoginForm,
    showLoginDialog,
  };
}

export function logUserIn() {
  return {
    type: 'LOG_USER_IN',
  };
}

export function showNonAuthPage() {
  return {
    type: 'SHOW_NON_AUTH_PAGE',
  };
}

export function showApplicationPage() {
  return {
    type: 'SHOW_APPLICATION_PAGE',
  };
}

export function showSignaturePage() {
  return {
    type: 'SHOW_SIGNATURE_PAGE',
  };
}

export function setLandlordLoginParams() {
  return {
    type: 'SET_LANDLORD_LOGIN_PARAMS',
  };
}

export function setMaintenanceLoginParams() {
  return {
    type: 'SET_MAINTENANCE_LOGIN_PARAMS',
  };
}

export function showLoginError(code, data) {
  return {
    type: 'SHOW_LOGIN_ERROR',
    loginErrorCode: code,
    data,
  };
}

export function postLanguage(data) {
  let formData = { language_code: data };
  return dispatch => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + '/user/preferences/language', formData)
        .done(response => {
          window.location.reload();
        })
        .catch(error => {
          console.error('request failed', error);
        }),
    });
  };
}

export function setRequireTwoFactor(verificationData) {
  return {
    type: 'REQUIRE_TWO_FACTOR_AUTH::NEED_VERIFICATION',
    verificationRequired: true,
    verificationStep: verificationData.error,
    previewMobileNumber: verificationData.preview_mobile_number,
    errorCode: verificationData.error_code || '',
    hasPhoneNumber: verificationData.has_phone_number,
  };
}

export function setRequireTwoFactorVerified() {
  return {
    type: 'REQUIRE_TWO_FACTOR_AUTH::VERIFIED',
  };
}

export function setRequireTwoFactorLoading() {
  return {
    type: 'REQUIRE_TWO_FACTOR_AUTH::LOADING',
  };
}

export function setRequireTwoFactorError() {
  return {
    type: 'REQUIRE_TWO_FACTOR_AUTH::ERROR',
  };
}

export function twoFactorAuth(formData, onSuccessfulVerification) {
  return dispatch => {
    dispatch(setRequireTwoFactorLoading());
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + '/user/two_factor_auth', formData)
        .done(() => {
          dispatch(setRequireTwoFactorVerified());
          onSuccessfulVerification();
        })
        .catch(error => {
          if (error.status === 403) {
            dispatch(setRequireTwoFactor(error.responseJSON));
          } else {
            dispatch(notifications.showFailure(error));
            dispatch(setRequireTwoFactorError());
          }
        }),
    });
  };
}
