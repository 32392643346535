export default function reducer(
  state = {
    rentRollCounts: {},
    showNewLeadDialog: false,
    showEditLeadDialog: false,
    leaseId: null,
    leaseDetails: null,
    scheduleAs: [],
    showOptionsMenu: false,
    showUnitOptions: false,
    showLeadOptions: false,
    showPaymentsLedgerDialog: false,
    showDocuments: false,
    showLoadingDocuments: false,
    displayMode: null,
    documents: [],
    anchorEl: null,
    downloadURL: null,
    documentToSign: null,
    pageNumber: 0,
    backToReview: false,
    showN1NextQuestion: false,
    showLastBillableDay: false,
    showPaymentPreference: false,
    showPrePayment: false,
    showLeaseNotesDialog: false,
    prePayments: [],
    lease_note: null,
    showManualRenewalDialog: false,
    documentUploaderDialogOpen: false,
    shouldFreeze: false,
    unitId: null,
    unitPayments: [],
    leadId: null,
    leadNotInvited: false,
    leadDetails: [],
    is_in_person_signing: null,
    tenantDetails: {},
    tenantNotes: [],
    activeLeaseId: null,
    applicationId: null,
    showInviteTenant: false,
    propertyId: null,
    rentRoll: [],
    tenants: [],
    fetchingTenants: false,
    fetchingRentRoll: false,
    tenantsCounts: {},
    tenantsLastFetched: 0,
    padDetail: null,
    searchTotalResultsCount: null,
    updateTenantActivityRating: false,
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_TENANTS::FETCH_TENANTS_PENDING': {
      return { ...state, fetchingTenants: true };
    }
    case 'LANDLORD_RENT_ROLL::FETCH_RENT_ROLL_PENDING': {
      return { ...state, fetchingRentRoll: true };
    }
    case 'LANDLORD_TENANTS::SAVE_TENANTS_STATS': {
      return { ...state, tenantsCounts: action.payload };
    }
    case 'LANDLORD_TENANTS::SAVE_TENANT_RATING': {
      return { ...state, updateTenantActivityRating: true };
    }
    case 'LANDLORD_TENANTS::ACTIVITY_RATINGS_UPDATED': {
      return { ...state, updateTenantActivityRating: false };
    }
    case 'LANDLORD_RENT_ROLL::SAVE_RENT_ROLL_STATS': {
      return { ...state, rentRollCounts: action.payload };
    }
    case 'LANDLORD_TENANTS::SHOW_PAD_DETAIL': {
      return { ...state, padDetail: action.padDetail };
    }
    case 'LANDLORD_TENANTS::SHOW_TENANT_NOTES': {
      return { ...state, tenantNotes: action.tenantNotes };
    }
    case 'LANDLORD_TENANTS::DOCUMENT_UPLOADER': {
      return { ...state, documentUploaderDialogOpen: action.documentUploaderDialogOpen };
    }
    case 'LANDLORD_TENANTS::CLEAR_TENANT_NOTES': {
      return { ...state, tenantNotes: [] };
    }
    case 'LANDLORD_TENANTS::SHOW_TENANT': {
      return { ...state, tenantDetails: action.tenantDetails };
    }
    case 'LANDLORD_TENANTS::SHOW_TENANTS': {
      if (state.tenantsLastFetched > action.timestamp) return state;
      return {
        ...state,
        tenants: [...state.tenants.slice(0, action.offset), ...action.tenants],
        tenantsLastFetched: action.timestamp,
        fetchingTenants: false,
        searchTotalResultsCount: action.searchTotalResultsCount,
      };
    }
    case 'LANDLORD_TENANTS::UNIT_PAYMENTS': {
      return { ...state, unitPayments: action.unitPayments };
    }
    case 'LANDLORD_TENANTS::SHOW_UNIT_PAYMENTS_DIALOG': {
      return { ...state, showPaymentsLedgerDialog: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::CLEAR_UNIT_OPTIONS': {
      return { ...state, showUnitOptions: false };
    }
    case 'LANDLORD_TENANTS::SAVE_UNIT_ID': {
      return {
        ...state,
        unitId: action.unitId,
        showUnitOptions: action.shouldShowUnitOptions,
      };
    }
    case 'LANDLORD_TENANTS::SAVE_LEAD_ID': {
      return {
        ...state,
        leadId: action.leadId,
        showLeadOptions: action.shouldShowLeadOptions,
        leadNotInvited: action.leadNotInvited,
        leadDetails: action.leadDetails,
      };
    }
    case 'LANDLORD_TENANTS::SHOW_MANUAL_RENEWAL_CREATOR': {
      return {
        ...state,
        showManualRenewalDialog: action.shouldShow,
        leaseDetails: action.leaseDetails,
      };
    }
    case 'LANDLORD_TENANTS::SHOW_LEASE_NOTES_FORM': {
      return { ...state, showLeaseNotesDialog: action.shouldShow };
    }
    case 'LANDLORD_RENT_ROLL::SHOW_RENTROLL': {
      let rentRoll = action.rentRoll;
      if (action.offset > 0) {
        rentRoll = [...state.rentRoll.slice(0, action.offset), ...action.rentRoll];
      }
      return {
        ...state,
        rentRoll: rentRoll,
        fetchingRentRoll: false,
        offset: action.offset,
      };
    }
    case 'LANDLORD_TENANTS::SHOW_NEW_LEAD_DIALOG': {
      return { ...state, showNewLeadDialog: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::SHOW_EDIT_LEAD_DIALOG': {
      return { ...state, showEditLeadDialog: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::SHOW_OPTIONS_MENU': {
      return {
        ...state,
        leaseId: action.leaseId,
        showOptionsMenu: action.shouldShow,
        anchorEl: action.anchorEl,
        propertyId: action.propertyId,
      };
    }
    case 'LANDLORD_TENANTS::SHOW_TENANT_OPTIONS_MENU': {
      return {
        ...state,
        cotenantId: action.cotenantId,
        leadId: action.leadId,
        showOptionsMenu: true,
        activeLeaseId: action.activeLeaseId,
        applicationId: action.applicationId,
        showInviteTenant: action.showInviteTenant,
        anchorEl: action.anchorEl,
      };
    }
    case 'LANDLORD_TENANTS::HIDE_TENANT_OPTIONS_MENU': {
      return {
        ...state,
        showOptionsMenu: false,
      };
    }
    case 'LANDLORD_TENANTS::FREEZE_TABLE': {
      return { ...state, shouldFreeze: action.shouldFreeze };
    }
    case 'LANDLORD_TENANTS::HIDE_OPTIONS_MENU': {
      return { ...state, showOptionsMenu: action.shouldShow, anchorEl: null };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_PAYMENT_PREFERENCE_MENU': {
      return { ...state, showPaymentPreference: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::SHOW_PRE_PAYMENT_FORM': {
      return { ...state, showPrePayment: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::OPEN_DOCUMENTS': {
      return {
        ...state,
        showDocuments: action.shouldShow,
        displayMode: action.displayMode,
        showLoadingDocuments: true,
        documents: [],
        propertyId: action.propertyId,
      };
    }
    case 'LANDLORD_TENANTS::SAVE_DOCUMENTS': {
      return { ...state, documents: action.documents, showLoadingDocuments: false };
    }
    case 'LANDLORD_TENANTS::LEASE_NOTE': {
      return { ...state, lease_note: action.lease_note };
    }
    case 'LANDLORD_TENANTS::PRE_PAYMENTS': {
      return { ...state, prePayments: action.prePayments };
    }
    case 'LANDLORD_TENANTS::SHOW_N1_NEXT_QUESTION': {
      if (!action.shouldShow) {
        state.leaseDetails.has_been_approved = false;
      }
      return { ...state, showN1NextQuestion: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::SHOW_LAST_BILLABLE_DAY': {
      return { ...state, showLastBillableDay: action.shouldShow };
    }
    case 'LANDLORD_TENANTS::DISPLAY_DOCUMENT': {
      return { ...state, documentToSign: action.documentToSign };
    }
    case 'LANDLORD_TENANTS::GO_TO_PAGE': {
      return {
        ...state,
        pageNumber: action.pageNumber,
        backToReview: state.pageNumber === 9,
      };
    }
    case 'LANDLORD_TENANTS::NEXT_PAGE': {
      let pageNumber = null;
      if (state.backToReview) {
        state.backToReview = false;
        pageNumber = 9;
      } else {
        pageNumber = state.pageNumber + 1;
      }
      return { ...state, pageNumber: pageNumber };
    }
    case 'LANDLORD_TENANTS::PREVIOUS_PAGE': {
      let pageNumber = null;
      if (state.backToReview) {
        state.backToReview = false;
        pageNumber = 9;
      } else {
        pageNumber = state.pageNumber - 1;
      }
      return { ...state, pageNumber: pageNumber };
    }
    case 'LANDLORD_TENANTS::SHOW_MOVE_OUT_CREATOR': {
      return {
        ...state,
        leaseDetails: action.leaseDetails,
        pageNumber: action.pageNumber,
      };
    }
    case 'LANDLORD_TENANTS::SHOW_RENEWAL_CREATOR': {
      return {
        ...state,
        leaseDetails: action.leaseDetails,
        scheduleAs: action.scheduleAs,
        documentToSign: null,
      };
    }
    case 'LANDLORD_TENANTS::UPDATE_IS_IN_PERSON_SIGNING': {
      return { ...state, is_in_person_signing: action.is_in_person_signing };
    }
    case 'LANDLORD::SAVE_LEASE_DETAILS': {
      return { ...state, leaseDetails: action.leaseDetails };
    }
    default:
      return state;
  }
}
