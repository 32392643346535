import { apiGet, apiPut } from 'helpers/ApiHelper';
import { redirectUrl } from 'helpers/UrlHelper';

import * as ByesignHelper from 'components/byesign/ByesignHelper';

import Auth from 'j-toker';
import { updateLanguage } from 'react-localize-redux';

export function getBrandingLogo(document_uuid) {
  return dispatch => {
    return apiGet(Auth.getApiUrl() + `/document_signing/branding_logo/${document_uuid}`)
      .done(response => {
        if (response.logo_url === null) {
          dispatch(saveBrandingLogo(ByesignHelper.constants.DEFAULT_LOGO));
        } else {
          dispatch(saveBrandingLogo(response.logo_url));
        }
      })
      .catch(error => {
        dispatch(signError());
      });
  };
}

export function saveBrandingLogo(logo_url) {
  return {
    type: 'DOCUMENT_PORTAL::SAVE_BRANDING_LOGO',
    logo_url,
  };
}

export function displayDocument(response) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_DOCUMENT',
    signatureDocument: response,
    shouldShow: true,
    currentSigner: response.signer,
  };
}

export function hideDocument() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_DOCUMENT',
    shouldShow: false,
    signatureDocument: null,
  };
}

export function openPadForSignature(id) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'SIGNATURE',
  };
}

export function openPadForInitials(id) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'INITIALS',
  };
}

export function openPadForTextBox(id) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'TEXTBOX',
  };
}

export function closePad() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_PAD',
    shouldShow: false,
  };
}

export function openTermsOfServiceDialog() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_TERMS_OF_SERVICE_DIALOG',
    shouldShow: true,
  };
}

export function closeTermsOfServiceDialog() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_TERMS_OF_SERVICE_DIALOG',
    shouldShow: false,
  };
}

export function openAgreementDialog() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_AGREEMENT_DIALOG',
    shouldShow: true,
  };
}

export function closeAgreementDialog() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_AGREEMENT_DIALOG',
    shouldShow: false,
  };
}

export function showSendingLoader(shouldShow) {
  return {
    type: 'DOCUMENT_PORTAL::IS_SENDING',
    isSending: shouldShow,
  };
}

export function enableSignButton(shouldEnable) {
  return {
    type: 'DOCUMENT_PORTAL::ENABLE_SIGN_BUTTON',
    shouldEnable: shouldEnable,
  };
}

export function captureSignature(id, url) {
  return {
    type: 'DOCUMENT_PORTAL::CAPTURE_SIGNATURE',
    signatureId: id,
    signatureUrl: url,
  };
}

export function signSuccess() {
  return {
    type: 'DOCUMENT_PORTAL::SIGN_SUCCESS',
  };
}

export function signError(errorResponse = null) {
  let assetName = ByesignHelper.constants.DEFAULT_ASSET_NAME;
  let documentStatus = ByesignHelper.status.UNKNOWN;
  let documentUrl, ownerName;

  if (errorResponse) {
    assetName = errorResponse.asset_name || assetName;
    documentStatus = errorResponse.document_status || documentStatus;
    documentUrl = errorResponse.document_url;
    ownerName = errorResponse.owner_name;
  }

  return {
    assetName,
    documentStatus,
    documentUrl,
    ownerName,
    type: 'DOCUMENT_PORTAL::SIGN_ERROR',
  };
}

export function openMenuForSignature(id, anchorEl) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_ACTIONS_MENU',
    signatureId: id,
    anchorEl: anchorEl,
    shouldShow: true,
    padType: 'SIGNATURE',
  };
}

export function openMenuForTextbox(id, anchorEl) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_ACTIONS_MENU',
    signatureId: id,
    anchorEl: anchorEl,
    shouldShow: true,
    padType: 'TEXTBOX',
  };
}

export function openMenuForInitials(id, anchorEl) {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_ACTIONS_MENU',
    signatureId: id,
    anchorEl: anchorEl,
    shouldShow: true,
    padType: 'INITIALS',
  };
}

export function closeMenu() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_ACTIONS_MENU',
    signatureId: null,
    anchorEl: null,
    shouldShow: false,
  };
}

export function deleteSignature(id) {
  return {
    type: 'DOCUMENT_PORTAL::DELETE_SIGNATURE',
    signatureId: id,
  };
}

export function nextAnchorId(justSigned = false) {
  return {
    type: 'DOCUMENT_PORTAL::NEXT_ANCHOR_ID',
    justSigned,
  };
}

export function getDocument(signer_id, document_id) {
  return dispatch => {
    return apiGet(
      Auth.getApiUrl() + `/document_signing/signers/${signer_id}/documents/${document_id}`
    )
      .done(response => {
        if (response.data.document.language_code) {
          dispatch(updateLanguage(response.data.document.language_code));
          localStorage.setItem('locale', response.data.document.language_code);
        }
        dispatch(displayDocument(response.data));
      })
      .catch(error => {
        dispatch(signError(error.responseJSON.error));
      });
  };
}

export function putDocument(data) {
  return dispatch => {
    return apiPut(
      Auth.getApiUrl() +
        `/document_signing/signers/${data.signer_uuid}/documents/${data.document_uuid}`,
      data
    )
      .done(response => {
        dispatch(showSendingLoader(false));
        dispatch(hideDocument());
        dispatch(enableSignButton(false));
        dispatch(signSuccess());
        redirectUrl(ByesignHelper.constants.SUCCESS, ByesignHelper.constants.REDIRECT_TIMEOUT_MS);
      })
      .catch(error => {
        window.Rollbar.error('Document Portal Signing Error', error);
        dispatch(signError(error.responseJSON.error));
        dispatch(showSendingLoader(false));
        redirectUrl(ByesignHelper.constants.CANCELLED, ByesignHelper.constants.REDIRECT_TIMEOUT_MS);
      });
  };
}

export function clearSignatures() {
  return {
    type: 'DOCUMENT_PORTAL::CLEAR_SIGNATURES',
  };
}

export function displayPreviousSignatureImage() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_PREVIOUS_SIGNATURE_IMAGE',
    shouldShow: true,
  };
}

export function hidePreviousSignatureImage() {
  return {
    type: 'DOCUMENT_PORTAL::SHOW_PREVIOUS_SIGNATURE_IMAGE',
    shouldShow: false,
  };
}

export const updateToggleSignatureTextMode = value => ({
  type: 'DOCUMENT_PORTAL::UPDATE_TOGGLE_SIGNATURE_TEXT_MODE',
  toggleSignatureTextMode: value,
});

export const updateSignatureTextValue = value => ({
  type: 'DOCUMENT_PORTAL::UPDATE_SIGNATURE_TEXT_VALUE',
  signatureTextValue: value,
});
