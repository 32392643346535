const initialState = {
  responses: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'INVITE_LEAD_FULFILLED': {
      if (!action.payload) return state;
      return {
        ...state,
        responses: state.responses.concat({
          key: 'invite-lead',
          data: action.payload.invitation_link,
        }),
      };
    }
    case 'REMIND_APPLICANTS_FULFILLED': {
      if (!action.payload) return state;
      return {
        ...state,
        responses: state.responses.concat({
          key: 'remind-applicants',
          data: action.payload.invitation_links,
        }),
      };
    }
    default:
      return state;
  }
}
