export default function reducer(
  state = {
    purchaseOrders: [],
    fetchingPurchaseOrders: false,
    purchaseOrderStats: {},
    fetchingPurchaseOrderStats: false,
    purchaseOrder: {},
    fetchingPurchaseOrder: false,
    vendors: [],
    fetchingPurchaseOrderVendors: false,
    creatingPurchaseOrder: false,
    updatingPurchaseOrder: false,
    fetchingPurchaseOrderGlAccounts: false,
    glAccounts: [],
    fetchingPurchaseOrderExpenseTypes: false,
    expenseTypes: [],
    approvingPurchaseOrder: false,
    denyingPurchaseOrder: false,
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDERS_PENDING': {
      return { ...state, fetchingPurchaseOrders: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::SHOW_PURCHASE_ORDERS': {
      const fetchedPurchaseOrders = action.purchaseOrdersResponse.data.map(data => data.attributes);

      return {
        ...state,
        fetchingPurchaseOrders: false,
        purchaseOrders: [...state.purchaseOrders.slice(0, action.offset), ...fetchedPurchaseOrders],
      };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDERS_REJECTED': {
      return { ...state, fetchingPurchaseOrders: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_STATS_PENDING': {
      return { ...state, fetchingPurchaseOrderStats: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_STATS_FULFILLED': {
      return {
        ...state,
        fetchingPurchaseOrderStats: false,
        purchaseOrderStats: action.payload.purchase_order_status_counts,
      };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_STATS_REJECTED': {
      return { ...state, fetchingPurchaseOrderStats: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_PENDING': {
      return { ...state, fetchingPurchaseOrder: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_FULFILLED': {
      return {
        ...state,
        fetchingPurchaseOrder: false,
        purchaseOrder: action.payload && action.payload.purchase_order,
      };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_REJECTED': {
      return { ...state, fetchingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_VENDORS_PENDING': {
      return { ...state, fetchingPurchaseOrderVendors: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_VENDORS_FULFILLED': {
      return {
        ...state,
        fetchingPurchaseOrderVendors: false,
        vendors: action.payload.vendors,
      };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_VENDORS_REJECTED': {
      return { ...state, fetchingPurchaseOrderVendors: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::CREATE_PURCHASE_ORDER_PENDING': {
      return { ...state, creatingPurchaseOrder: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::CREATE_PURCHASE_ORDER_FULFILLED': {
      return { ...state, creatingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::CREATE_PURCHASE_ORDER_REJECTED': {
      return { ...state, creatingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::UPDATE_PURCHASE_ORDER_PENDING': {
      return { ...state, updatingPurchaseOrder: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::UPDATE_PURCHASE_ORDER_FULFILLED': {
      return { ...state, updatingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::UPDATE_PURCHASE_ORDER_REJECTED': {
      return { ...state, updatingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::CREATE_PURCHASE_ORDER_COMMENT_PENDING': {
      return { ...state, creatingPurchaseOrderComment: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::CREATE_PURCHASE_ORDER_COMMENT_FULFILLED': {
      return { ...state, creatingPurchaseOrderComment: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::CREATE_PURCHASE_ORDER_COMMNENT_REJECTED': {
      return { ...state, creatingPurchaseOrderComment: false };
    }

    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_GL_ACCOUNTS_PENDING': {
      return { ...state, fetchingPurchaseOrderGlAccounts: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_GL_ACCOUNTS_FULFILLED': {
      return {
        ...state,
        fetchingPurchaseOrderGlAccounts: false,
        glAccounts: action.payload.gl_accounts,
      };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_GL_ACCOUNTS_REJECTED': {
      return { ...state, fetchingPurchaseOrderGlAccounts: false };
    }

    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_EXPENSE_TYPES_PENDING': {
      return { ...state, fetchingPurchaseOrderExpenseTypes: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_EXPENSE_TYPES_FULFILLED': {
      return {
        ...state,
        fetchingPurchaseOrderExpenseTypes: false,
        expenseTypes: action.payload.expense_types,
      };
    }
    case 'LANDLORD_PURCHASE_ORDERS::FETCH_PURCHASE_ORDER_EXPENSE_TYPES_REJECTED': {
      return { ...state, fetchingPurchaseOrderExpenseTypes: false };
    }

    case 'LANDLORD_PURCHASE_ORDERS::APPROVE_PURCHASE_ORDER_PENDING': {
      return { ...state, approvingPurchaseOrder: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::APPROVE_PURCHASE_ORDER_FULFILLED': {
      return { ...state, approvingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::APPROVE_PURCHASE_ORDER_REJECTED': {
      return { ...state, approvingPurchaseOrder: false };
    }

    case 'LANDLORD_PURCHASE_ORDERS::DENY_PURCHASE_ORDER_PENDING': {
      return { ...state, denyingPurchaseOrder: true };
    }
    case 'LANDLORD_PURCHASE_ORDERS::DENY_PURCHASE_ORDER_FULFILLED': {
      return { ...state, denyingPurchaseOrder: false };
    }
    case 'LANDLORD_PURCHASE_ORDERS::DENY_PURCHASE_ORDER_REJECTED': {
      return { ...state, denyingPurchaseOrder: false };
    }

    default:
      return state;
  }
}
