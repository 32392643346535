import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { SelectField, TextField } from 'redux-form-material-ui';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';

import landlordApplicationsReducer from 'reducers/landlordApplicationsReducer';
import Dropzone from 'react-dropzone';

import { AccentColor } from 'helpers/AccentColorHelper';

const validate = values => {
  const errors = {};

  if (!values.welcome_package && !values.welcome_package_name && !values.welcome_package_upload) {
    return errors;
  }

  if (!values.welcome_package && values.welcome_package_name && values.welcome_package_upload) {
    return errors;
  }

  if (!values.welcome_package && values.welcome_package_name) {
    errors.welcome_package_upload = 'Required';
  }

  if (!values.welcome_package && values.welcome_package_upload) {
    errors.welcome_package_name = 'Required';
  }

  if (values.welcome_package_name === 'None') {
    errors.welcome_package_name = 'Invalid Name';
  }

  return errors;
};

class renderDropzoneInput extends Component {
  state = {
    fileName: '',
  };

  updateFile = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };

  render() {
    const field = this.props;
    let dropzoneRef;

    const styles = {
      dropzoneBox: {
        height: '0px',
        width: '0px',
        float: 'none',
        display: 'block',
        margin: '15px auto 0px auto',
      },
      dropzoneButton: {
        width: '300px',
        margin: '25px auto 5px auto',
        display: 'block',
      },
    };

    return (
      <div>
        <Dropzone
          id="welcome_package_dropzone"
          style={styles.dropzoneBox}
          name={field.name}
          ref={node => {
            dropzoneRef = node;
          }}
          onDrop={filesToUpload => {
            const reader = new FileReader();
            reader.readAsDataURL(filesToUpload[0]);
            reader.addEventListener(
              'load',
              () => {
                field.input.onChange(reader.result);
                this.setState({ fileName: filesToUpload[0].name });
              },
              false
            );
          }}
        />
        <FlatButton
          primary={true}
          label="Click to add a file"
          style={styles.dropzoneButton}
          onClick={() => {
            dropzoneRef.open();
          }}
        />
        {field.meta.touched &&
          field.meta.error && (
            <span
              className="error"
              style={{
                color: AccentColor(),
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              {field.meta.error}
            </span>
          )}
        {<p>{this.state.fileName}</p>}
      </div>
    );
  }
}

let WelcomePackagePage = props => {
  const { handleSubmit, welcomePackages, change } = props;

  const welcomePackagesOptions = welcomePackages.map((welcomePackage, i) => (
    <MenuItem value={welcomePackage} primaryText={welcomePackage} key={i} />
  ));

  const handleChange = () => {
    change('welcome_package_name', null);
    change('welcome_package_upload', null);
  };

  const handleFileChange = () => {
    change('welcome_package', null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} id="form_field" encType="multipart/form-data">
        <h3 style={{ textAlign: 'center' }}> Welcome Package </h3>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>
          If you would like to send your tenant&rsquo;s a welcome package (such as general
          information, a welcome letter etc.) once they have successfully signed their lease, you
          can select it below.
        </div>
        <div className="row" style={{ marginTop: '15px', textAlign: 'center' }}>
          <div>
            <Field
              name="welcome_package"
              component={SelectField}
              style={{ fontSize: '14px', width: '300px', textAlign: 'left' }}
              floatingLabelText="Choose A Welcome Package"
              onChange={handleChange}
              floatingLabelStyle={{ paddingLeft: '60px' }}
              fullWidth
            >
              {welcomePackagesOptions}
            </Field>
          </div>
        </div>

        <div className="row" style={{ marginTop: '15px' }}>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <div style={{ fontSize: '14px' }}>
              Alternatively, you can upload a welcome package (pdf) below (we&rsquo;ll save it for
              next time!)
            </div>
          </div>
          <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-sm-12">
              <Field
                name={'welcome_package_name'}
                type="text"
                component={TextField}
                floatingLabelText="Welcome Package Name"
                onChange={handleFileChange}
                style={{ marginTop: '-15px', fontSize: '14px', width: '190px' }}
              />
            </div>
          </div>
          <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-sm-12">
              <Field
                type="file"
                name="welcome_package_upload"
                component={renderDropzoneInput}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

WelcomePackagePage = reduxForm({
  form: 'lease_creator_form', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WelcomePackagePage);

WelcomePackagePage = connect(
  state => ({
    initialValues: state.landlordApplications.leaseDetails, // pull initial values from account reducer
  }),
  { load: landlordApplicationsReducer } // bind account loading action creator
)(WelcomePackagePage);

export default WelcomePackagePage;
