import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { LeaseDocumentFormGroup } from 'components/landlord_app/applications/components/lease_creator/LeaseDocumentFormGroup';

const templateToValues = template =>
  template
    .map(leaseDoc => {
      return {
        [leaseDoc.type]: [],
      };
    })
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

const valuesToTemplate = (values, template) =>
  template
    .map(leaseDoc => {
      return {
        [leaseDoc.type]: values[leaseDoc.type] || [],
      };
    })
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    });

let LeaseDocumentsWizardPage = ({ reduxState, change, leaseDetails, leaseDocumentsFromForm }) => {
  const { lease_document_types } = leaseDetails;
  const [values, setValues] = useState(
    !isEmpty(leaseDocumentsFromForm)
      ? valuesToTemplate(leaseDocumentsFromForm, lease_document_types)
      : templateToValues(lease_document_types)
  );
  const handleSetValues = values => {
    setValues(values);
    change('lease_documents', values);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Additional Lease Details</h3>
      <form
        name="lease_documents"
        onSubmit={e => {
          e.preventDefault();
          change('lease_documents', values);
        }}
      >
        {lease_document_types.map((document, i) => {
          return (
            <LeaseDocumentFormGroup
              key={`LeaseDocumentFormGroups-${i}`}
              {...document}
              reduxState={reduxState}
              values={values}
              setValues={handleSetValues}
            />
          );
        })}
      </form>
    </div>
  );
};

LeaseDocumentsWizardPage = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(LeaseDocumentsWizardPage);

LeaseDocumentsWizardPage = connect((state, ownProps) => {
  return {
    form: ownProps.name,
    reduxState: state,
    leaseDetails: state.landlordApplications.leaseDetails || state.landlordTenants.leaseDetails,
    leaseDocumentsFromForm: state.form[ownProps.name].values.lease_documents,
  };
})(LeaseDocumentsWizardPage);

export default LeaseDocumentsWizardPage;
