export default function reducer(
  state = {
    reportData: [],
    reportDates: '',
    reportType: '',
    dateRange: {
      start_date: '',
      end_date: '',
    },
    showReportLoader: false,
    propertyIds: [],
    workflows: [],
    authenticatingInsights: false,
    authenticatingInsightsError: false,
    insightToken: null,
    insightKey: null,
    selectedDashboardId: null,
    fetchingInsightDashboards: false,
    insightDashboards: [],
  },
  action
) {
  switch (action.type) {
    case 'INSIGHTS::SAVE_REPORT': {
      return {
        ...state,
        reportData: action.reportData,
        dateRange: {
          start_date: action.startDate,
          end_date: action.endDate,
        },
        reportType: action.reportType,
        propertyIds: action.propertyIds,
      };
    }
    case 'INSIGHTS::SHOW_LOADER': {
      return {
        ...state,
        showReportLoader: action.showLoader,
      };
    }
    case 'INSIGHTS::SAVE_WORKFLOWS': {
      return {
        ...state,
        workflows: action.workflows,
      };
    }
    case 'INSIGHTS::AUTHENTICATE_INSIGHTS_PENDING': {
      return {
        ...state,
        authenticatingInsights: true,
        authenticatingInsightsError: false,
      };
    }
    case 'INSIGHTS::AUTHENTICATE_INSIGHTS_FULFILLED': {
      return {
        ...state,
        authenticatingInsights: false,
        authenticatingInsightsError: false,
        insightKey: action.payload.key,
        insightToken: action.payload.token,
        selectedDashboardId: action.payload.dashboard_id,
      };
    }
    case 'INSIGHTS::AUTHENTICATE_INSIGHTS_REJECTED': {
      return {
        ...state,
        authenticatingInsights: false,
        authenticatingInsightsError: true,
        insightToken: null,
        insightKey: null,
      };
    }
    case 'INSIGHTS::FETCH_INSIGHT_DASHBOARDS_PENDING': {
      return {
        ...state,
        fetchingInsightDashboards: true,
      };
    }
    case 'INSIGHTS::FETCH_INSIGHT_DASHBOARDS_FULFILLED': {
      return {
        ...state,
        fetchingInsightDashboards: false,
        insightDashboards: action.payload.dashboards,
      };
    }
    case 'INSIGHTS::FETCH_INSIGHT_DASHBOARDS_REJECTED': {
      return {
        ...state,
        fetchingInsightDashboards: false,
        insightDashboards: [],
      };
    }
    default:
      return state;
  }
}
