import React from 'react';
import { featureEnabled } from 'helpers/ToggleBot';

export const RenderIfToggleOn = ({ toggle, dontRenderIf, children }) => {
  if (dontRenderIf || !featureEnabled(toggle)) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export const RenderIfToggleOff = ({ toggle, dontRenderIf, children }) => {
  if (dontRenderIf || featureEnabled(toggle)) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
