export default function reducer(
  state = {
    showings: [],
    showingsCounts: {},
    fetchingShowings: false,
    showingDetails: {},
    showNewShowingDialog: false,
    showEditShowingDialog: false,
    propertyId: null,
    wizardPageNumber: 1,
    newShowingDetails: null,
    bookingHistory: [],
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_SHOWINGS::FETCH_SHOWINGS_PENDING': {
      return { ...state, fetchingShowings: true };
    }
    case 'LANDLORD_SHOWINGS::SAVE_SHOWINGS_STATS': {
      return { ...state, showingsCounts: action.payload };
    }
    case 'LANDLORD_SHOWINGS::SHOW_SHOWINGS': {
      if (state.showingsLastFetched > action.timestamp) return state;
      return {
        ...state,
        showings: [...state.showings.slice(0, action.offset), ...action.showings],
        showingsLastFetched: action.timestamp,
        fetchingShowings: false,
        offset: action.offset,
      };
    }
    case 'LANDLORD_SHOWINGS::SHOW_SHOWING': {
      return { ...state, showingDetails: action.showingDetails };
    }
    case 'SHOW_NEW_SHOWING_DIALOG': {
      return {
        ...state,
        showNewShowingDialog: action.shouldShow,
        propertyId: null,
        bookDate: null,
        bookTime: null,
        wizardPageNumber: 1,
      };
    }
    case 'SHOW_EDIT_SHOWING_DIALOG': {
      return {
        ...state,
        showEditShowingDialog: action.shouldShow,
        showingDetails: action.showingDetails,
      };
    }
    case 'UPDATE_PROPERTY_ID': {
      return { ...state, propertyId: action.id };
    }
    case 'UPDATE_WIZARD_PAGE_NUMBER': {
      return { ...state, wizardPageNumber: action.wizardPageNumber };
    }
    case 'UPDATE_NEW_SHOWING_DETAILS': {
      return { ...state, newShowingDetails: action.newShowingDetails };
    }
    case `FETCH_HISTORY`: {
      return { ...state, bookingHistory: action.bookingHistory };
    }
    default:
      return state;
  }
}
