import { FETCH_EVENT } from './actionTypes';

const initialState = {
  isFetching: false,
  site_event: [],
};

function doReceiveEvent(state, action) {
  const siteEventData = action.payload.site_event && action.payload.site_event.data.attributes;
  return { ...state, isFetching: false, site_event: siteEventData };
}

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_EVENT}_PENDING`:
      return { ...state, isFetching: true };
    case `${FETCH_EVENT}_REJECTED`:
      return { ...state, isFetching: false };
    case `${FETCH_EVENT}_FULFILLED`:
      return doReceiveEvent(state, action);

    default:
      return state;
  }
};

export default eventReducer;
