import store from 'store';
import { colors } from 'helpers/ColorHelper';
import { browserHistory } from 'react-router';
import { hostIsCapreit } from 'helpers/CurrentWindowLocation';

export function AccentColor() {
  let accentColor = store.getState().userTypes.accentColor;

  if (['qa-concertproperties.com', 'concertproperties.com'].includes(window.CLIENT_URL)) {
    accentColor = '#2c6786';
  }

  if (!hostIsCapreit() && browserHistory.getCurrentLocation().pathname.startsWith('/companies')) {
    accentColor = '#230cc2';
  }

  return accentColor || colors.RED;
}
