import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';

import RentalIncentiveFields from 'components/landlord_app/applications/components/common/RentalIncentiveFields';
import { getTranslatedString } from 'helpers/i18n';
import { featureEnabled } from 'helpers/ToggleBot';

let RentalIncentivePage = props => {
  const {
    handleSubmit,
    changeFieldValue,
    currentConcessionsOfUnit,
    selectedRentAmount,
    selectedConcessions,
  } = props;

  const onConcessionSelect = (value, index) => {
    const selectedConcession = props.currentConcessionsOfUnit.filter(
      concession => concession.id === value
    );

    let concessionAmount = selectedConcession[0].amount;
    if (selectedConcession[0].concession_type === 'percentage_amount') {
      // Turn the amount to a percentage by dividing by 100.0
      concessionAmount = selectedConcession[0].amount / 100.0 * selectedRentAmount;
    }

    changeFieldValue(`rental_incentives[${index}].incentive_amount`, concessionAmount);

    changeFieldValue(
      `rental_incentives[${index}].disableAmountSelection`,
      !selectedConcession[0].modifiable
    );
    changeFieldValue(`rental_incentives[${index}].term`, selectedConcession[0].term);
  };

  let selectedConcessionIds = [];
  if (selectedConcessions && selectedConcessions.length > 0) {
    selectedConcessionIds = selectedConcessions.map(concession => concession.incentive_id);
  }

  return (
    <div>
      <form onSubmit={handleSubmit} id="form_field">
        <h3 style={{ textAlign: 'center' }}>
          {getTranslatedString('landlordPortal.rentalIncentiveWizardPage.rentalIncentive')}
        </h3>
        {currentConcessionsOfUnit.length === 0 &&
          featureEnabled('ui.lease_creator.enable_concession_selection') && (
            <div style={{ textAlign: 'center' }}>
              <p>There are currently no incentives available for this property</p>
            </div>
          )}
        {(currentConcessionsOfUnit.length > 0 ||
          !featureEnabled('ui.lease_creator.enable_concession_selection')) && (
          <div>
            <div style={{ textAlign: 'center' }}>
              <p>{getTranslatedString('landlordPortal.rentalIncentiveWizardPage.instructions')}</p>
            </div>
            <div style={{ textAlign: 'center' }}>
              <FieldArray
                name="rental_incentives"
                component={RentalIncentiveFields}
                filteredConcessionIds={selectedConcessionIds}
                concessions={currentConcessionsOfUnit}
                onConcessionSelect={onConcessionSelect}
                rerenderOnEveryChange
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.name,
  };
};

RentalIncentivePage = compose(
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(RentalIncentivePage);

RentalIncentivePage = connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.name);
  return {
    initialValues: state.landlordApplications.leaseDetails,
    currentConcessionsOfUnit: state.landlordProperties.currentConcessionsOfUnit,
    selectedConcessions: selector(state, 'rental_incentives'),
  };
})(RentalIncentivePage);

export default RentalIncentivePage;
