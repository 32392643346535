import { combineReducers } from 'redux';
import { localeReducer } from 'react-localize-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as formReducer } from 'redux-form';

import dashboard from './dashboardReducer';
import authentication from './authenticationReducer';
import downloads from './downloadsReducer';
import tenantProfile from './tenantProfileReducer';
import notifications from './notificationsReducer';
import tenantApplication from './tenantApplicationReducer';
import bookings from './bookingsReducer';
import screenDimensions from './screenDimensionsReducer';
import documentPortal from './documentPortalReducer';
import documentPortalInPerson from './documentPortalInPersonReducer';
import renewalLeaseWizard from './renewalLeaseWizardReducer';
import references from './referencesReducer';
import survey from './surveyReducer';
import redirection from './redirectionReducer';
import todo from './todoReducer';
import timer from './timerReducer';
import applicationStatus from './applicationStatusReducer';
import userTypes from './userTypesReducer';
import tenantMaintenance from './tenantMaintenanceReducer';
import maintenanceDashboard from './maintenanceDashboardReducer';
import maintenanceReducer from './maintenanceReducer';
import maintenanceTicket from './maintenanceTicketReducer';
import chat from './chatReducer';
import landlordDashboard from './landlordDashboardReducer';
import landlordInsights from './landlordInsightReducer';
import landlordLeads from './landlordLeadsReducer';
import landlordUsers from './landlordUsersReducer';
import landlordShowings from './landlordShowingsReducer';
import landlordProperties from './landlordPropertiesReducer';
import landlordApplications from './landlordApplicationsReducer';
import landlordInspections from './landlordInspectionsReducer';
import landlordDocumentTemplates from './landlordDocumentTemplatesReducer';
import landlordPurchaseOrders from './landlordPurchaseOrdersReducer';
import landlordAPIDashboard from './landlordAPIDashboardReducer';
import landlordParcels from './landlordParcelsReducer';
import landlordNotices from './landlordNoticesReducer';
import landlordSignableDoc from './landlordSignableDocReducer';
import vendors from './vendorsReducer';
import reporting from './reportingReducer';
import companyDetails from './companyDetailsReducer';
import landlordTenants from './landlordTenantsReducer';
import navigation from './navigationReducer';
import landlordPayments from './landlordPaymentsReducer';
import leaseDetails from './leaseDetailsReducer';
import dropzoneMultiLine from './dropzoneMultiLineReducer';
import insights from './insightsReducer';
import siteEvent from './siteEventReducer';
import prospects from './prospectsReducer';
import logs from 'redux/logs/reducer';
import cypress from './cypressReducer';
import applicationBuilder from './applicationBuilderReducer'
// LANDLORD PORTAL
// TODO: Split the imports by tenant/landlord/maintenance
import projects from 'components/landlord_app/maintenance/projects/store/reducer';
import serviceRequests from 'components/landlord_app/maintenance/tickets/store/reducer';
import batches from 'components/landlord_app/batches/store/reducer';
import tenantActivity from 'components/landlord_app/tenants/components/TenantActivity/store/reducer';
import renewals from 'components/landlord_app/rent_roll/renewals/store/reducer';
import propertyMultiselect from 'components/shared/PropertyMultiselectAdvanced/store/reducer';
import promotions from 'components/landlord_app/promotions/store/reducer';
import events from 'components/landlord_app/calendar/Events/store/reducer';
import residentDrawer from 'components/landlord_app/tenants/components/ResidentDrawer/store/reducer';
import leaseCreatorV2 from 'components/landlord_app/applications/components/lease_creator_v2/store/reducer';
import applicationManagement from 'components/landlord_app/application_management/store/reducer';

const landlord = combineReducers({
  maintenance: combineReducers({
    projects,
    serviceRequests,
  }),
  batches,
  tenants: combineReducers({
    tenantActivity,
  }),
  renewals,
  properties: combineReducers({
    propertyMultiselect,
  }),
  promotions,
  events,
  residentDrawer,
  leaseCreatorV2,
  applicationManagement,
});

const appReducer = combineReducers({
  applicationStatus,
  authentication,
  bookings,
  chat,
  companyDetails,
  cypress,
  dashboard,
  documentPortal,
  documentPortalInPerson,
  downloads,
  dropzoneMultiLine,
  form: formReducer,
  insights,
  landlord, // TODO combine reducers, split into children of tenant/landlord/maintenance
  landlordAPIDashboard,
  landlordApplications,
  landlordDashboard,
  landlordDocumentTemplates,
  landlordPurchaseOrders,
  landlordInsights,
  landlordInspections,
  landlordLeads,
  landlordNotices,
  landlordSignableDoc,
  landlordParcels,
  landlordPayments,
  landlordProperties,
  landlordShowings,
  landlordTenants,
  landlordUsers,
  leaseDetails,
  loadingBar: loadingBarReducer,
  locale: localeReducer,
  logs,
  maintenanceDashboard,
  maintenanceReducer,
  maintenanceTicket,
  navigation,
  notifications,
  redirection,
  references,
  renewalLeaseWizard,
  reporting,
  siteEvent,
  screenDimensions,
  survey,
  tenantProfile,
  tenantApplication,
  tenantMaintenance,
  timer,
  todo,
  userTypes,
  vendors,
  prospects,
  applicationBuilder
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTHENTICATION::LOG_USER_OUT') {
    const { userTypes, locale, loadingBar, screenDimensions } = state;
    userTypes.permissions = {};
    state = { userTypes, locale, loadingBar, screenDimensions };
  }

  return appReducer(state, action);
};

export default rootReducer;
