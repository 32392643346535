import {
  FETCH_PROPERTIES,
  FETCH_PROPERTY_FILTERS,
  FETCH_PROPERTIES_FOR_REVIEW,
} from './actionTypes';

const initialState = {
  isFetching: false,
  isFetchingFilters: false,
  isFetchingReview: false,
  properties: [],
  propertiesForReview: [],
  filters: [],
};

function doReceiveAllProperties(state, action) {
  const propertiesData = action.payload.properties.data;
  return { ...state, isFetching: false, properties: propertiesData };
}

const multiselectPropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROPERTIES}_PENDING`:
      return { ...state, isFetching: true };
    case `${FETCH_PROPERTIES}_REJECTED`:
      return { ...state, isFetching: false };
    case `${FETCH_PROPERTIES}_FULFILLED`:
      return doReceiveAllProperties(state, action);

    case `${FETCH_PROPERTY_FILTERS}_PENDING`:
      return { ...state, isFetchingFilters: true };
    case `${FETCH_PROPERTY_FILTERS}_REJECTED`:
      return { ...state, isFetchingFilters: false };
    case `${FETCH_PROPERTY_FILTERS}_FULFILLED`:
      return { ...state, isFetchingFilters: false, filters: action.payload.filters };

    case `${FETCH_PROPERTIES_FOR_REVIEW}_PENDING`:
      return { ...state, isFetchingReview: true };
    case `${FETCH_PROPERTIES_FOR_REVIEW}_REJECTED`:
      return { ...state, isFetchingReview: false };
    case `${FETCH_PROPERTIES_FOR_REVIEW}_FULFILLED`:
      return {
        ...state,
        isFetchingReview: false,
        propertiesForReview: action.payload.properties_organized,
      };

    default:
      return state;
  }
};

export default multiselectPropertiesReducer;
