import * as ByesignHelper from 'components/byesign/ByesignHelper';

export default function reducer(
  state = {
    showDocument: false,
    showPad: false,
    showActionsMenu: false,
    showContinueButton: false,
    showAgreementDialog: false,
    showTermsOfServiceDialog: false,
    showPreviousSignatureImage: false,
    signButtonEnabled: false,
    isSending: false,
    anchorId: 0,
    width: 0,
    height: 0,
    signatures: [],
    signatureId: null,
    signatureSuccess: false,
    signatureError: false,
    signatureDocument: null,
    currentSigner: null,
    padType: 'SIGNATURE',
    assetName: ByesignHelper.constants.DEFAULT_ASSET_NAME,
    documentStatus: null,
    documentUrl: null,
    ownerName: null,
    companyName: null,
    colorScheme: null,
    brandingLogo: null,
    signatureTextValue: '',
    toggleSignatureTextMode: true,
  },
  action
) {
  switch (action.type) {
    case 'DOCUMENT_PORTAL::SHOW_DOCUMENT': {
      if (action.shouldShow) {
        state.assetName =
          action.signatureDocument.asset_name || ByesignHelper.constants.DEFAULT_ASSET_NAME;
        state.companyName = action.signatureDocument.company_name;
        state.colorScheme =
          action.signatureDocument.color_scheme || ByesignHelper.style.primarySchemeColor;
      }
      return {
        ...state,
        showDocument: action.shouldShow,
        signatureDocument: action.signatureDocument || state.signatureDocument,
        currentSigner: action.currentSigner,
      };
    }
    case 'DOCUMENT_PORTAL::SAVE_BRANDING_LOGO': {
      return { ...state, brandingLogo: action.logo_url };
    }
    case 'DOCUMENT_PORTAL::SHOW_PAD': {
      return {
        ...state,
        signatureId: action.signatureId,
        showPad: action.shouldShow,
        padType: action.padType,
      };
    }
    case 'DOCUMENT_PORTAL::SHOW_TERMS_OF_SERVICE_DIALOG': {
      return { ...state, showTermsOfServiceDialog: action.shouldShow };
    }
    case 'DOCUMENT_PORTAL::SHOW_AGREEMENT_DIALOG': {
      return { ...state, showAgreementDialog: action.shouldShow };
    }
    case 'DOCUMENT_PORTAL::SHOW_ACTIONS_MENU': {
      return {
        ...state,
        signatureId: action.signatureId,
        showActionsMenu: action.shouldShow,
        anchorEl: action.anchorEl,
        padType: action.padType,
      };
    }
    case 'DOCUMENT_PORTAL::SHOW_PREVIOUS_SIGNATURE_IMAGE': {
      return { ...state, showPreviousSignatureImage: action.shouldShow };
    }
    case 'DOCUMENT_PORTAL::IS_SENDING': {
      return { ...state, isSending: action.isSending };
    }
    case 'DOCUMENT_PORTAL::CAPTURE_SIGNATURE': {
      let signature = state.signatureDocument.signatures.filter(
        signature => signature.uuid === action.signatureId
      );
      let signersSignatures = state.signatureDocument.signatures.concat(state.signatures);
      let otherCheckboxSignatures = signersSignatures.filter(
        signature => signature.uuid !== action.signatureId && signature.marker.startsWith('[chk')
      );

      signature = signature.length === 1 ? signature[0] : null;
      if (signature) {
        let signatureUrl = action.signatureUrl;
        if (signature && signature.marker.startsWith('[chk') && signature.isChecked) {
          signatureUrl =
            'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/unchecked.png';
        }

        state.signatures.push({
          ...signature,
          image: signatureUrl,
          isChecked: !signature.isChecked,
        });
        state.signatureDocument.signatures = state.signatureDocument.signatures.filter(
          signature => signature.uuid !== action.signatureId
        );
      } else {
        // Editing Signature
        signature = state.signatures.filter(signature => signature.uuid === action.signatureId);
        signature = signature.length === 1 ? signature[0] : null;
        state.signatures = state.signatures.filter(
          signature => signature.uuid !== action.signatureId
        );

        let signatureUrl = action.signatureUrl;
        if (signature && signature.marker.startsWith('[chk') && signature.isChecked) {
          signatureUrl =
            'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/unchecked.png';
        }

        state.signatures.push({
          ...signature,
          image: signatureUrl,
          isChecked: !signature.isChecked,
        });
      }

      signature = state.signatures.filter(signature => signature.uuid === action.signatureId)[0];
      if (signature.marker.startsWith('[chk')) {
        otherCheckboxSignatures.forEach(otherCheckboxSignature => {
          let signatureUrl = 'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/x.png';
          if (signature && signature.isChecked) {
            signatureUrl =
              'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/unchecked.png';
          }

          state.signatures = state.signatures.filter(
            signature => signature.uuid !== otherCheckboxSignature.uuid
          );

          state.signatures.push({
            ...otherCheckboxSignature,
            image: signatureUrl,
            isChecked: !signature.isChecked,
          });

          state.signatureDocument.signatures = state.signatureDocument.signatures.filter(
            signature => signature.uuid !== otherCheckboxSignature.uuid
          );
        });
      }

      return { ...state };
    }
    case 'DOCUMENT_PORTAL::DELETE_SIGNATURE': {
      let signature = state.signatures.filter(signature => signature.uuid === action.signatureId);
      signature = signature.length === 1 ? signature[0] : null;
      state.signatureDocument.signatures.push({ ...signature, image: null });
      state.signatures = state.signatures.filter(
        signature => signature.uuid !== action.signatureId
      );
      return { ...state, signatureId: null };
    }
    case 'DOCUMENT_PORTAL::NEXT_ANCHOR_ID': {
      if (state.signatureDocument.signatures && state.signatureDocument.signatures.length > 0) {
        if (
          state.anchorId + 1 > state.signatureDocument.signatures.length - 1 ||
          action.justSigned === true
        ) {
          state.anchorId = 0;
        } else {
          state.anchorId += 1;
        }
        state.showContinueButton = false;
      } else {
        state.anchorId = 0;
        state.showContinueButton = true;
      }

      return { ...state };
    }
    case 'DOCUMENT_PORTAL::ENABLE_SIGN_BUTTON': {
      return { ...state, signButtonEnabled: action.shouldEnable };
    }
    case 'DOCUMENT_PORTAL::SIGN_SUCCESS': {
      return { ...state, signatureSuccess: true };
    }
    case 'DOCUMENT_PORTAL::SIGN_ERROR': {
      return {
        ...state,
        assetName: action.assetName,
        documentStatus: action.documentStatus,
        documentUrl: action.documentUrl,
        ownerName: action.ownerName,
        signatureError: true,
        showDocument: false,
      };
    }
    case 'DOCUMENT_PORTAL::UPDATE_TOGGLE_SIGNATURE_TEXT_MODE': {
      return { ...state, toggleSignatureTextMode: action.toggleSignatureTextMode };
    }
    case 'DOCUMENT_PORTAL::UPDATE_SIGNATURE_TEXT_VALUE': {
      return { ...state, signatureTextValue: action.signatureTextValue };
    }
    case 'DOCUMENT_PORTAL::CLEAR_SIGNATURES': {
      return {
        ...state,
        showDocument: false,
        showPad: false,
        showActionsMenu: false,
        showContinueButton: false,
        showAgreementDialog: false,
        signButtonEnabled: false,
        isSending: false,
        anchorId: 0,
        signatures: [],
        signatureId: null,
        signatureSuccess: false,
        signatureError: false,
        signatureDocument: null,
        padType: 'SIGNATURE',
        nextDocumentToSign: null,
        signatureRenewalYearly: false,
        signatureRenewalMonthly: false,
        showPreviousSignatureImage: false,
        assetName: ByesignHelper.constants.DEFAULT_ASSET_NAME,
        documentStatus: null,
        documentUrl: null,
        signatureTextValue: '',
        toggleSignatureTextMode: true,
      };
    }
    default:
      return state;
  }
}
