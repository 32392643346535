import { browserHistory } from 'react-router';

export function redirectUrl(result = null, delay = 0) {
  let query = browserHistory.getCurrentLocation().query;
  if (query.redirect_url) {
    setTimeout(() => {
      if (result) {
        let appender = query.redirect_url.indexOf('?') > 0 ? '&' : '?';
        window.location.replace(
          decodeURIComponent(`${query.redirect_url}${appender}result=${result}`)
        );
      } else {
        window.location.replace(decodeURIComponent(query.redirect_url));
      }
    }, delay);
  }
}

export function getEnvFromSubdomain() {
  let env;
  const hostParts = window.location.hostname.split('.');
  const [subdomain] = hostParts.length === 1 ? [null, hostParts[0]] : hostParts;
  if (!subdomain) {
    return null;
  }
  const startOfDash = subdomain.indexOf('-');
  if (startOfDash !== -1) {
    env = subdomain.substr(0, startOfDash);
    if (['dev', 'qa', 'staging', 'training'].includes(env)) {
      return env;
    }
  }
  return null;
}

export function getCustomAPISubdmomain() {
  if (window.CUSTOM_API_SUBDOMAIN === undefined) {
    return '';
  }
  return window.CUSTOM_API_SUBDOMAIN;
}

/**
 * Get the environment-appropriate federated graph URL
 * @param {'LOCAL' | 'DEV' | 'STAGING' | 'PROD'} env
 */
export function getGraphURL(env) {
  switch (env) {
    case 'DEV':
      return 'https://api.dev.happyco.com/graph';
    case 'STAGING':
      return 'https://api.staging.happyco.com/graph';
    case 'PROD':
      return 'https://api.happyco.com/graph';
    case 'LOCAL':
    default:
      return 'http://api.localhost:2000/graph';
  }
}
