import React from 'react';

import { FieldArray, touch, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FlatButton from 'material-ui/FlatButton';

import FieldItemDialog from './FieldItemDialog';
import { AccentColor } from 'helpers/AccentColorHelper';
import { colors } from 'helpers/ColorHelper';
import { getTranslatedString } from 'helpers/i18n';

class RenderFieldItems extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({ dialogOpen: false, dialogData: {} });
  }

  // open the dialog and push a new empty value onto the field array
  addItem = () => {
    const next_index = this.props.fields.length; // length before pushing new value
    const name = `${this.props.fields.name}[${next_index}]`; // name of the new field

    this.props.fields.push({ ...this.props.initialValues });

    this.setState({
      dialogOpen: true,
      dialogData: { index: next_index, name },
    });
  };

  // ensure item passes validation before closing the dialog
  createItem = async () => {
    const { formErrors, fields, onSave } = this.props;
    const dialogDataIndex = this.state.dialogData.index;
    if (formErrors && formErrors[fields.name] && formErrors[fields.name].length > dialogDataIndex) {
      const fieldErrors = formErrors[fields.name][dialogDataIndex];

      // keep dialog open and mark all fields with errors as touched
      Object.keys(fieldErrors).forEach(key => {
        this.props.touch(`${this.state.dialogData.name}.${key}`);
      });

      return false;
    }

    if (onSave) {
      await onSave(fields.get(dialogDataIndex));
    }

    this.closeDialog();
    return true;
  };

  // called when an item create/edit is cancelled
  cancelCreateItem = () => {
    const index = this.state.dialogData.index;
    const fieldArrayLength = this.props.fields.length;

    // we want to clear the values if we were creating a new
    // element. If we were editing we should restore the values
    if (this.state.editedItem) {
      this.props.fields.remove(index);
      this.props.fields.insert(index, this.state.editedItem);
    } else if (index === fieldArrayLength - 1) {
      this.props.fields.pop();
    }

    this.closeDialog();
  };

  editItem = index => {
    // we need to save the state of the item being edited so we can revert cancelled changes
    const editedItem = this.props.fields.get(index);

    this.setState({
      dialogOpen: true,
      dialogData: { index, name: `${this.props.fields.name}[${index}]` },
      editedItem,
    });
  };

  deleteItem = async index => {
    const { fields } = this.props;

    if (this.props.onDelete) {
      try {
        await this.props.onDelete(fields.get(index));
      } catch (err) {
        return;
      }
    } else {
      this.props.fields.remove(index);
    }
  };

  closeDialog = () => {
    this.setState({
      dialogOpen: false,
      dialogData: { index: null, name: null },
      editedItem: null,
    });
  };

  render() {
    const { fields, savedItemsProps, disabled } = this.props;
    const values = fields.getAll() || [];
    return (
      <div>
        {this.renderSavedItems(values, savedItemsProps, { disabled })}

        <div className="row" style={{ textAlign: 'center', marginTop: '20px' }}>
          <FlatButton
            label={
              this.props.buttonVerbiageOverride ||
              getTranslatedString('landlordPortal.fieldItems.addAdditional')
            }
            type="button"
            backgroundColor={!this.props.disabled ? AccentColor() : colors.GRAY}
            hoverColor="grey"
            style={{ color: 'white' }}
            disabled={this.props.disabled}
            onClick={this.addItem}
          />
        </div>

        <FieldItemDialog
          open={this.state.dialogOpen}
          onClose={this.cancelCreateItem}
          onSubmit={this.createItem}
          fields={fields}
          index={this.state.dialogData.index}
          name={this.state.dialogData.name}
          formName={this.props.meta.form}
          formComponent={this.props.renderForm}
          dialogHeaderVerbiage={this.props.dialogHeader}
          formProps={this.props.formProps}
        />
      </div>
    );
  }

  renderSavedItems(items, savedItemsProps, options = { disabled: false }) {
    if (!items || items.length === 0) return null;

    return this.props.renderSavedItems({
      items,
      savedItemsProps,
      onEdit: this.editItem,
      onDelete: this.deleteItem,
      disabled: options.disabled,
    });
  }
}

class FieldItems extends React.Component {
  /*
   * FieldItems is an extension of ReduxForm's FieldArray that provides the ability to
   * modify an array of form items while only mounting a single instance of an editable field
   *
   * See AncillaryItems.js for an example use case.
   *
   * Props:
   *
   * name (String): The name of the field for ReduxForm
   *
   * formName (String): The name of the parent ReduxForm
   *
   * renderSavedItems (Component): A functional React component responsible for rendering
   *   the items saved in the form. The component should receive a single props param with
   *   following signature:
   *   ({items, onEdit, onDelete, disabled}) => ReactNode
   *   where:
   *     items (Array): Array of objects representing from state
   *     onEdit: (Function (index) => null): Function that takes the index of an item to edit
   *       and opens an editing dialog
   *     onDelete: (Function (index) => null): Function that removes index from the from data
   *     disabled: (Boolean): Whether the form is currently set to disabled
   *
   * renderForm: (Component): A React component containing Field elements representing an editable form
   *
   */
  render() {
    return (
      <FieldArray
        name={this.props.name}
        component={RenderFieldItems}
        rerenderOnEveryChange
        formErrors={this.props.formErrors}
        touch={this.props.touch}
        disabled={this.props.disabled}
        renderSavedItems={this.props.renderSavedItems}
        renderForm={this.props.renderForm}
        dialogHeader={this.props.dialogHeader}
        formProps={this.props.formProps}
        savedItemsProps={this.props.savedItemsProps}
        onSave={this.props.onSave}
        onDelete={this.props.onDelete}
        buttonVerbiageOverride={this.props.buttonVerbiageOverride}
        initialValues={this.props.initialValues}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  formErrors: getFormSyncErrors(ownProps.formName)(state),
});

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      touch: fieldName => touch(ownProps.formName, fieldName),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FieldItems);
