import * as types from './types';

const initialState = {
  application_details: {
    assigned_applicant: {},
    assigned_unit: {},
    // rent: {}, read-only, so I'm not sure it's worth adding to the store
    lease_dates: {},
    co_applicants: [],
    occupants: [],
    rentable_items: [],
    rental_incentives: [],
    // fees: [], read-only, so I'm not sure it's worth adding to the store
    deposit: { amount_paid: null },
  },
  notifyApplicant: false, // enabled when the landlord clicks the "Send to Applicant" button,
  createApplicationSuccess: false,
  createApplicationFailed: false,
  createApplicationError: null,
  assignAgentSuccess: false,
  assignAgentFailure: false,
  isDraft: false,
  newApplicationId: null,
};

const applicationManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_APPLICATION_SUCCESS: {
      const { isDraft, newApplicationId } = action.payload;
      return {
        ...state,
        createApplicationSuccess: true,
        createApplicationFailed: false,
        createApplicationError: null,
        isDraft,
        newApplicationId,
      };
    }
    case types.CREATE_APPLICATION_FAILURE: {
      const { isDraft } = action.payload;
      return {
        ...state,
        createApplicationSuccess: false,
        createApplicationFailed: true,
        createApplicationError: action.errorMessage,
        isDraft,
      };
    }
    case types.RESET_APPLICATION_STATUSES: {
      return {
        ...state,
        createApplicationSuccess: false,
        createApplicationFailed: false,
        createApplicationError: null,
        assignAgentSuccess: false,
        assignAgentFailure: false,
        isDraft: false,
      };
    }
    case types.ASSIGN_AGENT_SUCCESS: {
      return {
        ...state,
        assignAgentSuccess: true,
        assignAgentFailure: false,
      };
    }
    case types.ASSIGN_AGENT_FAILURE: {
      return {
        ...state,
        assignAgentSuccess: false,
        assignAgentFailure: true,
      };
    }
    default:
      return state;
  }
};

export default applicationManagementReducer;
