import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Field, change, formValueSelector } from 'redux-form';

import { SelectField, TextField, RadioButtonGroup, DatePicker } from 'redux-form-material-ui';
import { RadioButton } from 'material-ui/RadioButton';
import MenuItem from 'material-ui/MenuItem';

import * as validator from 'helpers/Validator';
import * as formatter from 'helpers/Formatter';
import { deepDiver } from 'helpers/utilities';

import { getProperty } from 'actions/landlordPropertiesActions';
import CurrentLeasesOfUnit from 'components/shared/CurrentLeasesOfUnit';
import { getTranslatedString } from 'helpers/i18n';
import { featureEnabled } from 'helpers/ToggleBot';
import { toFriendlyDateString } from 'helpers/Formatter';

const LeaseItemForm = connect(
  (state, ownProps) => ({
    leaseStartDate: formValueSelector(ownProps.formName)(state, 'lease_start_date'),
    selectedPropertyId: deepDiver(state, `form.${ownProps.formName}.values.property.value`),
    property: state.landlordProperties.property,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        getProperty,
        changeFieldValue: (field, value) => change(ownProps.formName, field, value),
      },
      dispatch
    )
)(
  class extends React.Component {
    state = {
      selectedAssetCharges: [],
      selectedAssetAvailableUntil: null,
      selectedAssetNotRentable: false,
    };
    UNSAFE_componentWillMount() {
      const { selectedPropertyId, leaseStartDate, changeFieldValue, name } = this.props;
      if (
        selectedPropertyId &&
        (!this.props.property || this.props.property.id !== selectedPropertyId)
      ) {
        this.props.getProperty(selectedPropertyId);
      }

      const selectedItemType = this.getSelf().item_type;
      if (selectedItemType) {
        this.handleItemSelection(null, selectedItemType);
      }

      // default to monthly and the lease start date until we can handle this case
      changeFieldValue(`${name}.duration`, 'monthly');
      changeFieldValue(`${name}.payment_type`, 'recurring');
      if (leaseStartDate && !this.getSelf().start_date) {
        changeFieldValue(`${name}.start_date`, formatter.toISO8601DateTimeString(leaseStartDate));
      }

      // custom validation for unique asset_number per item_type
      this.uniqueAsset = (value, allValues) => {
        if (!allValues || typeof allValues.lease_items !== 'object') return undefined;

        const leaseItems = allValues.lease_items;
        for (let i = 0; i < leaseItems.length; i++) {
          for (let j = i + 1; j < leaseItems.length; j++) {
            if (
              leaseItems[i].item_type === leaseItems[j].item_type &&
              leaseItems[i].asset_number === leaseItems[j].asset_number
            ) {
              return getTranslatedString('landlordPortal.leaseItemForm.addingTwiceItem');
            }
          }
        }

        return undefined;
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPropertyId = this.props.selectedPropertyId;
      const nextPropertyId = nextProps.selectedPropertyId;
      if (nextPropertyId && nextPropertyId !== currentPropertyId) {
        this.props.getProperty(nextProps.selectedPropertyId);
      }
    }

    getAncillaryItemTypes() {
      if (!this.props.property || !this.props.property.ancillary_items) return [];

      let itemTypes = new Set();
      this.props.property.ancillary_items.forEach(item => {
        itemTypes.add(item.item_type);
      });

      itemTypes = Array.from(itemTypes);
      if (featureEnabled('ui.lease_creator.add_other_rentable_items')) {
        itemTypes.unshift('Other');
      }

      return itemTypes.map(item => <MenuItem value={item} primaryText={item} />);
    }

    handleItemSelection = (_e, itemType) => {
      const { name, changeFieldValue, property } = this.props;
      if (!property || !property.ancillary_items) return null;

      // group items by item type
      const items = this.props.property.ancillary_items.filter(item => itemType === item.item_type);
      if (!items.length) {
        // our query does not match any item types so make sure to clear autofills
        this.setState({ assets: null });
        changeFieldValue(`${name}.asset_number`, null);
        changeFieldValue(`${name}.ancillary_item_id`, null);
        return;
      }

      const item = items[0];
      if (item.is_asset) {
        // If this item is an asset we should populate and show a selection of asset numbers
        this.setState({
          assets: items.map(item => {
            return {
              primaryText: `${item.asset_number}${item.reserved_until == null
                ? ''
                : ` | Occupied start date: ${toFriendlyDateString(item.reserved_until)}`}`,
              assetNumber: item.asset_number,
            };
          }),
        });
      } else {
        // otherwise assume assume item is a one time fee
        this.setState({ assets: null });
        changeFieldValue(`${name}.ancillary_item_id`, item.id);
        changeFieldValue(`${name}.amount`, item.market_amount);
      }
    };

    handleAssetSelection = (_e, assetNumber) => {
      const { name, changeFieldValue, property } = this.props;
      const selectedItemType = this.getSelf().item_type;
      if (!property || !selectedItemType) return null;

      const item = property.ancillary_items.find(
        item => selectedItemType === item.item_type && assetNumber === item.asset_number
      );
      if (!item) return null;

      this.setState({ selectedAssetAvailableUntil: item.reserved_until });
      this.setState({ selectedAssetCharges: item.leased_dates });
      this.setState({ selectedAssetNotRentable: !item.is_rentable });

      // populate pricing based on selected asset number
      changeFieldValue(`${name}.payment_type`, 'recurring');
      changeFieldValue(`${name}.duration`, 'monthly');
      changeFieldValue(`${name}.ancillary_item_id`, item.id);

      changeFieldValue(`${name}.amount`, item.market_amount);
      changeFieldValue(`${name}.deposit_amount`, item.market_deposit_amount);
      changeFieldValue(`${name}.key_deposit_amount`, item.market_key_deposit_amount);
    };

    getSelf = () => {
      const { fields, index } = this.props;
      if (fields === undefined || index === undefined) return {};

      return fields.get(index) || {};
    };

    render() {
      return (
        <div className="row" key={this.props.index}>
          {this.renderNameAndPaymentType()}
          {this.renderPricing()}
        </div>
      );
    }

    renderNameAndPaymentType() {
      const { name } = this.props;

      const ancillaryItems = this.getAncillaryItemTypes();

      const showOther = this.getSelf().item_type === 'Other';
      const showAsset = (this.state.assets || []).length > 0 && !showOther;

      let assets = [];
      if (showAsset) {
        assets = this.state.assets.map(({ assetNumber, primaryText }) => (
          <MenuItem value={assetNumber} primaryText={primaryText} />
        ));
        // The selectedAsset will not be within available assets if it is already leased
        const selectedAsset = this.getSelf().asset_number;
        if (selectedAsset && !this.state.assets.includes(selectedAsset)) {
          assets.push(<MenuItem value={selectedAsset} primaryText={selectedAsset} />);
        }
      }

      return (
        <div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                name={`${name}.item_type`}
                component={SelectField}
                validate={[validator.required]}
                style={{ fontSize: '14px' }}
                floatingLabelText={getTranslatedString(
                  'landlordPortal.leaseItemForm.ancillaryItemType'
                )}
                fullWidth
                onChange={this.handleItemSelection}
              >
                {ancillaryItems}
              </Field>
            </div>

            {showAsset && (
              <div className="col-sm-6">
                <Field
                  name={`${name}.asset_number`}
                  component={SelectField}
                  validate={[validator.required]}
                  style={{ fontSize: '14px' }}
                  floatingLabelText={getTranslatedString(
                    'landlordPortal.leaseItemForm.assetNumber'
                  )}
                  fullWidth
                  iconStyle={{ marginLeft: '20px !important' }}
                  onChange={this.handleAssetSelection}
                >
                  {assets}
                </Field>
              </div>
            )}

            {showOther && (
              <div className="col-sm-6">
                <Field
                  name={`${name}.item_name`}
                  type="text"
                  component={TextField}
                  validate={[validator.required]}
                  floatingLabelText={getTranslatedString('landlordPortal.leaseItemForm.itemName')}
                  style={{ fontSize: '14px', width: '100%' }}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    renderPricing() {
      const { index, fields } = this.props;
      const itemType = (fields.get(index) || {}).payment_type;

      if (!itemType) return null;

      if (itemType === 'recurring') {
        return this.renderRecurring();
      } else if (itemType === 'one_time') {
        return this.renderOneTime();
      }
    }

    renderRecurring() {
      const { name } = this.props;

      const recurringOptions = [...Array(12).keys()].map(index => (
        <MenuItem
          value={`${index + 1}`}
          primaryText={`${index + 1} ${getTranslatedString('landlordPortal.leaseItemForm.month')}`}
        />
      ));

      return (
        <div>
          <div className="row">
            <div className="col-sm-4">
              <Field
                name={`${name}.amount`}
                type="text"
                component={TextField}
                validate={[validator.required]}
                floatingLabelText={getTranslatedString('landlordPortal.leaseItemForm.monthlyRent')}
                style={{
                  marginTop: '-15px',
                  fontSize: '14px',
                  width: '100%',
                }}
                disabled={!featureEnabled('ui.lease_creator.yuhu_rentable_items')}
                {...formatter.currencyMask}
              />
            </div>
            <div className="col-sm-4">
              <Field
                name={`${name}.deposit_amount`}
                type="text"
                component={TextField}
                validate={[validator.required]}
                floatingLabelText={getTranslatedString(
                  'landlordPortal.leaseItemForm.rentalDeposit'
                )}
                style={{
                  marginTop: '-15px',
                  fontSize: '14px',
                  width: '100%',
                }}
                {...formatter.currencyMask}
              />
            </div>
            <div className="col-sm-4">
              <Field
                name={`${name}.key_deposit_amount`}
                type="text"
                component={TextField}
                validate={[validator.required]}
                floatingLabelText={getTranslatedString('landlordPortal.leaseItemForm.keyDeposit')}
                style={{
                  marginTop: '-15px',
                  fontSize: '14px',
                  width: '100%',
                }}
                {...formatter.currencyMask}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Field
                name={`${name}.start_date`}
                component={DatePicker}
                validate={[validator.required]}
                format={value => formatter.toDate(value)}
                normalize={value => formatter.toISO8601DateTimeString(value)}
                formatDate={value => formatter.toFriendlyDateString(value)}
                openToYearSelection
                autoOk
                style={{ fontSize: '14px', width: '100%' }}
                textFieldStyle={{ fontSize: '14px', width: '100%' }}
                floatingLabelText={getTranslatedString('landlordPortal.leaseItemForm.startDate')}
              />
            </div>
            <div className="col-sm-6">
              <Field
                name={`${name}.duration`}
                component={SelectField}
                validate={[validator.required]}
                style={{ fontSize: '14px' }}
                floatingLabelText={getTranslatedString('landlordPortal.leaseItemForm.duration')}
                fullWidth
                iconStyle={{ marginLeft: '20px !important' }}
              >
                <MenuItem
                  value="monthly"
                  primaryText={getTranslatedString('landlordPortal.leaseItemForm.monthly')}
                />
                {recurringOptions}
              </Field>
            </div>

            <CurrentLeasesOfUnit
              leases={this.state.selectedAssetCharges}
              availableUntil={this.state.selectedAssetAvailableUntil}
            />
            {this.state.selectedAssetNotRentable && (
              <div style={{ color: 'red', padding: '15px', textAlign: 'center' }}>
                {getTranslatedString('landlordPortal.leaseItemForm.notRentable')}
              </div>
            )}
          </div>
        </div>
      );
    }

    renderOneTime() {
      const { name } = this.props;

      return (
        <div className="row">
          <div className="col-sm-4 col-sm-offset-4">
            <Field
              name={`${name}.amount`}
              type="text"
              component={TextField}
              disabled
              validate={[validator.required]}
              floatingLabelText={getTranslatedString('landlordPortal.leaseItemForm.amount')}
              style={{
                marginTop: '-15px',
                fontSize: '14px',
                width: '100%',
              }}
              {...formatter.currencyMask}
            />
          </div>

          <div className="row radiobutton-label">
            <div className="col-sm-8 col-sm-offset-3">
              <h4 style={{ fontWeight: 'bold' }}>
                {getTranslatedString('landlordPortal.leaseItemForm.depositOrFee')}
              </h4>

              <Field
                name={`${name}.charge_type`}
                component={RadioButtonGroup}
                validate={[validator.required]}
              >
                <RadioButton
                  value="deposit"
                  label={getTranslatedString('landlordPortal.leaseItemForm.deposit')}
                  disabled
                  style={{ width: '50%', display: 'inline-flex' }}
                />
                <RadioButton
                  value="fee"
                  label="Fee"
                  disabled
                  style={{ width: '50%', display: 'inline-flex' }}
                />
              </Field>
              <Field name={`${name}.charge_type`} component={formatter.RenderError} />
            </div>
          </div>
        </div>
      );
    }
  }
);

export default LeaseItemForm;
