export const currentLocation = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://' + window.location.hostname + ':3000';
  } else {
    return 'https://' + window.location.hostname;
  }
};

export const getCompanyName = () => {
  let companyName = window.location.hostname.split('.')[0].replace(/\b\w/g, (l) => l.toUpperCase());
  if (companyName.toLowerCase() === 'capreit') {
    return 'CAPREIT';
  }
  if (companyName.toLowerCase() === 'rpms') {
    return 'RPMS';
  }

  const edgeCases = ['app', 'localhost'];
  if (edgeCases.includes(companyName.toLowerCase())) return 'Yuhu';
  return companyName;
};

export const hostIs = (hosts) => {
  const hostParts = window.location.hostname.split('.');
  const subdomain = hostParts.length === 1 ? null : hostParts[0];

  if (subdomain === null) {
    return false;
  }

  const itemsAreStrings = (arr) =>
    arr.reduce((prev, curr) => {
      if (prev === false) return false;
      return typeof curr === 'string';
    }, true);

  if (typeof hosts === 'string') {
    return subdomain.includes(hosts);
  } else if (Array.isArray(hosts) && itemsAreStrings(hosts)) {
    return hosts.reduce((prev, curr) => {
      if (prev === true) return true;
      return subdomain.includes(curr);
    }, false);
  } else {
    // eslint-disable-next-line
    throw 'argument must be string or [string]';
  }
};

export const hostIsCapreit = () => {
  if (['test-residentonline.ie', 'residentonline.ie'].includes(window.CLIENT_URL)) return true;
  return hostIs(['capreit', 'rpms', 'canliving', 'eres']);
};

export const hostIsCapreitCanada = () => {
  return ['residentonline.ca'].includes(window.CLIENT_URL);
};

export const hostIsRPMS = () => {
  return hostIs('rpms');
};

export const hostIsCapreitIRES = () => {
  if (['residentonline.ie'].includes(window.CLIENT_URL)) return true;
  if (['test-residentonline.ie'].includes(window.CLIENT_URL) === false) return false;

  return hostIs('ireland');
};

export const hostIsCapreitERES = () => {
  if (['huurderonline.nl'].includes(window.CLIENT_URL)) return true;
  if (['test-residentonline.ie'].includes(window.CLIENT_URL) === false) return false;

  return hostIs('netherlands');
};

export const hostIsBoardwalk = () => {
  if (['nebeling.dev', 'bwalk.com'].includes(window.CLIENT_URL)) return true;
  return hostIs(['bwalk']);
};

export const hostIsWestbank = () => {
  return hostIs(['westbank']);
};

export const hostIsLifestyleRentals = () => {
  return hostIs(['lifestylerentals']);
};

export const hostIsCarrington = () => {
  return hostIs(['carrington']);
};

export const hostIsConcert = () => {
  return ['qa-concertproperties.com', 'concertproperties.com'].includes(window.CLIENT_URL);
};

export const hostIsBgoResidents = () => {
  return ['bgoresidents.com'].includes(window.CLIENT_URL);
};

export const hostIsHollyburn = () => {
  return ['hollyburn.com'].includes(window.CLIENT_URL);
};

// TODO: replace with custom application flow
export const hostIsFourStar = () => {
  return ['fourstarrealty.com'].includes(window.CLIENT_URL);
};

export const hostIsYuhuProd = () => {
  return ['yuhu.io'].includes(window.CLIENT_URL);
};

export const hostIsYuhuQA = () => {
  return ['yuhuqa.io'].includes(window.CLIENT_URL);
};

export const hostIsLocal = () => {
  return ['localhost', 'yuhu.localhost'].includes(window.location.hostname);
};

export const hostIsAvenue = () => {
  return ['avenueliving'].includes(window.CLIENT_URL);
};
