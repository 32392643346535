export default function reducer(
  state = {
    todoData: [],
    numberOfTasks: 0,
  },
  action
) {
  switch (action.type) {
    case 'TODOS::SHOW_TODO_LIST': {
      return {
        ...state,
        numberOfTasks: action.numberOfTasks,
        todoData: action.todoData,
      };
    }
    case 'TODOS::UPDATE_TODO_MESSAGES': {
      const todoData = state.todoData.filter(todo => {
        return todo.event_type !== 'Messages';
      });
      const numberOfTasks = state.numberOfTasks > 0 ? state.numberOfTasks - 1 : 0;
      return { ...state, todoData: todoData, numberOfTasks: numberOfTasks };
    }
    default:
      return state;
  }
}
