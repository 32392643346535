import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import ReduxDropzone from 'components/shared/ReactDropzoneReduxComponent';
import { SelectField, TextField } from 'redux-form-material-ui';
import MenuItem from 'material-ui/MenuItem';

import * as validator from 'helpers/Validator';
import { getTranslatedString } from 'helpers/i18n';

const validate = values => {
  const errors = {};

  if (!values.schedule_a && values.schedule_a_name && values.schedule_a_body) {
    return errors;
  }

  if (!values.schedule_a_name) {
    errors.schedule_a_name = getTranslatedString('landlordPortal.scheduleWizardPage.required');
  }

  if (!values.schedule_a_body) {
    errors.schedule_a_body = getTranslatedString('landlordPortal.scheduleWizardPage.required');
  }
  return errors;
};

let SchedulePage = props => {
  const {
    handleSubmit,
    scheduleAs,
    openScheduleAForm,
    closeScheduleAForm,
    showScheduleAForm,
    change,
  } = props;

  const scheduleAsOptions = scheduleAs.map((scheduleA, index) => (
    <MenuItem key={index} value={scheduleA} primaryText={scheduleA} />
  ));

  const clearFields = () => {
    change('schedule_a', null);
    change('schedule_a_name', null);
    change('schedule_a_body', null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} id="form_field">
        <h3 style={{ textAlign: 'center' }}> Schedule A </h3>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>
          This is appended to the lease, and is used to describe the the lease and other terms
          applicable to the lease. We highly recommend using our default Schedule A.
        </div>
        {!showScheduleAForm && (
          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-12" style={{ textAlign: 'center' }}>
              <Field
                name="schedule_a"
                component={SelectField}
                floatingLabelText="Schedule"
                floatingLabelStyle={{ paddingLeft: '100px' }}
                style={{
                  marginTop: '-15px',
                  fontSize: '14px',
                  textAlign: 'left',
                }}
              >
                {scheduleAsOptions}
              </Field>
              <div
                className="col-sm-12"
                style={{
                  textAlign: 'center',
                  padding: '10px',
                  zIndex: '1000',
                  marginBottom: '20px',
                }}
              >
                <button
                  type="button"
                  className="link-button"
                  onClick={event => {
                    event.preventDefault();
                    clearFields();
                    openScheduleAForm();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {' '}
                  Click to Add A New Schedule A{' '}
                </button>
              </div>
            </div>
          </div>
        )}
        {showScheduleAForm && (
          <div>
            <div className="row" style={{ textAlign: 'center', marginTop: '35px' }}>
              <div className="col-sm-12" style={{ textAlign: 'center' }}>
                <Field
                  name="schedule_a_name"
                  hintText="Schedule A name (for future reference)"
                  component={TextField}
                  fullWidth
                  style={{
                    marginTop: '-15px',
                    fontSize: '14px',
                    width: '300px',
                  }}
                  validate={[validator.required]}
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col-sm-12" style={{ textAlign: 'center' }}>
                <Field
                  name="schedule_a_file"
                  fileValidation="application/pdf"
                  component={ReduxDropzone}
                  labelText="Upload a PDF copy of your schedule A to append to this lease."
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <button
                  type="button"
                  className="link-button"
                  onClick={event => {
                    event.preventDefault();
                    clearFields();
                    closeScheduleAForm();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {' '}
                  Cancel{' '}
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.name,
  };
};

SchedulePage = compose(
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })
)(SchedulePage);

SchedulePage = connect(state => ({
  initialValues: state.landlordApplications.leaseDetails,
}))(SchedulePage);

export default SchedulePage;
