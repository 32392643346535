import React from 'react';

import CompanyAddressForm from 'navigation/CompanyAddressForm';

let CompanyAddressWizardPage = props => {
  return (
    <div>
      <div>
        <h3 style={{ textAlign: 'center' }}>Company Address</h3>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <div style={{ fontSize: '14px' }}>
            Provide your company’s legal address to be used in your lease. You only have to do this
            once. You can modify it later under the Company Profile.
          </div>
        </div>

        <CompanyAddressForm {...props} showSubmitButton={false} />
      </div>
    </div>
  );
};

export default CompanyAddressWizardPage;
