const makeType = (key) => {
  return 'LANDLORD::LEASE_CREATOR::' + key;
};

export const STORE_DOCUMENT_TEMPLATES_FOR_PROPERTY = makeType(
  'STORE_DOCUMENT_TEMPLATES_FOR_PROPERTY'
);
export const STORE_BASE_DOCUMENT_TEMPLATE = makeType('STORE_BASE_DOCUMENT_TEMPLATE');
export const STORE_FIXED_ADDENDUMS_FOR_LEASE = makeType('STORE_FIXED_ADDENDUMS_FOR_LEASE');
export const STORE_CONDITIONAL_ADDENDUMS_FOR_LEASE = makeType(
  'STORE_CONDITIONAL_ADDENDUMS_FOR_LEASE'
);
export const STORE_AVAILABLE_ADDITIONAL_CHARGES = makeType('STORE_AVAILABLE_ADDITIONAL_CHARGES');
export const STORE_CURRENT_ADDITIONAL_CHARGES_FOR_LEASE = makeType(
  'STORE_CURRENT_ADDITIONAL_CHARGES_FOR_LEASE'
);
export const STORE_CURRENT_RENTABLE_ITEMS_FOR_LEASE = makeType(
  'STORE_CURRENT_RENTABLE_ITEMS_FOR_LEASE'
);
export const STORE_SERVICES_AND_UTILITIES_FOR_LEASE = makeType(
  'STORE_SERVICES_AND_UTILITIES_FOR_LEASE'
);
export const STORE_ADDITIONAL_FIELDS_FOR_LEASE = makeType('STORE_ADDITIONAL_FIELDS_FOR_LEASE');
export const STORE_LEASE_REVIEWS = makeType('STORE_LEASE_REVIEWS');
export const STORE_LATEST_LEASE_REVIEW = makeType('STORE_LATEST_LEASE_REVIEW');
