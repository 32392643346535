import styled from 'styled-components';
import { Select } from 'antd';

export default styled(Select)`
  font-size: 16px;
  width: 100%;
  .ant-select-selection__rendered .ant-row {
    display: flex;
    align-items: center;
  }

  .ant-select-selection__rendered {
    font-size: 16px;
  }
`;
