//LIBRARIES
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { localize } from 'react-localize-redux';
import { RadioButtonGroup } from 'redux-form-material-ui';
import { RadioButton } from 'material-ui/RadioButton';

//HELPERS
import * as validator from 'helpers/Validator';
import { RenderError } from 'helpers/Formatter';

export const BooleanRadioButtons = localize()(
  class BooleanRadioButtons extends Component {
    render() {
      const {
        name,
        radioButtonStyle,
        trueLabel,
        trueLabelDetail,
        falseLabel,
        falseLabelDetail,
        disabled,
        stackRadio,
      } = this.props;

      const style = radioButtonStyle || {
        display: stackRadio ? 'block' : 'inline-flex',
        width: '50%',
        fontSize: '14px',
      };

      return (
        <div>
          <Field
            name={name}
            component={RadioButtonGroup}
            parse={value => value === 'true'}
            format={value => {
              if (value === undefined || value === null) return;
              return value ? 'true' : 'false';
            }}
            onChange={event => {
              if (this.props.onUpdate) {
                this.props.onUpdate(event.target.value === 'true');
              }
            }}
            {...this.props}
          >
            <RadioButton
              value="true"
              label={
                <LabelDetails
                  title={trueLabel || this.props.translate('request_type.yes')}
                  details={trueLabelDetail}
                />
              }
              style={style}
              disabled={disabled}
            />
            <RadioButton
              value="false"
              label={
                <LabelDetails
                  title={falseLabel || this.props.translate('request_type.no')}
                  details={falseLabelDetail}
                />
              }
              style={style}
              disabled={disabled}
            />
          </Field>
          <Field name={name} validate={[validator.required]} component={RenderError} />
        </div>
      );
    }
  }
);

const LabelDetails = ({ title, details }) => (
  <div style={{ width: 'calc(100% - 60px)' }}>
    <p style={{ fontSize: '14px' }}>{title}</p>
    <p>{details}</p>
  </div>
);
