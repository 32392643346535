import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FlatButton from 'material-ui/FlatButton';

import { colors } from 'helpers/ColorHelper';
import { AccentColor } from 'helpers/AccentColorHelper';
import { getTranslatedString } from 'helpers/i18n';

const FieldItemDialog = class extends React.Component {
  UNSAFE_componentWillMount() {
    // avoid double clicking after a selection
    this.setState({ buttonsActive: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open === false) {
      this.setState({ buttonsActive: true });
    }
  }

  onClose = () => {
    this.setState({ buttonsActive: false });
    this.props.onClose();
  };

  onSubmit = async () => {
    this.setState({ buttonsActive: false });

    // onSubmit returns false and does not close if fields are not valid
    await this.props.onSubmit();
    this.setState({ buttonsActive: true });
  };

  render() {
    return (
      <Dialog
        disableBackdropClick
        fullScreen={this.props.fullScreen}
        open={this.props.open}
        onClose={this.props.toggleOpen}
        classes={{ paper: 'form-dialog' }}
        aria-labelledby="add-lease-item"
      >
        <DialogTitle id="add-lease-item">
          <h3 style={{ textAlign: 'center', margin: 0 }}>{this.props.dialogHeaderVerbiage}</h3>
        </DialogTitle>

        <DialogContent>
          <this.props.formComponent
            formName={this.props.formName}
            name={this.props.name}
            fields={this.props.fields}
            index={this.props.index}
            formProps={this.props.formProps}
          />
        </DialogContent>

        <DialogActions style={{ display: 'block' }}>
          <FlatButton
            onClick={this.onClose}
            id="close-button"
            style={{ color: AccentColor() }}
            disabled={!this.state.buttonsActive}
          >
            {getTranslatedString('cancel')}
          </FlatButton>

          <FlatButton
            onClick={this.onSubmit}
            id="close-button"
            disabled={!this.state.buttonsActive}
            hoverColor="grey"
            backgroundColor={AccentColor()}
            style={{ color: colors.WHITE, float: 'right' }}
          >
            {getTranslatedString('next')}
          </FlatButton>
        </DialogActions>
      </Dialog>
    );
  }
};

export default withMobileDialog()(FieldItemDialog);
