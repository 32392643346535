import { GET_AMENITY_BOOKINGS, GET_NOTICES, GET_EVENTS, GET_RATINGS } from './actionTypes';

const initialState = {
  amenityBookings: [],
  totalAmenityBookings: 0,
  isLoading: false,
  events: [],
  totalEventCount: 0,
  residentNotices: [],
  totalResidentNotices: 0,
  residentRatings: { interest: [], temperature: [] },
  totalRatingsCount: 0,
};

function doReceiveAmenityBookings(state, action) {
  const amenityBookings = action.payload.amenity_bookings;
  const totalAmenityBookings = action.payload.total_amenity_booking_count;

  return {
    ...state,
    isLoading: false,
    amenityBookings: amenityBookings,
    totalAmenityBookings: totalAmenityBookings,
  };
}

function doRecieveNotices(state, action) {
  const residentNotices = action.payload.notices;
  const totalResidentNotices = action.payload.total_notice_count;

  return {
    ...state,
    isLoading: false,
    residentNotices: residentNotices,
    totalResidentNotices: totalResidentNotices,
  };
}

function doRecieveEvents(state, action) {
  const events = action.payload.rsvped_events;
  const totalEventCount = action.payload.total_rsvped_event_count;

  return {
    ...state,
    isLoading: false,
    events: events,
    totalEventCount: totalEventCount,
  };
}

function doRecieveRatings(state, action) {
  const ratings = action.payload.ratings;
  const totalRatingsCount = action.payload.total_ratings_count;

  return {
    ...state,
    isLoading: false,
    residentRatings: ratings,
    totalRatingsCount: totalRatingsCount,
  };
}

const tenantActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_AMENITY_BOOKINGS}_PENDING`:
      return { ...state, isLoading: true };
    case `${GET_AMENITY_BOOKINGS}_FULFILLED`:
      return doReceiveAmenityBookings(state, action);
    case `${GET_AMENITY_BOOKINGS}_REJECTED`:
      return { ...state, isLoading: false };
    case `${GET_EVENTS}_PENDING`:
      return { ...state, isLoading: true };
    case `${GET_EVENTS}_FULFILLED`:
      return doRecieveEvents(state, action);
    case `${GET_EVENTS}_REJECTED`:
      return { ...state, isLoading: false };
    case `${GET_NOTICES}_PENDING`:
      return { ...state, isLoading: true };
    case `${GET_NOTICES}_FULFILLED`:
      return doRecieveNotices(state, action);
    case `${GET_NOTICES}_REJECTED`:
      return { ...state, isLoading: false };
    case `${GET_RATINGS}_PENDING`:
      return { ...state, isLoading: true };
    case `${GET_RATINGS}_FULFILLED`:
      return doRecieveRatings(state, action);
    case `${GET_RATINGS}_REJECTED`:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default tenantActivityReducer;
