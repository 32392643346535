export default function reducer(
  state = {
    showPage: false,
    showLoginForm: false,
    loginErrorCode: null,
    shouldShowNonAuthPage: false,
    showLoginDialog: false,
    twoAuthVerification: {
      verificationRequired: false,
      verificationStep: 'loading',
      previewMobileNumber: '',
      errorCode: '',
      hasPhoneNumber: false,
    },
  },
  action
) {
  switch (action.type) {
    case 'AUTHENTICATION::TOGGLE_LOGIN_DIALOG': {
      return {
        ...state,
        showLoginForm: action.showLoginForm,
        showLoginDialog: action.showLoginDialog,
      };
    }
    case 'AUTHENTICATION::DIALOG_LOG_OUT': {
      return {
        ...state,
        showPage: action.showPage,
        showLoginForm: true,
        showLoginDialog: action.showLoginDialog,
        shouldShowNonAuthPage: false,
        showApplicationPage: false,
        showSignaturePage: false,
      };
    }
    case 'AUTHENTICATION::LOG_USER_OUT': {
      return {
        ...state,
        showPage: action.showPage,
        showLoginForm: true,
        showLoginDialog: action.showLoginDialog,
        shouldShowNonAuthPage: false,
        showApplicationPage: false,
        showSignaturePage: false,
      };
    }
    case 'AUTHENTICATION::RETURN_TO': {
      return {
        ...state,
        returnTo: action.returnTo,
      };
    }
    case 'SHOW_LOGIN_ERROR': {
      return {
        ...state,
        showPage: false,
        showLoginForm: true,
        shouldShowNonAuthPage: false,
        showApplicationPage: false,
        showSignaturePage: false,
        loginErrorCode: action.loginErrorCode,
        loginErrorData: action.data,
      };
    }
    case 'LOG_USER_IN': {
      return {
        ...state,
        showPage: true,
        showLoginForm: false,
        shouldShowNonAuthPage: false,
        showApplicationPage: false,
        showSignaturePage: false,
      };
    }
    case 'SHOW_NON_AUTH_PAGE': {
      return {
        ...state,
        showPage: false,
        showLoginForm: true,
        shouldShowNonAuthPage: true,
        showApplicationPage: false,
        showSignaturePage: false,
      };
    }
    case 'SHOW_APPLICATION_PAGE': {
      return {
        ...state,
        showPage: false,
        showLoginForm: false,
        shouldShowNonAuthPage: false,
        showApplicationPage: true,
        showSignaturePage: false,
      };
    }
    case 'SHOW_SIGNATURE_PAGE': {
      return {
        ...state,
        showPage: false,
        showLoginForm: false,
        shouldShowNonAuthPage: false,
        showApplicationPage: false,
        showSignaturePage: true,
      };
    }
    case 'REQUIRE_TWO_FACTOR_AUTH::NEED_VERIFICATION': {
      return {
        ...state,
        twoAuthVerification: {
          verificationRequired: action.verificationRequired,
          verificationStep: action.verificationStep,
          previewMobileNumber: action.previewMobileNumber,
          errorCode: action.errorCode,
          hasPhoneNumber: action.hasPhoneNumber,
        },
      };
    }
    case 'REQUIRE_TWO_FACTOR_AUTH::LOADING': {
      return {
        ...state,
        twoAuthVerification: {
          ...state.twoAuthVerification,
          verificationStep: 'loading',
          errorCode: '',
        },
      };
    }
    case 'REQUIRE_TWO_FACTOR_AUTH::ERROR': {
      return {
        ...state,
        twoAuthVerification: {
          ...state.twoAuthVerification,
          errorCode: 'verificationRequired.error',
        },
      };
    }
    case 'REQUIRE_TWO_FACTOR_AUTH::VERIFIED': {
      return {
        ...state,
        twoAuthVerification: {
          verificationRequired: false,
          verificationStep: 'loading',
          previewMobileNumber: '',
          errorCode: '',
        },
      };
    }
    default:
      return state;
  }
}
