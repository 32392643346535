import * as ByesignHelper from 'components/byesign/ByesignHelper';

export default function reducer(
  state = {
    showDocument: false,
    showPad: false,
    showActionsMenu: false,
    showContinueButton: false,
    showFinishButton: false,
    showSignerDialog: false,
    showAgreementDialog: false,
    signButtonEnabled: false,
    isSending: false,
    anchorId: 0,
    width: 0,
    height: 0,
    signatures: [],
    signatureId: null,
    signatureSuccess: false,
    signatureDocument: null,
    processedSigners: [],
    padType: 'SIGNATURE',
    assetName: ByesignHelper.constants.DEFAULT_ASSET_NAME,
    companyName: null,
    colorScheme: null,
  },
  action
) {
  switch (action.type) {
    case 'IN_PERSON::SHOW_DOCUMENT': {
      if (action.shouldShow) {
        state.assetName =
          action.signatureDocument.asset_name || ByesignHelper.constants.DEFAULT_ASSET_NAME;
        state.companyName = action.signatureDocument.company_name;
        state.colorScheme =
          action.signatureDocument.color_scheme || ByesignHelper.style.primarySchemeColor;
      }
      return {
        ...state,
        showDocument: action.shouldShow,
        signatureDocument: action.signatureDocument || state.signatureDocument,
        showStartButton: true,
      };
    }
    case 'IN_PERSON::SHOW_PAD': {
      return {
        ...state,
        signatureId: action.signatureId,
        showPad: action.shouldShow,
        padType: action.padType,
      };
    }
    case 'IN_PERSON::SHOW_AGREEMENT_DIALOG': {
      return { ...state, showAgreementDialog: action.shouldShow };
    }
    case 'IN_PERSON::SHOW_SIGNER_DIALOG': {
      return {
        ...state,
        showSignerDialog: action.shouldShow,
        showStartButton: action.shouldShow,
      };
    }
    case 'IN_PERSON::CLOSE_SIGNER_DIALOG_ON_CANCEL': {
      state.currentSigner = null;
      return {
        ...state,
        showSignerDialog: false,
        showStartButton: state.processedSigners.length === 0,
      };
    }
    case 'IN_PERSON::SHOW_ACTIONS_MENU': {
      return {
        ...state,
        signatureId: action.signatureId,
        showActionsMenu: action.shouldShow,
        anchorEl: action.anchorEl,
        padType: action.padType,
      };
    }
    case 'IN_PERSON::SHOW_FINISH_BUTTON': {
      return {
        ...state,
        currentSigner: null,
        showContinueButton: false,
        showStartButton: false,
        showFinishButton: true,
      };
    }
    case 'IN_PERSON::IS_SENDING': {
      return { ...state, isSending: action.isSending };
    }
    case 'IN_PERSON::CAPTURE_SIGNATURE': {
      let signature = state.signatureDocument.signatures.filter(
        signature => signature.uuid === action.signatureId
      );
      let signersSignatures = state.signatureDocument.signatures
        .concat(state.signatures)
        .filter(signature => signature.signer_uuid === state.currentSigner.uuid);
      let otherCheckboxSignatures = signersSignatures.filter(
        signature => signature.uuid !== action.signatureId && signature.marker.startsWith('[chk')
      );

      signature = signature.length === 1 ? signature[0] : null;
      if (signature) {
        let signatureUrl = action.signatureUrl;
        if (signature && signature.marker.startsWith('[chk') && signature.isChecked) {
          signatureUrl =
            'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/unchecked.png';
        }

        state.signatures.push({
          ...signature,
          image: signatureUrl,
          isChecked: !signature.isChecked,
        });
        state.signatureDocument.signatures = state.signatureDocument.signatures.filter(
          signature => signature.uuid !== action.signatureId
        );
      } else {
        // Editing Signature
        signature = state.signatures.filter(signature => signature.uuid === action.signatureId);
        signature = signature.length === 1 ? signature[0] : null;
        state.signatures = state.signatures.filter(
          signature => signature.uuid !== action.signatureId
        );

        let signatureUrl = action.signatureUrl;
        if (signature && signature.marker.startsWith('[chk') && signature.isChecked) {
          signatureUrl =
            'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/unchecked.png';
        }

        state.signatures.push({
          ...signature,
          image: signatureUrl,
          isChecked: !signature.isChecked,
        });
      }

      signature = state.signatures.filter(signature => signature.uuid === action.signatureId)[0];
      if (signature.marker.startsWith('[chk')) {
        otherCheckboxSignatures.forEach(otherCheckboxSignature => {
          let signatureUrl = 'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/x.png';
          if (signature && signature.isChecked) {
            signatureUrl =
              'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/unchecked.png';
          }

          state.signatures = state.signatures.filter(
            signature => signature.uuid !== otherCheckboxSignature.uuid
          );

          state.signatures.push({
            ...otherCheckboxSignature,
            image: signatureUrl,
            isChecked: !signature.isChecked,
          });

          state.signatureDocument.signatures = state.signatureDocument.signatures.filter(
            signature => signature.uuid !== otherCheckboxSignature.uuid
          );
        });
      }

      return { ...state };
    }
    case 'IN_PERSON::DELETE_SIGNATURE': {
      let signature = state.signatures.filter(signature => signature.uuid === action.signatureId);
      signature = signature.length === 1 ? signature[0] : null;
      state.signatureDocument.signatures.push({ ...signature, image: null });
      state.signatures = state.signatures.filter(
        signature => signature.uuid !== action.signatureId
      );
      return { ...state, signatureId: null };
    }
    case 'IN_PERSON::NEXT_ANCHOR_ID': {
      let signersSignatures = state.signatureDocument.signatures.filter(
        signature => signature.signer_uuid === state.currentSigner.uuid
      );

      if (signersSignatures && signersSignatures.length > 0) {
        if (state.anchorId + 1 > signersSignatures.length - 1 || action.justSigned === true) {
          state.anchorId = 0;
        } else {
          state.anchorId += 1;
        }
        state.showContinueButton = false;
      } else {
        state.anchorId = 0;
        state.showContinueButton = true;
      }

      return { ...state };
    }
    case 'IN_PERSON::NEXT_SIGNER': {
      if (state.currentSigner) {
        state.processedSigners.push(state.currentSigner);
        state.signatureDocument.signers = state.signatureDocument.signers.filter(
          signer => signer.uuid !== state.currentSigner.uuid
        );
      }

      let signer = state.signatureDocument.signers.filter(signer => signer.is_agent === false);
      if (signer.length === 0) {
        signer = state.signatureDocument.signers[0];
      } else {
        signer = signer[0];
      }

      return { ...state, currentSigner: signer };
    }
    case 'IN_PERSON::ENABLE_SIGN_BUTTON': {
      return { ...state, signButtonEnabled: action.shouldEnable };
    }
    case 'IN_PERSON::SIGN_SUCCESS': {
      return { ...state, signatureSuccess: true };
    }
    case 'IN_PERSON::SIGN_YEARLY': {
      return {
        ...state,
        signatureRenewalMonthly: false,
        signatureRenewalYearly: false,
        signatureSuccess: false,
      };
    }
    case 'IN_PERSON::CLEAR_SIGNATURES': {
      return {
        showDocument: false,
        showPad: false,
        showActionsMenu: false,
        showContinueButton: false,
        showFinishButton: false,
        showSignerDialog: false,
        showAgreementDialog: false,
        signButtonEnabled: false,
        isSending: false,
        anchorId: 0,
        signatures: [],
        signatureId: null,
        signatureSuccess: false,
        signatureDocument: null,
        processedSigners: [],
        padType: 'SIGNATURE',
        companyName: null,
      };
    }
    default:
      return state;
  }
}
