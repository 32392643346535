export default function reducer(
  state = {
    users: [],
    roles: [],
    userDetails: {},
    unitTypes: [],
    showNewUserDialog: false,
    showEditUserDialog: false,
    showOptionsMenu: false,
    anchorEl: null,
    user: null,
    usersCounts: {},
    searchTotalResultsCount: null,
    fetchingUsers: false,
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_USER::FETCH_USERS_PENDING': {
      return { ...state, fetchingUsers: true };
    }
    case 'LANDLORD_USER::STORE_UNIT_TYPES': {
      return { ...state, unitTypes: action.unitTypes };
    }
    case 'LANDLORD_USER::SHOW_USERS': {
      return { ...state, users: action.users };
    }
    case 'LANDLORD_USER::SHOW_FILTERED_USERS': {
      return {
        ...state,
        users: [...state.users.slice(0, action.offset), ...action.users],
        searchTotalResultsCount: action.searchTotalResultsCount,
        fetchingUsers: false,
      };
    }
    case 'LANDLORD_USER::SAVE_USERS_STATS': {
      return { ...state, usersCounts: action.payload };
    }
    case 'LANDLORD_USER::SHOW_USER': {
      const ssoProviders = ['okta', 'azure'];
      return {
        ...state,
        user: { ...action.user, providerIsSSO: ssoProviders.includes(action.user.provider) },
      };
    }
    case 'LANDLORD_USER::SAVE_ROLES': {
      return { ...state, roles: action.roles };
    }
    case 'LANDLORD_USER::SHOW_OPTIONS_MENU': {
      const userDetails = action.userDetails || state.userDetails;
      return {
        ...state,
        userDetails: userDetails,
        showOptionsMenu: action.shouldShow,
        anchorEl: action.anchorEl,
      };
    }
    default:
      return state;
  }
}
