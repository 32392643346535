export default function reducer(
  state = {
    dialogOpen: false,
    ticketDetails: { property: {}, events: [] },
    show_messaging: false,
    events: [],
    maintenanceTickets: [],
    maintenanceTicketNotes: [],
    maintenanceTicketsCounts: [],
    searchTotalResultsCount: null,
    fetchingMaintenanceTickets: false,
    fetchingNotes: false,
    ticketsLastFetched: 0,
    remindingVendor: false,
  },
  action
) {
  switch (action.type) {
    case 'MAINTENANCE::FETCH_MORE_TICKETS_PENDING': {
      return {
        ...state,
        fetchingMaintenanceTickets: true,
      };
    }
    case 'MAINTENANCE::FETCH_MORE_TICKETS_FULFILLED': {
      return {
        ...state,
        fetchingMaintenanceTickets: false,
      };
    }
    case 'MAINTENANCE::FETCH_TICKETS_PENDING': {
      return {
        ...state,
        fetchingMaintenanceTickets: true,
        maintenanceTickets: [],
      };
    }
    case 'MAINTENANCE::SAVE_TICKETS_STATS': {
      return {
        ...state,
        maintenanceTicketsCounts: action.payload,
      };
    }
    case 'MAINTENANCE::GET_TICKET': {
      return {
        ...state,
        ticketDetails: action.details,
      };
    }
    case 'MAINTENANCE::SAVE_ALL_TICKETS': {
      if (state.ticketsLastFetched > action.timestamp) return state;

      return {
        ...state,
        fetchingMaintenanceTickets: false,
        maintenanceTickets: action.maintenance_tickets,
        ticketsLastFetched: action.timestamp,
      };
    }
    case 'MAINTENANCE::SAVE_MORE_TICKETS': {
      return {
        ...state,
        fetchingMaintenanceTickets: false,
        maintenanceTickets: [
          ...state.maintenanceTickets.slice(0, action.offset),
          ...action.maintenance_tickets,
        ],
        searchTotalResultsCount: action.searchTotalResultsCount,
      };
    }
    case 'MAINTENANCE::RESET_TICKETS': {
      return {
        ...state,
        fetchingMaintenanceTickets: false,
        maintenanceTickets: [],
        searchTotalResultsCount: 0,
      };
    }
    case 'MAINTENANCE::OPEN_DIALOG': {
      return { ...state, dialogOpen: true };
    }
    case 'MAINTENANCE::CLOSE_DIALOG': {
      return { ...state, dialogOpen: false };
    }
    case 'MAINTENANCE::FETCH_NOTES_PENDING': {
      return { ...state, fetchingNotes: true };
    }
    case 'MAINTENANCE::SHOW_NOTES': {
      return {
        ...state,
        maintenanceTicketNotes: action.maintenanceTicketNotes,
        fetchingNotes: false,
      };
    }
    case 'MAINTENANCE::CLEAR_NOTES': {
      return { ...state, maintenanceTicketNotes: [], fetchingNotes: false };
    }
    case 'MAINTENANCE::REMIND_VENDOR_PENDING': {
      return { ...state, remindingVendor: true };
    }
    case 'MAINTENANCE::REMIND_VENDOR_FULFILLED': {
      return { ...state, remindingVendor: false };
    }
    case 'MAINTENANCE::REMIND_VENDOR_REJECTED': {
      return { ...state, remindingVendor: false };
    }
    default:
      return state;
  }
}
