import store from 'store';
import { getClient } from 'launchDarkly';

export function featureEnabledInDatabase(featureSet, key) {
  if (featureSet[key] && featureSet[key]['enabled'] === true) {
    return true;
  }
  return false;
}

export function featureEnabledInLaunchDarkly(key, defaultValue = false) {
  const client = getClient();

  if (!client) {
    console.warn('[LaunchDarkly]', 'Client not initialized while trying to read a feature flag');
    return false;
  }

  return client.variation(key, defaultValue);
}

// Checks if the feature is enabled in Launch Darkly
// It uses the feature from featureSet within redux as a default value
// Default to false if key does not exist on neither both.
export function featureEnabled(key) {
  const { featureSet } = store.getState().userTypes;

  return featureEnabledInLaunchDarkly(key, featureEnabledInDatabase(featureSet, key));
}

// Counts how many of the array of keys are enabled
export function countFeaturesEnabled(keys = []) {
  const { featureSet } = store.getState().userTypes;
  const featureSetKeys = Object.keys(featureSet);

  const launchDarklyClient = getClient();
  const launchDarklyFlags = launchDarklyClient ? launchDarklyClient.allFlags() : {};
  const launchDarklyFlagsKeys = Object.keys(launchDarklyFlags);

  let featuresEnabledCount = 0;

  keys.forEach((key) => {
    if (featureSetKeys.includes(key) && featureSet[key]['enabled']) {
      featuresEnabledCount++;
    } else if (launchDarklyFlagsKeys.includes(key) && launchDarklyFlags[key]) {
      featuresEnabledCount++;
    }
  });

  return featuresEnabledCount;
}
