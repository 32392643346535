import { createMuiTheme } from '@material-ui/core';
import getMuiTheme from 'material-ui/styles/getMuiTheme'; // Needed for onClick

export const muiTheme = (color) => {
  return getMuiTheme({
    fontFamily: '"Circular", sans-serif',
    palette: {
      textColor: '#4e4e4e',
      primary1Color: color,
      accent1Color: color,
      primary2Color: color,
      pickerHeaderColor: color,
      error: {
        main: '#ff0000',
      },
    },
    typography: {
      fontSize: 14,
      useNextVariants: true,
    },
    tabs: {
      backgroundColor: '#f7f7f7',
      textColor: '#8c8c8c',
      selectedTextColor: '#4e4e4e',
    },
    MuiDialogActions: {
      action: {
        textTransform: 'uppercase',
      },
    },
  });
};

export const muiThemeNext = (color) => {
  return createMuiTheme({
    palette: {
      textColor: '#4e4e4e',
      primary: { main: color },
      secondary: { main: color },
      error: { main: '#ff0000' },
      pickerHeaderColor: color,
      contrastThreshold: 0,
      tonalOffset: 0,
    },
    shape: {
      borderRadius: '8px',
    },
    tabs: {
      backgroundColor: '#f7f7f7',
      textColor: '#8c8c8c',
      selectedTextColor: '#4e4e4e',
    },
    overrides: {
      MuiInputBase: {
        root: {
          fontFamily: 'Circular',
        },
        inputMultiline: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
      MuiPrivateNotchedOutline: {
        legend: {
          border: 'none !important',
        },
      },
      MuiListItemText: {
        root: {
          textTransform: 'capitalize',
        },
      },
      MuiDialogActions: {
        root: {
          textTransform: 'uppercase',
        },
        action: {
          textTransform: 'uppercase',
        },
      },
      MuiDrawer: {
        paper: {
          height: '100% !important',
        },
      },
      MuiStepper: {
        root: {
          backgroundColor: '#fdfdfd',
        },
      },
      MuiStepIcon: {
        root: {
          color: '#dedede',
        },
        active: {
          color: color,
        },
        text: {
          fontFamily: 'Circular-Bold',
          fontSize: '11px',
        },
      },
      MuiListSubheader: {
        root: {
          fontSize: '12px',
        },
      },
      MuiButtonBase: {
        root: {
          boxShadow: 'none !important',
          textTransform: 'uppercase',
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: color,
          },
        },
        colorPrimary: {
          '&$checked': {
            color: color,
          },
        },
      },
      MuiButton: {
        root: {
          fontFamily: 'Circular',
          fontSize: '14px',
          boxShadow: 'none',
          color: color,
          textTransform: 'uppercase',
        },
        disabled: {
          color: color,
          backgroundColor: '#eee',
        },
        label: {
          fontFamily: 'Circular',
        },
      },
      MuiTypography: {
        body1: {
          color: '#4e4e4e',
          fontSize: '15px',
          fontFamily: 'Circular',
        },
        subheading: {
          fontFamily: 'Circular',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: '0 !important',
        },
      },
      MuiFormGroup: {
        root: {
          flexWrap: 'inherit',
          flexDirection: 'row',
        },
      },
      MuiDialog: {
        paper: {
          color: '#4e4e4e',
          width: '95%',
          maxWidth: '600px',
          borderRadius: '8px',
        },
      },
      MuiSelect: {
        root: {
          color: '#4e4e4e',
          width: '100%',
          fontFamily: 'Circular',
        },
        select: {
          color: '#4e4e4e',
          fontSize: '14px',
          fontFamily: 'Circular',
        },
        selectMenu: {
          color: '#4e4e4e',
          fontSize: '14px',
          fontFamily: 'Circular',
        },
      },
      MuiListItem: {
        root: {
          color: '#4e4e4e',
          fontSize: '15px',
          fontFamily: 'Circular',
          textTransform: 'capitalize',
        },
      },
      MuiPaper: {
        elevation8: {
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px -1px rgba(0, 0, 0, 0.25)',
        },
      },
      MuiOutlinedInput: {
        input: {
          fontSize: '14px',
        },
      },
      MuiInput: {
        root: {
          color: '#4e4e4e',
          fontSize: '15px',
          fontFamily: 'Circular',
        },
        input: {
          fontFamily: 'Circular',
          fontSize: '14px',
        },
        underline: {
          '&:not($disabled):before': {
            backgroundColor: '#e0e0e0',
            borderBottom: `1px solid #e0e0e0`,
            height: 1,
          },
          '&:hover:not($disabled):before': {
            backgroundColor: '#c5c5c5',
            borderBottom: `1px solid #c5c5c5`,
            height: 1,
          },
          '&:after': {
            borderBottom: `2px solid ${color}`,
          },
          '&:before': {
            borderBottom: `1px solid #e0e0e0`,
          },
          '&:hover:not(.MuiInput-disabled-203):not(.MuiInput-focused-202):not(.MuiInput-error-205):before':
            {
              borderBottom: `2px solid ${color}`,
            },
          '&:not($disabled):not($focused):not($error):before': {
            borderBottom: '#e0e0e0',
            height: 1,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '#c5c5c5',
            height: 1,
          },
        },
      },
      MuiMenuItem: {
        root: {
          color: '#4e4e4e',
          fontSize: '15px',
          fontFamily: 'Circular',
          textTransform: 'capitalize',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '14px',
          fontFamily: 'Circular',
        },
        shrink: {
          transform: 'translate(0, 8px) scale(0.75)',
          fontSize: 13,
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },
      MuiSvgIcon: {
        root: {},
        fontSize: {
          width: '2em',
          height: '2em',
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '12px',
          marginTop: '4px',
          fontFamily: 'Circular',
        },
      },
    },
  });
};

export const materialUIPickerTheme = (color) =>
  createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: color,
          fontFamily: 'Circular',
        },
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: `${color}8f`,
          fontFamily: 'Circular',
        },
      },
      MuiPickersDay: {
        selected: {
          backgroundColor: `${color} !important`,
          fontFamily: 'Circular',
        },
        current: {
          color: color,
          fontFamily: 'Circular',
        },
        day: {
          fontSize: '1rem',
          fontFamily: 'Circular',
          padding: 0,
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: {
          fontSize: '1rem',
          fontFamily: 'Circular',
        },
      },
      MuiInput: {
        root: {
          color: '#4e4e4e',
          fontSize: '14px',
          fontFamily: 'Circular',
        },
        underline: {
          '&:not($disabled):before': {
            backgroundColor: '#e0e0e0',
            height: 1,
          },
          '&:hover:not($disabled):before': {
            backgroundColor: '#c5c5c5',
            height: 1,
          },
          '&:after': {
            borderBottom: `2px solid ${color}`,
          },
          '&:before': {
            borderBottom: `1px solid #e0e0e0`,
          },
          '&:hover:not(.MuiInput-disabled-203):not(.MuiInput-focused-202):not(.MuiInput-error-205):before':
            {
              borderBottom: `2px solid ${color}`,
            },
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: color,
        },
        pin: {
          backgroundColor: color,
        },
        thumb: {
          borderColor: color,
        },
      },
      MuiTypography: {
        display2: {
          fontSize: '3.2125rem',
          fontFamily: 'Circular',
        },
        display1: {
          lineHeight: '0.9em',
          fontSize: '2.125rem',
          fontFamily: 'Circular',
        },
        subheading: {
          fontSize: '1rem',
          fontFamily: 'Circular',
        },
        body1: {
          fontSize: '1rem',
          fontFamily: 'Circular',
        },
        colorPrimary: {
          color: color,
        },
        headline: {
          fontFamily: 'Circular',
          fontSize: '2.5rem',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          fontSize: '1rem',
          color: color,
          fontFamily: 'Circular',
        },
      },
      MuiFormLabel: {
        root: {
          lineHeight: '12px',
          letterSpacing: '0.025em',
          fontSize: '14px',
          fontFamily: 'Circular',
        },
        focused: {
          lineHeight: '12px',
          letterSpacing: '0.025em',
          fontSize: '14px',
          fontFamily: 'Circular',
        },
      },
    },
  });

export const muiThemeNext16px = (color) => {
  return createMuiTheme({
    palette: {
      textColor: '#4e4e4e',
      primary: { main: color },
      secondary: { main: color },
      error: { main: '#ff0000' },
      pickerHeaderColor: color,
      contrastThreshold: 0,
      tonalOffset: 0,
    },
    shape: {
      borderRadius: '8px',
    },
    tabs: {
      backgroundColor: '#f7f7f7',
      textColor: '#8c8c8c',
      selectedTextColor: '#4e4e4e',
    },
    overrides: {
      MuiInputBase: {
        root: {
          fontFamily: 'Circular',
        },
        inputMultiline: {
          fontSize: '16px',
          lineHeight: '18px',
        },
      },
      MuiPrivateNotchedOutline: {
        legend: {
          border: 'none !important',
        },
      },
      MuiListItemText: {
        root: {
          textTransform: 'capitalize',
        },
      },
      MuiDialogActions: {
        root: {
          textTransform: 'uppercase',
        },
        action: {
          textTransform: 'uppercase',
        },
      },
      MuiDrawer: {
        paper: {
          height: '100% !important',
        },
      },
      MuiStepper: {
        root: {
          backgroundColor: '#fdfdfd',
        },
      },
      MuiStepIcon: {
        root: {
          color: '#dedede',
        },
        active: {
          color: color,
        },
        text: {
          fontFamily: 'Circular-Bold',
          fontSize: '11px',
        },
      },
      MuiListSubheader: {
        root: {
          fontSize: '12px',
        },
      },
      MuiButtonBase: {
        root: {
          boxShadow: 'none !important',
          textTransform: 'uppercase',
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: color,
          },
        },
        colorPrimary: {
          '&$checked': {
            color: color,
          },
        },
      },
      MuiButton: {
        root: {
          fontFamily: 'Circular',
          fontSize: '14px',
          boxShadow: 'none',
          color: color,
          textTransform: 'uppercase',
        },
        disabled: {
          color: color,
          backgroundColor: '#eee',
        },
        label: {
          fontFamily: 'Circular',
        },
      },
      MuiTypography: {
        body1: {
          color: '#4e4e4e',
          fontSize: '15px',
          fontFamily: 'Circular',
        },
        subheading: {
          fontFamily: 'Circular',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: '0 !important',
        },
      },
      MuiFormGroup: {
        root: {
          flexWrap: 'inherit',
          flexDirection: 'row',
        },
      },
      MuiDialog: {
        paper: {
          color: '#4e4e4e',
          width: '95%',
          maxWidth: '600px',
          borderRadius: '8px',
        },
      },
      MuiSelect: {
        root: {
          color: '#4e4e4e',
          width: '100%',
          fontFamily: 'Circular',
        },
        select: {
          color: '#4e4e4e',
          fontSize: '16px',
          fontFamily: 'Circular',
        },
        selectMenu: {
          color: '#4e4e4e',
          fontSize: '16px',
          fontFamily: 'Circular',
        },
      },
      MuiListItem: {
        root: {
          color: '#4e4e4e',
          fontSize: '15px',
          fontFamily: 'Circular',
          textTransform: 'capitalize',
        },
      },
      MuiPaper: {
        elevation8: {
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px -1px rgba(0, 0, 0, 0.25)',
        },
      },
      MuiOutlinedInput: {
        input: {
          fontSize: '16px',
        },
      },
      MuiInput: {
        root: {
          color: '#4e4e4e',
          fontSize: '16px',
          fontFamily: 'Circular',
        },
        input: {
          fontFamily: 'Circular',
          fontSize: '16px',
        },
        underline: {
          '&:not($disabled):before': {
            backgroundColor: '#e0e0e0',
            borderBottom: `1px solid #e0e0e0`,
            height: 1,
          },
          '&:hover:not($disabled):before': {
            backgroundColor: '#c5c5c5',
            borderBottom: `1px solid #c5c5c5`,
            height: 1,
          },
          '&:after': {
            borderBottom: `2px solid ${color}`,
          },
          '&:before': {
            borderBottom: `1px solid #e0e0e0`,
          },
          '&:hover:not(.MuiInput-disabled-203):not(.MuiInput-focused-202):not(.MuiInput-error-205):before':
            {
              borderBottom: `2px solid ${color}`,
            },
          '&:not($disabled):not($focused):not($error):before': {
            borderBottom: '#e0e0e0',
            height: 1,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '#c5c5c5',
            height: 1,
          },
        },
      },
      MuiMenuItem: {
        root: {
          color: '#4e4e4e',
          fontSize: '16px',
          fontFamily: 'Circular',
          textTransform: 'capitalize',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '14px',
          fontFamily: 'Circular',
        },
        shrink: {
          transform: 'translate(0, 8px) scale(0.75)',
          fontSize: 13,
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },
      MuiSvgIcon: {
        root: {},
        fontSize: {
          width: '2em',
          height: '2em',
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '12px',
          marginTop: '4px',
          fontFamily: 'Circular',
        },
      },
    },
  });
};
