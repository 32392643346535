export default function reducer(
  state = {
    showSuccessfulVerificationMessage: false,
    showLoader: false,
    surveyVerificationMessage: '',
    tenantFirstName: '',
    surveyInProgress: false,
  },
  action
) {
  switch (action.type) {
    case 'SURVEY::DISPLAY_SURVEY': {
      return {
        ...state,
        surveyTitle: action.surveyTitle,
        surveyDescription: action.surveyDescription,
        surveyQuestions: action.surveyQuestions,
        tenantFirstName: action.tenantFirstName,
        surveyInProgress: true,
      };
    }
    case 'SURVEY::DISPLAY_LANDLORD_VERIFICATION_MESSAGE': {
      return {
        ...state,
        surveyVerificationMessage: action.surveyVerificationMessage,
      };
    }
    case 'DISPLAY_LANDLORD_REFERENCE_VERIFICATION': {
      return {
        ...state,
        landlord_reference_verification: action.landlord_reference_verification,
        showLoader: false,
        showSuccessfulVerificationMessage: false,
      };
    }
    case 'DISPLAY_LOADER': {
      return {
        ...state,
        showLoader: action.display,
        maintenance_survey_verification: null,
        showSuccessfulVerificationMessage: false,
      };
    }
    case 'SURVEY::DISPLAY_SUCCESSFUL_VERIFICATION_MESSAGE': {
      return {
        ...state,
        showSuccessfulVerificationMessage: true,
        showLoader: false,
        surveyInProgress: false,
      };
    }
    case 'DISPLAY_MAINTENANCE_SURVEY_VERIFICATION': {
      return {
        ...state,
        maintenance_survey_verification: action.maintenance_survey_verification,
        showSuccessfulVerificationMessage: false,
        showLoader: false,
      };
    }
    default:
      return state;
  }
}
