import React from 'react';

export const RenderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <p
      style={{
        color: '#ff3434',
        fontSize: '14px',
        padding: '0 0px',
        textAlign: 'center',
        marginTop: '5px',
      }}
      id="errorMessage"
    >
      {error}
    </p>
  ) : (
    false
  );

export const RenderGhostError = ({ meta: { touched, error } }) =>
  touched && error ? <span id="errorMessage" /> : false;
