export default function reducer(
  state = {
    width: 0,
    height: 0,
  },
  action
) {
  switch (action.type) {
    case 'CHANGE_DIMENSIONS': {
      return { ...state, width: action.width, height: action.height };
    }
    default:
      return state;
  }
}
