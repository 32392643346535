import React, { Fragment } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: ${props => props.width || '100%'};
  position: relative;
  &::before {
    content: '\\25bc';
    position: absolute;
    color: var(--ultramarine-blue);
    width: auto;
    right: 1rem;
    top: ${props => (props.label ? '60%' : '50%')};
    transform: ${props => (props.label ? 'translateY(-30%)' : 'translateY(-50%)')} scale(0.8, 0.5);
    padding-top: 1px;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 51px;
  padding: 0 1rem;
  appearance: none;
  background-color: #fff;
  border-color: var(--light-periwinkle);
  font-size: 1rem;
`;

const StyledLabel = styled.label`
  margin-left: -10px;
  transform: scale(0.75) translateY(15px);
  transform-origin: left top 0px;
  float: left;
`;

export const Select = ({ width, options, label, name, ...rest }) => {
  return (
    <Fragment>
      {label && (
        <StyledLabel for={`select-${name}`} aria-label={label}>
          {label}
        </StyledLabel>
      )}
      <StyledContainer width={width} label={label}>
        <StyledSelect id={`select-${name}`} name={name} {...rest}>
          {options.map(option => (
            <option key={`option-${option.value}`} {...option}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </StyledContainer>
    </Fragment>
  );
};
