const initialState = {
  landlordDetails: {},
  activeConversation: null,
  lastMessageTime: null,
  lastConversationTime: null,
  properties: [],
  conversations: [],
  messages: [],
  toggleChatDrawer: false,
  loadingMostRecentMessages: false,
  loadingMoreMessages: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_CHAT_DRAWER': {
      return {
        ...state,
        toggleChatDrawer: action.showDrawer,
      };
    }
    case 'LOAD_CONVERSATIONS': {
      return { ...state, loadingConversations: true };
    }
    case 'LOAD_CONVERSATIONS_SUCCESS': {
      let lastConversationTime =
        action.conversations.length > 0 ? action.conversations[0].updated_at : null;
      return {
        ...state,
        loadingConversations: false,
        conversations: action.conversations,
        lastConversationTime: lastConversationTime,
      };
    }
    case 'LOAD_MORE_CONVERSATIONS_SUCCESS': {
      return {
        ...state,
        loadingConversations: false,
        conversations: state.conversations.concat(action.conversations),
      };
    }
    case 'ACTIVE_CONVERSATION': {
      return {
        ...state,
        activeConversation: action.conversation,
      };
    }
    case 'UPDATE_CONVERSATIONS_SUCCESS': {
      const updatedConversations = action.conversations;
      const conversations = [...state.conversations];
      for (let i = 0; i < updatedConversations.length; i++) {
        for (let j = 0; j < conversations.length; j++) {
          if (updatedConversations[i].id === conversations[j].id) {
            conversations[j] = updatedConversations[i];
          }
        }
      }

      return {
        ...state,
        conversations,
      };
    }

    case 'LOAD_MESSAGES': {
      return { ...state, loadingMostRecentMessages: true, loadingMoreMessages: false };
    }
    case 'LOAD_MORE_MESSAGES': {
      return { ...state, loadingMostRecentMessages: false, loadingMoreMessages: true };
    }
    case 'LOAD_MESSAGES_SUCCESS': {
      let lastMessageTime =
        action.messages.length > 0 && action.messages[action.messages.length - 1].date;
      return {
        ...state,
        lastMessageTime: lastMessageTime,
        loadingMostRecentMessages: false,
        loadingMoreMessages: false,
        messages: action.messages,
      };
    }
    case 'LOAD_MORE_MESSAGES_SUCCESS': {
      return {
        ...state,
        loadingMostRecentMessages: false,
        loadingMoreMessages: false,
        messages: action.messages.concat(state.messages),
      };
    }
    case 'NEW_MESSAGE': {
      return {
        ...state,
        lastMessageTime: action.message.date,
        messages: [
          ...state.messages.filter(m => m.message_id !== action.message.message_id),
          action.message,
        ],
      };
    }
    case 'LANDLORD_DETAILS': {
      return { ...state, landlordDetails: action.landlordDetails };
    }
    case 'LANDLORD_PROPERTIES': {
      return { ...state, properties: action.properties };
    }
    default:
      return state;
  }
}
