export default function reducer(
  state = {
    leaseDetails: {},
    leaseDocumentDetails: {},
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD::SAVE_LEASE_DETAILS': {
      return { ...state, leaseDetails: action.leaseDetails };
    }
    case 'LANDLORD::SAVE_LEASE_DOCUMENT_DETAILS': {
      return { ...state, leaseDocumentDetails: action.leaseDocumentDetails };
    }
    case 'LANDLORD::SAVE_LEASE_DETAILS_IN_PROGRESS': {
      return { ...state, leaseDetails: action.leaseDetails };
    }
    default:
      return state;
  }
}
