import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/ColorHelper';

const StyledButton = styled.button`
  color: ${props => (props.disabled ? colors.BATTLESHIP_GREY : props.accentColor)};
  font-size: 14px;
  border: none;
  border-bottom: 1px solid ${props =>
      props.disabled ? colors.BATTLESHIP_GREY : props.accentColor};
  padding: 0;
  border-radius: 0 !important;
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const CtaText = ({ accentColor = colors.ULTRAMARINE_BLUE, children, ...rest }) => {
  return (
    <StyledButton accentColor={accentColor} {...rest}>
      {children}
    </StyledButton>
  );
};

export default CtaText;
