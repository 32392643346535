import { initialColors, getRandomColour } from 'helpers/ColorHelper';
import { FETCH_RESIDENTS } from './actionTypes';

const initialState = {
  isFetching: false,
  residents: [],
};

function doReceiveResidents(state, action) {
  let residents = action.payload.residents;
  // Add a random colour for the profile circle
  residents = residents.map(resident => {
    return { ...resident, color: getRandomColour(initialColors) };
  });

  return { ...state, residents, isFetching: false };
}

const serviceRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_RESIDENTS}_PENDING`:
      return { ...state, isFetching: true };
    case `${FETCH_RESIDENTS}_REJECTED`:
      return { ...state, isFetching: false };
    case `${FETCH_RESIDENTS}_FULFILLED`:
      return doReceiveResidents(state, action);

    default:
      return state;
  }
};

export default serviceRequestsReducer;
