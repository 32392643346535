import {
  GET_CLIENT_ID,
  CREATE_HELLOSIGN_UNCLAIMED_DRAFT,
  GET_SIGN_URL
} from 'actions/landlordSignableDocActions';

export default function reducer(
  state = {
    fetchingClientId: false,
    clientId: undefined,
    fetchingHellosignUnclaimedDraft: false,
    hellosignUnclaimedDraft: {},
    fetchingSignUrl: false,
  },
  action
) {
  switch (action.type) {
    case `${GET_CLIENT_ID}_PENDING`: {
      return { ...state, fetchingClientId: true };
    }
    case `${GET_CLIENT_ID}_FULFILLED`: {
      return { ...state, fetchingClientId: false, clientId: action.payload.client_id };
    }
    case `${GET_CLIENT_ID}_REJECTED`: {
      return { ...state, fetchingClientId: false };
    }
    case `${CREATE_HELLOSIGN_UNCLAIMED_DRAFT}_PENDING`: {
      return {
        ...state,
        fetchingHellosignUnclaimedDraft: true,
      };
    }
    case `${CREATE_HELLOSIGN_UNCLAIMED_DRAFT}_REJECTED`: {
      return {
        ...state,
        fetchingHellosignUnclaimedDraft: false,
      };
    }
    case `${CREATE_HELLOSIGN_UNCLAIMED_DRAFT}_FULFILLED`: {
      return {
        ...state,
        fetchingHellosignUnclaimedDraft: false,
        hellosignUnclaimedDraft: action.payload.data.attributes,
      };
    }
    case `${GET_SIGN_URL}_PENDING`: {
      return {
        ...state,
        fetchingSignUrl: true,
      };
    }
    case `${GET_SIGN_URL}_REJECTED`: {
      return {
        ...state,
        fetchingSignUrl: false,
      };
    }
    case `${GET_SIGN_URL}_FULFILLED`: {
      return {
        ...state,
        fetchingSignUrl: false,
      };
    }
    default:
      return state;
  }
}
