import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import FieldItems from 'components/shared/FieldItems/FieldItems';

import { RenderLeaseItem } from 'components/shared/LeaseItems/helpers';
import LeaseItemForm from 'components/shared/LeaseItems/LeaseItemForm';
import { getProperty } from 'actions/landlordPropertiesActions';
import { deepDiver } from 'helpers/utilities';
import { getTranslatedString } from 'helpers/i18n';
import { featureEnabled } from 'helpers/ToggleBot';
import isEmpty from 'lodash/isEmpty';

class LeaseItems extends React.Component {
  componentDidMount() {
    if (this.props.selectedProperty && this.props.selectedProperty.value) {
      this.props.getProperty(this.props.selectedProperty.value);
    }
  }

  render() {
    const yardiBasedDisabled =
      !isEmpty(this.props.propertyFetched) &&
      !featureEnabled('ui.lease_creator.yuhu_rentable_items') &&
      this.props.propertyFetched.ancillary_items &&
      this.props.propertyFetched.ancillary_items.length === 0;
    const disabled =
      this.props.disabled ||
      !this.props.selectedProperty ||
      isEmpty(this.props.propertyFetched) ||
      yardiBasedDisabled;

    return (
      <div className="row" style={{ marginBottom: '25px' }}>
        <div>
          <h3 style={{ textAlign: 'center', margin: '25px 0px 5px 0px' }}>
            {getTranslatedString('landlordPortal.leaseItems.items')}{' '}
          </h3>

          <p style={{ textAlign: 'center', marginBottom: '15px' }}>
            {yardiBasedDisabled
              ? getTranslatedString('landlordPortal.leaseItems.noRentableItemsForYardi')
              : getTranslatedString('landlordPortal.leaseItems.addLeasingItems')}
          </p>
        </div>

        <FieldItems
          name="lease_items"
          formName={this.props.formName}
          renderSavedItems={RenderLeaseItem}
          renderForm={LeaseItemForm}
          disabled={disabled}
          dialogHeader={getTranslatedString('landlordPortal.leaseItems.addLeaseItem')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedProperty: formValueSelector(ownProps.formName)(state, 'property'),
  propertyFetched: deepDiver(state, 'landlordProperties.property'),
});

const mapDispatchToProps = { getProperty };

export default connect(mapStateToProps, mapDispatchToProps)(LeaseItems);
