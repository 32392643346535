import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

import thunk from 'redux-thunk';
import throttleActions from 'redux-throttle';

import promiseMiddleware from 'redux-promise-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import launchDarklyMiddleware from 'redux/middlewares/launchDarkly';

import reducer from './reducers';

let middleware = null;

const defaultWait = 3000; // Milliseconds
const defaultThrottleOption = {
  // https://lodash.com/docs#throttle
  leading: true,
  trailing: true,
};

const throttle = throttleActions(defaultWait, defaultThrottleOption);

const hostParts = window.location.hostname.split('.');
const hostname = hostParts.length === 1 ? hostParts[0] : hostParts[1];
if (hostname === 'localhost') {
  middleware = composeWithDevTools(
    applyMiddleware(
      thunk,
      promiseMiddleware,
      loadingBarMiddleware(),
      throttle,
      launchDarklyMiddleware
    )
  );
} else {
  middleware = applyMiddleware(
    thunk,
    promiseMiddleware,
    loadingBarMiddleware(),
    throttle,
    launchDarklyMiddleware
  );
}

export default createStore(reducer, middleware);
