// TODO: [subdomain-urls]
import { getEnvFromSubdomain } from 'helpers/UrlHelper';
import { getCustomAPISubdmomain } from 'helpers/UrlHelper';

const BASE_CONSTANTS = {};

const envFromSubdomain = getEnvFromSubdomain() ? `${getEnvFromSubdomain()}-` : '';
const customAPISubdmomain = getCustomAPISubdmomain() ? `${getCustomAPISubdmomain()}-` : '';
const environment = envFromSubdomain || customAPISubdmomain;

const LOCAL_CONSTANTS = {
  ENV: 'local',
  BASE_API_URL: `http://${environment}api.localhost`,
  WS_URL: `ws://api.localhost/cable`,
};

const QA_CONSTANTS = {
  ENV: 'qa',
  BASE_API_URL: `https://${environment}api.${window.CLIENT_URL}`,
  WS_URL: `wss://${environment}api.${window.CLIENT_URL}/cable`,
};

const STAGING_CONSTANTS = {
  ENV: 'staging',
  BASE_API_URL: `https://${environment}api.${window.CLIENT_URL}`,
  WS_URL: `wss://${environment}api.${window.CLIENT_URL}/cable`,
};

const TRAINING_CONSTANTS = {
  ENV: 'training',
  BASE_API_URL: `https://${environment}api.${window.CLIENT_URL}`,
  WS_URL: `wss://${environment}api.${window.CLIENT_URL}/cable`,
};

const PROD_CONSTANTS = {
  ENV: 'production',
  BASE_API_URL: `https://${environment}api.${window.CLIENT_URL}`,
  WS_URL: `wss://${environment}api.${window.CLIENT_URL}/cable`,
};

function getEnv() {
  const hostParts = window.location.hostname.split('.');
  let [subdomain, hostname] = hostParts.length === 1 ? [null, hostParts[0]] : hostParts;

  if (envFromSubdomain) {
    subdomain = subdomain.replace(envFromSubdomain, '');
  }
  const URL_CONSTANTS = {
    SUBDOMAIN: subdomain,
    HOSTNAME: hostname,
    CLIENT_HOSTNAME:
      subdomain && subdomain !== 'app' && subdomain !== window.DEFAULT_ENV_SUBDOMAIN
        ? subdomain
        : hostname,
  };
  const BASE_URL = `https://${subdomain}.${window.CLIENT_URL}`;

  if (window.ENV === 'production') {
    return {
      ...BASE_CONSTANTS,
      ...PROD_CONSTANTS,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  } else if (window.ENV === 'dev') {
    return {
      ...BASE_CONSTANTS,
      ...QA_CONSTANTS,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  } else if (window.ENV === 'qa') {
    return {
      ...BASE_CONSTANTS,
      ...QA_CONSTANTS,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  } else if (window.ENV === 'staging') {
    return {
      ...BASE_CONSTANTS,
      ...STAGING_CONSTANTS,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  } else if (window.ENV === 'training') {
    return {
      ...BASE_CONSTANTS,
      ...STAGING_CONSTANTS,
      ...TRAINING_CONSTANTS,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  } else if (window.ENV === 'development') {
    const BASE_URL = `${subdomain ? `${subdomain}.` : ''}${hostname}`;
    return {
      ...BASE_CONSTANTS,
      ...LOCAL_CONSTANTS,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  } else {
    const BASE_URL = `${subdomain ? `${subdomain}.` : ''}${hostname}`;
    return {
      ...BASE_CONSTANTS,
      ENV: 'local',
      BASE_API_URL: `http://${environment}api.missing_env`,
      ...URL_CONSTANTS,
      BASE_URL,
    };
  }
}

/**
 * Map different yuhu environments to consolidated HappyCo environment set.
 * Used to access equivalent services/experiences in an environment across HappyCo (such as the graph API, or other
 * product stacks).
 *
 * @returns 'LOCAL' | 'DEV' | 'STAGING' | 'PROD'
 */
const getConsolidatedEnv = () => {
  /**
   * @type {'local' | 'development' | 'dev' | 'qa' | 'staging' | 'training' | 'production'}
   */
  const env = window.ENV;
  switch (env) {
    case 'local':
    case 'development':
      return 'LOCAL';
    case 'dev':
      return 'DEV';
    case 'qa':
    case 'staging':
      return 'STAGING';
    case 'training':
    case 'production':
    default:
      return 'PROD';
  }
};

export default getEnv();
export { getConsolidatedEnv };
