export default function reducer(
  state = {
    vendors: [],
    groupedVendors: [],
    assignment: [],
    vendorDetails: { address: {}, vendor_transactions: [] },
    vendorDialogIsOpen: false,
    addVendorDialogIsOpen: false,
    editVendor: false,
  },
  action
) {
  switch (action.type) {
    case 'VENDORS::SAVE_VENDORS': {
      return { ...state, vendors: action.vendors };
    }
    case 'VENDORS::SAVE_GROUPED_VENDORS': {
      return {
        ...state,
        groupedVendors: action.vendor,
        assignment: action.assignment,
      };
    }
    case 'VENDORS::SHOW_ADD_VENDOR_INFO_DIALOG': {
      return {
        ...state,
        addVendorDialogIsOpen: action.isOpen,
        vendorDetails: { address: {}, vendor_transactions: [] },
        editVendor: false,
      };
    }
    case 'VENDORS::SHOW_EDIT_VENDOR_DIALOG': {
      return {
        ...state,
        addVendorDialogIsOpen: action.isOpen,
        editVendor: true,
      };
    }
    case 'VENDORS::CLOSE_ADD_VENDOR_INFO_DIALOG': {
      return {
        ...state,
        addVendorDialogIsOpen: action.isOpen,
        vendorDetails: { address: {}, vendor_transactions: [] },
        editVendor: false,
      };
    }
    case 'VENDORS::CLOSE_VENDOR_INFO_DIALOG': {
      return { ...state, vendorDialogIsOpen: action.isOpen };
    }
    case 'VENDORS::SHOW_VENDOR_INFO_DIALOG': {
      return {
        ...state,
        vendorDialogIsOpen: action.isOpen,
        vendorDetails: action.vendorDetails,
      };
    }
    default:
      return state;
  }
}
