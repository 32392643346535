import * as actionTypes from './actionTypes';

const initialState = {
  showResidentDrawer: false,
  loading: false,
  showInvitedDrawer: false,
  showOptOutResident: false,
  showPasswordReset: false,
  showDeleteDrawer: false,
};

const residentDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_RESIDENT_DRAWER:
      return { ...state, showResidentDrawer: true };
    case actionTypes.CLOSE_RESIDENT_DRAWER:
      return { ...state, showResidentDrawer: false };
    case actionTypes.LOADING:
      return { ...state, loading: action.loading };
    case actionTypes.SHOW_INVITED_DRAWER:
      return { ...state, showInvitedDrawer: action.show };
    case actionTypes.SHOW_OPT_OUT_RESIDENT:
      return { ...state, showOptOutResident: action.show };
    case actionTypes.SHOW_PASSWORD_RESET:
      return { ...state, showPasswordReset: action.show };
    case actionTypes.SHOW_DELETE_DRAWER:
      return { ...state, showDeleteDrawer: action.show };

    default:
      return state;
  }
};

export default residentDrawerReducer;
