export default function reducer(
  state = {
    unit: null,
    lease_id: null,
    inspection_type: null,
    inspectionReport: null,
    inspectionDocument: null,
    inspections: [],
    fetchingInspectionTemplates: false,
    upsertingTemplate: false,
    templates: [],
    templatesCounts: {},
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_INSPECTIONS::CLEAR_INSPECTIONS': {
      return {
        ...state,
        unit: null,
        lease_id: null,
        inspection_type: null,
        inspectionReport: {},
        templates: [],
      };
    }
    case 'LANDLORD_INSPECTIONS::SAVE_UNIT': {
      return { ...state, unit: action.unit };
    }
    case 'LANDLORD_INSPECTIONS::SAVE_INSPECTIONS': {
      return { ...state, inspections: action.inspections };
    }
    case 'LANDLORD_INSPECTIONS::SAVE_INSPECTION_REPORT': {
      return { ...state, inspectionReport: action.inspectionReport };
    }
    case 'LANDLORD_INSPECTIONS::SAVE_INSPECTION_DOCUMENT': {
      return { ...state, inspectionDocument: action.inspectionDocument };
    }
    case 'LANDLORD_INSPECTIONS::SAVE_INSPECTION_TYPE': {
      return { ...state, inspection_type: action.inspectionType };
    }
    case 'LANDLORD_INSPECTIONS::SAVE_INFO': {
      // We only want to update unit and inspection_report if we pass in a value
      const unitAndReport = {};
      if (action.unit) unitAndReport['unit'] = action.unit;
      if (action.inspection_report) unitAndReport['inspectionReport'] = action.inspection_report;

      return {
        ...state,
        ...unitAndReport,
        lease_id: action.lease_id,
        inspection_type: action.inspection_type,
      };
    }
    case 'LANDLORD_INSPECTIONS::CREATE_TEMPLATE_PENDING': {
      return { ...state, upsertingTemplate: true };
    }
    case 'LANDLORD_INSPECTIONS::CREATE_TEMPLATE_FULFILLED': {
      return { ...state, upsertingTemplate: false };
    }
    case 'LANDLORD_INSPECTIONS::CREATE_TEMPLATE_REJECTED': {
      return { ...state, upsertingTemplate: false };
    }
    case 'LANDLORD_INSPECTIONS::FETCH_FILTERED_TEMPLATES_PENDING': {
      return {
        ...state,
        fetchingInspectionTemplates: true,
      };
    }
    case 'LANDLORD_INSPECTIONS::FETCH_FILTERED_TEMPLATES_FULFILLED': {
      const newTemplates = action.payload.data.map(template => {
        return {
          ...template.attributes,
          ...template.attributes.data,
        };
      });
      return {
        ...state,
        fetchingInspectionTemplates: false,
        templatesSearchResultsCount: action.payload.meta.total_count,
        templates: [...state.templates.slice(0, action.payload.meta.offset), ...newTemplates],
      };
    }
    case 'LANDLORD_INSPECTIONS::FETCH_FILTERED_TEMPLATES_REJECTED': {
      return {
        ...state,
        fetchingInspectionTemplates: false,
      };
    }
    case 'LANDLORD_INSPECTIONS::FETCH_TEMPLATE_STATS_FULFILLED': {
      return { ...state, templatesCounts: action.payload.template_counts };
    }
    default:
      return state;
  }
}
