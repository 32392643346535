export default function reducer(
  state = {
    maintenanceGraphData: {},
    inspectionGraphData: {},
    loadingMaintenanceGraphData: false,
    loadingInspectionGraphData: false,
    maintenanceGraphLevel: null,
    inspectionGraphLevel: null,
    insightUserMaintenanceTickets: [],
    insightUserDetails: {},
  },
  action
) {
  switch (action.type) {
    case 'DASHBOARD_MAINTENANCE_INSIGHTS::RECEIVE_GRAPH_DATA': {
      return {
        ...state,
        maintenanceGraphData: {
          ...state.maintenanceGraphData,
          [action.data.insight_id]: action.data,
        },
        loadingMaintenanceGraphData: false,
        maintenanceGraphLevel: action.data.insight_id,
      };
    }
    case 'DASHBOARD_MAINTENANCE_INSIGHTS::RECEIVE_USER_TICKETS': {
      return {
        ...state,
        insightUserMaintenanceTickets: action.data,
        insightUserDetails: {
          userId: action.userId,
          userName: action.userName,
        },
        loadingMaintenanceGraphData: false,
      };
    }
    case 'DASHBOARD_MAINTENANCE_INSIGHTS::LOADING_GRAPH_DATA': {
      return {
        ...state,
        loadingMaintenanceGraphData: true,
      };
    }
    case 'DASHBOARD_MAINTENANCE_INSIGHTS::SET_GRAPH_LEVEL': {
      return {
        ...state,
        maintenanceGraphLevel: action.level,
      };
    }
    case 'DASHBOARD_MAINTENANCE_INSIGHTS::GRAPH_DATA_ERROR': {
      return {
        ...state,
        loadingMaintenanceGraphData: false,
        maintenanceGraphLevel: null,
        insightUserMaintenanceTickets: [],
        insightUserDetails: {},
      };
    }
    default:
      return state;
  }
}
