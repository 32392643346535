import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import { localize } from 'react-localize-redux';
import '../byesign/signaturePage.css';
import { localizePrivacyStatementLink } from 'helpers/Localization';
import { getCurrentLocale } from 'helpers/i18n';

class TermsOfServiceDialog extends Component {
  render() {
    const { translate, ...otherProps } = this.props;
    return (
      <Dialog
        autoScrollBodyContent={true}
        bodyStyle={{ '-ms-overflow-y': 'none' }}
        contentStyle={{ '-ms-overflow-y': 'none' }}
        modal={false}
        {...otherProps}
      >
        <div>
          <div>
            <h1>YUHU, INC.</h1>
            <h1>YUHU.IO TERMS OF USE</h1>
            <div>
              <div>
                <p>
                  <em>Last Updated: August 25, 2017</em>
                </p>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  Welcome to the Web site of YUHU Inc.. Your use of this Web site (the "Site") and
                  the services made available on the Site is subject to these Terms of Use (these
                  "Terms"). Yuhu provides you on-demand electronic signature services, as updated
                  from time to time, which includes online uploads, display, delivery,
                  acknowledgment, electronic signature, and limited storage services for eContracts
                  (the "Services"). By using any of our Services, you agree to be bound by, and use
                  our Services in compliance with, these Terms of Use. IF YOU DO NOT AGREE TO THESE
                  TERMS OF USE, DO NOT USE OUR SERVICES.
                </p>
                <p>
                  We may make changes to these Terms from time to time. When we do, we will revise
                  the "last updated" date given above. It is your responsibility to review these
                  Terms frequently and to remain informed of any changes to them. The then-current
                  version of these Terms will supersede all earlier versions. You agree that your
                  continued use of our Services after such changes have been published to our
                  Services will constitute your acceptance of such revised Terms.
                </p>
                <p>‍</p>
                <h4>LICENSE TO USE OUR SERVICES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  Subject to these Terms, we and our licensors grant to you a limited, personal,
                  non-exclusive, non-transferable license to use our Services for your personal use
                  and not for resale or further distribution. Your right to use our Services is
                  limited by all terms and conditions set forth in these Terms. Except for your
                  pre-existing rights and this license granted to you, we and our licensors retain
                  all right, title and interest in and to our Services, including all related
                  intellectual property rights. Our Services and those of our licensors are
                  protected by applicable intellectual property laws, including Canadian copyright
                  law and international treaties. Except as otherwise explicitly provided in these
                  Terms or as may be expressly permitted by applicable law, you will not, and will
                  not permit or authorize any third party to: (i) reproduce, modify, translate,
                  enhance, decompile, disassemble, reverse engineer or create derivative works of
                  any of our Services; (ii) rent, lease or sublicense access to any of our Services;
                  or (iii) circumvent or disable any security or technological features or measures
                  of our Services. Any rights not expressly granted herein are reserved by us.
                </p>
                <p>‍</p>
                <h4>OWNERSHIP OF YOUR CONTENT</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  You retain ownership of all intellectual property rights in any content submitted
                  by you in the course of using the Services ("Content"). Yuhu does not claim
                  ownership over any of your Content. These Terms do not grant us any licenses or
                  rights to your Content except for the limited rights needed for us to provide the
                  Services to you.
                </p>
                <p>‍</p>
                <h4>ACCESS TO OUR SERVICES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  We do not provide you with the equipment to access our Services. You are
                  responsible for all fees charged by third parties related to your access and use
                  of our Services (e.g., charges by Internet service providers). We reserve the
                  right to modify or discontinue, temporarily or permanently, all or any portion of
                  our Services without notice. We will not be liable to you or to any third party
                  for any modification, suspension, or discontinuance of all or any portion of our
                  Services. We also reserve the right, in our sole discretion, to reject, refuse to
                  post, or remove any material that you post or submit for posting, and to restrict,
                  suspend, or terminate your access to our Services at any time, for any or no
                  reason, with or without prior notice, and without liability.
                </p>
                <p>‍</p>
                <h4>AGREEING TO THE SERVICES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  To enable parties to rely upon the signed documents between them, you agree, in
                  using our Services or submitting an eSigned document, to the means we use to
                  secure electronic records and electronic signatures for the eDocument you signed
                  through us.
                </p>
                <p>‍</p>
                <h4>RESTRICTIONS</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  You must comply with all applicable laws when using our Services. Other than
                  viewing, signing, retrieving, or exercising other rights you may have to a
                  eContract provided to you by another user through the Services, and except as may
                  be expressly permitted by applicable law or as YUHU may authorize expressly in
                  writing, you will not, and will not permit anyone else to: (i) store, copy,
                  modify, distribute, or resell any of the information; audio, visual, and
                  audiovisual works, or other content made available on our Services (collectively,
                  "Service Content") or compile or collect any Service Content as part of a database
                  or other work; (ii) use any automated tool (e.g., robots, spiders) to access or
                  use our Services or to store, copy, modify, distribute, or resell any Service
                  Content; (iii) rent, lease, or sublicense your access to our Services to another
                  person; (iv) use any Services or Service Content for any purpose except for your
                  own personal use; (v) circumvent or disable any digital rights management, usage
                  rules, or other security features of our Services; (vi) use our Services in a
                  manner that overburdens, or that threatens the integrity, performance, or
                  availability of, our Services; or (vii) remove, alter, or obscure any proprietary
                  notices (including copyright and trademark notices) on any portion of our Services
                  or any Service Content. The Site and the Services may not be used: (i) in (or by a
                  national or resident of) Cuba, Iran, North Korea, Sudan, Syria, or any other
                  country with respect to which the United States maintains trade sanctions
                  prohibiting the shipment of goods; or (ii) to anyone on or acting on behalf of an
                  entity on the SDN List or the U.S. Commerce Department’s Denied Persons List or
                  Entities List (together referred to as "U.S. Prohibited Party Lists"). By using
                  the Site and the Service, you represent and warrant that you are not (a) located
                  in or a national or resident of any country noted above that is subject to U.S.
                  trade sanctions, or (b) on any U.S. Prohibited Party List or acting on behalf of
                  any person or entity on any such list.
                </p>
                <p>‍</p>
                <h4>PRIVACY POLICY</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  We may collect registration and other information about you through our Services.
                  Our collection and use of this information is governed by our Privacy Policy,
                  available&nbsp;{' '}
                  <a
                    href={localizePrivacyStatementLink(
                      this.props.privacyStatementLink,
                      getCurrentLocale()
                    )}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </a>.
                </p>
                <p>‍</p>
                <h4>LINKING TO OUR SERVICES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  Framing of our Services within or as part of any third-party services, or any
                  other manner of incorporating parts of our Services as part of another Web site or
                  service, is not permitted without our prior written consent.
                </p>
                <p>‍</p>
                <h4>RESTRICTED AREAS OF THE SERVICES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  Certain parts of our Services, including account management features, may be
                  password-restricted to registered users or other authorized persons
                  ("Password-Protected Areas"). If you are authorized to gain access to any
                  Password-Protected Areas, you agree that you are entirely responsible for
                  maintaining the confidentiality of your password, and agree to notify us if the
                  password is lost, stolen, disclosed to an unauthorized third party, or otherwise
                  may have been compromised. You agree that you are entirely responsible for any and
                  all activities that occur under your account, whether or not you are the
                  individual who undertakes such activities. You agree to immediately notify us of
                  any unauthorized use of your account or any other breach of security in relation
                  to your password or our Services that is known to you.
                </p>
                <p>‍</p>
                <h4>USER POSTINGS TO OUR SERVICES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  You may have the opportunity to post information and content to our Services. You
                  agree that we are free to use any comments, information, ideas, concepts, methods,
                  techniques, content, and any other material in any post or submission that you may
                  make to our Services, such as on our blog, (each, a "Submission"), without further
                  compensation, acknowledgement, or payment, for any purpose whatsoever including,
                  without limitation, modifying or improving the Services. Furthermore, by making
                  any Submission on the Services, you grant us a perpetual, non-exclusive, fully
                  paid, royalty-free, irrevocable, sublicensable, worldwide license and right to
                  display, use, perform, reproduce, modify, distribute and create derivative works
                  of the Submission in any media, software, or technology of any kind now existing
                  or developed in the future, without any obligation to provide attribution or
                  compensation to you or any third party. A Submission does not include any content
                  you upload on your account in normal use of the Services, such as a contract you
                  upload and send for signature.
                </p>
                <p>
                  BY POSTING OR PROVIDING ANY SUBMISSION, YOU REPRESENT AND WARRANT THAT PUBLIC
                  POSTING AND USE OF YOUR SUBMISSION BY US WILL NOT INFRINGE UPON OR VIOLATE THE
                  RIGHTS OF ANY THIRD PARTY.
                </p>
                <p>‍</p>
                <h4>LINKS AND THIRD PARTY CONTENT</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  Our Services may display, or contain links to, third party products, services, and
                  Web sites. Any opinions, advice, statements, services, offers, or other
                  information that constitutes part of the content expressed, authored, or made
                  available by other users or other third parties on our Services, or which is
                  accessible through or may be located using our Services (collectively, "Third
                  Party Content") are those of the respective authors or producers and not of us or
                  our shareholders, directors, officers, employees, agents, or representatives. We
                  do not control Third Party Content and do not guarantee the accuracy, integrity or
                  quality of such Third Party Content. We are not responsible for the performance
                  of, we do not endorse, and we are not responsible or liable for, any Third Party
                  Content or any information or materials advertised in any Third Party Content. By
                  using our Services, you may be exposed to content that is offensive, indecent, or
                  objectionable. We are not to be responsible or liable, directly or indirectly, for
                  any damage or loss caused to you by your use of or reliance on any goods,
                  services, or information available on or through any third party service or Third
                  Party Content. It is your responsibility to evaluate the information, opinion,
                  advice, or other content available on and through our Services.
                </p>
                <p>‍</p>
                <h4>USE POLICIES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  You are solely responsible for any Content and other material that you submit,
                  publish, transmit, or display on, through, or with our Services.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  You will not use our Services to: (i) upload, post, email, or otherwise transmit
                  any Submission that contains unlawful, harmful, threatening, abusive, harassing,
                  tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy,
                  hateful, or racially, ethnically or otherwise objectionable; (ii) harm us or third
                  parties in any way; (iii) impersonate any person or entity, or otherwise
                  misrepresent your affiliation with a person or entity; (iv) upload, post, email,
                  or otherwise transmit any Submission that you do not have a right to transmit
                  under any law or under contractual or fiduciary relationships (such as inside
                  information, proprietary and confidential information learned or disclosed as part
                  of employment relationships or under nondisclosure agreements); (v) upload, post,
                  email or otherwise transmit any Submission that infringes any patent, trademark,
                  trade secret, copyright, or other right of any party; (vi) upload, post, email, or
                  otherwise transmit any unsolicited or unauthorized advertising, promotional
                  materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other
                  forms of solicitation; (vii) upload, post, email, or otherwise transmit any
                  material that contains software viruses or any other computer code, files, or
                  programs designed to interrupt, destroy, or limit the functionality of any
                  computer software or hardware or telecommunications equipment; (viii) interfere
                  with or disrupt the Services or servers or networks connected to the Services, or
                  disobey any requirements, procedures, policies or regulations of networks
                  connected to the Services; (ix) intentionally or unintentionally violate any
                  applicable local, state, national or international law or regulation; (x) "stalk"
                  or otherwise harass another; or (xi) collect or store personal data about other
                  users.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  You will not share your password, (or in the case of developers, your secret key),
                  let anyone else access your account, or do anything else that might jeopardize the
                  security of your account. We reserve the right to remove any Content from our Site
                  and/or Services, and/or disclose Content or other information relating to your use
                  of the Services (1) if required to do so by law or in the good faith belief that
                  such action is necessary to conform to the edicts of the law or comply with legal
                  process served on us or this web site; (2) to protect and defend the rights or
                  property of YUHU INC; or (3) to act in urgent circumstances to protect the
                  security or safety of any users of the Services or Site, or the public.
                </p>
                <p>‍</p>
                <h4>TRADEMARKS</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  "Yuhu," the Yuhu logo, and any other product or service name or slogan displayed
                  on our Services are trademarks of YUHU INC and its suppliers or licensors, and may
                  not be copied, imitated or used, in whole or in part, without the prior written
                  permission of YUHU INC or the applicable trademark holder. You may not use any
                  metatags or any other "hidden text" utilizing "Yuhu" or any other name, trademark
                  or product or service name of YUHU INC without our prior written permission. In
                  addition, the look and feel of our Services, including all page headers, custom
                  graphics, button icons and scripts, is the service mark, trademark and/or trade
                  dress of Yuhu and may not be copied, imitated or used, in whole or in part,
                  without our prior written permission. All other trademarks, registered trademarks,
                  product names and company names or logos mentioned in our Services are the
                  property of their respective owners. Reference to any products, services,
                  processes or other information, by trade name, trademark, manufacturer, supplier,
                  or otherwise does not constitute or imply endorsement, sponsorship, or
                  recommendation thereof by us.
                </p>
                <p>‍</p>
                <h4>FEEDBACK</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  We may provide you with a mechanism to provide feedback, suggestions, and ideas,
                  if you choose, about our Services ("Feedback"). You agree that we may, in our sole
                  discretion, use the Feedback you provide to us in any way, including in future
                  enhancements and modifications to our Services. You hereby grant to us and our
                  assigns a perpetual, worldwide, fully transferable, sublicensable, irrevocable,
                  royalty free license to use, reproduce, modify, create derivative works from,
                  distribute, and display the Feedback in any manner for any purpose, in any media,
                  software, or technology of any kind now existing or developed in the future,
                  without any obligation to provide attribution or compensation to you or any third
                  party.
                </p>
                <p>‍</p>
                <h4>DISCLAIMER OF WARRANTIES</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  YOUR USE OF THE SERVICES AND THE SERVICE CONTENT IS AT YOUR SOLE RISK. THE
                  SERVICES AND THE SERVICE CONTENT EACH ARE PROVIDED ON AN "AS IS" AND "AS
                  AVAILABLE" BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR
                  SUPPLIERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE
                  DO NOT WARRANT THE COMPREHENSIVENESS, CORRECTNESS, LEGALITY, OR ACCURACY OF THE
                  SERVICE OR SERVICE CONTENT OR THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR
                  FREE. ANY MATERIAL THAT YOU ACCESS OR OBTAIN THROUGH OUR SERVICES IS DONE AT YOUR
                  OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                  COMPUTER OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY MATERIAL THROUGH
                  OUR SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
                  FROM US OR THROUGH OR FROM OUR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY
                  STATED IN THESE TERMS.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  Any rights not expressly granted herein are reserved by us.
                </p>
                <p>‍</p>
                <h4>LIMITATION OF LIABILITY</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR SUPPLIERS AND
                  LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                  CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
                  LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF THESE DAMAGES), RESULTING FROM YOUR USE OF OUR
                  SERVICES AND SERVICE CONTENT. UNDER NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF
                  US AND OUR SUPPLIERS AND LICENSORS OF ALL KINDS ARISING OUT OF OR RELATED TO YOUR
                  USE OF THE SERVICES AND SERVICE CONTENT (INCLUDING BUT NOT LIMITED TO WARRANTY
                  CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS
                  BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE AMOUNTS, IF ANY, THAT YOU HAVE
                  PAID TO US FOR YOUR USE OF THE SERVICES AND SERVICE CONTENT.
                </p>
                <p>‍</p>
                <h4>INDEMNITY</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  You will indemnify and hold us, our suppliers and licensors, and our respective
                  subsidiaries, affiliates, officers, agents, employees, representatives, and
                  assigns harmless from any costs, damages, expenses, and liability caused by your
                  use of the Services and Service Content, your violation of these Terms, or your
                  violation of any rights of a third party through use of the Services or Service
                  Content.
                </p>
                <p>‍</p>
                <h4>GENERAL</h4>
                <p>‍</p>
                <p style={{ textAlign: 'justify' }}>
                  Enforcement of these Terms will be governed by the laws of Canada, excluding its
                  conflict and choice of law principles. The exclusive jurisdiction and venue for
                  any claims arising out of or related to these Terms or your use of the Services or
                  Service Content will lie in the province and federal courts located in Toronto,
                  Ontario, and each party irrevocably agrees to submit to the jurisdiction of such
                  courts. Notwithstanding this, you agree that we will be allowed to apply for
                  injunctive remedies (or an equivalent type of urgent legal relief) in any
                  jurisdiction. Our failure to enforce any right or provision in these Terms will
                  not constitute a waiver of such right or provision unless acknowledged and agreed
                  to by us in writing. In the event that a court of competent jurisdiction finds any
                  provision of these Terms to be illegal, invalid, or unenforceable, the remaining
                  provisions will remain in full force and effect. These Terms constitutes the whole
                  legal agreement between the parties in connection with your use of the Sites and
                  Services, and governs such use. These Terms completely replace and supersede any
                  prior agreements between the parties, whether written or oral, in connection with
                  the Site and Services. Except as expressly provided in these Terms, any
                  modification of or changes to these Terms must be in a writing duly authorized by
                  an authorized representative of ours.
                </p>
                <p>
                  If you have any questions about our Services or these Terms, you may contact us
                  at: help@yuhu.io.
                </p>
              </div>
            </div>
            <div />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default localize()(TermsOfServiceDialog);
