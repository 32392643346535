// See https://material-ui.com/demos/autocomplete/
import React from 'react';
import { localize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { MuiThemeProvider } from '@material-ui/core';

import { AccentColor } from 'helpers/AccentColorHelper';
import { muiThemeNext } from 'helpers/MUIFiles';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  MuiListSubheader: {
    root: {
      marginLeft: 5,
    },
  },
  input: {
    display: 'flex',
    padding: 0,
    lineHeight: 2.6,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    fontFamily: 'Circular !important',
    fontWeight: '100 !important',
  },
  chip: {
    backgroundColor: 'grey',
    fontSize: '13px',
    fontFamily: 'Circular',
    borderRadius: '4px',
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 14,
    fontFamily: 'Circular !important',
    fontWeight: '100 !important',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 14,
    fontFamily: 'Circular !important',
    fontWeight: '100 !important',
  },
  paper: {
    position: 'absolute',
    zIndex: 10000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={event => {
        props.removeProps.onClick();
        props.removeProps.onMouseDown(event);
      }}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu,
};

class IntegrationReactSelect extends React.Component {
  state = {
    single: null,
    multi: null,
  };

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      menuPortal: styles => ({
        ...styles,
        zIndex: 1301,
        boxShadow:
          '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      }),
      input: base => ({
        ...base,
        styles,
        color: theme.palette.text.primary,
      }),
      groupHeading: base => ({
        ...base,
        marginLeft: 5,
        marginTop: 5,
        fontFamily: 'Circular',
      }),
      menuList: base => ({
        ...base,
        maxHeight: 175,
      }),
    };

    if (this.props.variant === 'outlined') {
      classes.placeholder = 'react-outline-select-placeholder';
      classes.valueContainer = 'react-outline-select-valueContainer';
    }

    return (
      <MuiThemeProvider theme={muiThemeNext(AccentColor())}>
        <div className={classes.root}>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.props.data}
            components={components}
            menuPortalTarget={document.body}
            value={this.props.value}
            noOptionsMessage={() =>
              this.props.translate('landlordPortal.searchableSelect.noOption').props.children}
            textFieldProps={{
              label: this.props.label,
              InputLabelProps: {
                shrink: true,
              },
              variant: this.props.variant,
              error: this.props.error,
              helperText: this.props.helperText,
            }}
            {...this.props}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(localize()(IntegrationReactSelect));
