export default function reducer(
  state = {
    application_builder_step_block: null
  },
  action
) {
  switch (action.type) {
    case 'APPLICATION_BUILDER_STEP_BLOCK':
      return { ...state, application_builder_step_block: action.application_builder_step_block };
    default:
      return state;
  }
}
