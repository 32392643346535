import React from 'react';
import styled from 'styled-components';

const ValueStyled = styled.p`
  text-align: left;
  color: rgba(78, 78, 78, 1);
  font-size: 20px;
  font-family: 'Circular-Black';
  text-transform: ${props => props.textTransform || 'capitalize'};
`;

const style = {
  root: {
    textAlign: 'left',
    marginBottom: '15px',
  },
  title: {
    padding: '0px',
    color: 'rgba(78, 78, 78, 0.9)',
    fontSize: '13px',
    margin: 0,
  },
};

const ReviewField = props => {
  const value = props.format ? props.format(props.value) : props.value;

  return (
    <div style={{ ...style.root, ...props.style }}>
      <p style={{ ...style.title, ...props.titleStyle }}>{props.title}</p>

      <ValueStyled textTransform={props.textTransform}>{value}</ValueStyled>
    </div>
  );
};

export default ReviewField;
