const BASE_ACTION_TYPE = 'LANDLORD::RENT_ROLL::RENEWAL';

export const OPEN_RENEWAL_WIZARD = `${BASE_ACTION_TYPE}::OPEN_RENEWAL_WIZARD`;
export const CLOSE_RENEWAL_WIZARD = `${BASE_ACTION_TYPE}::CLOSE_RENEWAL_WIZARD`;

export const FETCH_YARDI_LEASE = `${BASE_ACTION_TYPE}::FETCH_YARDI_LEASE`;

export const FETCH_PROPOSALS = `${BASE_ACTION_TYPE}::FETCH_PROPOSALS`;
export const STORE_PROPOSALS = `${BASE_ACTION_TYPE}::STORE_PROPOSALS`;
export const SELECT_PROPOSAL = `${BASE_ACTION_TYPE}::SELECT_PROPOSAL`;
export const SELECT_LEASE_TERM = `${BASE_ACTION_TYPE}::SELECT_LEASE_TERM`;

export const STORE_DETAILS = `${BASE_ACTION_TYPE}::STORE_DETAILS`;
export const CLEAR_DETAILS = `${BASE_ACTION_TYPE}::CLEAR_DETAILS`;

export const FETCH_SCHEDULE_AS = `${BASE_ACTION_TYPE}::FETCH_SCHEDULE_AS`;
export const STORE_SCHEDULE_AS = `${BASE_ACTION_TYPE}::STORE_SCHEDULE_AS`;

export const CREATE_SCHEDULE_AS = `${BASE_ACTION_TYPE}::CREATE_SCHEDULE_AS`;

export const FETCH_LEASE_COTENANTS = `${BASE_ACTION_TYPE}::FETCH_LEASE_COTENANTS`;
export const STORE_LEASE_COTENANTS = `${BASE_ACTION_TYPE}::STORE_LEASE_COTENANTS`;

export const CREATE_RENEWAL = `${BASE_ACTION_TYPE}::CREATE_RENEWAL`;

export const STORE_SELECTED_APPLICANTS = `${BASE_ACTION_TYPE}::STORE_SELECTED_APPLICANTS`;
export const STORE_DEFAULT_SERVICES_AND_UTILS = `${BASE_ACTION_TYPE}::STORE_DEFAULT_SERVICES_AND_UTILS`;
export const STORE_DEFAULT_LEASE_DOCUMENT_TYPES = `${BASE_ACTION_TYPE}::STORE_DEFAULT_LEASE_DOCUMENT_TYPES`;
export const STORE_USE_NOTICE_OF_INCREASE = `${BASE_ACTION_TYPE}::STORE_USE_NOTICE_OF_INCREASE`;

export const INVITE_APPLICANT = `${BASE_ACTION_TYPE}::INVITE_APPLICANT`;
export const BYPASS_APPLICANT = `${BASE_ACTION_TYPE}::BYPASS_APPLICANT`;
export const APPROVE_APPLICANT = `${BASE_ACTION_TYPE}::APPROVE_APPLICANT`;

export const STORE_RENEWAL = `${BASE_ACTION_TYPE}::STORE_RENEWAL`;
export const SUBMIT_RENEWAL = `${BASE_ACTION_TYPE}::SUBMIT_RENEWAL`;
export const CANCEL_RENEWAL = `${BASE_ACTION_TYPE}::CANCEL_RENEWAL`;
