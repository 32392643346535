import { apiGet, apiPut } from 'helpers/ApiHelper';
import { redirectUrl } from 'helpers/UrlHelper';

import * as ByesignHelper from 'components/byesign/ByesignHelper';

import * as documentPortalActions from 'actions/documentPortalActions';

import Auth from 'j-toker';
import { updateLanguage } from 'react-localize-redux';

function showDocument(response) {
  return {
    type: 'IN_PERSON::SHOW_DOCUMENT',
    signatureDocument: response,
    shouldShow: true,
  };
}

function hideDocument() {
  return {
    type: 'IN_PERSON::SHOW_DOCUMENT',
    shouldShow: false,
    signatureDocument: null,
  };
}

export function openPadForSignature(id) {
  return {
    type: 'IN_PERSON::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'SIGNATURE',
  };
}

export function openPadForCheckBox(id) {
  return {
    type: 'IN_PERSON::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'CHECKBOX',
  };
}

export function openPadForTextBox(id) {
  return {
    type: 'IN_PERSON::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'TEXTBOX',
  };
}

export function openPadForInitials(id) {
  return {
    type: 'IN_PERSON::SHOW_PAD',
    signatureId: id,
    shouldShow: true,
    padType: 'INITIALS',
  };
}

export function closePad() {
  return {
    type: 'IN_PERSON::SHOW_PAD',
    shouldShow: false,
  };
}

export function openAgreementDialog() {
  return {
    type: 'IN_PERSON::SHOW_AGREEMENT_DIALOG',
    shouldShow: true,
  };
}

export function closeAgreementDialog() {
  return {
    type: 'IN_PERSON::SHOW_AGREEMENT_DIALOG',
    shouldShow: false,
  };
}

export function openSignerDialog(id) {
  return {
    type: 'IN_PERSON::SHOW_SIGNER_DIALOG',
    shouldShow: true,
    signerId: id,
  };
}

export function closeSignerDialog() {
  return {
    type: 'IN_PERSON::SHOW_SIGNER_DIALOG',
    shouldShow: false,
  };
}

export function closeSignerDialogOnCancel() {
  return {
    type: 'IN_PERSON::CLOSE_SIGNER_DIALOG_ON_CANCEL',
  };
}

export function showSendingLoader(shouldShow) {
  return {
    type: 'IN_PERSON::IS_SENDING',
    isSending: shouldShow,
  };
}

export function enableSignButton(shouldEnable) {
  return {
    type: 'IN_PERSON::ENABLE_SIGN_BUTTON',
    shouldEnable: shouldEnable,
  };
}

export function captureSignature(id, url, isChecked = false) {
  return {
    type: 'IN_PERSON::CAPTURE_SIGNATURE',
    signatureId: id,
    signatureUrl: url,
    isChecked,
  };
}

function signSuccess() {
  return {
    type: 'IN_PERSON::SIGN_SUCCESS',
  };
}

export function openMenuForSignature(id, anchorEl) {
  return {
    type: 'IN_PERSON::SHOW_ACTIONS_MENU',
    signatureId: id,
    anchorEl: anchorEl,
    shouldShow: true,
    padType: 'SIGNATURE',
  };
}

export function openMenuForTextbox(id, anchorEl) {
  return {
    type: 'IN_PERSON::SHOW_ACTIONS_MENU',
    signatureId: id,
    anchorEl: anchorEl,
    shouldShow: true,
    padType: 'TEXTBOX',
  };
}

export function openMenuForInitials(id, anchorEl) {
  return {
    type: 'IN_PERSON::SHOW_ACTIONS_MENU',
    signatureId: id,
    anchorEl: anchorEl,
    shouldShow: true,
    padType: 'INITIALS',
  };
}

export function closeMenu() {
  return {
    type: 'IN_PERSON::SHOW_ACTIONS_MENU',
    signatureId: null,
    anchorEl: null,
    shouldShow: false,
  };
}

export function deleteSignature(id) {
  return {
    type: 'IN_PERSON::DELETE_SIGNATURE',
    signatureId: id,
  };
}

export function nextAnchorId(justSigned = false) {
  return {
    type: 'IN_PERSON::NEXT_ANCHOR_ID',
    justSigned,
  };
}

export function nextSigner() {
  return {
    type: 'IN_PERSON::NEXT_SIGNER',
  };
}

export function displayFinishButton() {
  return {
    type: 'IN_PERSON::SHOW_FINISH_BUTTON',
  };
}

export function getDocument(document_id, access_token, document_type, isModeInternal) {
  return dispatch => {
    let url;
    if (isModeInternal) {
      url = `/document_signing/landlord_internal_in_person_documents/${document_id}?access_token=${access_token}&document_type=${document_type}`;
    } else {
      url = `/document_signing/documents/${document_id}?access_token=${access_token}`;
    }
    return apiGet(Auth.getApiUrl() + url)
      .done(response => {
        if (response.data.document.language_code) {
          dispatch(updateLanguage(response.data.document.language_code));
          localStorage.setItem('locale', response.data.document.language_code);
        }
        dispatch(showDocument(response.data));
      })
      .catch(error => {
        dispatch(documentPortalActions.signError(error.responseJSON.error));
      });
  };
}

export function putDocument(data, document_type, isModeInternal) {
  return dispatch => {
    let url;
    if (isModeInternal) {
      url = `/document_signing/landlord_internal_in_person_documents/${data.document_uuid}?access_token=${data.access_token}&document_type=${document_type}`;
    } else {
      url = `/document_signing/documents/${data.document_uuid}?access_token=${data.access_token}`;
    }
    return apiPut(Auth.getApiUrl() + url, data)
      .done(response => {
        dispatch(showSendingLoader(false));
        dispatch(hideDocument());
        dispatch(enableSignButton(false));
        dispatch(signSuccess());
        redirectUrl(ByesignHelper.constants.SUCCESS, ByesignHelper.constants.REDIRECT_TIMEOUT_MS);
      })
      .catch(error => {
        window.Rollbar.error('In-Person Document Portal Signing Error', error);
        dispatch(documentPortalActions.signError(error.responseJSON.error));
        dispatch(showSendingLoader(false));
        redirectUrl(ByesignHelper.constants.CANCELLED, ByesignHelper.constants.REDIRECT_TIMEOUT_MS);
      });
  };
}

export function clearSignatures() {
  return {
    type: 'IN_PERSON::CLEAR_SIGNATURES',
  };
}
