export default function reducer(
  state = {
    dialogOpen: false,
    select_availability: false,
    showBookingError: false,
    isLoading: false,
    modifyShowing: false,
    collectLeadDetails: false,
    bookingConfirmed: false,
    timeAvailability: {},
    bookingDetails: {},
    amenityDetails: {},
    amenityBookings: [],
    amenityPricing: {},
    amenities: [],
    errorMessage: '',
  },
  action
) {
  switch (action.type) {
    case 'BOOKINGS::AMENITY_PRICING': {
      return {
        ...state,
        amenityPricing: action.amenityPricing,
      };
    }
    case 'BOOKINGS::UPDATE_BOOKING_AVAILABILITY': {
      return {
        ...state,
        isLoading: false,
        showBookingError: false,
        timeAvailability: action.timeAvailability,
        select_availability: action.select_availability,
      };
    }
    case 'LANDLORD_BOOKINGS::CONFIRM_BOOKING': {
      return { ...state, bookingConfirmed: true };
    }
    case 'LANDLORD_BOOKINGS::GO_BACK': {
      return {
        ...state,
        collectLeadDetails: false,
      };
    }
    case 'LANDLORD_BOOKINGS::COLLECT_LEAD_DETAILS': {
      return {
        ...state,
        isLoading: false,
        showBookingError: false,
        select_availability: false,
        collectLeadDetails: true,
        bookingDetails: action.bookingDetails,
      };
    }
    case 'BOOKINGS::UPDATE_BOOKING_AVAILABILITY_ERROR': {
      return { ...state, isLoading: false, showBookingError: true, errorMessage: action.message };
    }
    case 'BOOKINGS::SET_TO_IS_LOADING': {
      return { ...state, isLoading: action.isLoading, showBookingError: false };
    }
    case 'BOOKINGS::SHOW_MODIFY_SHOWING': {
      return { ...state, modifyShowing: action.modifyShowing };
    }
    case 'BOOKINGS::REMOVE_PREVIOUS_SEARCH_RESULTS': {
      return {
        ...state,
        select_availability: false,
        showBookingError: false,
        errorMessage: '',
        isLoading: true,
      };
    }
    case 'BOOKINGS::OPEN_DIALOG': {
      return { ...state, dialogOpen: true };
    }
    case 'BOOKINGS::CLOSE_DIALOG': {
      return { ...state, dialogOpen: false, modifyShowing: false };
    }
    case 'BOOKINGS::RESET_BOOKING_STATE': {
      return {
        select_availability: false,
        isLoading: false,
        showBookingError: false,
        modifyShowing: false,
        timeAvailability: {},
      };
    }
    default:
      return state;
  }
}
