//LIBRARIES
import React, { Component } from 'react';
import { TextField } from 'redux-form-material-ui';
import { Field } from 'redux-form';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';

export default class AddressAutocomplete extends Component {
  componentDidMount() {
    this.setState({ value: this.props.streetAddress || '' });
    let addressId = this.props.id || 'addressAutocompleteField';
    this.input = document.getElementById(addressId);
    const options = {
      componentRestrictions: {
        country: this.props.countryCode,
      },
      types: ['address'],
    };

    this.geoAutocomplete = {};

    if (window.google) {
      this.geoAutocomplete = new window.google.maps.places.Autocomplete(this.input, options);
    }
  }

  componentDidUpdate() {
    if (window.google && !isEmpty(this.geoAutocomplete)) {
      this.geoAutocomplete.addListener('place_changed', () => {
        const selectedPlace = this.geoAutocomplete.getPlace();
        const componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_1: 'long_name',
          country: 'long_name',
          postal_code: 'long_name',
          postal_code_prefix: 'short_name',
        };
        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        let selectedSuggest = {};
        for (let addressComponent of selectedPlace.address_components) {
          const addressType = addressComponent.types[0];
          if (componentForm[addressType]) {
            selectedSuggest[addressType] = addressComponent[componentForm[addressType]];
          }
        }
        let street_number = !selectedSuggest.street_number ? '' : selectedSuggest.street_number;

        this.props.getAddressFromAutoComplete(selectedSuggest);
        this.props.addToRedux(
          `${street_number} ${selectedSuggest.route}`,
          selectedSuggest.locality,
          //Need to upper case first letters for each word in order to get initial values to work for reduxform
          startCase(toLower(selectedSuggest.administrative_area_level_1)),
          selectedSuggest.country,
          selectedSuggest.postal_code || selectedSuggest.postal_code_prefix
        );
        this.input.value = `${street_number} ${selectedSuggest.route}`;
      });
    }
  }

  _handleChange = (event, value) => this.setState({ value });

  render() {
    return (
      <Field
        name={this.props.name}
        type="text"
        component={TextField}
        id={this.props.id || 'addressAutocompleteField'}
        floatingLabelText={this.props.floatingLabelText || ''}
        style={this.props.style}
        onChange={this._handleChange}
        placeholder=""
        hintText={this.props.hintText || 'Enter your Full Address'}
        underlineStyle={this.props.underlineStyle}
        underlineFocusStyle={this.props.underlineFocusStyle}
        inputStyle={this.props.inputStyle}
        hintStyle={this.props.hintStyle}
        errorStyle={this.props.errorStyle}
        validate={this.props.validate}
      />
    );
  }
}
