import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Typography, Card } from 'antd';

import CtaText from 'components/ui/CtaText';
import TextBox, { TextArea } from 'components/ui/TextBox';
import { Select } from 'components/ui/Select/Select';

const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

const StyledCard = styled(Card)`
  background-color: white;
  padding: 2rem 0 1rem;
  border-radius: 24px;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.09);
  position: relative;
  margin-bottom: 1rem;

  .ant-card-body {
    padding: 0 2rem;
  }
`;

const StyledLabel = styled.label`
  display: block;
`;

const StyledCloseButton = styled.div`
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.2rem;
`;

const { Title } = Typography;

export const LeaseDocumentFormGroup = ({
  type,
  label,
  maxCount,
  fields,
  reduxState,
  values,
  setValues,
}) => {
  const [num, setNum] = useState(get(values, `${type}.length`, 0));
  const valuesForFormGroup = values[type];
  const canAddMore = maxCount === -1 ? num < 100 : num < maxCount;

  useEffect(
    () => {
      if (maxCount === 1) {
        setNum(maxCount);
      }
    },
    [maxCount, setNum]
  );

  const handleClick = () => {
    setValues({
      ...values,
      [type]: [...values[type], {}],
    });
    setNum(num + 1);
  };

  const handleChange = (e, i) => {
    const leaseDocValues = [...values[type]];
    leaseDocValues[i] = { ...leaseDocValues[i], [e.target.name]: e.target.value };
    setValues({
      ...values,
      [type]: leaseDocValues,
    });
  };

  const removeValues = i => {
    const leaseDocValues = [...values[type]];
    leaseDocValues.splice(i, 1);
    setValues({
      ...values,
      [type]: leaseDocValues,
    });
    setNum(num - 1);
  };

  return (
    <StyledContainer>
      <Title level={4}>{label}</Title>
      {new Array(num).fill().map((_, i) => {
        return (
          <StyledCard key={`document-${type}-${i}`}>
            <StyledCloseButton onClick={() => removeValues(i)}>x</StyledCloseButton>
            {fields.map(({ tag, type, name, label, options = [], dynamicOptions }, j, required) => {
              return (
                <StyledLabel key={`document-${type}-${i}-field-${j}`}>
                  {label}
                  {tag === 'input' && (
                    <TextBox
                      type={type || 'text'}
                      name={name}
                      required={required === 'true'}
                      value={get(valuesForFormGroup, `[${i}].${name}`)}
                      onChange={e => handleChange(e, i)}
                    />
                  )}
                  {tag === 'textarea' && (
                    <TextArea
                      name={name}
                      value={get(valuesForFormGroup, `[${i}].${name}`)}
                      onChange={e => handleChange(e, i)}
                    />
                  )}
                  {tag === 'select' && (
                    <Select
                      name={name}
                      required
                      value={get(valuesForFormGroup, `[${i}].${name}`)}
                      onChange={e => handleChange(e, i)}
                      options={[
                        {
                          label: 'choose',
                          value: '',
                        },
                      ]
                        .concat(options)
                        .concat(
                          get(reduxState, dynamicOptions, []).map(option => {
                            return {
                              label: option.full_name,
                              value: option.id,
                            };
                          })
                        )}
                    />
                  )}
                </StyledLabel>
              );
            })}
          </StyledCard>
        );
      })}
      {canAddMore && (
        <CtaText type="button" onClick={handleClick}>
          add more
        </CtaText>
      )}
    </StyledContainer>
  );
};
