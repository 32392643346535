import moment from 'moment';
import { subYears, parse, startOfDay, endOfDay, isBefore } from 'date-fns';

export const DaysBetween = (first, second) => {
  const dateOne = moment(first);
  const dateTwo = moment(second);

  return Math.ceil(dateTwo.diff(dateOne, 'days'));
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getTimeRange = (date, timeRange) => {
  if (timeRange === 'month') {
    return monthNames[new Date(date * 1000).getUTCMonth()];
  }
  return new Date(date * 1000).getUTCDate();
};

export const addMinutesToTimeStr = (timeStr, minutes) => {
  return moment(timeStr, 'h:ma').add(minutes, 'm').format('h:mmA');
};

export const generateOpenCalendarSlots = (lengthOfShowings) => {
  // using 14 hours here, starting at 7am going to 9pm, 15 minute increments
  const numberOfSlots = 14 / (15 / 60);

  let slots = [];
  let i;
  for (i = 0; i <= numberOfSlots; i++) {
    slots.push(
      moment('7:00AM', 'h:ma')
        .add(i * 15, 'm')
        .format('h:mmA')
    );
  }

  return slots;
};

export const addDayToCurrentDay = () => {
  return new Date().setDate(new Date().getDate() + 1);
};

export const isSameDay = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return start.setHours(0, 0, 0, 0) === end.setHours(0, 0, 0, 0);
};

export const isInvalidAgeRequirement = (minimumAge, value) =>
  value && minimumAge && new Date(value) > subYears(new Date(), minimumAge);

export const monthDifferenceFromAvailabilityDate = (availabilityDate, currentDate = new Date()) => {
  const parsedAvailabilityDate = availabilityDate
    ? parse(availabilityDate, 'yyyy-MM-dd', currentDate)
    : currentDate;

  let monthDiff = parsedAvailabilityDate.getUTCMonth() - currentDate.getUTCMonth();
  const yearDiff = parsedAvailabilityDate.getFullYear() - currentDate.getFullYear();

  if (monthDiff < 1 || yearDiff < 0) {
    monthDiff = 1;
    if (currentDate.getUTCDate() === 1) {
      monthDiff = 0;
    }
  } else if (parsedAvailabilityDate.getUTCDate() > 1) {
    monthDiff += 1;
  }

  return monthDiff;
};

/**
 * Checks if a date period is within another date period.
 *
 * @param {Date|string|moment} startDate1 - Start of the first date period.
 * @param {Date|string|moment} endDate1 - End of the first date period.
 * @param {Date|string|moment} startDate2 - Start of the second date period.
 * @param {Date|string|moment} endDate2 - End of the second date period.
 * @return {boolean} Returns true if the first date period is within the second one.
 */
export const isDatePeriodWithinAnother = (startDate1, endDate1, startDate2, endDate2) => {
  const start1 = moment(startDate1);
  const end1 = moment(endDate1);
  const start2 = moment(startDate2);
  const end2 = moment(endDate2);

  return start1.isSameOrAfter(start2) && end1.isSameOrBefore(end2);
};

export const disablePreviousDatesByAvailabilityDate = (date, availabilityDate) => {
  // Disable past dates
  if (date && date < moment().startOf('day')) {
    return true;
  }

  // Disable dates before availabilityDate (if it exists)
  if (availabilityDate && date && date < moment(availabilityDate).startOf('day')) {
    return true;
  }

  return false;
};
