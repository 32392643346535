import { apiGet } from 'helpers/ApiHelper';
import Auth from 'j-toker';
import { showFailure } from './notificationActions';

export const GET_CLIENT_ID = 'LANDLORD_SIGNABLE_DOC::GET_CLIENT_ID';
export const CREATE_HELLOSIGN_UNCLAIMED_DRAFT =
  'LANDLORD_SIGNABLE_DOC::CREATE_HELLOSIGN_UNCLAIMED_DRAFT';
export const GET_SIGN_URL = 'LANDLORD_SIGNABLE_DOC::GET_SIGN_URL';

export function getSignableDocClientId() {
  return dispatch => {
    return dispatch({
      type: GET_CLIENT_ID,
      payload: apiGet(`${Auth.getApiUrl()}/signable_doc/api_keys`).catch(error => {
        dispatch(showFailure(error));
      }),
    });
  };
}

export function createHellosignUnclaimedDraft(leaseId) {
  return async (dispatch) => {
    try {
      dispatch({ type: `${CREATE_HELLOSIGN_UNCLAIMED_DRAFT}_PENDING` });
      const response = await apiGet(
        Auth.getApiUrl() + `/signable_doc/create_unclaimed_draft`, {
          lease_id: leaseId
        }
      );
      dispatch({
        type: `${CREATE_HELLOSIGN_UNCLAIMED_DRAFT}_FULFILLED`,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: `${CREATE_HELLOSIGN_UNCLAIMED_DRAFT}_REJECTED` });
      throw Error;
    }
  };
}

export function getSignUrlForInPerson(applicationUuid) {
  return async (dispatch) => {
    try {
      dispatch({ type: `${GET_SIGN_URL}_PENDING` });
      const response = await apiGet(
        `${Auth.getApiUrl()}/signable_doc/sign_url?application_uuid=${applicationUuid}&in_person=true`
      );
      dispatch({
        type: `${GET_SIGN_URL}_FULFILLED`,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({ type: `${GET_SIGN_URL}_REJECTED` });
      throw Error;
    }
  };
}