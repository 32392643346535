import { apiPostMultipart, apiPutMultipart, apiGet, apiDelete } from 'helpers/ApiHelper';
import Auth from 'j-toker';
import { showFailure, showSuccess } from './notificationActions';

export const SHOW_DOCUMENT_TEMPLATE = 'LANDLORD_DOCUMENT_TEMPLATES::SHOW_DOCUMENT_TEMPLATE';
export const FETCH_DOCUMENT_TEMPLATE = 'LANDLORD_DOCUMENT_TEMPLATES::FETCH_DOCUMENT_TEMPLATE';
export const FILTER_DOCUMENT_TEMPLATES = 'LANDLORD_DOCUMENT_TEMPLATES::FILTER_DOCUMENT_TEMPLATES';
export const FETCH_DOCUMENT_TEMPLATES = 'LANDLORD_DOCUMENT_TEMPLATES::FETCH_DOCUMENT_TEMPLATES';
export const DUPLICATE_DOCUMENT_TEMPLATES =
  'LANDLORD_DOCUMENT_TEMPLATES::DUPLICATE_DOCUMENT_TEMPLATE';
export const DELETE_DUPLICATE_DOCUMENT_TEMPLATES =
  'LANDLORD_DOCUMENT_TEMPLATES::DELETE_DUPLICATE_DOCUMENT_TEMPLATE';
export const FETCH_DOCUMENT_TEMPLATES_STATS =
  'LANDLORD_DOCUMENT_TEMPLATES::FETCH_DOCUMENT_TEMPLATES_STATS';

const prepareFormData = rawData => {
  let formData = new FormData();

  for (let key in rawData) {
    if (['file', 'property_uuids', 'base_document_template_ids'].includes(key)) {
      rawData[key].forEach((item, i) => {
        formData.append(`${key}[${i}]`, item);
      });
    } else if (key === 'uploadedFiles') {
      formData.append('uploaded_files', JSON.stringify(rawData[key]));
    } else if (
      ['additional_fields', 'additional_charges', 'services_and_utilities'].includes(key)
    ) {
      rawData[key].forEach((item, i) => {
        formData.append(`${key}[${i}]`, JSON.stringify(item));
      });
    } else {
      formData.append(key, rawData[key]);
    }
  }

  return formData;
};

export function createDocumentTemplate(data) {
  const route = '/signable_doc/document_templates';
  const formData = prepareFormData(data);

  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::CREATE_DOCUMENT_TEMPLATE',
      payload: apiPostMultipart(Auth.getApiUrl() + route, formData)
        .then(response => {
          dispatch(showSuccess('documentTemplateCreated'));
          return response;
        })
        .catch(error => {
          throw new Error(error);
        }),
    });
  };
}

export function displayDocumentTemplate(response) {
  return {
    type: SHOW_DOCUMENT_TEMPLATE,
    payload: response,
  };
}

export function getFilteredDocumentTemplates(subset = '', offset = 0, options = {}) {
  const filterData = prepareFormData(options);
  return dispatch => {
    return dispatch({
      type: FILTER_DOCUMENT_TEMPLATES,
      payload: apiPostMultipart(
        `${Auth.getApiUrl()}/signable_doc/filtered_document_templates?subset=${subset}&offset=${offset}`,
        filterData
      )
        .then(response => {
          dispatch({
            type: `${FILTER_DOCUMENT_TEMPLATES}_FULFILLED`,
            payload: response,
          });
        })
        .catch((error, textStatus) => {
          dispatch(showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getDocumentTemplateStats() {
  return dispatch => {
    return dispatch({
      type: FETCH_DOCUMENT_TEMPLATES_STATS,
      payload: apiGet(
        Auth.getApiUrl() + '/signable_doc/document_templates_stats'
      ).catch((error, textStatus) => {
        dispatch(showFailure(error));
        console.error(textStatus);
      }),
    });
  };
}

export function getDocumentTemplate(documentTemplateId) {
  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::GET_DOCUMENT_TEMPLATE',
      payload: apiGet(
        `${Auth.getApiUrl()}/signable_doc/document_templates/${documentTemplateId}`
      ).catch(error => {
        dispatch(showFailure(error));
      }),
    });
  };
}

export function getAllDocumentTemplates(offset = 0) {
  return dispatch => {
    return dispatch({
      type: FETCH_DOCUMENT_TEMPLATES,
      payload: apiGet(
        `${Auth.getApiUrl()}/signable_doc/document_templates?offset=${offset}`
      ).catch((error, textStatus) => {
        dispatch(showFailure(error));
        console.error(textStatus);
      }),
    });
  };
}

export function updateDocumentTemplate(data, id) {
  const route = `/signable_doc/document_templates/${id}`;
  const formData = prepareFormData(data);

  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::UPDATE_DOCUMENT_TEMPLATE',
      payload: apiPutMultipart(Auth.getApiUrl() + route, formData)
        .then(response => {
          dispatch(showSuccess('documentTemplateUpdated'));
          return response;
        })
        .catch(error => {
          throw new Error(error);
        }),
    });
  };
}

export function archiveDocumentTemplate(data) {
  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::ARCHIVE_DOCUMENT_TEMPLATE',
      payload: apiPutMultipart(
        `${Auth.getApiUrl()}/signable_doc/document_templates/${data.id}/archive`
      )
        .then(response => {
          const isArchived = response.data.attributes.archived;

          const successMessage = isArchived
            ? 'documentTemplateArchived'
            : 'documentTemplateUnArchived';
          dispatch(showSuccess(successMessage));
          return response;
        })
        .catch(error => {
          dispatch(showFailure(error));
        }),
    });
  };
}

export function duplicateDocumentTemplate(data) {
  return dispatch => {
    return dispatch({
      type: DUPLICATE_DOCUMENT_TEMPLATES,
      payload: apiPostMultipart(
        `${Auth.getApiUrl()}/signable_doc/document_templates/${data.id}/duplicate`
      )
        .then(response => {
          return response;
        })
        .catch(error => {
          dispatch(showFailure(error));
        }),
    });
  };
}

export function deleteDuplicateDocumentTemplate(data) {
  return dispatch => {
    return dispatch({
      type: DELETE_DUPLICATE_DOCUMENT_TEMPLATES,
      payload: apiDelete(`${Auth.getApiUrl()}/signable_doc/document_templates/${data.id}/delete`)
        .then(response => {
          dispatch(showSuccess('documentTemplateDeleted'));
          return response;
        })
        .catch(error => {
          dispatch(showFailure(error));
        }),
    });
  };
}

export function publishDocumentTemplate(data) {
  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::PUBLISH_DOCUMENT_TEMPLATE',
      payload: apiPutMultipart(
        `${Auth.getApiUrl()}/signable_doc/document_templates/${data.id}/publish`
      )
        .then(response => {
          dispatch(showSuccess('documentTemplatePublished'));
          return response;
        })
        .catch(error => {
          dispatch(showFailure(error));
        }),
    });
  };
}

export function getConditionalDocumentTemplateTypes() {
  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::GET_CONDITIONAL_TYPES',
      payload: apiGet(
        `${Auth.getApiUrl()}/signable_doc/available_conditional_types`
      ).catch((error, textStatus) => {
        dispatch(showFailure(error));
        console.error(textStatus);
      }),
    });
  };
}

export function refreshEditUrlForDocumentTemplate(documentTemplateId) {
  const route = `/signable_doc/document_templates/${documentTemplateId}/edit`;

  return dispatch => {
    return dispatch({
      type: 'LANDLORD_DOCUMENT_TEMPLATES::EDIT_DOCUMENT_TEMPLATE',
      payload: apiPutMultipart(Auth.getApiUrl() + route)
        .then(response => {
          dispatch(showSuccess('documentTemplateUpdated'));
          return response;
        })
        .catch(error => {
          dispatch(showFailure(error));
        }),
    });
  };
}
