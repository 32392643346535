export default function reducer(
  state = {
    documents: [],
    numberOfDocuments: 0,
  },
  action
) {
  switch (action.type) {
    case 'ORGANIZE_DOCUMENTS': {
      return {
        ...state,
        documents: action.documents,
        numberOfDocuments: action.numberOfDocuments,
      };
    }
    default:
      return state;
  }
}
