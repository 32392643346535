export default function reducer(
  state = {
    signatureRenewalYearly: false,
    signatureRenewalMonthly: false,
    documentToSign: null,
  },
  action
) {
  switch (action.type) {
    case 'RENEWAL_LEASE_WIZARD::ALLOW_MONTHLY_AND_YEARLY': {
      return {
        ...state,
        signatureRenewalMonthly: true,
        signatureRenewalYearly: true,
      };
    }
    case 'RENEWAL_LEASE_WIZARD::ALLOW_YEARLY': {
      return {
        ...state,
        signatureRenewalMonthly: false,
        signatureRenewalYearly: true,
      };
    }
    case 'RENEWAL_LEASE_WIZARD::ALLOW_MONTHLY': {
      return {
        ...state,
        signatureRenewalMonthly: true,
        signatureRenewalYearly: false,
      };
    }
    case 'RENEWAL_LEASE_WIZARD::UPDATE_DOCUMENT_TO_SIGN': {
      return { ...state, documentToSign: action.documentToSign };
    }
    default:
      return state;
  }
}
