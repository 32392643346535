export default function reducer(
  state = {
    eventDetails: {},
    siteEvents: [],
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_SITE_EVENTS::SHOW_EVENT_DETAILS': {
      return {
        ...state,
        eventDetails: action.eventDetails,
      };
    }
    case 'LANDLORD_SITE_EVENTS::SHOW_EVENTS': {
      return {
        ...state,
        siteEvents: action.siteEvents,
      };
    }
    default:
      return state;
  }
}
