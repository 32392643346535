//
// This component must be used within the context of reduxForm
//

import React from 'react';
import { Field } from 'redux-form';
import { CSSTransitionGroup } from 'react-transition-group';
import IconButton from 'material-ui/IconButton';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import FlatButton from 'material-ui/FlatButton';
import { AccentColor } from 'helpers/AccentColorHelper';

import { DatePicker, SelectField, TextField } from 'redux-form-material-ui';
import MenuItem from 'material-ui/MenuItem';

import * as validator from 'helpers/Validator';
import * as formatter from 'helpers/Formatter';

import { getTranslatedString } from 'helpers/i18n';
import { featureEnabled } from 'helpers/ToggleBot';

const _renderConcessionSelectionforYardi = (
  isYardi,
  incentive,
  index,
  onConcessionSelect,
  concessions,
  filteredConcessionIds
) => {
  if (!isYardi) return;

  return (
    <div className="row" style={{ marginBottom: '0px' }}>
      <div className="col-xs-12">
        <Field
          name={`${incentive}.incentive_id`}
          validate={[validator.required]}
          component={SelectField}
          floatingLabelText={getTranslatedString(
            'landlordPortal.rentalIncentiveFields.incentiveType'
          )}
          onChange={(e, value) => onConcessionSelect(value, index)}
          fullWidth
          autoWidth
          style={{ fontSize: '14px', textAlign: 'left' }}
          iconStyle={{ marginLeft: '20px !important' }}
        >
          {concessions.map((concession) => (
            <MenuItem
              disabled={filteredConcessionIds.includes(concession.id)}
              value={concession.id}
              primaryText={concession.title}
            />
          ))}
        </Field>
      </div>
    </div>
  );
};

// const _renderConcessionFrequencyforYardi = (isYardi, fields, index, incentive) => {
//   if (!isYardi) return;
//
//   return (
//     <div className="col-sm-4">
//       <Field
//         name={`${incentive}.frequency`}
//         validate={[validator.required, validator.termDateRange]}
//         component={SelectField}
//         floatingLabelText={getTranslatedString('landlordPortal.rentalIncentiveFields.frequency')}
//         fullWidth
//         autoWidth
//         style={{ fontSize: '14px', textAlign: 'left' }}
//         iconStyle={{ marginLeft: '20px !important' }}
//       >
//         <MenuItem
//           value={`${fields.get(index).term} month(s)`}
//           primaryText={`${fields.get(index).term} month(s)`}
//         />
//       </Field>
//     </div>
//   );
// };

const _renderConcessionFrequencyforNonYardi = (isYardi, incentive) => {
  // if (isYardi) return;

  return (
    <div className="col-sm-4">
      <Field
        name={`${incentive}.frequency`}
        validate={[validator.required]}
        component={SelectField}
        floatingLabelText={getTranslatedString('landlordPortal.rentalIncentiveFields.frequency')}
        fullWidth
        autoWidth
        style={{ fontSize: '14px', textAlign: 'left' }}
        iconStyle={{ marginLeft: '20px !important' }}
      >
        <MenuItem value={null} primaryText="" />
        <MenuItem
          value="Monthly (for the Entire Lease)"
          primaryText={getTranslatedString('landlordPortal.rentalIncentiveFields.monthly')}
        />
        <MenuItem value="1 month" primaryText="1 month" />
        {new Array(17).fill(0).map((val, menu_index) => (
          <MenuItem
            value={`${menu_index + 2} months`}
            primaryText={`${menu_index + 2} ${getTranslatedString(
              'landlordPortal.rentalIncentiveFields.months'
            )}`}
          />
        ))}
      </Field>
    </div>
  );
};

export default ({
  fields,
  meta: { touched, error, submitFailed },
  concessions,
  onConcessionSelect,
  filteredConcessionIds,
}) => (
  <div className="row">
    <CSSTransitionGroup
      transitionName="addTransaction"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      {fields.map((incentive, index) => (
        <div key={index} className="row">
          <div className="cotenantHeader" style={{ padding: '0 15px', marginBottom: '-10px' }}>
            <h4 style={{ lineHeight: '26px' }}>
              {getTranslatedString('landlordPortal.rentalIncentiveFields.rentalIncentiveNo')}
              {index + 1}
              <IconButton
                style={{
                  padding: 0,
                  verticalAlign: 'text-bottom',
                  width: 'none',
                  height: 'none',
                  float: 'right',
                }}
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <DeleteIcon size={24} style={{ padding: 0 }} />
              </IconButton>
            </h4>
          </div>

          {_renderConcessionSelectionforYardi(
            featureEnabled('ui.lease_creator.enable_concession_selection'),
            incentive,
            index,
            onConcessionSelect,
            concessions,
            filteredConcessionIds
          )}

          <div className="row" style={{ marginBottom: '0px' }}>
            <div className="col-sm-4">
              <Field
                name={`${incentive}.effective_date`}
                validate={[validator.required, validator.concessionDateRange]}
                component={DatePicker}
                openToYearSelection
                style={{ fontSize: '14px', width: '100%' }}
                formatDate={formatter.reformatDate}
                autoOk
                textFieldStyle={{ fontSize: '14px', width: '100%' }}
                floatingLabelText={getTranslatedString(
                  'landlordPortal.rentalIncentiveFields.startDate'
                )}
              />
            </div>

            {/*{_renderConcessionFrequencyforYardi(*/}
            {/*  featureEnabled('ui.lease_creator.enable_concession_selection'),*/}
            {/*  fields,*/}
            {/*  index,*/}
            {/*  incentive*/}
            {/*)}*/}
            {_renderConcessionFrequencyforNonYardi(
              featureEnabled('ui.lease_creator.enable_concession_selection'),
              incentive
            )}

            <div className="col-sm-4" style={{ textAlign: 'center' }}>
              <Field
                name={`${incentive}.incentive_amount`}
                validate={[validator.required]}
                fullWidth
                type="text"
                component={TextField}
                disabled={fields.get(index).disableAmountSelection}
                floatingLabelText={getTranslatedString(
                  'landlordPortal.rentalIncentiveFields.monthlyAmount'
                )}
                textFieldStyle={{ fontSize: '14px' }}
                style={{ fontSize: '14px' }}
                {...formatter.currencyMask}
              />
            </div>
            <div className="col-sm-12" style={{ textAlign: 'center', marginTop: '15px' }}>
              <Field
                name={`${incentive}.description`}
                type="text"
                component={TextField}
                validate={[validator.required]}
                floatingLabelText={getTranslatedString(
                  'landlordPortal.rentalIncentiveFields.description'
                )}
                multiline
                fullWidth
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
        </div>
      ))}
    </CSSTransitionGroup>

    <div className="row" style={{ textAlign: 'center', marginTop: '20px' }}>
      <FlatButton
        label={getTranslatedString('landlordPortal.rentalIncentiveFields.addRentalIncentive')}
        type="button"
        id="button"
        backgroundColor={AccentColor()}
        hoverColor="grey"
        style={{ color: 'white' }}
        onClick={() => {
          fields.push({});
        }}
      />
      {error && <p style={{ marginTop: '10px', fontSize: '11px' }}>{error}</p>}
    </div>
  </div>
);
