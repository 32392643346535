// TODO:
// - cleanup where review data comes from. Should just grab all form values
// - use ReviewField instead
import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { localize } from 'react-localize-redux';

import { colors } from 'helpers/ColorHelper';
import TableHeader from 'components/shared/TableHeader';
import ReviewField from 'components/shared/ReviewField';
import Select from 'components/ui/SelectField';

import ServicesAndUtilitiesWizardPage from './servicesAndUtilitiesWizardPage';
import { PAGES } from 'components/landlord_app/applications/LeaseCreator';
import { RenderLeaseItem } from 'components/shared/LeaseItems/helpers';
import { toFriendlyDateString, formatMoney } from 'helpers/Formatter';
import {
  calculateProratedAmount,
  calculateLeaseItemsProratedAmount,
  leaseRentalIncentiveProratedAmount,
} from 'helpers/numberHelpers';

import { getTranslatedString } from 'helpers/i18n';
import env from 'config/constants';
import { showCreateLeaseSuccess, clearClaimUrl } from 'actions/landlordApplicationsActions';
import { featureEnabled } from 'helpers/ToggleBot';
import { hellosignClient } from 'helpers/hellosignClient';
import { currentLocale } from 'components/shared/useSignatureRequests';
import { hostIsAvenue } from 'helpers/CurrentWindowLocation';

let reviewPackagePage = localize()(
  ({
    handleSubmit,
    reviewValues,
    goToPage,
    paymentProvider,
    translate,
    change,
    cotenants,
    additionalOccupants,
    documentTemplates,
    agents,
    userId,
    claimUrl,
    clientId,
    showCreateLeaseSuccess,
    clearClaimUrl,
    validationErrors,
    useHellosign,
    prorationType,
    includeAllRentalChargesInProratedRent,
    hideServicesAndUtilities,
  }) => {
    const applicants = useMemo(() => cotenants.filter((c) => !c.is_guarantor), [cotenants]);
    const guarantors = useMemo(() => cotenants.filter((c) => c.is_guarantor), [cotenants]);

    useEffect(() => {
      const userHasPermission = agents.filter((agent) => agent.attributes.id === userId).length > 0;
      if (userHasPermission) {
        // we need this AND the defaultValue props in the Select component for agent_id to work
        change('agent_id', userId);
      }
    }, [userId, change, agents]);

    useEffect(() => {
      if (claimUrl) {
        let payload = {
          clientId: clientId,
          skipDomainVerification: env.ENV !== 'production',
        };

        if (featureEnabled('hellosign-with-locale')) {
          payload = { ...payload, locale: currentLocale() };
        }

        hellosignClient.open(claimUrl, payload);
      }
    }, [clientId, claimUrl]);

    useEffect(() => {
      hellosignClient.on('send', () => {
        showCreateLeaseSuccess();
        clearClaimUrl();
      });
    }, [showCreateLeaseSuccess, clearClaimUrl]);

    const userHasPermission = agents.filter((agent) => agent.attributes.id === userId).length > 0;

    const renderHelloSignLeaseConfiguration = () => {
      const leaseTemplates = [];
      const otherTemplates = [];

      const numApplicants = applicants.length;
      const numGuarantors = guarantors.length;
      const numOccupants = additionalOccupants.length;

      const noSignaturesRequired = (template) => {
        return (
          template.signable_doc_type === 'other' &&
          template.max_num_applicants === 0 &&
          template.max_num_guarantors === 0 &&
          template.max_num_occupants === 0
        );
      };

      const filteredDocumentTemplates = documentTemplates.filter(
        (template) =>
          (numApplicants <= template.max_num_applicants &&
            numGuarantors <= template.max_num_guarantors &&
            numOccupants <= template.max_num_occupants &&
            template.status === 'published') ||
          noSignaturesRequired(template)
      );

      filteredDocumentTemplates.forEach((template) => {
        if (template.signable_doc_type === 'lease') {
          leaseTemplates.push(template);
        } else {
          otherTemplates.push(template);
        }
      });

      return (
        <div>
          <h3 style={{ textAlign: 'center' }}>
            {getTranslatedString('landlordPortal.leaseCreator.setupSignatures')}
          </h3>
          <h4>{getTranslatedString('landlordPortal.leaseCreator.documents')}</h4>
          <Select
            name="lease_template_id"
            showSearch
            placeholder={getTranslatedString('landlordPortal.leaseCreator.leaseTemplate')}
            size="large"
            onChange={(value) => {
              change('lease_template_id', value);
            }}
            style={{ marginBottom: '8px' }}
          >
            {leaseTemplates.map((template) => (
              <Select.Option value={template.id} key={`lease_template_${template.id}`}>
                {template.title}
              </Select.Option>
            ))}
          </Select>
          {validationErrors.lease_template_id && (
            <p style={{ color: colors.RED }}>{validationErrors.lease_template_id}</p>
          )}
          <Select
            name="other_template_ids"
            mode="multiple"
            showSearch
            placeholder={getTranslatedString('landlordPortal.leaseCreator.otherTemplate')}
            size="large"
            onChange={(value) => {
              change('other_template_ids', value);
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ marginBottom: '8px' }}
          >
            {otherTemplates.map((template) => (
              <Select.Option value={template.id} key={`other_template_${template.id}`}>
                {template.title}
              </Select.Option>
            ))}
          </Select>
          <h4>{getTranslatedString('landlordPortal.leaseCreator.applicants')}</h4>
          {applicants.map((applicant) => (
            <div key={`applicant${applicant.id}`}>
              {applicant.first_name} {applicant.last_name} | {applicant.email}
            </div>
          ))}
          {applicants.length === 0 && <div>N/A</div>}
          <h4>{getTranslatedString('landlordPortal.leaseCreator.guarantors')}</h4>
          {guarantors.map((guarantor) => (
            <div key={`guarantor${guarantor.id}`}>
              {guarantor.first_name} {guarantor.last_name} | {guarantor.email}
            </div>
          ))}
          {guarantors.length === 0 && <div>N/A</div>}
          <h4>{getTranslatedString('landlordPortal.leaseCreator.occupants')}</h4>
          {additionalOccupants.map((occupant) => (
            <div key={`occupant${occupant.id}`}>
              {occupant.full_name} | {occupant.email}
            </div>
          ))}
          {additionalOccupants.length === 0 && <div>N/A</div>}
          <h4>{getTranslatedString('landlordPortal.leaseCreator.assignAgent')}</h4>
          <Select
            name="agent_id"
            showSearch
            placeholder={getTranslatedString('landlordPortal.leaseCreator.assignAgent')}
            size="large"
            defaultValue={userHasPermission ? userId : null}
            onChange={(value) => {
              change('agent_id', value);
            }}
          >
            {agents.map((agent) => (
              <Select.Option value={agent.attributes.id} key={`agent_${agent.attributes.id}`}>
                {agent.attributes.first_name} {agent.attributes.last_name} |{' '}
                {agent.attributes.email}
              </Select.Option>
            ))}
          </Select>
          {validationErrors.agent_id && (
            <p style={{ color: colors.RED }}>{validationErrors.agent_id}</p>
          )}
        </div>
      );
    };

    let proratedAmount = calculateProratedAmount(
      reviewValues.monthly_rent,
      reviewValues.early_move_in_date,
      reviewValues.lease_start_date,
      prorationType
    );

    if (reviewValues.leaseItems && reviewValues.leaseItems.length > 0) {
      const formattedLeaseItems = reviewValues.leaseItems.map((leaseItem) => ({
        amount: leaseItem.amount,
        start_date: toFriendlyDateString(leaseItem.start_date),
      }));
      let leaseItemsProratedAmount = calculateLeaseItemsProratedAmount(
        formattedLeaseItems,
        reviewValues.early_move_in_date,
        reviewValues.lease_start_date,
        prorationType,
        includeAllRentalChargesInProratedRent
      );
      proratedAmount = proratedAmount + leaseItemsProratedAmount;
    }

    if (hostIsAvenue() && reviewValues.rental_incentives?.length > 0) {
      let rentalIncentiveProratedAmount = leaseRentalIncentiveProratedAmount(
        reviewValues.early_move_in_date,
        reviewValues.lease_start_date,
        reviewValues.rental_incentives
      );
      proratedAmount = proratedAmount - rentalIncentiveProratedAmount;
    }

    return (
      <form
        onSubmit={handleSubmit}
        id="form_field"
        encType="multipart/form-data"
        className="reviewForm"
      >
        {featureEnabled('custom_lease_creator') && useHellosign ? (
          renderHelloSignLeaseConfiguration()
        ) : (
          <div>
            <h3 style={{ textAlign: 'center' }}> Lease Review </h3>
            <TableHeader
              name={translate('property_and_unit')}
              borderRadius="8px"
              onEdit={() => goToPage(PAGES.PROPERTY)}
            />
            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col-sm-6">
                <ReviewField title="Property" value={reviewValues.property_value} />
              </div>
              {reviewValues.unit_number_value && (
                <div className="col-sm-6">
                  <ReviewField title="Unit" value={reviewValues.unit_number_value} />
                </div>
              )}
              {reviewValues.unitTypeValue && reviewValues.delayUnitAssignment && (
                <div className="col-sm-6">
                  <ReviewField title="Unit Type" value={reviewValues.unitTypeValue} />
                </div>
              )}
            </div>

            <TableHeader
              borderRadius="8px"
              name={translate('leaseTerms')}
              onEdit={() => goToPage(PAGES.LEASE_TERM)}
            />
            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col-sm-4">
                <ReviewField title="Lease Start Date" value={reviewValues.lease_start_date} />
              </div>
              <div className="col-sm-4">
                <ReviewField title="Lease End Date" value={reviewValues.lease_end_date} />
              </div>
              <div className="col-sm-4">
                <ReviewField title="Move In Date" value={reviewValues.early_move_in_date} />
              </div>
              {reviewValues.isProratedCharged && (
                <div className="col-sm-4">
                  <ReviewField title="Prorated Amount" value={formatMoney(proratedAmount)} />
                </div>
              )}
            </div>

            <div className="row" style={{ margintop: '15px' }}>
              <div className="col-xs-12">
                <ReviewField
                  title="Rental Insurance Required?"
                  value={reviewValues.renters_insurance_required ? 'Yes' : 'No'}
                />
              </div>
            </div>

            <TableHeader
              borderRadius="8px"
              name={translate('leasePricing')}
              onEdit={() => goToPage(PAGES.LEASE_PRICING)}
            />
            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col-sm-4">
                <ReviewField title="Monthly Rent" value={reviewValues.monthly_rent} />
              </div>
              <div className="col-sm-4">
                <ReviewField title="Deposit Amount" value={reviewValues.deposit_amount} />
              </div>
              <div className="col-sm-4">
                <ReviewField title="Key Deposit" value={reviewValues.key_deposit} />
              </div>
            </div>

            {reviewValues.keys_description && (
              <div className="row">
                <h5
                  style={{
                    marginTop: '15px',
                    marginLeft: '10px',
                    color: colors.DISABLED_GRAY,
                  }}
                >
                  Key Details
                </h5>
                <p>{reviewValues.keys_description}</p>
              </div>
            )}

            {paymentProvider && (
              <div className="row" style={{ marginTop: '0px' }}>
                <div className="col-sm-12">
                  <ReviewField
                    title="Collect first months rent with deposit?"
                    value={reviewValues.collect_first_months_with_deposit ? 'Yes' : 'No'}
                  />
                </div>
              </div>
            )}

            {(reviewValues.leaseItems || []).length > 0 && (
              <div className="row" style={{ marginTop: '0px' }}>
                <div className="col-sm-12" style={{ padding: '0 5px' }}>
                  <p
                    style={{
                      padding: '0 10px',
                      color: 'rgba(78, 78, 78, 0.9)',
                      fontSize: '13px',
                      margin: 0,
                      textAlign: 'center',
                    }}
                  >
                    Additional Lease Items
                  </p>
                  {RenderLeaseItem({
                    items: reviewValues.leaseItems,
                    disabled: true,
                  })}
                </div>
              </div>
            )}

            <TableHeader
              borderRadius="8px"
              name={translate('scheduleA')}
              onEdit={() => goToPage(PAGES.SCHEDULE)}
            />
            {reviewValues.schedule_a && (
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col-sm-12">
                  <div style={{ textAlign: 'center' }}>
                    <ReviewField title="Schedule A Name" value={reviewValues.schedule_a} />
                  </div>
                </div>
              </div>
            )}

            <TableHeader
              borderRadius="8px"
              name={translate('rentalIncentive')}
              onEdit={() => goToPage(PAGES.RENTAL_INCENTIVE)}
            />
            {reviewValues.rental_incentives &&
              reviewValues.rental_incentives.map((rentalIncentive) => {
                return (
                  <div className="row" style={{ marginTop: '15px' }}>
                    <div className="col-sm-4">
                      <ReviewField
                        title="Start Date"
                        value={toFriendlyDateString(rentalIncentive.effective_date)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <ReviewField title="Frequency" value={rentalIncentive.frequency} />
                    </div>
                    <div className="col-sm-4">
                      <ReviewField
                        title="Monthly Amount"
                        value={formatMoney(rentalIncentive.incentive_amount)}
                      />
                    </div>
                  </div>
                );
              })}

            {!hideServicesAndUtilities && (
              <React.Fragment>
                <TableHeader
                  borderRadius="8px"
                  name={translate('servicesAndUtilities')}
                  onEdit={() => goToPage(PAGES.SERVICES_AND_UTILITIES)}
                />
                <div className="row" style={{ marginTop: '-20px', marginLeft: '4px' }}>
                  <ServicesAndUtilitiesWizardPage form="lease_creator_form" isReviewMode />
                </div>
              </React.Fragment>
            )}

            <TableHeader
              borderRadius="8px"
              name={translate('welcomePackage')}
              onEdit={() => goToPage(PAGES.WELCOME_PACKAGE)}
            />
            {(reviewValues.welcome_package || reviewValues.welcome_package_name) && (
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col-sm-12">
                  <div style={{ textAlign: 'center' }}>
                    <ReviewField
                      title="Welcome Package"
                      value={reviewValues.welcome_package || reviewValues.welcome_package_name}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </form>
    );
  }
);

reviewPackagePage = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(reviewPackagePage);

const selector = formValueSelector('lease_creator_form');

let extractInitialValues = (state, ownProps) => {
  // TODO: rewrite all of this
  if (selector(state, 'property')) {
    const property_value = selector(state, 'property')['label'];
    let unit_number_value = null;
    if (selector(state, 'unit_selected')) {
      unit_number_value = selector(state, 'unit_selected')['label'];
    }
    const schedule_a = selector(state, 'schedule_a');
    const schedule_a_body = selector(state, 'schedule_a_body');
    const schedule_a_name = selector(state, 'schedule_a_name');
    let welcome_package = selector(state, 'welcome_package');
    const welcome_package_name = selector(state, 'welcome_package_name');
    const welcome_package_upload = selector(state, 'welcome_package_upload');
    const keys_description = selector(state, 'keys_description');
    const leaseItems = selector(state, 'lease_items');
    const lease_start_date = toFriendlyDateString(selector(state, 'lease_start_date'));
    const lease_end_date = toFriendlyDateString(selector(state, 'lease_end_date'));
    const early_move_in_date = toFriendlyDateString(selector(state, 'early_move_in_date'));
    const monthly_rent = formatMoney(selector(state, 'monthly_rent'));
    const deposit_amount = formatMoney(selector(state, 'deposit_amount'));
    const key_deposit = formatMoney(selector(state, 'key_deposit'));
    const effective_date = toFriendlyDateString(selector(state, 'effective_date'));
    const frequency = selector(state, 'frequency');
    const incentive_amount = selector(state, 'incentive_amount');
    const collect_first_months_with_deposit = selector(state, 'collect_first_months_with_deposit');
    const renters_insurance_required = selector(state, 'renters_insurance_required');
    const rental_incentives = selector(state, 'rental_incentives');
    const cotenants = selector(state, 'cotenants');
    const documentTemplates = state.landlordDocumentTemplates.documentTemplates;
    const agents = state.landlordApplications.application.agents;
    const additionalOccupants = state.landlordApplications.application.additional_occupants;
    const userId = state.userTypes.userId;
    const claimUrl = state.landlordApplications.claimUrl;
    const clientId = state.landlordSignableDoc.clientId;
    const useHellosign = state.landlordApplications.application.use_hellosign;
    const isProratedCharged = selector(state, 'create_prorated_charge');
    const unitTypeSelected = state.landlordApplications.leaseDetails.unit_type_selected;
    const unitTypeValue = unitTypeSelected ? unitTypeSelected.label : null;
    const delayUnitAssignment = state.landlordApplications.leaseDetails.delay_unit_assignment;
    const prorationType = state.companyDetails.companyPreferences.prorate_type;
    const includeAllRentalChargesInProratedRent =
      state.companyDetails.companyPreferences.include_all_rental_charges_in_prorated_rent;

    if (!welcome_package && !welcome_package_name && !welcome_package_upload) {
      welcome_package = 'None';
    }
    return {
      form: ownProps.name,
      cotenants,
      documentTemplates,
      additionalOccupants,
      agents,
      userId,
      claimUrl,
      clientId,
      useHellosign,
      reviewValues: {
        property_value,
        unit_number_value,
        schedule_a,
        schedule_a_body,
        schedule_a_name,
        welcome_package,
        welcome_package_name,
        welcome_package_upload,
        keys_description,
        leaseItems,
        lease_start_date,
        lease_end_date,
        early_move_in_date,
        monthly_rent,
        deposit_amount,
        key_deposit,
        collect_first_months_with_deposit,
        effective_date,
        frequency,
        incentive_amount,
        renters_insurance_required,
        rental_incentives,
        isProratedCharged,
        unitTypeValue,
        delayUnitAssignment,
      },
      prorationType,
      includeAllRentalChargesInProratedRent,
    };
  } else {
    //Is in edit mode
    return {
      form: ownProps.name,
      initialValues: state.landlordApplications.leaseDetails,
      reviewValues: {
        ...state.landlordApplications.leaseDetails,
        property_value: state.landlordApplications.leaseDetails.property.label,
      },
      prorationType: state.companyDetails.companyPreferences.prorate_type,
      includeAllRentalChargesInProratedRent:
        state.companyDetails.companyPreferences.include_all_rental_charges_in_prorated_rent,
    };
  }
};

reviewPackagePage = connect(
  (state, ownProps) => {
    return extractInitialValues(state, ownProps);
  },
  {
    showCreateLeaseSuccess,
    clearClaimUrl,
  }
)(reviewPackagePage);

export default reviewPackagePage;
