export default function reducer(
  state = {
    report_data: [],
    report_type: 'General Journal',
    reportDialog: false,
    report_dates: '',
  },
  action
) {
  switch (action.type) {
    case 'SAVE_REPORT': {
      return {
        ...state,
        report_data: action.data,
        report_dates: action.date_range,
      };
    }
    case 'SELECT_REPORT': {
      return { ...state, report_type: action.data };
    }
    case 'OPEN_REPORT_DIALOG': {
      return { ...state, reportDialog: true };
    }
    case 'CLOSE_REPORT_DIALOG': {
      return { ...state, reportDialog: false };
    }
    default:
      return state;
  }
}
