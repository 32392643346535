export default function reducer(
  state = {
    data: {
      applications: 0,
      tickets: 0,
      showings: 0,
      unread_messages: 0,
      multiFamilyEnabled: false,
      urgent_tickets: 0,
      new_tickets: 0,
      duplicateTenants: [],
      duplicateTenantsCount: 0,
    },
  },
  action
) {
  switch (action.type) {
    case 'DASHBOARD::RECEIVE_DATA': {
      return {
        ...state,
        data: action.data,
      };
    }
    case 'DASHBOARD::RECEIVE_GRAPH_DATA': {
      return {
        ...state,
        graphData: action.data,
      };
    }
    case 'DASHBOARD::DUPLICATE_LEADS': {
      return {
        ...state,
        duplicateTenants: action.duplicateTenants,
      };
    }
    case 'DASHBOARD::DUPLICATE_LEAD_STATS': {
      return {
        ...state,
        duplicateTenantsCount: action.duplicateTenantsCount,
      };
    }
    default:
      return state;
  }
}
