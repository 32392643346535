export default function reducer(
  state = {
    fetchingProspects: false,
    prospect: {},
    prospects: [],
    prospectsCounts: {},
    showNewLeadDialog: false,
    showEditLeadDialog: false,
    leadDashboard: {},
    trafficSources: [],
    referrals: [],
  },
  action
) {
  switch (action.type) {
    case 'PROSPECTS::FETCH_PROSPECTS_PENDING': {
      return { ...state, fetchingProspects: true };
    }
    case 'PROSPECTS::SAVE_PROSPECTS_STATS': {
      return { ...state, prospectsCounts: action.payload };
    }
    case 'PROSPECTS::SHOW_PROSPECT': {
      return { ...state, prospect: action.prospect };
    }
    case 'PROSPECTS::SHOW_PROSPECTS': {
      if (state.prospectsLastFetched > action.timestamp) return state;
      return {
        ...state,
        prospects: [...state.prospects.slice(0, action.offset), ...action.prospects],
        prospectsLastFetched: action.timestamp,
        fetchingProspects: false,
        offset: action.offset,
      };
    }
    default:
      return state;
  }
}
