import { apiGet, apiPost, apiPut, apiPutMultipart, apiDelete } from 'helpers/ApiHelper';
import Auth from 'j-toker';
import * as notifications from './notificationActions';
import { setLandlordLogo, setLandlordPropertyTypes, setAccentColor } from './userTypesActions';

export function getBillingDetails() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/billing`)
        .done((response) => {
          dispatch({
            type: 'COMPANY::SAVE_BILLING_DETAILS',
            billingDetails: response.billing_details,
          });
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updateBillingDetails(data) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/billing`, data)
        .done((response) => {
          dispatch(notifications.showSuccess('billingDetailsSuccessfullyUpdated'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error(error);
        }),
    });
  };
}

export function connectOAuth(provider, data) {
  return (dispatch) =>
    apiPost(Auth.getApiUrl() + `/landlords/oauth/${provider}`, data)
      .done((response) => {
        dispatch(notifications.showSuccess('successfullyConnectedAccount'));
      })
      .catch((error) => {
        dispatch(notifications.showFailure(error));
        console.error(error);
      });
}

export function destroyCalendarTokens() {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + `/oauth/destroy_calendar_tokens`)
        .done((response) => {
          if (response.azureConnected) {
            dispatch(notifications.showFailure('Could not disconnect your Azure account'));
            return;
          }

          if (response.googleConnected) {
            dispatch(notifications.showFailure('Could not disconnect your Google account'));
            return;
          }

          dispatch({
            type: 'USER_TYPES::UPDATE_CALENDAR_CONNECTIONS',
            azureConnected: response.azureConnected,
            googleConnected: response.googleConnected,
          });
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function generateOAuthUserToken() {
  return (dispatch) => {
    return dispatch({
      type: 'GENERATE_OAUTH_USER_TOKEN',
      payload: apiPost(Auth.getApiUrl() + '/oauth/user_token')
        .done((response) => {
          return response.token;
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error(error);
        }),
    });
  };
}

export function getCompanyDetails() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/company`)
        .done((response) => {
          dispatch(saveCompany(response.company));
          dispatch(setLandlordPropertyTypes(response.company.property_types));
          // backwards support for accessing address directly from reducer
          if (response.company) {
            dispatch(saveAddress(response.company.address));
          }
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getCompanyPreferences() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/preferences`)
        .done((response) => {
          dispatch(savePreferences(response));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

function savePreferences(preferences) {
  return {
    type: 'COMPANY::SAVE_PREFERENCES',
    preferences,
  };
}

function saveAddress(data) {
  return {
    type: 'COMPANY::SAVE_ADDRESS',
    address: data,
  };
}

function saveCompany(company) {
  return {
    type: 'COMPANY::SAVE_COMPANY',
    company,
  };
}

//deprecated - use updateCompany
export function updateCompanyAddress(formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/legal_address`, formData)
        .done((response) => {
          dispatch(getCompanyDetails());
          dispatch(notifications.showSuccess('companyAddressSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updateCompany(formData) {
  const multiPartFormData = new FormData();
  if (formData.new_logo) {
    multiPartFormData.append('file', formData.new_logo.file[0]);
  }
  if (formData.new_hero_image) {
    multiPartFormData.append('hero_image_file', formData.new_hero_image.file[0]);
  }

  const data = JSON.stringify(formData);
  multiPartFormData.append('data', data);

  return (dispatch) => {
    return dispatch({
      payload: apiPutMultipart(Auth.getApiUrl() + `/landlords/company`, multiPartFormData)
        .done((response) => {
          dispatch(getCompanyDetails());
          if (response.landlord_logo_url) {
            dispatch(setLandlordLogo(response.landlord_logo_url));
          }
          if (response.accent_color) {
            dispatch(setAccentColor(response.accent_color));
          }
          if (response.property_types) {
            dispatch(setLandlordPropertyTypes(response.property_types));
          }
          dispatch(notifications.showSuccess('companyDetailsSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updateCompanyPreferences(formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/preferences`, formData)
        .done((response) => {
          dispatch(getCompanyPreferences());
          dispatch(notifications.showSuccess('companyPreferencesSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function deleteCompanyRole(id) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + `/landlords/roles/${id}`)
        .done((response) => {
          dispatch(getCompanyDetails());
          dispatch(notifications.showSuccess('companyRoleSuccessfullyDeleted'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function createOrUpdateCompanyRole(roleId, formData) {
  return (dispatch) => {
    return dispatch({
      payload: rolesPayload(roleId, formData)
        .done((response) => {
          dispatch(getCompanyDetails());
          dispatch(notifications.showSuccess(response.translationCode));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

function rolesPayload(roleId, formData) {
  if (formData.whitelisted_ips_enabled !== true) {
    formData.whitelisted_ips = [];
  }
  formData.sso_enabled = formData.sso_enabled === true;
  if (roleId) {
    return apiPut(Auth.getApiUrl() + `/landlords/roles/${roleId}`, formData);
  } else {
    return apiPost(Auth.getApiUrl() + '/landlords/roles', formData);
  }
}
