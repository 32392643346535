// @flow
import { getTranslatedString } from 'helpers/i18n';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

export function startsWith(initialString: string, searchString: string): boolean {
  if (typeof initialString !== 'string') return false;

  return initialString.indexOf(searchString) === 0;
}

export function capitalize(text: string): string {
  return text[0].toUpperCase() + text.substring(1);
}

export function toSentenceCase(string, { separator = '_', useCapitalize = true } = {}) {
  const splitSentence = string.split(separator).join(' ');

  return useCapitalize ? capitalize(splitSentence) : splitSentence;
}

/**
 * Transforms a string to sentence case, ensuring the first character is capitalized
 * and all other characters are lower case.
 *
 * Returns an empty string if the input is null, undefined, or an empty string.
 *
 * @example
 * sentenceCase('HELLO WORLD') // 'Hello world'
 *
 * @function
 * @name sentenceCase
 * @param {string} [string=''] - The string to be transformed.
 * @returns {string} The transformed string in sentence case, or an empty string if the input is null, undefined, or an empty string.
 */
export const sentenceCase = (string) => upperFirst(lowerCase(string));

export function isFilterInStrings(strings: string, filter: mixed): boolean {
  if (typeof strings !== 'object' || typeof filter !== 'string') return false;

  const arrayOfFilteredValues = dynamicEscape(filter)
    .split(/,|\s/)
    .filter((entry) => entry.trim() !== '');
  const regexStr = '(?=.*' + arrayOfFilteredValues.join(')(?=.*') + ').+';

  const searchRegEx = new RegExp(regexStr, 'gi');

  return strings.join(' ').match(searchRegEx) !== null;
}

function dynamicEscape(string: string): string {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function unitAndPropertyName(unitNumber, propertyName) {
  const unitNumberWithDash = unitNumber ? `${unitNumber} - ` : '';
  return `${unitNumberWithDash}${propertyName}`;
}

export function formatSinNumber(sinNumber) {
  return `${sinNumber.slice(0, 3) + '-' + sinNumber.slice(3, 6) + '-' + sinNumber.slice(6, 9)}`;
}

export function tenantResidentVerbiage(propertyType) {
  if (propertyType === 'commercial') {
    return getTranslatedString('tenant');
  }
  return getTranslatedString('resident');
}

// return 1 or -1  for the sorting
export const stringArraySort = (a, b) => (a > b ? 1 : b > a ? -1 : 0);

// https://stackoverflow.com/questions/14872554/sorting-on-a-custom-order
export const applyCustomOrder = (arr, desiredOrder, key) => {
  const ordering = {};
  for (var i = 0; i < desiredOrder.length; i++) {
    ordering[desiredOrder[i]] = i;
  }

  return arr.sort((a, b) => {
    return ordering[a[key]] - ordering[b[key]];
  });
};

export const formatNamesArray = (namesList) => {
  if (!namesList?.length) {
    return '';
  }

  if (namesList.length === 1) {
    return namesList[0];
  }

  const namesListCopy = [...namesList];
  const lastName = namesListCopy.pop();
  const formattedNamesList = `${namesListCopy.join(', ')} and ${lastName}`;

  return formattedNamesList;
};
