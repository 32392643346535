import { initializeClient } from 'launchDarkly';

const launchDarklyMiddleware = (storeAPI) => (next) => (action) => {
  let launchDarklyUser;

  if (
    action.type === 'USER_TYPES::SET_USER_AS_LANDLORD' ||
    action.type === 'USER_TYPES::SET_LANDLORD_DETAILS' ||
    action.type === 'USER_TYPES::SET_USER_AS_LEAD'
  ) {
    launchDarklyUser = {
      key: action.landlordId,
      name: action.landlordName,
    };
  }

  if (launchDarklyUser && launchDarklyUser.key && launchDarklyUser.name) {
    initializeClient(launchDarklyUser).then(() => {
      next(action);
    });

    return;
  }

  return next(action);
};

export default launchDarklyMiddleware;
