export default function reducer(
  state = {
    updateSuccessNotice: false,
    updateFailureNotice: false,
    notificationBody: null,
    showLoader: false,
    verbiage: null,
    showSecondaryLoader: false,
    explosionCelebration: false,
  },
  action
) {
  switch (action.type) {
    case 'NOTIFICATIONS::CELEBRATION_EXPLOSION': {
      return { ...state, explosionCelebration: true };
    }
    case 'UPDATE_SUCCESS': {
      if (action.message) {
        return {
          ...state,
          updateSuccessNotice: action.updateSuccessNotice,
          message: action.message,
          explosionCelebration: false,
        };
      }
      return {
        ...state,
        updateSuccessNotice: action.updateSuccessNotice,
        message: null,
        explosionCelebration: false,
      };
    }
    case 'UPDATE_FAIL': {
      if (action.message) {
        return {
          ...state,
          updateFailureNotice: action.updateFailureNotice,
          message: action.message,
          explosionCelebration: false,
        };
      }
      return {
        ...state,
        updateFailureNotice: action.updateFailureNotice,
        message: null,
        explosionCelebration: false,
      };
    }
    case 'CLEAR_UPDATE_SUCCESS': {
      return {
        ...state,
        updateSuccessNotice: action.updateSuccessNotice,
        explosionCelebration: false,
      };
    }
    case 'CLEAR_UPDATE_FAILURE': {
      return {
        ...state,
        updateFailureNotice: action.updateFailureNotice,
        explosionCelebration: false,
      };
    }
    case 'NOTIFICATIONS::SHOW_SECONDARY_LOADER': {
      return { ...state, showSecondaryLoader: action.showLoader, explosionCelebration: false };
    }
    case 'SHOW_FULL_SCREEN_LOADER': {
      return {
        ...state,
        showLoader: action.shouldShow,
        verbiage: action.verbiage,
        explosionCelebration: false,
      };
    }
    default:
      return state;
  }
}
