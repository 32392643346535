import * as types from './types';

const initialState = {
  availableDocumentTemplates: [],
  documentTemplateForProperty: [],
  selectedBaseDocumentTemplate: {},
  conditionalAddendums: [],
  conditionalAddendumsForLease: [],
  fixedAddendums: [],
  fixedAddendumsForLease: [],
  availableAdditionalCharges: [],
  availableAdditionalFields: [],
  rentableItemsForLease: [],
  additionalChargesForLease: [],
  currentAdditionalChargesForLease: [],
  currentRentableItemsForLease: [],
  servicesForLease: [],
  utilitiesForLease: [],
  additionalFieldsForLease: [],
  leaseReviews: [],
  latestLeaseReview: {}
};

const leaseCreatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_DOCUMENT_TEMPLATES_FOR_PROPERTY: {
      return {
        ...state,
        documentTemplateForProperty: [...action.documentTemplateForProperty],
        availableDocumentTemplates: [...action.availableDocumentTemplates],
        conditionalAddendums: [...action.conditionalAddendums],
        fixedAddendums: [...action.fixedAddendums],
      };
    }
    case types.STORE_BASE_DOCUMENT_TEMPLATE: {
      return {
        ...state,
        selectedBaseDocumentTemplate: action.selectedBaseDocumentTemplate,
      };
    }
    case types.STORE_FIXED_ADDENDUMS_FOR_LEASE: {
      return {
        ...state,
        fixedAddendumsForLease: action.fixedAddendumsForLease,
      };
    }
    case types.STORE_AVAILABLE_ADDITIONAL_CHARGES: {
      return {
        ...state,
        availableAdditionalCharges: action.availableAdditionalCharges,
      };
    }
    case types.STORE_CURRENT_ADDITIONAL_CHARGES_FOR_LEASE: {
      return {
        ...state,
        currentAdditionalChargesForLease: action.currentAdditionalChargesForLease,
      };
    }
    case types.STORE_CURRENT_RENTABLE_ITEMS_FOR_LEASE: {
      return {
        ...state,
        currentRentableItemsForLease: action.currentRentableItemsForLease,
      };
    }
    case types.STORE_CONDITIONAL_ADDENDUMS_FOR_LEASE: {
      return {
        ...state,
        conditionalAddendumsForLease: action.conditionalAddendumsForLease,
      };
    }
    case types.STORE_SERVICES_AND_UTILITIES_FOR_LEASE: {
      return {
        ...state,
        servicesForLease: action.servicesForLease,
        utilitiesForLease: action.utilitiesForLease,
      };
    }
    case types.STORE_ADDITIONAL_FIELDS_FOR_LEASE: {
      return {
        ...state,
        additionalFieldsForLease: action.additionalFieldsForLease,
      };
    }
    case types.STORE_LATEST_LEASE_REVIEW: {
      return {
        ...state,
        latestLeaseReview: action.latestLeaseReview,
      };
    }
    case types.STORE_LEASE_REVIEWS: {
      return {
        ...state,
        leaseReviews: action.leaseReviews,
      };
    }
    default:
      return state;
  }
};

export default leaseCreatorReducer;
