import React, { Component } from 'react';

import './signaturePage.css';

import { SyncLoader } from 'halogenium';
import { browserHistory } from 'react-router';
import { MdCheckCircle as CheckIcon } from 'react-icons/md';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import { BounceLoader } from 'halogenium';
import { localize } from 'react-localize-redux';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';

import * as ByesignHelper from './ByesignHelper';
import { redirectUrl } from 'helpers/UrlHelper';
import { titleize } from 'helpers/Formatter';
import TermsOfServiceDialog from 'components/shared/TermsOfServiceDialog';
import SignatureErrorView from './signatureErrorView';
import SignaturePadDialog from './signaturePadDialog';

import * as documentPortalInPersonActions from 'actions/documentPortalInPersonActions';
import * as documentPortalActions from 'actions/documentPortalActions';

import Measure from 'react-measure';

import { getLandlordVerbiageOrTranslatedString } from 'helpers/i18n';

let documentPortalInPerson = connect(
  (store) => {
    return {
      showDocument: store.documentPortalInPerson.showDocument,
      showPad: store.documentPortalInPerson.showPad,
      showActionsMenu: store.documentPortalInPerson.showActionsMenu,
      showNextButton: !store.documentPortalInPerson.showContinueButton,
      showStartButton: store.documentPortalInPerson.showStartButton,
      showAgreementDialog: store.documentPortalInPerson.showAgreementDialog,
      showSignerDialog: store.documentPortalInPerson.showSignerDialog,
      showFinishButton: store.documentPortalInPerson.showFinishButton,
      currentSigner: store.documentPortalInPerson.currentSigner,
      isSending: store.documentPortalInPerson.isSending,
      isPadTypeSignature: store.documentPortalInPerson.padType === 'SIGNATURE',
      isPadTypeTextBox: store.documentPortalInPerson.padType === 'TEXTBOX',
      anchorEl: store.documentPortalInPerson.anchorEl,
      anchorId: store.documentPortalInPerson.anchorId,
      signButtonEnabled: store.documentPortalInPerson.signButtonEnabled,
      signatureDocument: store.documentPortalInPerson.signatureDocument,
      signatureId: store.documentPortalInPerson.signatureId,
      signatures: store.documentPortalInPerson.signatures,
      signaturePageWidth: store.screenDimensions.width,
      signaturePageHeight: store.screenDimensions.height,
      signatureSuccess: store.documentPortalInPerson.signatureSuccess,
      processedSigners: store.documentPortalInPerson.processedSigners,
      companyName: store.documentPortalInPerson.companyName,
      colorScheme: store.documentPortalInPerson.colorScheme,
      showTermsOfServiceDialog: store.documentPortal.showTermsOfServiceDialog,
      showPreviousSignatureImage: store.documentPortal.showPreviousSignatureImage,
      signatureError: store.documentPortal.signatureError,
      signatureTextValue: store.documentPortal.signatureTextValue,
      toggleSignatureTextMode: store.documentPortal.toggleSignatureTextMode,
      privacyStatementLink: store.userTypes.privacyStatementLink,
    };
  },
  {
    ...documentPortalInPersonActions,
    openTermsOfServiceDialog: documentPortalActions.openTermsOfServiceDialog,
    closeTermsOfServiceDialog: documentPortalActions.closeTermsOfServiceDialog,
    displayPreviousSignatureImage: documentPortalActions.displayPreviousSignatureImage,
    hidePreviousSignatureImage: documentPortalActions.hidePreviousSignatureImage,
    updateSignatureTextValue: documentPortalActions.updateSignatureTextValue,
    updateToggleSignatureTextMode: documentPortalActions.updateToggleSignatureTextMode,
  }
)(
  class DocumentPortalInPerson extends Component {
    constructor(props) {
      super(props);
      this.sigPad = {};
      this.state = {
        trimmedDataURL: null,
        documentWidth: { 0: 0 },
        documentHeight: { 0: 0 },
        containerWidth: 0,
        showDocumentPagesAfterFullyLoaded: false,
      };
    }

    moveSignature = (width, height, pageHeight, pageNumber) => {
      const documentHeights = this.state.documentHeight;
      const documentWidths = this.state.documentWidth;
      if (width > 1190) {
        width = 1190;
      }
      documentWidths[pageNumber] = width;
      documentHeights[pageNumber] = height;
      this.setState({ documentWidth: documentWidths, documentHeight: documentHeights });
    };

    componentDidMount() {
      this.clearSignatures();
      const query = browserHistory.getCurrentLocation().query;
      let document_uuid = query.document_uuid || this.props.document_uuid;
      let access_token = query.access_token || this.props.access_token;
      let document_type = query.document_type || this.props.document_type;
      if (document_uuid && access_token) {
        this.props.getDocument(document_uuid, access_token, document_type, true);
      }
    }

    clearSignatures = () => {
      this.props.dispatch(documentPortalActions.clearSignatures());
      this.props.dispatch(documentPortalInPersonActions.clearSignatures());
    };

    openPad = (id, marker, signatureTextYPosition) => {
      let own_signatures;
      if (this.isSignature(marker)) {
        this.props.openPadForSignature(id);
        own_signatures = this.props.signatures.filter(
          (signature) =>
            this.props.currentSigner.uuid === signature.signer_uuid &&
            this.isSignature(signature.marker)
        );
      } else if (this.isTextbox(marker)) {
        this.props.openPadForTextBox(id);
        own_signatures = [];
      } else if (this.isCheckbox(marker)) {
        this.checkbox(id, true);
        own_signatures = [];
      } else {
        this.props.openPadForInitials(id);
        own_signatures = this.props.signatures.filter(
          (signature) =>
            this.props.currentSigner.uuid === signature.signer_uuid &&
            !this.isSignature(signature.marker)
        );
      }

      let lastIndex = (own_signatures || []).length - 1;
      if (
        own_signatures.length > 0 &&
        !this.isTextbox(own_signatures[lastIndex].marker) &&
        !this.isCheckbox(own_signatures[lastIndex].marker)
      ) {
        setTimeout(() => {
          this.previousSignatureImage.src = own_signatures[lastIndex].image;
        }, 0); // Doing this so the sigpad autopopulates the signature.
        this.props.displayPreviousSignatureImage();
        this.props.enableSignButton(true);
      } else {
        ByesignHelper.prepareSignatureTextMode(
          this.props,
          this.props.currentSigner,
          marker,
          this.updateSignatureTextCanvas,
          signatureTextYPosition
        );
      }
    };

    openAgreementDialog = (id) => {
      this.props.openAgreementDialog();
    };

    closeAgreementDialog = () => {
      this.props.closeAgreementDialog();
    };

    openMenu = (uuid, marker, anchorEl) => {
      if (this.isSignature(marker)) {
        this.props.openMenuForSignature(uuid, anchorEl);
      } else if (this.isTextbox(marker)) {
        this.props.openMenuForTextbox(uuid, anchorEl);
      } else if (this.isCheckbox(marker)) {
        this.checkbox(uuid);
      } else {
        this.props.openMenuForInitials(uuid, anchorEl);
      }
    };

    closeMenu = () => {
      this.props.closeMenu();
    };

    deleteSignature = (event) => {
      event.preventDefault();
      this.props.closeMenu();
      this.props.deleteSignature(this.props.signatureId);
      this.props.nextAnchorId();
    };

    editSignature = (event) => {
      event.preventDefault();
      this.props.closeMenu();
      this.props.hidePreviousSignatureImage();
      this.props.enableSignButton(false);

      if (this.props.isPadTypeSignature) {
        this.props.openPadForSignature(this.props.signatureId);
      } else if (this.props.isPadTypeTextBox) {
        this.props.openPadForTextBox(this.props.signatureId);
      } else {
        this.props.openPadForInitials(this.props.signatureId);
      }
    };

    updateSignatureTextCanvas = (signatureTextValue, signatureTextYPosition) => {
      setTimeout(() => {
        ByesignHelper.updateSignatureTextCanvas(
          this.signatureTextCanvas,
          signatureTextValue,
          signatureTextYPosition,
          this.props.isPadTypeTextBox
        );
      }, 0);
    };

    goToNextSignature = (justSigned) => {
      this.props.closePad();
      setTimeout(() => {
        let anchorId = this.props.anchorId;
        let el = document.getElementById(`e-anchor-${anchorId}`);
        if (el) {
          scrollIntoView(el, {
            time: 500,
          });
        }
        this.props.nextAnchorId(justSigned);
      }, 0);
    };

    signDocument = () => {
      let signers = this.props.processedSigners;
      for (let index = 0; index < signers.length; index++) {
        signers[index].signatures = this.props.signatures.filter(
          (signature) => signature.signer_uuid === signers[index].uuid
        );
      }

      let data = {
        document_uuid: this.props.signatureDocument.document.uuid,
        signers: signers,
        access_token:
          browserHistory.getCurrentLocation().query.access_token || this.props.access_token,
      };
      this.props.putDocument(data, this.props.document_type, this.props.isModeInternal);
      this.props.enableSignButton(false);
      this.props.closeAgreementDialog();
      this.props.showSendingLoader(true);
    };

    isSignature = (marker) => {
      return ByesignHelper.isSignature(marker);
    };

    isTextbox = (marker) => {
      return ByesignHelper.isTextbox(marker);
    };

    isCheckbox = (marker) => {
      return ByesignHelper.isCheckbox(marker);
    };

    openSignerDialog = () => {
      this.props.nextSigner();
      this.props.openSignerDialog();
    };

    closeSignerDialogOnCancel = () => {
      this.props.closeSignerDialogOnCancel();
    };

    continueToNextSigner = () => {
      this.props.nextSigner();
      this.props.updateSignatureTextValue('');
      this.props.updateToggleSignatureTextMode(true);
      if (this.props.signatureDocument.signatures.length !== 0) {
        this.props.openSignerDialog();
      } else {
        this.props.displayFinishButton();
      }
    };

    startSigningDocumentForSigner = () => {
      this.props.closeSignerDialog();
      this.goToNextSignature(true);
    };

    onCancel = () => {
      let query = browserHistory.getCurrentLocation().query;
      if (query.redirect_url) {
        redirectUrl(ByesignHelper.constants.CANCELLED);
      } else {
        if (this.props.isModeInternal) {
          browserHistory.push('/companies/dashboard');
        } else {
          browserHistory.push('/dashboard');
        }
      }
    };

    checkbox = (signatureId, isChecked = false) => {
      let url = 'https://prod-ca-central-1.s3.ca-central-1.amazonaws.com/public/x.png';

      this.props.captureSignature(signatureId, url, isChecked);
    };

    onRestart = () => {
      this.setState({ showDocumentPagesAfterFullyLoaded: false });
      this.clearSignatures();
      const query = browserHistory.getCurrentLocation().query;
      let document_uuid = query.document_uuid || this.props.document_uuid;
      let access_token = query.access_token || this.props.access_token;
      let document_type = query.document_type || this.props.document_type;
      this.props.getDocument(document_uuid, access_token, document_type, this.props.isModeInternal);
    };

    widthRatio = (pageNumber) => {
      if (
        this.props.signatureDocument &&
        this.props.signatureDocument.pages &&
        this.props.signatureDocument.pages.length > 0 &&
        this.props.signatureDocument.pages.length > pageNumber - 1
      ) {
        return ByesignHelper.widthRatio(
          this.state.documentWidth[pageNumber],
          this.props.signatureDocument.pages[pageNumber - 1].width
        );
      }

      return 1;
    };

    heightRatio = (pageNumber) => {
      if (
        this.props.signatureDocument &&
        this.props.signatureDocument.pages &&
        this.props.signatureDocument.pages.length > 0 &&
        this.props.signatureDocument.pages.length > pageNumber - 1
      ) {
        return ByesignHelper.heightRatio(
          this.state.documentHeight[pageNumber],
          this.props.signatureDocument.pages[pageNumber - 1].height
        );
      }

      return 1;
    };

    yPositionOffset = (heightRatio) => {
      return ByesignHelper.yPositionOffset(heightRatio);
    };

    render() {
      const translate = this.props.translate;
      const companyName = this.props.companyName || translate('defaultLandlordName').props.children;

      const isLandscape =
        this.props.signaturePageWidth > this.props.signaturePageHeight &&
        this.props.signaturePageHeight < 500;

      const BREAK_POINT = this.props.isPadTypeSignature ? 466 : 256;
      const MARGIN_RATIO = 36;

      if (this.props.signatureError && !this.props.signatureDocument) {
        redirectUrl(ByesignHelper.constants.CANCELLED, ByesignHelper.constants.REDIRECT_TIMEOUT_MS);
      }

      const style = {
        titleContentStyle: {
          color: '#4e4e4e',
          padding: isLandscape ? '5px 0px 5px 20px' : '20px 20px',
        },
      };

      const isSmallMobileDevice = ByesignHelper.isSmallMobileDevice(
        this.props.signaturePageWidth,
        this.props.signaturePageHeight
      );
      if (isLandscape || isSmallMobileDevice) {
        ByesignHelper.style.customContentStyle.transform = 'none';
        ByesignHelper.style.customContentStyle.top = '20px';
      }
      let signatureTextYPosition = isLandscape || isSmallMobileDevice ? 50 : 70;

      const colorScheme = this.props.colorScheme || ByesignHelper.style.grayColor;
      const opacity = this.state.showDocumentPagesAfterFullyLoaded ? 1 : 0;
      let css = `
      #mobile-menu-container {
        display: none;
      }
      .signature-border {
        border: 2px solid ${colorScheme};
      }
      .centers {
        padding: 0 0 88px 0 !important;
      }
      #signature-document {
        opacity: ${opacity};
      }`;

      let pages = [];
      if (this.props.signatureDocument) {
        for (let i = 0; i < this.props.signatureDocument.pages.length; i++) {
          pages.push(
            <Measure
              ref="measureComponent"
              onMeasure={(dimensions) => {
                this.moveSignature(
                  dimensions.width,
                  dimensions.height,
                  this.props.signatureDocument.pages[i].height,
                  i + 1
                );
              }}
            >
              <div key={i} className="signature-document-page">
                <div
                  style={{
                    margin: 'auto',
                  }}
                >
                  <img
                    alt=""
                    src={this.props.signatureDocument.pages[i].image_url}
                    style={{ display: 'initial' }}
                    onLoad={() => {
                      this.refs.measureComponent.measure();
                      this.setState({ showDocumentPagesAfterFullyLoaded: true });
                    }}
                  />
                </div>
              </div>
            </Measure>
          );
        }
        pages.push(
          <div key={this.props.signatureDocument.pages.length} className="signature-document-page">
            <div
              style={{
                margin: 'auto',
              }}
            />
          </div>
        );
      }

      let fields = [];
      let anchorCounter = 0;
      if (this.props.signatureDocument) {
        for (let index = 0; index < this.props.signatureDocument.signatures.length; index++) {
          let x = this.props.signatureDocument.signatures[index].x;
          let y = this.props.signatureDocument.signatures[index].y;
          let page_number = this.props.signatureDocument.signatures[index].page_number;
          let marker = this.props.signatureDocument.signatures[index].marker;
          const signatureBoxWidth = !this.isSignature(marker) ? 65 : 75;
          let uuid = this.props.signatureDocument.signatures[index].uuid;
          let belongs_to_current_signer =
            this.props.currentSigner &&
            this.props.currentSigner.uuid ===
              this.props.signatureDocument.signatures[index].signer_uuid;
          let widthFactor = this.widthRatio(page_number);
          let heightFactor =
            y * this.heightRatio(page_number) - this.yPositionOffset(this.heightRatio(page_number));
          let field_css = `#e-signature-${index}{
            transform: translate(${x * widthFactor}px,${heightFactor}px);
            width: ${signatureBoxWidth * this.widthRatio(page_number)}px;
            height: ${25 * this.heightRatio(page_number)}px;
            font-size: ${11 * this.widthRatio(page_number)}px;
            display: flex;
            align-items: center;
          } .e-signature-img img, .e-existing-signature-img img{
            width: ${70 * this.widthRatio(page_number)}px;
            height: ${20 * this.heightRatio(page_number)}px;
            background-size:
              ${70 * this.widthRatio(page_number)}px
              ${20 * this.heightRatio(page_number)}px !important;
            padding: 0;
          }`;

          if (this.isTextbox(marker)) {
            field_css = `#e-signature-${index}{
              transform: translate(${x * widthFactor}px,${heightFactor + 18}px);
              width: ${150 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
              font-size: ${11 * this.widthRatio(page_number)}px;
              display: flex;
              align-items: center;
            } .e-signature-img img, .e-existing-signature-img img{
              width: ${150 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
              background-size:
                ${150 * this.widthRatio(page_number)}px
                ${16 * this.heightRatio(page_number)}px !important;
              padding: 0;
            }`;
          }

          if (this.isCheckbox(marker)) {
            field_css = `#e-signature-${index}{
              transform: translate(${x * widthFactor}px,${heightFactor + 18}px);
              width: ${16 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
              font-size: ${11 * this.widthRatio(page_number)}px;
              display: flex;
              align-items: center;
            } .e-signature-img img, .e-existing-signature-img img{
              width: ${16 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
              background-size:
                ${16 * this.widthRatio(page_number)}px
                ${16 * this.heightRatio(page_number)}px !important;
              padding: 0;
            }`;
          }

          if (belongs_to_current_signer) {
            field_css += `#e-anchor-${anchorCounter}{
              transform: translate(0px,${heightFactor}px);
            }
            `;
            anchorCounter += 1;
          }

          css += field_css;

          let classNames = belongs_to_current_signer
            ? 'e-signature clickable signature-border'
            : 'e-signature';
          let text = belongs_to_current_signer ? 'signHere' : 'signature';
          fields.push(
            <div key={index}>
              <div
                id={`e-signature-${index}`}
                className={classNames}
                onClick={(index) => {
                  if (belongs_to_current_signer) {
                    this.openPad(uuid, marker, signatureTextYPosition);
                  }
                }}
              >
                {' '}
                {this.isSignature(marker) && (
                  <div style={{ paddingLeft: '4px' }}>{translate(text)}</div>
                )}
                {this.isTextbox(marker) && !this.isSignature(marker) && (
                  <div style={{ paddingLeft: '4px' }}>{translate('text')}</div>
                )}
                {!this.isTextbox(marker) &&
                  !this.isSignature(marker) &&
                  !this.isCheckbox(marker) && (
                    <div style={{ paddingLeft: '4px' }}>{translate('initials')}</div>
                  )}
              </div>
              {belongs_to_current_signer && (
                <div id={`e-anchor-${anchorCounter - 1}`} style={{ width: '1px' }}>
                  {' '}
                </div>
              )}
            </div>
          );
        }

        if (this.props.signatureDocument.existing_signatures) {
          for (
            let index = 0;
            index < this.props.signatureDocument.existing_signatures.length;
            index++
          ) {
            let x = this.props.signatureDocument.existing_signatures[index].x;
            let y = this.props.signatureDocument.existing_signatures[index].y;
            let page_number = this.props.signatureDocument.existing_signatures[index].page_number;
            let marker = this.props.signatureDocument.existing_signatures[index].marker;
            let widthFactor = this.widthRatio(page_number);
            let heightFactor =
              y * this.heightRatio(page_number) -
              this.yPositionOffset(this.heightRatio(page_number));
            let signature_css = `#e-existing-signature-img-${index}{
              transform: translate(${x * widthFactor}px,${heightFactor}px);
            }
            .e-existing-initials-img img{
              width: ${20 * this.widthRatio(page_number)}px;
              height: ${20 * this.heightRatio(page_number)}px;
              background-size:
                ${20 * this.widthRatio(page_number)}px
                ${20 * this.heightRatio(page_number)}px;
              padding: 0;
            }
            .e-existing-signature-img img{
              width: ${70 * this.widthRatio(page_number)}px;
              height: ${20 * this.heightRatio(page_number)}px;
              background-size:
                ${70 * this.widthRatio(page_number)}px
                ${20 * this.heightRatio(page_number)}px;
              padding: 0;
            }`;

            if (this.isTextbox(marker)) {
              signature_css = `#e-existing-signature-img-${index}{
                transform: translate(${x * widthFactor}px,${heightFactor + 18}px);
                width: ${150 * this.widthRatio(page_number)}px;
                height: ${16 * this.heightRatio(page_number)}px;
              }
              .e-existing-textbox-img img{
                width: ${150 * this.widthRatio(page_number)}px;
                height: ${16 * this.heightRatio(page_number)}px;
                padding: 0;
              }`;
            }

            if (this.isCheckbox(marker)) {
              signature_css = `#e-existing-signature-img-${index}{
                transform: translate(${x * widthFactor}px,${heightFactor + 18}px);
                width: ${16 * this.widthRatio(page_number)}px;
                height: ${16 * this.heightRatio(page_number)}px;
              }
              .e-existing-textbox-img img{
                width: ${16 * this.widthRatio(page_number)}px;
                height: ${16 * this.heightRatio(page_number)}px;
                padding: 0;
              }`;
            }

            css += signature_css;
            let image = this.props.signatureDocument.existing_signatures[index].image_url;
            let uuid = this.props.signatureDocument.existing_signatures[index].uuid;
            let specificClassName = this.isSignature(
              this.props.signatureDocument.existing_signatures[index].marker
            )
              ? 'e-existing-signature-img'
              : 'e-existing-initials-img';

            if (this.isTextbox(marker) || this.isCheckbox(marker)) {
              specificClassName = 'e-existing-textbox-img';
            }

            fields.push(
              <div
                key={uuid}
                id={`e-existing-signature-img-${index}`}
                className={specificClassName}
              >
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  alt=""
                  style={{ backgroundImage: 'url(' + image + ')' }}
                />
              </div>
            );
          }
        }
      }

      if (this.props.signatures) {
        for (let index = 0; index < this.props.signatures.length; index++) {
          let x = this.props.signatures[index].x;
          let y = this.props.signatures[index].y;
          let page_number = this.props.signatures[index].page_number;
          let belongs_to_current_signer =
            this.props.currentSigner &&
            this.props.currentSigner.uuid === this.props.signatures[index].signer_uuid;
          let marker = this.props.signatures[index].marker;
          const signatureBoxWidth = !this.isSignature(marker) ? 65 : 75;
          let widthFactor = this.widthRatio(page_number);
          let heightFactor =
            y * this.heightRatio(page_number) - this.yPositionOffset(this.heightRatio(page_number));
          let signature_css = `#e-signature-img-${index}{
            transform: translate(${x * widthFactor}px,${heightFactor}px);
            width: ${signatureBoxWidth * this.widthRatio(page_number)}px;
            height: ${28 * this.heightRatio(page_number)}px;
          }
          div.e-initials-img.clickable.signature-border {
            width: ${25 * this.widthRatio(page_number)}px !important;
          }
          .e-initials-img img, .e-existing-initials-img img{
            width: ${20 * this.widthRatio(page_number)}px;
            height: ${20 * this.heightRatio(page_number)}px;
            background-size:
              ${20 * this.widthRatio(page_number)}px
              ${20 * this.heightRatio(page_number)}px;
            padding: 0;
          }
          .e-signature-img img, .e-existing-signature-img img{
            width: ${70 * this.widthRatio(page_number)}px;
            height: ${20 * this.heightRatio(page_number)}px;
            background-size:
              ${70 * this.widthRatio(page_number)}px
              ${20 * this.heightRatio(page_number)}px;
            padding: 0;
          }`;

          if (this.isTextbox(marker)) {
            signature_css = `#e-signature-img-${index}{
              transform: translate(${x * widthFactor}px,${heightFactor + 18}px);
              width: ${150 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
            }
            .e-signature-img img, .e-existing-signature-img img{
              width: ${150 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
              background-size:
                ${150 * this.widthRatio(page_number)}px
                ${16 * this.heightRatio(page_number)}px;
              padding: 0;
            }`;
          }

          if (this.isCheckbox(marker)) {
            signature_css = `#e-signature-img-${index}{
              transform: translate(${x * widthFactor}px,${heightFactor + 18}px);
              width: ${16 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
            }
            .e-signature-img img, .e-existing-signature-img img{
              width: ${16 * this.widthRatio(page_number)}px;
              height: ${16 * this.heightRatio(page_number)}px;
              background-size:
                ${16 * this.widthRatio(page_number)}px
                ${16 * this.heightRatio(page_number)}px;
              padding: 0;
            }`;
          }

          css += signature_css;
          let image = this.props.signatures[index].image;
          let uuid = this.props.signatures[index].uuid;
          let specificClassName = this.isSignature(marker) ? 'e-signature-img' : 'e-initials-img';
          if (this.isTextbox(marker) || this.isCheckbox(marker)) {
            specificClassName = 'e-textbox-img';
          }
          let classNames = belongs_to_current_signer
            ? `${specificClassName} clickable signature-border`
            : `${specificClassName} black-border`;
          fields.push(
            <div key={uuid} id={`e-signature-img-${index}`} className={classNames}>
              <img
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                alt=""
                style={{ backgroundImage: 'url(' + image + ')' }}
                onClick={(event) => {
                  event.preventDefault();
                  if (belongs_to_current_signer) {
                    this.openMenu(uuid, marker, event.currentTarget);
                  }
                }}
              />
            </div>
          );
        }
      }

      if (this.props.signatureDocument && this.props.signatureDocument.full_name_markers) {
        for (
          let index = 0;
          index < this.props.signatureDocument.full_name_markers.length;
          index++
        ) {
          const nameMarkers = this.props.signatureDocument.full_name_markers;
          let x = nameMarkers[index].x;
          let y = nameMarkers[index].y;
          let page_number = nameMarkers[index].page_number;
          let widthFactor = this.widthRatio(page_number);
          let heightFactor =
            y * this.heightRatio(page_number) - this.yPositionOffset(this.heightRatio(page_number));
          let field_css = `#e-full_name-${index}{
            transform: translate(${x * widthFactor}px,${heightFactor + 15}px);
            font-size: ${11 * this.widthRatio(page_number)}px;
            display: flex;
            align-items: center;
            position: absolute;
          }`;
          css += field_css;
          fields.push(
            <div key={`name-${index}`}>
              <div id={`e-full_name-${index}`}>
                <div style={{ paddingLeft: '4px' }}>{nameMarkers[index].content}</div>
              </div>
            </div>
          );
        }
      }

      let canvas_width = this.props.isPadTypeSignature ? 400 : 200;
      if (this.props.signaturePageWidth < BREAK_POINT) {
        canvas_width =
          canvas_width * (this.props.signaturePageWidth / BREAK_POINT) -
          (1 - this.props.signaturePageWidth / BREAK_POINT) * MARGIN_RATIO;
      }

      if (this.props.isPadTypeTextBox) {
        canvas_width = 250;
      }

      const agreementDialogActions = [
        <FlatButton
          label={translate('close')}
          primary={true}
          style={{ color: ByesignHelper.style.grayColor }}
          onClick={this.closeAgreementDialog}
        />,
        <FlatButton
          label={translate('iAgree')}
          type="button"
          primary={true}
          backgroundColor={colorScheme}
          style={{ color: 'white' }}
          onClick={this.signDocument}
        />,
      ];

      const termsOfServiceActions = [
        <FlatButton
          label={translate('close')}
          primary={true}
          style={{ color: ByesignHelper.style.grayColor }}
          onClick={this.props.closeTermsOfServiceDialog}
        />,
      ];

      const signerDialogActions = [
        <FlatButton
          label={translate('close')}
          primary={true}
          style={{ color: ByesignHelper.style.grayColor }}
          onClick={this.props.closeSignerDialogOnCancel}
        />,
        <FlatButton
          label={translate('iAgree')}
          type="button"
          primary={true}
          backgroundColor={colorScheme}
          style={{ color: 'white' }}
          onClick={this.startSigningDocumentForSigner}
        />,
      ];

      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div id="e-document" style={{ height: '90%' }}>
            <SignaturePadDialog
              titleContentStyle={style.titleContentStyle}
              isLandscape={isLandscape}
              isSmallMobileDevice={isSmallMobileDevice}
              updateSignatureTextCanvas={this.updateSignatureTextCanvas}
              goToNextSignature={() => this.goToNextSignature(true)}
              colorScheme={colorScheme}
              signatureTextCanvasRef={(el) => (this.signatureTextCanvas = el)}
              previousSignatureImageRef={(el) => (this.previousSignatureImage = el)}
              canvas_width={canvas_width}
              isPadTypeSignature={this.props.isPadTypeSignature}
              showPad={this.props.showPad}
              showPreviousSignatureImage={this.props.showPreviousSignatureImage}
              toggleSignatureTextMode={this.props.toggleSignatureTextMode}
              enableSignButton={this.props.enableSignButton}
              signatureTextValue={this.props.signatureTextValue}
              updateToggleSignatureTextMode={this.props.updateToggleSignatureTextMode}
              updateSignatureTextValue={this.props.updateSignatureTextValue}
              closePad={this.props.closePad}
              signButtonEnabled={this.props.signButtonEnabled}
              currentSigner={this.props.currentSigner}
              hidePreviousSignatureImage={this.props.hidePreviousSignatureImage}
              signatureId={this.props.signatureId}
              captureSignature={this.props.captureSignature}
              signatureTextYPosition={signatureTextYPosition}
              isTextbox={this.props.isPadTypeTextBox}
            />

            <Dialog
              title={<div> {translate('lastStep')} </div>}
              open={this.props.showAgreementDialog}
              modal={false}
              actions={agreementDialogActions}
              titleStyle={style.titleContentStyle}
              bodyStyle={ByesignHelper.style.bodyAgreementContentStyle}
              contentStyle={ByesignHelper.style.customAgreementContentStyle}
              repositionOnUpdate={!isLandscape && !isSmallMobileDevice}
              style={{ zIndex: '3000' }}
            >
              <div>
                <div>
                  {translate('signatureAgreement1')}
                  <button
                    type="button"
                    className="link-button"
                    onClick={() => this.props.openTermsOfServiceDialog()}
                  >
                    {translate('termsOfService')}
                  </button>
                </div>
                <div style={{ marginTop: '10px' }}>{translate('signatureAgreement2')}</div>
              </div>
            </Dialog>

            <TermsOfServiceDialog
              open={this.props.showTermsOfServiceDialog}
              actions={termsOfServiceActions}
              onRequestClose={this.props.closeTermsOfServiceDialog}
              titleStyle={style.titleContentStyle}
              bodyStyle={ByesignHelper.style.bodyTermsContentStyle}
              contentStyle={ByesignHelper.style.customTermsContentStyle}
              repositionOnUpdate={!isLandscape && !isSmallMobileDevice}
              style={{ zIndex: '5000', overflow: 'scroll', width: '100%' }}
              privacyStatementLink={this.props.privacyStatementLink}
            />

            {this.props.currentSigner && (
              <Dialog
                title={
                  <div>
                    {getLandlordVerbiageOrTranslatedString('nameGreeting', {
                      name: titleize(
                        this.props.currentSigner.first_name +
                          ' ' +
                          this.props.currentSigner.last_name
                      ),
                    })}
                  </div>
                }
                open={this.props.showSignerDialog}
                modal={false}
                actions={signerDialogActions}
                titleStyle={style.titleContentStyle}
                bodyStyle={ByesignHelper.style.bodyAgreementContentStyle}
                contentStyle={ByesignHelper.style.customAgreementContentStyle}
                style={{ zIndex: '3000' }}
              >
                <div>
                  <div>
                    {translate('signatureAgreement1')}
                    <button
                      type="button"
                      className="link-button"
                      onClick={() => this.props.openTermsOfServiceDialog()}
                    >
                      {translate('termsOfService')}
                    </button>
                  </div>
                  <div style={{ marginTop: '10px' }}>{translate('signatureAgreement2')}</div>
                </div>
              </Dialog>
            )}

            <Popover
              open={this.props.showActionsMenu}
              anchorEl={this.props.anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              onRequestClose={this.closeMenu}
            >
              <Menu>
                <MenuItem primaryText={translate('edit')} onClick={(e) => this.editSignature(e)} />
                <MenuItem primaryText={translate('delete')} onClick={this.deleteSignature} />
              </Menu>
            </Popover>

            <Measure
              onMeasure={(dimensions) => {
                this.setState({ containerWidth: dimensions.width });
              }}
            >
              <div>
                {!this.props.isSending && this.props.showDocument && !this.props.signatureError && (
                  <div>
                    <style>{css}</style>
                    <div id="signature-document">
                      <div id="signature-document-inner" style={{ padding: 0, display: 'block' }}>
                        {fields}
                        {pages}
                      </div>
                    </div>
                  </div>
                )}

                {this.props.isSending && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '30%',
                      zIndex: '500',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'center',
                        width: '60px',
                        margin: 'auto',
                      }}
                    >
                      <BounceLoader color={ByesignHelper.style.primarySchemeColor} />
                    </p>
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: '15px',
                        marginBottom: '15px',
                      }}
                    >
                      {translate('sendingDocument', { name: `${companyName}` })}
                    </p>
                  </div>
                )}

                {!this.props.signatureSuccess &&
                  !this.props.signatureError &&
                  !this.props.showDocument && (
                    <div
                      style={{
                        position: 'fixed',
                        top: '30%',
                        zIndex: '500',
                        left: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <SyncLoader
                          color={ByesignHelper.style.primarySchemeColor}
                          size="16px"
                          margin="4px"
                        />
                      </p>
                      <p style={{ textAlign: 'center', fontSize: '12px' }} id="loader">
                        {translate('retrievingDocument')}
                      </p>
                    </div>
                  )}
                {this.props.signatureSuccess && !this.props.signatureError && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '30%',
                      zIndex: '500',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'center',
                        marginBottom: '5px',
                      }}
                    >
                      <CheckIcon style={{ fontSize: '85px', color: '#4cca6e' }} />
                    </p>
                    <p style={{ textAlign: 'center', fontSize: '15px' }} id="loader">
                      {translate('receivedDocument', {
                        name: `${titleize(companyName)}`,
                      })}
                    </p>
                  </div>
                )}

                {this.props.signatureError && <SignatureErrorView isLandscape />}
              </div>
            </Measure>
          </div>

          <div
            id="signaturefooterbar"
            style={{
              paddingright: '15px',
              width: `${this.state.containerWidth}px`,
              height: '92px',
              left: 'auto',
            }}
          >
            <div style={{ padding: '0 15px' }}>
              <div id="footer_instructions">
                {this.props.signatureDocument &&
                  this.props.signatureDocument.signers.length !== 0 &&
                  !this.props.signatureSuccess &&
                  !this.props.signatureError &&
                  !this.props.isSending && (
                    <span>
                      <h5 id="main_footer_instructions">
                        {' '}
                        {translate('completeMandatoryFields')}{' '}
                      </h5>
                      <h5 id="sub_footer_instructions"> {translate('followInstructions')} </h5>
                    </span>
                  )}
                {this.props.signatureDocument &&
                  this.props.signatureDocument.signers.length === 0 &&
                  !this.props.signatureSuccess &&
                  !this.props.signatureError &&
                  !this.props.isSending && (
                    <span>
                      <h5 id="main_footer_instructions"> {translate('nothingToDo')} </h5>
                      <h5 id="sub_footer_instructions"> {translate('clickFinish')} </h5>
                    </span>
                  )}
                {this.props.signatureSuccess &&
                  !this.props.signatureError &&
                  !this.props.isSending && (
                    <span>
                      <h5 id="main_footer_instructions"> {translate('documentSubmitted')} </h5>
                    </span>
                  )}
              </div>
              <div id="footer_buttons">
                {this.props.signatureSuccess && this.props.isModeInternal && this.props.onContinue && (
                  <span>
                    <FlatButton
                      label={translate('continue')}
                      primary={true}
                      style={{ color: colorScheme, marginRight: '5px' }}
                      onClick={this.props.onContinue}
                    />
                  </span>
                )}
                {!this.props.signatureSuccess &&
                  !this.props.signatureError &&
                  !this.props.isSending && (
                    <span>
                      {this.props.showFinishButton && (
                        <span>
                          <FlatButton
                            label={translate('restart')}
                            primary={true}
                            style={{
                              color: ByesignHelper.style.grayColor,
                              marginRight: '5px',
                            }}
                            onClick={this.onRestart}
                          />
                          <FlatButton
                            label={translate('finish')}
                            type="button"
                            primary={true}
                            backgroundColor={colorScheme}
                            hoverColor="grey"
                            style={{ color: 'white' }}
                            onClick={this.signDocument}
                          />
                        </span>
                      )}
                      {!this.props.showFinishButton && this.props.showStartButton && (
                        <span>
                          {!this.props.hideClose && (
                            <FlatButton
                              label={translate('close')}
                              primary={true}
                              style={{
                                color: ByesignHelper.style.grayColor,
                                marginRight: '5px',
                              }}
                              onClick={this.props.onCancel || this.onCancel}
                            />
                          )}
                          <FlatButton
                            label={translate('start')}
                            type="button"
                            primary={true}
                            backgroundColor={colorScheme}
                            hoverColor="grey"
                            style={{ color: 'white' }}
                            onClick={this.openSignerDialog}
                          />
                        </span>
                      )}
                      {!this.props.showFinishButton &&
                        !this.props.showStartButton &&
                        !this.props.showNextButton && (
                          <span>
                            <FlatButton
                              label={translate('restart')}
                              primary={true}
                              style={{
                                color: ByesignHelper.style.grayColor,
                                marginRight: '5px',
                              }}
                              onClick={this.onRestart}
                            />
                            <FlatButton
                              label={translate('continue')}
                              type="button"
                              primary={true}
                              backgroundColor={colorScheme}
                              hoverColor="grey"
                              style={{ color: 'white' }}
                              onClick={this.continueToNextSigner}
                            />
                          </span>
                        )}
                      {!this.props.showFinishButton &&
                        !this.props.showStartButton &&
                        this.props.showNextButton &&
                        !this.props.hideClose && (
                          <span>
                            <FlatButton
                              label={translate('restart')}
                              primary={true}
                              style={{
                                color: ByesignHelper.style.grayColor,
                                marginRight: '5px',
                              }}
                              onClick={this.onRestart}
                            />
                            <FlatButton
                              label={translate('next')}
                              type="button"
                              primary={true}
                              backgroundColor={colorScheme}
                              hoverColor="grey"
                              style={{ color: 'white' }}
                              onClick={() => this.goToNextSignature(false)}
                            />
                          </span>
                        )}
                      <p
                        style={{
                          margin: 0,
                          textAlign: 'right',
                          fontSize: '9px',
                          marginRight: '10px',
                        }}
                      >
                        powered by <span id="headerlogo"> yuhu </span>
                      </p>
                    </span>
                  )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);

export default localize()(documentPortalInPerson);
