import React from 'react';
import { titleize } from 'helpers/Formatter';
import { startsWith } from 'helpers/StringHelper';
import { colors } from 'helpers/ColorHelper';

export const widthRatio = (documentWidth, signatureDocumentWidth) => {
  return documentWidth / signatureDocumentWidth;
};

export const heightRatio = (documentHeight, signatureDocumentHeight) => {
  return documentHeight / signatureDocumentHeight;
};

export const yPositionOffset = heightRatio => {
  return 25 * heightRatio;
};

export const constants = {
  CANCELLED: 'cancelled',
  SUCCESS: 'completed',
  SIGNATURE_MARKER: '[sig|req|signer',
  TEXT_BOX_MARKER: '[txt|req|signer',
  CHECK_BOX_MARKER: '[chk|req|signer',
  REDIRECT_TIMEOUT_MS: 3000,
  DEFAULT_LOGO: 'yuhu',
  DEFAULT_ASSET_NAME: 'default',
  SIGNATURE_TEXT_LENGTH: 20,
  INITIALS_TEXT_LENGTH: 6,
  PRODUCTION: 'production',
  TEST: 'test',
};

export const status = {
  UNKNOWN: 'UNKNOWN',
  DOCUMENT_CANCELLED: 'DOCUMENT_CANCELLED',
  INVALID_DOCUMENT: 'INVALID_DOCUMENT',
  PENDING_FILE: 'PENDING_FILE',
  CREATING_DOCUMENT: 'CREATING_DOCUMENT',
  DOCUMENT_CREATED: 'DOCUMENT_CREATED',
  AGENT_SIGNATURE_REQUIRED: 'AGENT_SIGNATURE_REQUIRED',
  PENDING_SIGNATURES: 'PENDING_SIGNATURES',
  PROCESSING_SIGNATURES: 'PROCESSING_SIGNATURES',
  DOCUMENT_COMPLETED: 'DOCUMENT_COMPLETED',
  VERSIONED_DOCUMENT_COMPLETED: 'VERSIONED_DOCUMENT_COMPLETED',
  DOCUMENT_SIGNER_ALREADY_SIGNED: 'DOCUMENT_SIGNER_ALREADY_SIGNED',
  INCORRECT_AGENT_SIGNER: 'INCORRECT_AGENT_SIGNER',
};

export const style = {
  primarySchemeColor: colors.RED,
  previousSignatureImageStyle: {
    textAlign: 'center',
    border: '1px solid #e6e6e6',
    padding: '15px 0px',
  },
  bodyContentStyle: {
    padding: '0px 20px 0px 20px',
  },
  bodyAgreementContentStyle: {
    padding: '0px 20px 20px 20px',
  },
  customContentStyle: {
    maxWidth: '440px',
    width: '95%',
  },
  customAgreementContentStyle: {
    maxWidth: '440px',
    width: '95%',
  },
  customTermsContentStyle: {
    width: '95%',
    overflow: 'scroll',
  },
  bodyTermsContentStyle: {
    padding: '0px 20px 20px 20px',
    overflow: 'scroll',
  },
  grayColor: '#6F6F6F',
};

export function isSmallMobileDevice(width, height) {
  return width <= 420 && height <= 740;
}

export const scaleSignatureTextFontSize = characterLength => {
  characterLength = characterLength > 24 ? 24 : characterLength;
  const maxFontSize = 73;
  const ratio = 6 / 3;
  return maxFontSize - ratio * characterLength;
};

export const updateSignatureTextCanvas = (canvas, value, textYPosition, isTextBox) => {
  if (!canvas) return;

  const fontSize = scaleSignatureTextFontSize(value.length);
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.font = `${fontSize}px Bradley Hand, cursive`;
  let verticalTextPosition = textYPosition;
  if (isTextBox) {
    ctx.font = "16px 'Circular', sans-serif";
    verticalTextPosition = 20;
  }
  ctx.fillText(value, 7, verticalTextPosition);
};

export const retrieveSignerNameText = (signer, isPadTypeSignature, isTextbox) => {
  if (!isValidSignerFirstName(signer)) return '';

  if (isTextbox) {
    return '';
  }

  if (isPadTypeSignature) {
    return titleize(signer.first_name[0] + '. ' + signer.last_name);
  }

  return signer.first_name[0].toUpperCase() + '.' + signer.last_name[0].toUpperCase() + '.';
};

export const showSignPadInstructions = (
  showPreviousSignatureImage,
  isPadTypeSignature,
  toggleSignatureTextMode,
  translate,
  isTextbox
) => {
  if (showPreviousSignatureImage) return null;

  // if the type of signature is not initials
  if (isPadTypeSignature) {
    // show type name instructions
    if (toggleSignatureTextMode) return <div>{translate('canvasInstructions3')}</div>;
    // otherwise, draw signature
    return <div>{translate('canvasInstructions1')}</div>;
  }

  if (isTextbox) {
    return <div>{translate('canvasInstructions5')}</div>;
  }

  // if we are dealing with initials, type initials
  if (toggleSignatureTextMode) return <div>{translate('canvasInstructions4')}</div>;
  //if still intials, just draw them
  return <div>{translate('canvasInstructions2')}</div>;
};

export const isValidSignerFirstName = signer => {
  if (!signer.first_name) return false;

  if (signer.is_agent && signer.first_name === 'Agent') return false;

  return true;
};

export const isSignature = marker => {
  return startsWith(marker, constants.SIGNATURE_MARKER);
};

export const isTextbox = marker => {
  return startsWith(marker, constants.TEXT_BOX_MARKER);
};

export const isCheckbox = marker => {
  return startsWith(marker, constants.CHECK_BOX_MARKER);
};

export const prepareSignatureTextMode = (
  props,
  currentSigner,
  marker,
  updateSignatureTextCanvas,
  signatureTextYPosition
) => {
  props.hidePreviousSignatureImage();
  let validSignerFirstName = isValidSignerFirstName(currentSigner);
  props.enableSignButton(validSignerFirstName);
  let name = retrieveSignerNameText(currentSigner, isSignature(marker), isTextbox(marker));
  props.updateSignatureTextValue(name);
  updateSignatureTextCanvas(name, signatureTextYPosition, isTextbox);
};
