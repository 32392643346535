const makeType = key => {
  return 'LANDLORD::BATCHES::' + key;
};

export const FETCH_BATCHES = makeType('FETCH_BATCHES');
export const STORE_BATCHES = makeType('STORE_BATCHES');
export const FETCH_BATCH_STATS = makeType('FETCH_BATCH_STATS');
export const STORE_BATCH_STATS = makeType('STORE_BATCH_STATS');

export const FETCH_BATCH = makeType('FETCH_BATCH');
export const STORE_BATCH = makeType('STORE_BATCH');

export const POST_BATCH = makeType('POST_BATCH');
export const PUT_BATCH = makeType('PUT_BATCH');

export const CREATE_BATCH = makeType('CREATE_BATCH');
export const UPDATE_BATCH = makeType('UPDATE_BATCH');
export const DELETE_BATCH = makeType('DELETE_BATCH');
export const SCHEDULE_BATCH = makeType('SCHEDULE_BATCH');
export const UNSCHEDULE_BATCH = makeType('UNSCHEDULE_BATCH');

export const CREATE_BATCH_PAYMENT = makeType('CREATE_BATCH_PAYMENT');
export const FETCH_BATCH_PAYMENTS = makeType('FETCH_BATCH_PAYMENTS');
export const STORE_BATCH_PAYMENTS = makeType('STORE_BATCH_PAYMENTS');
export const DELETE_BATCH_PAYMENT = makeType('DELETE_BATCH_PAYMENT');
export const UPDATE_BATCH_PAYMENT = makeType('UPDATE_BATCH_PAYMENT');
