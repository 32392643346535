export default function reducer(
  state = {
    properties: [],
    visitors: [],
    units: [],
    rooms: [],
    property: {},
    agents: [],
    accounts: [],
    currentLeasesOfUnit: [],
    amenities: [],
    availability: {},
    promotions: [],
    surveys: [],
    surveyQuestions: {},
    unitActiveLease: null,
    surveyResults: {},
    unit: { market_rent: null },
    unitTypes: [],
    amenity: {},
    tenants: [],
    currentConcessionsOfUnit: [],
    selectedTenantID: null,
    calendarHolds: [],
    unitResidents: [],
    creatingMaintenanceWaiver: false,
    creatingProspectMessage: false,
    creatingGoogleReview: false,
    portfolios: [],
    checkingUnitAvailability: false,
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_TENANTS::STORE_UNIT_TYPES': {
      return { ...state, unitTypes: action.unitTypes };
    }
    case 'LANDLORD_PROPERTIES::SAVE_UNIT': {
      return { ...state, unit: action.unit };
    }
    case 'LANDLORD_PROPERTIES::SHOW_PROPERTIES': {
      return { ...state, properties: action.properties, property: {} };
    }
    case 'LANDLORD_PROPERTIES::SHOW_UNITS': {
      return { ...state, units: action.units };
    }
    case 'LANDLORD_PROPERTIES::STORE_ROOMS': {
      return { ...state, rooms: action.rooms };
    }
    case 'LANDLORD_PROPERTIES::SHOW_MARKET_RENT': {
      return { ...state, marketRent: action.marketRent };
    }
    case 'LANDLORD_PROPERTIES::SHOW_PORTFOLIOS': {
      return { ...state, portfolios: action.portfolios };
    }
    case 'PROPERTY_ACCOUNTS::SHOW_ACCOUNTS': {
      return { ...state, accounts: action.accounts };
    }
    case 'LANDLORD_PROPERTIES::SHOW_PROPERTY': {
      return { ...state, property: action.property };
    }
    case 'LANDLORD_PROPERTIES::GET_AGENTS': {
      return { ...state, agents: action.agents };
    }
    case 'LANDLORD_PROPERTIES::GET_OWNERS': {
      return { ...state, owners: action.owners };
    }
    case 'LANDLORD_PROPERTIES::SHOW_CREATING_PROPERTY': {
      return { ...state, showCreatingProperty: true };
    }
    case 'LANDLORD_PROPERTIES::SHOW_CREATING_PROPERTY_DONE': {
      return { ...state, showCreatingProperty: false };
    }
    case 'LANDLORD_PROPERTIES::SHOW_CURRENT_LEASES_OF_UNIT': {
      return {
        ...state,
        currentLeasesOfUnit: action.currentLeasesOfUnit,
        currentConcessionsOfUnit: action.currentConcessionsOfUnit,
      };
    }
    case 'LANDLORD_PROPERTIES::SHOW_UNIT_ACTIVE_LEASE': {
      return { ...state, unitActiveLease: action.unitActiveLease };
    }
    case 'LANDLORD_PROPERTIES::SHOW_AMENITIES': {
      return { ...state, amenities: action.amenities };
    }
    case 'LANDLORD_AMENITIES::SHOW_AMENITY': {
      return { ...state, amenity: action.amenityDetails };
    }
    case 'LANDLORD_TENANTS::SHOW_TENANTS': {
      return { ...state, tenants: action.tenants };
    }
    case 'LANDLORD_TENANTS::SELECT_TENANT': {
      return { ...state, selectedTenantID: action.selectedTenantID };
    }
    case 'LANDLORD_AMENITIES::SHOW_AVAILABILITY': {
      return { ...state, availability: action.availability };
    }
    case 'LANDLORD_PROPERTIES::SHOW_PROMOTIONS': {
      return { ...state, promotions: action.promotions };
    }
    case 'LANDLORD_PROPERTIES::SHOW_SURVEYS': {
      return { ...state, surveys: action.surveys };
    }
    case 'LANDLORD_PROPERTIES::SHOW_SURVEY_QUESTIONS': {
      return { ...state, surveyQuestions: action.surveyQuestions };
    }
    case 'LANDLORD_PROPERTIES::SHOW_SURVEY_RESULTS': {
      return { ...state, surveyResults: action.surveyResults };
    }
    case 'LANDLORD_PROPERTIES::DISPLAY_VISITORS': {
      return { ...state, visitors: action.visitors };
    }
    case 'LANDLORD_AMENITIES::SHOW_CALENDAR_HOLDS': {
      return { ...state, calendarHolds: action.calendarHolds };
    }
    case 'LANDLORD_PROPERTIES::SHOW_UNIT_RESIDENTS': {
      return { ...state, unitResidents: action.residents };
    }
    case 'LANDLORD_PROPERTIES::CREATE_GOOGLE_REVIEW_PENDING': {
      return { ...state, creatingGoogleReview: true };
    }
    case 'LANDLORD_PROPERTIES::CREATE_GOOGLE_REVIEW_FULFILLED': {
      return { ...state, creatingGoogleReview: false };
    }
    case 'LANDLORD_PROPERTIES::CREATE_GOOGLE_REVIEW_REJECTED': {
      return { ...state, creatingGoogleReview: false };
    }
    case 'LANDLORD_PROPERTIES::CREATE_MAINTENANCE_WAIVER_PENDING': {
      return { ...state, creatingMaintenanceWaiver: true };
    }
    case 'LANDLORD_PROPERTIES::CREATE_MAINTENANCE_WAIVER_FULFILLED': {
      return { ...state, creatingMaintenanceWaiver: false };
    }
    case 'LANDLORD_PROPERTIES::CREATE_MAINTENANCE_WAIVER_REJECTED': {
      return { ...state, creatingMaintenanceWaiver: false };
    }
    case 'LANDLORD_PROPERTIES::CREATE_PROSPECT_MESSAGE_PENDING': {
      return { ...state, creatingProspectMessage: true };
    }
    case 'LANDLORD_PROPERTIES::CREATE_PROSPECT_MESSAGE_FULFILLED': {
      return { ...state, creatingProspectMessage: false };
    }
    case 'LANDLORD_PROPERTIES::CREATE_PROSPECT_MESSAGE_REJECTED': {
      return { ...state, creatingProspectMessage: false };
    }
    case 'LANDLORD_PROPERTIES::CHECK_UNIT_AVAILABILITY_FOR_LEASE_PENDING': {
      return { ...state, checkingUnitAvailability: true };
    }
    case 'LANDLORD_PROPERTIES::CHECK_UNIT_AVAILABILITY_FOR_LEASE_FULFILLED': {
      return { ...state, checkingUnitAvailability: false };
    }
    case 'LANDLORD_PROPERTIES::CHECK_UNIT_AVAILABILITY_FOR_LEASE_REJECTED': {
      return { ...state, checkingUnitAvailability: false };
    }
    default:
      return state;
  }
}
