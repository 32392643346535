import React, { Component, Fragment } from 'react';

import { updateCompanyAddress } from 'actions/companyDetailsActions';
import * as landlordApplicationsActions from 'actions/landlordApplicationsActions';
import { displayLoader, showSuccess } from 'actions/notificationActions';
import {
  getProperties,
  getUnits,
  getCurrentLeasesOfUnit,
  clearCurrentLeasesOfUnit,
  getUnit,
  getUnitTypes,
} from 'actions/landlordPropertiesActions';

import { fetchCurrentLeasesForSingleUnits } from 'helpers/PropertiesHelper';

import { browserHistory } from 'react-router';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { destroy, change, formValueSelector } from 'redux-form';
import { CSSTransitionGroup } from 'react-transition-group';
import get from 'lodash/get';

import PropertyWizardPage from './components/lease_creator/propertyWizardPage';
import LeaseTermWizardPage from './components/lease_creator/leaseTermsWizardPage';
import ScheduleWizardPage from './components/lease_creator/scheduleWizardPage';
import LeasePricingWizardPage from './components/lease_creator/leasePricingWizardPage';
import RentalIncentiveWizardPage from './components/lease_creator/rentalIncentiveWizardPage';
import ServicesAndUtilitiesWizardPage from './components/lease_creator/servicesAndUtilitiesWizardPage';
import WelcomePackageWizardPage from './components/lease_creator/welcomePackageWizardPage';
import ReviewWizardPage from './components/lease_creator/reviewWizardPage';
import CompanyAddressWizardPage from './components/lease_creator/companyAddressWizardPage';
import LeaseDocumentsWizardPage from './components/lease_creator/leaseDocumentsWizardPage';
import DocumentPortalInPerson from 'components/byesign/documentPortalInPerson';
import FooterButtons from 'components/shared/FooterButtons';
import { featureEnabled } from 'helpers/ToggleBot';

import FlatButton from 'material-ui/FlatButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Form, Formik } from 'formik';
import TextField from 'material-ui/TextField';
import DialogActions from '@material-ui/core/DialogActions';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import * as Yup from 'yup';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Circular", sans-serif',
    useNextVariants: true,
  },
});

const denialSchema = Yup.object().shape({
  lease_denial_reason: Yup.string()
    .min(16, 'Denial reason must be at least 16 characters.')
    .required('Enter reason for denying this lease.'),
});

export const PAGES = {
  COMPANY_ADDRESS: 0,
  PROPERTY: 1,
  LEASE_TERM: 2,
  SCHEDULE: 3,
  LEASE_PRICING: 4,
  RENTAL_INCENTIVE: 5,
  SERVICES_AND_UTILITIES: 6,
  WELCOME_PACKAGE: 7,
  LEASE_DOCUMENTS: 8,
  REVIEW: 9,
  DOCUMENT_PORTAL_IN_PERSON: 10,
};

export default connect(
  (store) => {
    const selector = formValueSelector('lease_creator_form');

    return {
      showPage: store.authentication.showPage,
      properties: store.landlordProperties.properties,
      units: store.landlordProperties.units,
      unitTypes: store.landlordProperties.unitTypes,
      currentLeasesOfUnit: store.landlordProperties.currentLeasesOfUnit,
      leaseDetails: store.landlordApplications.leaseDetails,
      showScheduleAForm: store.landlordApplications.showScheduleAForm,
      scheduleAs: store.landlordApplications.scheduleAs,
      welcomePackages: store.landlordApplications.welcomePackages,
      pageNumber: store.landlordApplications.pageNumber,
      documentToSign: store.landlordApplications.documentToSign,
      companyAddress: store.companyDetails.address,
      unitDetails: store.landlordProperties.unit,
      application: store.landlordApplications.application,
      selectedRentAmount: selector(store, 'monthly_rent'),
      selectedProperty: selector(store, 'property'),
      paymentProvider: store.userTypes.paymentProvider,
    };
  },
  {
    getProperties,
    getUnits,
    getUnitTypes,
    getCurrentLeasesOfUnit,
    getUnit,
    clearCurrentLeasesOfUnit,
    showSuccess,
    displayLoader,
    updateCompanyAddress,
    ...landlordApplicationsActions,
    destroy,
    change,
  }
)(
  class LeaseCreator extends Component {
    state = {
      validationErrors: {},
      confirmationDialogOpen: false,
    };

    componentDidMount = () => {
      if (!this.props.leaseDetails) {
        browserHistory.push('/companies/applications');
        return;
      }

      this.props.getProperties({ for_multiselect: true });
      this.props
        .getUnits(this.props.leaseDetails.property.value, {
          for_multiselect: true,
          only_rentables: true,
        })
        .then(this.onGetUnitsSuccess);
      this.props.getUnitTypes(this.props.leaseDetails.property.value, {
        available_wait_units: true,
      });
      this.props.getScheduleAs(this.props.leaseDetails.lease_id);
      this.props.getWelcomePackages();
      this.props.clearCurrentLeasesOfUnit();

      if (this.props.leaseDetails.unit_id) {
        this.props.getUnit(this.props.leaseDetails.unit_id);
        this.props.getCurrentLeasesOfUnit(
          this.props.leaseDetails.unit_id,
          this.props.leaseDetails.lease_id
        );
      }

      if (this.props.isModeEdit && this.props.documentToSign) {
        this.props.goToPage(PAGES.DOCUMENT_PORTAL_IN_PERSON);
        return;
      }

      if (this.props.documentToSign) {
        this.props.goToPage(PAGES.DOCUMENT_PORTAL_IN_PERSON);
        return;
      }

      if (this.props.isModeEdit && !this.props.documentToSign) {
        this.props.goToPage(PAGES.REVIEW);
        return;
      }

      if (this.props.companyAddress === null) {
        this.props.goToPage(PAGES.COMPANY_ADDRESS);
      } else {
        this.props.goToPage(PAGES.PROPERTY);
      }
    };

    onGetUnitsSuccess = (response) => {
      if (this.props.units.length !== 1) {
        return;
      }

      fetchCurrentLeasesForSingleUnits(
        response.value.data,
        this.props.getCurrentLeasesOfUnit,
        this.changeFieldValue
      );
    };

    submitLease = (values) => {
      if (featureEnabled('custom_lease_creator')) {
        this.props.postLease(values, this.props.isModeEdit);
      } else {
        if (this.props.isModeEdit) {
          this.props.displayLoader('update your lease');
        } else {
          this.props.displayLoader('create your lease');
        }
        this.props.postLease(values, this.props.isModeEdit);
        this.props.destroy('lease_creator_form');
      }
    };

    denyLease = async (leaseDenialReason) => {
      this.setState({ isSending: true });
      await this.props.denyLease(this.props.leaseDetails.lease_id, leaseDenialReason);
      this.setState({ isSending: false });
      this.props.showSuccess('Successfully Denied Lease');
      this.goToApplicationsPage();
    };

    toggleDialog = () => {
      this.setState({
        confirmationDialogOpen: !this.state.confirmationDialogOpen,
      });
    };

    goToApplicationsPage = () => {
      this.props.destroy('lease_creator_form');
      browserHistory.push('/companies/applications');
    };

    finishCreatingLease = () => {
      let message = this.props.isModeEdit
        ? 'Successfully Edited Lease.'
        : 'Successfully Created Lease.';
      this.props.showSuccess(message);
      this.goToApplicationsPage();
    };

    changeFieldValue = (field, value) => {
      this.props.change('lease_creator_form', field, value);
    };

    nextPage = (values) => {
      if (
        this.props.pageNumber === PAGES.RENTAL_INCENTIVE &&
        this.props.selectedProperty?.hide_services_and_utilities
      ) {
        this.props.goToPage(PAGES.WELCOME_PACKAGE);
      } else if (
        this.props.pageNumber === PAGES.LEASE_DOCUMENTS - 1 &&
        !get(this.props, 'leaseDetails.lease_document_types.length')
      ) {
        this.props.goToPage(PAGES.LEASE_DOCUMENTS + 1);
      } else if (this.props.pageNumber === PAGES.LEASE_DOCUMENTS) {
        const nestedForm = document.forms['lease_documents'];
        if (!nestedForm) throw Error('Lease documents form not found');

        // form.submit() does not trigger native HTML interactive constraint validation
        // form.requestSubmit() does, but is not supported by all browsers yet
        // create an submit input and click it to trigger HTML interactive constraint validation
        const submitter = document.createElement('input');
        submitter.type = 'submit';
        submitter.hidden = true;
        nestedForm.appendChild(submitter);
        submitter.click();
        nestedForm.removeChild(submitter);

        if (nestedForm.reportValidity()) return this.props.nextPage();
      } else if (this.props.pageNumber !== PAGES.REVIEW) {
        this.props.nextPage();
      } else if (this.props.pageNumber === PAGES.REVIEW) {
        if (featureEnabled('custom_lease_creator') && this.props.application.use_hellosign) {
          if (!values.lease_template_id || !values.agent_id) {
            this.setState({
              validationErrors: {
                ...this.state.validationErrors,
                lease_template_id: !values.lease_template_id ? 'Required' : null,
                agent_id: !values.agent_id ? 'Required' : null,
              },
            });
            return;
          } else {
            this.setState({
              validationErrors: {},
            });
            return this.submitLease(values);
          }
        }

        this.submitLease(values);
      }
    };

    goBack = (page) => {
      if (
        this.props.pageNumber === PAGES.WELCOME_PACKAGE &&
        this.props.selectedProperty?.hide_services_and_utilities
      ) {
        this.props.goToPage(PAGES.RENTAL_INCENTIVE);
      } else if (
        this.props.pageNumber === PAGES.LEASE_DOCUMENTS + 1 &&
        !get(this.props, 'leaseDetails.lease_document_types.length')
      ) {
        this.props.goToPage(PAGES.LEASE_DOCUMENTS - 1);
      } else if (this.props.pageNumber === PAGES.WELCOME_PACKAGE) {
        this.props.goToPage(PAGES.SERVICES_AND_UTILITIES);
      } else if (page > PAGES.PROPERTY) {
        this.props.previousPage();
      } else {
        browserHistory.push('/companies/applications');
      }
    };

    render() {
      const page = this.props.pageNumber;
      const formPageProps = {
        name: 'lease_creator_form',
        previousPage: this.props.previousPage,
        onSubmit: this.nextPage,
        initialValues: {
          month_to_month: false,
        },
      };

      const showWizardPages =
        this.props.leaseDetails && this.props.showPage && page <= PAGES.REVIEW;
      const classNames = 'body';
      const disableFieldSelection = this.props.leaseDetails
        ? this.props.leaseDetails.disable_lease_wizard_selections
        : false;

      return (
        <Fragment>
          {this._renderDenyLease()}
          <div className={showWizardPages ? classNames : ''} role="main">
            {showWizardPages && (
              <div className="centers">
                <div>
                  <div className="stage-header clearfix" style={{ marginBottom: '25px' }}>
                    <span className="page-title">
                      <Link
                        id="maintenanceHeader"
                        style={{ color: 'inherit' }}
                        to="/companies/applications"
                      >
                        Applications
                      </Link>{' '}
                      >{' '}
                    </span>
                    <span className="page-title">
                      {this.props.isModeEdit ? 'Edit Lease' : 'Lease Creator'}
                    </span>
                  </div>
                  <div
                    className="container application-container"
                    ref="LeaseCreatorContainer"
                    style={{ height: '100%', overflow: 'hidden' }}
                  >
                    <div className="row">
                      <CSSTransitionGroup
                        transitionName="fade_out"
                        transitionEnterTimeout={200}
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionLeaveTimeout={200}
                      >
                        {page === PAGES.COMPANY_ADDRESS && this.props.companyAddress === null && (
                          <CompanyAddressWizardPage
                            {...formPageProps}
                            form="lease_creator_form"
                            initialValues={this.props.leaseDetails}
                            previousPage={this.goToApplicationsPage}
                          />
                        )}
                        {page === PAGES.PROPERTY && (
                          <PropertyWizardPage
                            {...formPageProps}
                            properties={this.props.properties}
                            units={this.props.units}
                            unitTypes={this.props.unitTypes}
                            getUnits={getUnits}
                            getUnitTypes={this.props.getUnitTypes}
                            currentLeaseId={this.props.leaseDetails.lease_id}
                            leaseDetails={this.props.leaseDetails}
                            currentLeasesOfUnit={this.props.currentLeasesOfUnit}
                            getCurrentLeasesOfUnit={this.props.getCurrentLeasesOfUnit}
                            getUnit={this.props.getUnit}
                            clearCurrentLeasesOfUnit={this.props.clearCurrentLeasesOfUnit}
                            changeFieldValue={this.changeFieldValue}
                            onGetUnitsSuccess={this.onGetUnitsSuccess}
                          />
                        )}
                        {page === PAGES.LEASE_TERM && (
                          <LeaseTermWizardPage
                            {...formPageProps}
                            leaseDetails={this.props.leaseDetails}
                            currentLeasesOfUnit={this.props.currentLeasesOfUnit}
                            disableFieldSelection={disableFieldSelection}
                            unitDetails={this.props.unitDetails}
                            changeFieldValue={this.changeFieldValue}
                          />
                        )}
                        {page === PAGES.SCHEDULE && (
                          <ScheduleWizardPage
                            {...formPageProps}
                            scheduleAs={this.props.scheduleAs}
                            openScheduleAForm={this.props.openScheduleAForm}
                            closeScheduleAForm={this.props.closeScheduleAForm}
                            showScheduleAForm={this.props.showScheduleAForm}
                          />
                        )}
                        {page === PAGES.LEASE_PRICING && (
                          <LeasePricingWizardPage
                            {...formPageProps}
                            disableFieldSelection={disableFieldSelection}
                            disableDepositSelection={featureEnabled(
                              'ui.lease_creator.disable_deposit'
                            )}
                            leaseDetails={this.props.leaseDetails}
                          />
                        )}
                        {page === PAGES.RENTAL_INCENTIVE && (
                          <RentalIncentiveWizardPage
                            {...formPageProps}
                            changeFieldValue={this.changeFieldValue}
                            selectedRentAmount={this.props.selectedRentAmount}
                          />
                        )}
                        {page === PAGES.SERVICES_AND_UTILITIES && (
                          <ServicesAndUtilitiesWizardPage
                            {...formPageProps}
                            form="lease_creator_form"
                            isReviewMode={false}
                          />
                        )}
                        {page === PAGES.WELCOME_PACKAGE && (
                          <WelcomePackageWizardPage
                            {...formPageProps}
                            welcomePackages={this.props.welcomePackages}
                          />
                        )}
                        {page === PAGES.REVIEW && (
                          <ReviewWizardPage
                            {...formPageProps}
                            goToPage={this.props.goToPage}
                            isModeEdit={this.props.isModeEdit}
                            validationErrors={this.state.validationErrors}
                            paymentProvider={this.props.paymentProvider}
                            hideServicesAndUtilities={
                              this.props.selectedProperty?.hide_services_and_utilities
                            }
                          />
                        )}
                        {page === PAGES.LEASE_DOCUMENTS && (
                          <LeaseDocumentsWizardPage {...formPageProps} />
                        )}
                      </CSSTransitionGroup>
                    </div>
                  </div>
                  <div style={{ height: '170px' }} />
                </div>
                <FooterButtons
                  deleteAction={
                    this.props.application.can_approve_leases ? this.toggleDialog : null
                  }
                  deleteName={this.props.application.can_approve_leases ? 'DENY' : ''}
                  formName="lease_creator_form"
                  submitName="Next"
                  backAction={() => this.goBack(page)}
                  disableSubmitOverride={true}
                />
              </div>
            )}
            {this.props.documentToSign && page === PAGES.DOCUMENT_PORTAL_IN_PERSON && (
              <DocumentPortalInPerson
                {...this.props.documentToSign}
                onContinue={this.finishCreatingLease}
                isModeInternal={true}
              />
            )}
          </div>
        </Fragment>
      );
    }

    _renderDenyLease = () => {
      return (
        <div className="col-sm-6" style={{ textAlign: 'center' }}>
          <MuiThemeProvider theme={theme}>
            <Formik
              id={'submit_lease_denial'}
              innerRef={this.formRef}
              initialValues={{
                lease_denial_reason: '',
              }}
              validationSchema={denialSchema}
              onSubmit={(values) => {
                this.denyLease(values.lease_denial_reason);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Dialog disableBackdropClick open={this.state.confirmationDialogOpen} fullWidth>
                  <DialogTitle>Deny Created Lease</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <p>
                        Enter a reason for denying this lease so we can email the details to the
                        leasing agent to allow them to make the necessary changes.
                      </p>
                      <p>NOTE: This does not deny the application.</p>
                    </DialogContentText>
                    <Form>
                      <TextField
                        name="lease_denial_reason"
                        autoFocus
                        margin="dense"
                        id="cancellation_reason"
                        label="Reason for denial"
                        type="text"
                        fullWidth
                        multiLine
                        variant="outline"
                        value={values.lease_denial_reason}
                        onChange={handleChange}
                      />
                      {errors.lease_denial_reason && touched.lease_denial_reason ? (
                        <div style={{ color: '#ff3333' }}>{errors.lease_denial_reason}</div>
                      ) : null}
                    </Form>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'space-between' }}>
                    <FlatButton label="Close" primary={true} onClick={this.toggleDialog} />
                    # if is loading... do this
                    <FlatButton
                      label="Deny Lease"
                      primary
                      onClick={handleSubmit}
                      disabled={this.state.isSending}
                    />
                  </DialogActions>
                </Dialog>
              )}
            </Formik>
          </MuiThemeProvider>
        </div>
      );
    };
  }
);
