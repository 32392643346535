export default function reducer(
  state = {
    units: [{ value: 'n/a', label: 'n/a' }],
    properties: [{ value: 'n/a', label: 'n/a' }],
    maintenance: [{ assignment: 'All Employees', label: 'n/a', value: 'n/a' }],
    displayTenantList: false,
    tenants: [{ value: 'n/a', label: 'n/a' }],
    showMessaging: false,
    showMessagingClass: 'fullWidth',
    assignment: ['All Employees'],
    companyTags: ['Appliances'],
    startJob: false,
    stepOne: true,
    stepTwo: false,
    signatureSigned: false,
    signaturePNG: null,
    agents: [{ assignment: 'All Employees', label: 'n/a', value: 'n/a' }],
    maintenanceAssets: [],
    maintenanceAsset: {},
    recurringTickets: [],
    escalationRules: [],
    recurringTicketsCounts: {},
    fetchingRecurringTickets: false,
    maintenanceCategories: [],
    maintenanceLocations: [],
    all_agents_and_maintenance_list: [],
    assetCounts: {
      total_count: 0,
      outstanding_workorders: 0,
      upcoming_service: 0,
      replace: 0,
      broken: 0,
    },
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_MAINTENANCE::FETCH_RECURRING_TICKETS_PENDING': {
      return { ...state, fetchingRecurringTickets: true };
    }
    case 'LANDLORD_MAINTENANCE::SAVE_RECURRING_TICKETS_STATS': {
      return { ...state, recurringTicketsCounts: action.payload };
    }
    case 'MAINTENANCE_ESCALATION_RULES::SAVE_ESCALATION_RULES': {
      return {
        ...state,
        escalationRules: action.escalationRules,
      };
    }
    case 'MAINTENANCE::SAVE_RECURRING_TICKETS': {
      if (state.recurringTicketsLastFetched > action.timestamp) return state;
      return {
        ...state,
        recurringTickets: [
          ...state.recurringTickets.slice(0, action.offset),
          ...action.recurringTickets,
        ],
        recurringTicketsLastFetched: action.timestamp,
        fetchingRecurringTickets: false,
        offset: action.offset,
      };
    }
    case 'MAINTENANCE_ASSETS::SAVE_MAINTENANCE_ASSET': {
      return {
        ...state,
        maintenanceAsset: action.maintenanceAsset,
      };
    }
    case 'MAINTENANCE_ASSETS::SAVE_MAINTENANCE_ASSETS': {
      return {
        ...state,
        maintenanceAssets: action.maintenanceAssets,
        assetCounts: action.counts || state.assetCounts,
      };
    }
    case 'SAVE_PROPERTY_LIST': {
      return {
        ...state,
        properties: action.properties,
      };
    }
    case 'ENABLE_SIGN_BUTTON': {
      return {
        ...state,
        signatureSigned: action.boolean,
        signaturePNG: action.image,
      };
    }
    case 'BACK_TO_TICKET_STEP_1': {
      return {
        ...state,
        stepOne: true,
        stepTwo: false,
      };
    }
    case 'BEGIN_JOB': {
      return {
        ...state,
        startJob: true,
      };
    }
    case 'COMPLETE_TICKET_STEP_1': {
      return {
        ...state,
        stepOne: false,
        stepTwo: true,
      };
    }
    case 'END_JOB': {
      return {
        ...state,
        startJob: false,
        stepOne: true,
        stepTwo: false,
      };
    }
    case 'PAUSE_JOB': {
      return {
        ...state,
        startJob: false,
        stepOne: true,
        stepTwo: false,
      };
    }
    case 'COMPANY_TAGS': {
      return {
        ...state,
        companyTags: action.maintenanceTags,
      };
    }
    case 'SAVE_MAINTENANCE_CATEGORIES': {
      return {
        ...state,
        maintenanceCategories: action.maintenanceCategories,
      };
    }
    case 'SAVE_MAINTENANCE_LOCATIONS': {
      return {
        ...state,
        maintenanceLocations: action.maintenanceLocations,
      };
    }
    case 'SAVE_MAINTENANCE_LIST': {
      return {
        ...state,
        maintenance: action.maintenance,
        assignment: action.assignment,
      };
    }
    case 'SAVE_EMPLOYEE_LIST': {
      return {
        ...state,
        all_agents_and_maintenance_list: action.all_agents_and_maintenance_list,
      };
    }
    case 'MAINTENANCE::SAVE_AGENTS': {
      return {
        ...state,
        agents: action.agents,
      };
    }
    case 'SAVE_UNIT_LIST': {
      return {
        ...state,
        units: action.units,
      };
    }
    case 'SHOW_TENANT_LIST': {
      return {
        ...state,
        showTenantList: true,
        tenants: action.tenants,
        displayTenantList: true,
      };
    }
    case 'HIDE_TENANT_LIST': {
      return {
        ...state,
        showTenantList: false,
        tenants: [{ value: 'n/a', label: 'n/a' }],
        displayTenantList: false,
      };
    }
    case 'CLEAR': {
      return {
        ...state,
        units: [{ value: 'n/a', label: 'n/a' }],
        properties: [{ value: 'n/a', label: 'n/a' }],
        maintenance: [{ value: 'n/a', label: 'n/a' }],
        displayTenantList: false,
        tenants: [{ value: 'n/a', label: 'n/a' }],
        showMessaging: false,
        showMessagingClass: 'fullWidth',
      };
    }
    default:
      return state;
  }
}
