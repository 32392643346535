import {
  FETCH_PROJECT,
  FETCH_ALL_PROJECTS,
  FETCH_PROJECT_STATS,
  SHOW_FILTERED_PROJECTS,
  CLEAR_SELECTED_PROJECT,
  STORE_PROJECT_PURCHASE_ORDERS,
  SHOW_FILTERED_TICKETS,
  REMOVE_UNLINKED_TICKET_FROM_PROJECT,
} from './actionTypes';

const initialState = {
  isFetching: false,
  data: [],
  tickets: [],
  selectedProject: {},
  selectedProjectPurchaseOrders: [],
  projectCounts: {
    open: 0,
    closed: 0,
    all: 0,
  },
  filteredProjectCount: 0,
};

function doReceiveAllProjects(state, action) {
  const projectData = action.payload.projects.data;
  // Using a serializer in render_json wraps the data in an attributes object
  const newData = projectData.map(project => project.attributes);
  // If there's an offset append the data
  return { ...state, isFetching: false, data: newData };
}

function doFilterProjects(state, action) {
  const projectData = action.payload.projects.data;
  const filteredProjectCount = action.payload.search_total_results_count;
  // Using a serializer in render_json wraps the data in an attributes object
  const newData = projectData.map(project => project.attributes);
  // If there's an offset append the data
  const data = [...state.data.slice(0, action.offset), ...newData];

  return { ...state, isFetching: false, data, filteredProjectCount };
}

function doFilterTickets(state, action) {
  const ticketData = action.payload.maintenance_tickets;
  // If there's an offset append the data
  const tickets = [...state.tickets.slice(0, action.offset), ...ticketData];
  return { ...state, tickets };
}

function doRemoveLinkedTicket(state, action) {
  const tickets = state.tickets.filter(ticket => ticket.id !== action.maintenanceTicketID);
  return { ...state, tickets };
}

function storeProjectPurchaseOrders(state, action) {
  return {
    ...state,
    selectedProjectPurchaseOrders: [
      ...state.selectedProjectPurchaseOrders.slice(0, action.payload.offset),
      ...action.payload.purchaseOrders,
    ],
  };
}

const maintenanceProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROJECT_STATS}_FULFILLED`:
      return { ...state, projectCounts: action.payload.project_status_counts };
    case `${FETCH_ALL_PROJECTS}_PENDING`:
      return { ...state, isFetching: true };
    case `${FETCH_ALL_PROJECTS}_REJECTED`:
      return { ...state, isFetching: false };
    case `${FETCH_ALL_PROJECTS}_FULFILLED`:
      return doReceiveAllProjects(state, action);
    case `${FETCH_PROJECT}_FULFILLED`:
      return { ...state, selectedProject: action.payload.data };
    case CLEAR_SELECTED_PROJECT:
      return { ...state, selectedProject: {} };
    case SHOW_FILTERED_PROJECTS:
      return doFilterProjects(state, action);
    case STORE_PROJECT_PURCHASE_ORDERS:
      return storeProjectPurchaseOrders(state, action);
    case SHOW_FILTERED_TICKETS:
      return doFilterTickets(state, action);
    case REMOVE_UNLINKED_TICKET_FROM_PROJECT:
      return doRemoveLinkedTicket(state, action);

    default:
      return state;
  }
};

export default maintenanceProjectsReducer;
