import React, { Component } from 'react';

import * as ByesignHelper from './ByesignHelper';
import { colors } from 'helpers/ColorHelper';

import { localize } from 'react-localize-redux';

import { MdError as ErrorIcon } from 'react-icons/md';
import { MdCheckCircle as CheckIcon } from 'react-icons/md';
import { FaClock as ClockIcon } from 'react-icons/fa';
import { MdLock as LockIcon } from 'react-icons/md';

import { connect } from 'react-redux';

let SignatureErrorView = class extends Component {
  render() {
    const translate = this.props.translate;
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          top: '30%',
          zIndex: '500',
        }}
      >
        {this.props.documentStatus === ByesignHelper.status.UNKNOWN && (
          <div>
            <p
              style={{
                textAlign: 'center',
                marginTop: this.props.isLandscape ? '15%' : '190px',
                marginBottom: '5px',
              }}
            >
              <ErrorIcon
                style={{
                  fontSize: '85px',
                  color: colors.RED,
                }}
              />
            </p>
            {this.props.assetName === ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <span>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: 0,
                  }}
                  id="loader"
                >
                  {translate('signatureError.unknown.message1')}
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    marginTop: '5px',
                  }}
                  id="loader"
                >
                  {translate('signatureError.unknown.message2')}
                </p>
              </span>
            )}

            {this.props.assetName !== ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <p
                style={{
                  margin: 'auto',
                  marginTop: '5px',
                  textAlign: 'center',
                  fontSize: '16px',
                  maxWidth: '320px',
                }}
                id="loader"
              >
                {translate(`${this.props.assetName}.signatureError.unknown.message1`)}
              </p>
            )}
          </div>
        )}
        {this.props.documentStatus === ByesignHelper.status.DOCUMENT_CANCELLED && (
          <div>
            <p
              style={{
                textAlign: 'center',
                marginTop: this.props.isLandscape ? '15%' : '190px',
                marginBottom: '5px',
              }}
            >
              <ErrorIcon
                style={{
                  fontSize: '85px',
                  color: colors.RED,
                }}
              />
            </p>
            {this.props.assetName === ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <span>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: 0,
                  }}
                  id="loader"
                >
                  {translate('signatureError.cancelled.message1')}
                </p>
              </span>
            )}

            {this.props.assetName !== ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <p
                style={{
                  margin: 'auto',
                  marginTop: '5px',
                  textAlign: 'center',
                  fontSize: '16px',
                  maxWidth: '320px',
                }}
                id="loader"
              >
                {translate(`${this.props.assetName}.signatureError.cancelled.message1`, {
                  documentUrl: this.props.documentUrl,
                })}
              </p>
            )}
          </div>
        )}
        {this.props.documentStatus === ByesignHelper.status.DOCUMENT_SIGNER_ALREADY_SIGNED && (
          <div>
            <p
              style={{
                textAlign: 'center',
                marginTop: this.props.isLandscape ? '15%' : '190px',
                marginBottom: '5px',
              }}
            >
              <CheckIcon
                style={{
                  fontSize: '85px',
                  color: colors.GREEN,
                }}
              />
            </p>
            {this.props.assetName === ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <span>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: 0,
                  }}
                  id="loader"
                >
                  {translate(`signatureError.already_signed.message1`, {
                    documentUrl: this.props.documentUrl,
                  })}
                </p>
              </span>
            )}

            {this.props.assetName !== ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <p
                style={{
                  margin: 'auto',
                  marginTop: '5px',
                  textAlign: 'center',
                  fontSize: '16px',
                  maxWidth: '320px',
                }}
                id="loader"
              >
                {translate(`${this.props.assetName}.signatureError.already_signed.message1`, {
                  documentUrl: this.props.documentUrl,
                })}
              </p>
            )}
          </div>
        )}
        {this.props.documentStatus === ByesignHelper.status.CREATING_DOCUMENT && (
          <div>
            <p
              style={{
                textAlign: 'center',
                marginTop: this.props.isLandscape ? '15%' : '190px',
                marginBottom: '5px',
              }}
            >
              <ClockIcon
                style={{
                  fontSize: '85px',
                  color: colors.RED,
                }}
              />
            </p>
            {this.props.assetName === ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <span>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: 0,
                  }}
                  id="loader"
                >
                  {translate(`signatureError.creating_document.message1`)}
                </p>
              </span>
            )}

            {this.props.assetName !== ByesignHelper.constants.DEFAULT_ASSET_NAME && (
              <p
                style={{
                  margin: 'auto',
                  marginTop: '5px',
                  textAlign: 'center',
                  fontSize: '16px',
                  maxWidth: '320px',
                }}
                id="loader"
              >
                {translate(`${this.props.assetName}.signatureError.creating_document.message1`)}
              </p>
            )}
          </div>
        )}

        {this.renderIncorrectAgentSignerError()}
      </div>
    );
  }

  renderIncorrectAgentSignerError = () => {
    return (
      <div>
        {this.props.documentStatus === ByesignHelper.status.INCORRECT_AGENT_SIGNER && (
          <div>
            <p
              style={{
                textAlign: 'center',
                marginTop: this.props.isLandscape ? '15%' : '190px',
                marginBottom: '5px',
              }}
            >
              <LockIcon
                style={{
                  fontSize: '85px',
                  color: colors.RED,
                }}
              />
            </p>

            <span>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  marginBottom: 0,
                }}
                id="loader"
              >
                {this.props.translate(`signatureError.incorrect_agent_signer.message1`, {
                  agent_name: this.props.ownerName,
                })}
              </p>
            </span>
          </div>
        )}
      </div>
    );
  };
};

SignatureErrorView = connect((store) => ({
  assetName: store.documentPortal.assetName,
  documentStatus: store.documentPortal.documentStatus,
  documentUrl: store.documentPortal.documentUrl,
  ownerName: store.documentPortal.ownerName,
}))(SignatureErrorView);

export default localize()(SignatureErrorView);
