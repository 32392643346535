import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

import { toFriendlyDateString } from 'helpers/Formatter';
import { colors } from 'helpers/ColorHelper';

import { RenderIfToggleOn } from 'components/shared/ToggleRenderer';

class CurrentLeasesOfUnit extends Component {
  render() {
    const { leases, availableUntil } = this.props;
    let currentCotenants = [];
    leases.forEach(lease => {
      currentCotenants.push(
        <div key={lease.id}>
          {lease.cotenant || lease.unit}
          {!!lease.lease_start_date && ' has an existing lease'}
          <span style={{ color: colors.RED, fontWeight: 'bold' }}>
            &nbsp;{' '}
            {lease.lease_start_date ? `from ${toFriendlyDateString(lease.lease_start_date)} ` : ''}
          </span>{' '}
          <span style={{ color: colors.RED, fontWeight: 'bold' }}>
            &nbsp; {lease.lease_end_date ? `to ${toFriendlyDateString(lease.lease_end_date)}` : ''}
          </span>
          {lease.month_to_month && ' on a month to month lease'}
        </div>
      );
    });

    return (
      <CSSTransitionGroup
        transitionName="lease_occupied"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {!!availableUntil && (
          <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-sm-12" style={{ textAlign: 'center', marginTop: '10px' }}>
              <p style={{ color: 'red', fontFamily: 'Circular-Bold', fontSize: '15px' }}>
                <i className="em em-raised_hands" /> This lease item is currently occupied until{' '}
                {toFriendlyDateString(availableUntil)} <i className="em em-raised_hands" />
              </p>
            </div>
          </div>
        )}
        <RenderIfToggleOn toggle="ui.lease_creator.yuhu_rentable_items">
          {!availableUntil &&
            leases &&
            leases.length > 0 && (
              <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-sm-12" style={{ textAlign: 'center', marginTop: '10px' }}>
                  <p style={{ color: 'red', fontFamily: 'Circular-Bold', fontSize: '15px' }}>
                    <i className="em em-raised_hands" /> This unit is currently occupied{' '}
                    <i className="em em-raised_hands" />
                  </p>
                  <div style={{ marginTop: '10px' }}>{currentCotenants}</div>
                </div>
              </div>
            )}
        </RenderIfToggleOn>
      </CSSTransitionGroup>
    );
  }
}

export default CurrentLeasesOfUnit;
