export const colors = {
  RED: '#ff3434',
  YELLOW: '#ffe400',
  GRAY: '#A4A4A4',
  OFF_WHITE: '#e0e0e0',
  DISABLED_GRAY: '#bdbdbd',
  FONT_GRAY: '#585858',
  FONT_DARK_GREY: '#2b2c31',
  LIGHT_GRAY: '#f1f1f1',
  ICON_GRAY: '#6F6F6F',
  MENU_ITEM_GRAY: '#656565',
  DARK_GRAY: '#4e4e4e',
  MED_DARK_GRAY: '#4F4F4F',
  DARKEST_GRAY: '#424242',
  GREEN: '#009804',
  BLACK: '#000000',
  WHITE: '#ffffff',
  BLUE: '#7fabff',
  BLUEISH_GRAY: '#e8f2ff',
  OKTA_BLUE: '#0073b2',
  OKTA_BLUE_HOVER: '#007dc1',
  ACCENT_GRAY: '#9e9e9e',
  AMBER: '#ffc97d',
  LIGHT_ORANGE: '#ff7600',
  PURPLE: '#81008d',
  LABEL_GRAY: '#b5b5b5',
  BODY_GRAY: '#f7f7f7',
  FADE_GRAY: '#888888',
  LIGHTEST_GRAY: '#fbfbfb',
  SIDEBAR_NESTED_GRAY: '#ececec',
  MEDIUM_GRAY: '#757575',
  DARK: '#141316',
  CHARCOAL_GREY: '#313134',
  SLATE_GREY: '#65666D',
  ULTRAMARINE_BLUE: '#1C1ABA',
  BLUEY_GREY: '#65666d',
  LIGHT_PERIWINKLE: '#d9d9e3',
  YUHU_RED: '#ff3333',
  YUHU_RED_TINT: '#b02323',
  BATTLESHIP_GREY: '#74777a',
  PALE_GREY: '#f5f6fa',
  PUMPKIN_ORANGE: '#f18500',
  LIGHT_PURPLE: '#6236ff',
  YELLOW_ORANGE: '#fbb600',
  LIGHT_BLUE_GREY: '#DFE8FF',
  BLUE_DENIM: '#015eb8',
  LOGIN_BACKGROUND_GREY: '#fbfbfd',
  TODO_ITEM_BG: '#3e3e3e',
  CHECKMARK_GREEN: '#0CA068',
  FOREST_GREEN: '#377D22',
  DIALOG_BACKGROUND: '#FAFAFC',
  DIALOG_BORDER: '#D9D9E3',
  FOOTER_SHADOW: '#e6e6f0',
  STATE_BLUE: '#001AFF',
  CHAR_COUNT: '#828282',
  PURE_BLUE: '#2000ff',
  LIGHT_GREY: '#F2F2F2',
  HR_GREY: '#c4c4c4',
  LINK_BLUE: '#2164e9',
  BORDER: '#dfdfdf',
  GREY_BLUE: '#dfe8ff',
  BANNER_GREEN: '#398325',
  DEEP_BLUE: '#1822b3',
  WIZARD_GREY: '#FAFAFC',
  BLACK_RUSSIAN: '#2B2C32',
  SPACE_GRAY: '#333333',
  CHALK_BLUE: '#628EFF',
  ERROR_RED: '#F00000',
  HELPER_ORANGE: '#FFF7E6',
  HELPER_RED: '#FFEBEB',
  BANNER_SHADOW: '#383838',
  DARK_BUTTON: '#46466D',
};

export const leaseCreatorColors = {
  ERROR_RED: '#FF2E2E',
  WIZARD_BACKGROUND: '#F5F4F3',
  FORM_BORDER: '#E5E3E0',
  INFO_MSG_COLOR: '#155C65',
  HEADER: '#262626',
  INFORMATION: '#595959',
};

export const maintenanceColors = {
  PENDING: 'red',
  OPEN: '#194bff',
  REVIEWED: '#194bff',
  SCHEDULED: '#c280ff',
  ASSIGNED: '#c280ff',
  ACKNOWLEDGED: '#8400ff',
  ON_THE_WAY: 'orange',
  DEFERRED: '#ff0099',
  AUTO_DEFERRED: '#ff0099',
  FIXING: 'orange',
  FIXED: 'green',
  PARTS_NEEDED: colors.LIGHT_ORANGE,
  CONTRACTOR_NEEDED: colors.LIGHT_ORANGE,
  CLOSED: '#01b106',
  INACTIVE: 'grey',
  CONTACT_TENANT: '#ff4d4d',
  CANCELLED: 'grey',
};

export const applicationColors = {
  PENDING: '#194bff',
  PAYMENT_RECEIVED: '#01b106',
  APPLICATION_DENIED: 'red',
  PENDING_APPROVAL: '#dac510',
  PENDING_LANDLORD_SIGNATURE: '#1fc925',
  PENDING_TENANT_SIGNATURE: 'orange',
  PENDING_LEASE_APPROVAL: '#7e00f3',
  CREATE_LEASE: '#018dff',
  PENDING_ALL_TENANTS: 'orange',
  CANCELLED: 'grey',
  FLAGGED: '#db7c00',
  PENDING_PAYMENT: '#98cc00',
  LEASED_TO_OTHER_APPLICANT: '#f31295',
  UNIT_RESERVED_FOR_OTHER: '#f31295',
  PENDING_APPLICATION_SUBMISSION: '#orange',
  APPROVED: '#01b106',
};

export const initialColors = {
  RED: '#ff3434',
  YELLOW: '#ffe400',
  GREEN: '#009804',
  BLUE: '#7fabff',
  OKTA_BLUE: '#0073b2',
  AMBER: '#ffc97d',
  LIGHT_ORANGE: '#ff7600',
  PURPLE: '#81008d',
  ULTRAMARINE_BLUE: '#230cc2',
  YUHU_RED: '#ff3333',
  PUMPKIN_ORANGE: '#f18500',
  LIGHT_PURPLE: '#6236ff',
  YELLOW_ORANGE: '#fbb600',
  BLUE_DENIM: '#015eb8',
  CHECKMARK_GREEN: '#0CA068',
  FOREST_GREEN: '#377D22',
};

export const iconColors = {
  CHECK_CIRCLE_FILLED: '#44D7B6',
  CHECK_CIRCLE_OUTLINED: '#519E62',
  MINUS_CIRCLE_FILLED: '#FF3434',
  EXCLAMATION_CIRCLE_FILLED: '#E3990C',
  WARNING_FILLED: '#F7B500',
  LOADING_OUTLINED: '#2000ff',
  SUCCESS: '#44d7b6',
};

export const badgeColors = {
  YELLOW: '#E3990C',
};

export function shadeColor(color, percent) {
  // From https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;

  return (
    '#' +
    (0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

export const getRandomColour = colorsObj => {
  const keys = Object.keys(colorsObj);
  return colorsObj[keys[(keys.length * Math.random()) << 0]];
};
