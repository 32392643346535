import { PAGES } from 'components/landlord_app/applications/LeaseCreator';

export default function reducer(
  state = {
    applications: [],
    applicationsCounts: {},
    fetchingApplications: false,
    applicationId: null,
    showOptionsMenu: false,
    anchorEl: null,
    scheduleAs: [],
    welcomePackages: [],
    leaseDetails: null,
    showScheduleAForm: false,
    showDenyApplication: false,
    showInviteLeadDialog: false,
    documentToSign: null,
    pageNumber: 0,
    backToReview: false,
    applicationDashboard: {},
    referralSuccess: false,
    application: {},
    upsertingDocumentTemplate: false,
    claimUrl: null,
    fetchingDocumentTemplatesForProperty: false,
    documentTemplatesForProperty: [],
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_APPLICATIONS::FETCH_APPLICATIONS_PENDING': {
      return { ...state, fetchingApplications: true };
    }
    case 'LANDLORD_APPLICATIONS::GET_APPLICATION': {
      return { ...state, application: action.application, claimUrl: null };
    }
    case 'LANDLORD_APPLICATIONS::SAVE_APPLICATIONS_STATS': {
      return { ...state, applicationsCounts: action.payload };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_APPLICATION_DASHBOARD': {
      return { ...state, applicationDashboard: action.applicationDashboard };
    }
    case 'LANDLORD_APPLICATIONS::SAVE_APPLICATION_ID': {
      return { ...state, applicationId: action.applicationId };
    }
    case 'LANDLORD_APPLICATIONS::REMOVE_APPLICATION': {
      return {
        ...state,
        applications: state.applications.filter(a => a.application_id !== action.applicationId),
      };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_APPLICATIONS': {
      if (state.showingsLastFetched > action.timestamp) return state;
      return {
        ...state,
        applications: [...state.applications.slice(0, action.offset), ...action.applications],
        applicationsLastFetched: action.timestamp,
        fetchingApplications: false,
        offset: action.offset,
      };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_DENY_APPLICATION': {
      return {
        ...state,
        showDenyApplication: action.shouldShow,
        showOptionsMenu: false,
      };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_OPTIONS_MENU': {
      return {
        ...state,
        applicationId: action.applicationId,
        showOptionsMenu: action.shouldShow,
        anchorEl: action.anchorEl,
      };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_LEASE_CREATOR': {
      return {
        ...state,
        leaseDetails: action.leaseDetails,
        applicationId: action.leaseDetails.application_id,
        documentToSign: null,
        pageNumber: 0,
        backToReview: false,
      };
    }
    case 'LANDLORD_APPLICATIONS::SAVE_LEASE_DRAFT_DATA': {
      return {
        ...state,
        leaseDetails: {
          ...state.leaseDetails,
          draft_lease_data: action.leaseDetailsDraftData,
        },
      };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_SCHEDULE_A_FORM': {
      return { ...state, showScheduleAForm: action.shouldShow };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_SCHEDULE_AS': {
      return { ...state, scheduleAs: action.scheduleAs };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_WELCOME_PACKAGES': {
      return { ...state, welcomePackages: action.welcomePackages };
    }
    case 'LANDLORD_APPLICATIONS::SHOW_INVITE_LEAD_DIALOG': {
      return {
        ...state,
        showInviteLeadDialog: action.shouldShow,
        transferApplication: action.transferApplication,
      };
    }
    case 'LANDLORD_APPLICATIONS::GO_TO_PAGE': {
      return {
        ...state,
        pageNumber: action.pageNumber,
        backToReview: state.pageNumber === PAGES.REVIEW,
      };
    }
    case 'LANDLORD_APPLICATIONS::NEXT_PAGE': {
      let pageNumber = null;
      if (state.backToReview) {
        state.backToReview = false;
        pageNumber = PAGES.REVIEW;
      } else {
        pageNumber = state.pageNumber + 1;
      }
      return { ...state, pageNumber: pageNumber };
    }
    case 'LANDLORD_APPLICATIONS::PREVIOUS_PAGE': {
      let pageNumber = null;
      if (state.backToReview) {
        state.backToReview = false;
        pageNumber = PAGES.REVIEW;
      } else {
        pageNumber = state.pageNumber - 1;
      }
      return { ...state, pageNumber: pageNumber };
    }
    case 'LANDLORD_APPLICATIONS::DISPLAY_DOCUMENT': {
      return { ...state, documentToSign: action.documentToSign };
    }
    case 'LANDLORD_APPLICATIONS::SET_CLAIM_URL': {
      return { ...state, claimUrl: action.payload };
    }
    case 'LANDLORD_APPLICATIONS::DOCUMENT_TEMPLATES_FOR_PROPERTY_PENDING': {
      return {
        ...state,
        fetchingDocumentTemplatesForProperty: true,
      };
    }
    case 'LANDLORD_APPLICATIONS::DOCUMENT_TEMPLATES_FOR_PROPERTY_REJECTED': {
      return {
        ...state,
        fetchingDocumentTemplatesForProperty: false,
      };
    }
    case 'LANDLORD_APPLICATIONS::DOCUMENT_TEMPLATES_FOR_PROPERTY_FULFILLED': {
      const json_api_data = action.payload.document_templates.data;
      const attributes_data = json_api_data.map(arr => ({
        value: arr.attributes.id,
        label: arr.attributes.title,
        signable_doc_type: arr.attributes.signable_doc_type,
        status: arr.attributes.status,
        additional_charges: arr.attributes.additional_charges,
        additional_fields: arr.attributes.additional_fields,
        conditional_addendum_name: arr.attributes.conditional_addendum_name,
        base_document_template_ids: arr.attributes.base_document_template_ids,
        services_and_utilities: arr.attributes.services_and_utilities,
      }));
      return {
        ...state,
        fetchingDocumentTemplatesForProperty: false,
        documentTemplatesForProperty: attributes_data,
      };
    }
    default:
      return state;
  }
}
