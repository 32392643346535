export default function reducer(
  state = {
    tickets: [
      {
        subject: '',
        scheduled_at: '',
        status: '',
      },
    ],
    loadingTickets: false,
    rooms: [{ label: 'None', value: 'None' }],
    ticket: null,
  },
  action
) {
  switch (action.type) {
    case 'SAVE_MAINTENANCE_TICKETS': {
      return {
        ...state,
        tickets: action.tickets,
        loadingTickets: false,
      };
    }
    case 'LOAD_MAINTENANCE_TICKETS': {
      return {
        ...state,
        loadingTickets: true,
      };
    }
    case 'SAVE_MAINTENANCE_ROOMS': {
      return {
        ...state,
        rooms: action.rooms,
      };
    }
    case 'SAVE_MAINTENANCE_TICKET': {
      return {
        ...state,
        ticket: action.ticket,
      };
    }
    case 'UNSAVE_MAINTENANCE_TICKET': {
      return {
        ...state,
        ticket: null,
      };
    }
    default:
      return state;
  }
}
