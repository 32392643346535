import {
  OPEN_RENEWAL_WIZARD,
  CLOSE_RENEWAL_WIZARD,
  STORE_PROPOSALS,
  SELECT_PROPOSAL,
  STORE_DETAILS,
  CLEAR_DETAILS,
  SELECT_LEASE_TERM,
  STORE_SCHEDULE_AS,
  STORE_LEASE_COTENANTS,
  CREATE_RENEWAL,
  STORE_SELECTED_APPLICANTS,
  STORE_DEFAULT_SERVICES_AND_UTILS,
  STORE_USE_NOTICE_OF_INCREASE,
  STORE_RENEWAL,
  STORE_DEFAULT_LEASE_DOCUMENT_TYPES,
} from './actionTypes';
import { CHARGE_TYPE_MAPPING } from '../constants';

const initialReviewRenewalDetailsFormValues = {
  insurance_required: false,
  services: {
    details: undefined,
    gas: {
      is_included: false,
    },
    air_conditioning: {
      is_included: false,
    },
    storage_space: {
      is_included: false,
    },
    on_site_laundry: {
      is_included: false,
      charge_type: CHARGE_TYPE_MAPPING.no_charge,
    },
    guest_parking: {
      is_included: false,
      charge_type: CHARGE_TYPE_MAPPING.no_charge,
    },
    other: [],
  },
  utilities: {
    details: undefined,
    electricity: {
      paid_by: CHARGE_TYPE_MAPPING.tenant,
    },
    heat: {
      paid_by: CHARGE_TYPE_MAPPING.tenant,
    },
    water: {
      paid_by: CHARGE_TYPE_MAPPING.tenant,
    },
  },
  schedule_a_id: undefined,
  notice_of_increase: {
    use_n1: undefined,
    use_n2: undefined,
    more_than_guideline: undefined,
    has_been_approved: undefined,
  },
  lease_documents: undefined,
};

const initialState = {
  showRenewalWizard: false,
  leaseId: undefined,
  proposals: [],
  selectedProposal: {},
  details: initialReviewRenewalDetailsFormValues,
  selectedLeaseTerm: undefined,
  scheduleAs: [],
  leaseCotenants: [],
  selectedApplicants: [],
  renewal: undefined,
  lease_document_types: undefined,
};

function storeDefaultServicesAndUtils(state, action) {
  const defaultServicesAndUtils = action.services_and_utilities;
  const newDetails = { ...state.details, ...defaultServicesAndUtils };
  return { ...state, details: newDetails };
}

function storeDefaultLeaseDocumentTypes(state, action) {
  const defaultLeaseDocumentTypes = action.lease_document_types;
  return { ...state, lease_document_types: defaultLeaseDocumentTypes };
}

function storeUseNoticeOfIncrease(state, action) {
  const use_n1 = action.use_n1;
  const use_n2 = action.use_n2;

  let newNoticeOfIncrease = state.details.notice_of_increase;

  if (use_n1) {
    newNoticeOfIncrease = {
      ...newNoticeOfIncrease,
      use_n1: true,
      more_than_guideline: false,
      has_been_approved: false,
    };
  }
  if (use_n2) {
    newNoticeOfIncrease = { ...newNoticeOfIncrease, use_n2: true };
  }

  const newDetails = { ...state.details, notice_of_increase: newNoticeOfIncrease };
  return { ...state, details: newDetails };
}

const renewalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${OPEN_RENEWAL_WIZARD}`:
      return { ...state, showRenewalWizard: true, leaseId: action.leaseId };
    case `${CLOSE_RENEWAL_WIZARD}`:
      return { ...state, showRenewalWizard: false };
    case `${STORE_PROPOSALS}`:
      return { ...state, proposals: action.proposals };
    case `${SELECT_PROPOSAL}`:
      return { ...state, selectedProposal: action.selectedProposal };
    case `${STORE_DETAILS}`:
      return { ...state, details: action.details };
    case `${CLEAR_DETAILS}`:
      return { ...state, details: initialReviewRenewalDetailsFormValues };
    case `${SELECT_LEASE_TERM}`:
      return { ...state, selectedLeaseTerm: action.selectedLeaseTerm };
    case `${STORE_SCHEDULE_AS}`:
      return { ...state, scheduleAs: action.scheduleAs };
    case `${STORE_LEASE_COTENANTS}`:
      return { ...state, leaseCotenants: action.leaseCotenants };
    case `${STORE_DEFAULT_SERVICES_AND_UTILS}`:
      return storeDefaultServicesAndUtils(state, action);
    case `${STORE_DEFAULT_LEASE_DOCUMENT_TYPES}`:
      return storeDefaultLeaseDocumentTypes(state, action);
    case `${STORE_USE_NOTICE_OF_INCREASE}`:
      return storeUseNoticeOfIncrease(state, action);
    case `${CREATE_RENEWAL}`:
      return { ...state };
    case `${STORE_SELECTED_APPLICANTS}`:
      return { ...state, selectedApplicants: action.selectedApplicants };
    case `${STORE_RENEWAL}`:
      return { ...state, renewal: action.renewal };
    default:
      return state;
  }
};

export default renewalsReducer;
