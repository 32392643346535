import Auth from 'j-toker';
import constants from 'config/constants';

import { browserHistory } from 'react-router';
import { initializeClient } from 'launchDarkly';

import store from 'store';
import { setUserAsLandlord, setUserAsLead, setUserAsMaintenance } from 'actions/userTypesActions';
import { logUserOut } from 'actions/authenticationActions';
import { apiGet } from 'helpers/ApiHelper';

let isAuthenticatingFlag = true;

export function isAuthenticating() {
  return isAuthenticatingFlag;
}

export function authenticationSetup() {
  const apiAgentUrl = `${constants.BASE_API_URL}/v1`;

  const authConfiguration = Auth.configure([
    {
      default: {
        apiUrl: apiAgentUrl,
        emailSignInPath: '/auth_lead/sign_in',
        signOutPath: '/auth_lead/sign_out',
        emailRegistrationPath: '/auth_lead/',
        passwordResetPath: '/auth_lead/password',
        passwordUpdatePath: '/auth_lead/password',
        tokenValidationPath: '/auth_lead/validate_token',
        storage: 'localStorage',
        passwordResetSuccessUrl: function () {
          const getUrl = window.location;
          const baseUrl =
            getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[0];
          return baseUrl + 'tenant_change_password_reset';
        },
      },
    },
    {
      landlord: {
        apiUrl: apiAgentUrl,
        emailSignInPath: '/auth_agent/sign_in',
        signOutPath: '/auth_agent/sign_out',
        emailRegistrationPath: '/auth_agent/',
        passwordResetPath: '/auth_agent/password',
        passwordUpdatePath: '/auth_agent/password',
        tokenValidationPath: '/auth_agent/validate_token',
        storage: 'localStorage',
        passwordResetSuccessUrl: function () {
          const getUrl = window.location;
          const baseUrl =
            getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[0];
          return baseUrl + 'companies/agent_change_password_reset';
        },
      },
    },
    {
      maintenance: {
        apiUrl: apiAgentUrl,
        emailSignInPath: '/auth_maintenance/sign_in',
        signOutPath: '/auth_maintenance/sign_out',
        emailRegistrationPath: '/auth_maintenance/',
        passwordResetPath: '/auth_maintenance/password',
        passwordUpdatePath: '/auth_maintenance/password',
        tokenValidationPath: '/auth_maintenance/validate_token',
        storage: 'localStorage',
        passwordResetSuccessUrl: function () {
          const getUrl = window.location;
          const baseUrl =
            getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[0];
          return baseUrl + 'maintenance/maintenance_change_password_reset';
        },
      },
    },
  ]);

  if (!authConfiguration) {
    return null;
  }

  return authConfiguration
    .then(async (response) => {
      isAuthenticatingFlag = false;
      Auth.configDfd = null;

      if (response.feature_set) {
        await initializeClient({ key: response.landlord_id, name: response.landlord_name });
      }

      return response;
    })
    .catch((error) => {
      isAuthenticatingFlag = false;
      Auth.configDfd = null;

      throw error;
    });
}

export function isOnlyApiUser(permissions) {
  if (!permissions) return false;
  let value = 0;
  Object.keys(permissions).forEach((permission_key) => {
    // ignore non permissions
    if (['id', 'user_id', 'user_type', 'users', 'api_dashboard'].includes(permission_key)) {
      value += 0;
    } else {
      value += permissions[permission_key];
    }
  });

  value += permissions.api_dashboard;

  return value === 1;
}

export function isSelfService(permissions) {
  if (!permissions) return false;

  return (
    permissions.maintenance_tickets_kiosk || permissions.parcels_kiosk || permissions.calendar_kiosk
  );
}

export function isSelfServiceRoute(page, permissions) {
  if (!permissions) return false;

  return [
    '/companies/dashboard',
    '/companies/maintenance_tickets_kiosk',
    '/companies/parcels_kiosk',
    '/companies/calendar_kiosk',
  ].includes(page);
}

export function logOutLandlordUser(options) {
  store.dispatch(setUserAsLandlord());
  store.dispatch(logUserOut(options));
}

export function logOutLeadUser(options) {
  store.dispatch(setUserAsLead());
  store.dispatch(logUserOut(options));
}

export function logOutMaintenanceUser() {
  store.dispatch(setUserAsMaintenance());
  store.dispatch(logUserOut());
  browserHistory.push('/maintenance');
}

export function refreshCurrentAgent() {
  apiGet(Auth.getApiUrl() + '/auth_agent/validate_token').then((response) => {
    store.dispatch(setUserAsLandlord(response.data));
  });
}
