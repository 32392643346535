import { DaysBetween } from 'helpers/DateHelper';

export function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export function calculateProratedAmount(
  totalRent,
  moveInDate,
  leaseStartDate,
  prorationType = 'yearly'
) {
  if (!totalRent) return 0;

  if (typeof totalRent === 'string' && totalRent.startsWith('$')) {
    totalRent = totalRent.replace('$', '').replace(',', '');
  }

  let proratedAmount = 0;

  if (moveInDate === leaseStartDate) return proratedAmount;

  const diffDays = DaysBetween(moveInDate, leaseStartDate);
  if (prorationType === 'yearly') {
    const totalYearlyAmount = totalRent * 12;
    const dailyAmount = totalYearlyAmount / 365;

    proratedAmount = dailyAmount * diffDays;
  } else if (prorationType === 'monthly') {
    const date = new Date(moveInDate);
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysInMonth = getDaysInMonth(year, month + 1);
    proratedAmount = (totalRent / daysInMonth) * diffDays;
  }

  return proratedAmount;
}

export function calculateLeaseItemsProratedAmount(
  leaseItems,
  earlyMoveInDate,
  leaseStartDate,
  prorationType = 'yearly',
  includeAllRentalChargesInProratedRent
) {
  if ((leaseItems && leaseItems.length === 0) || !earlyMoveInDate || !leaseStartDate) return 0;

  if (includeAllRentalChargesInProratedRent) {
    return leaseItems
      .filter((leaseItem) => new Date(leaseItem.start_date) >= new Date(earlyMoveInDate))
      .reduce(
        (acc, curr) =>
          calculateProratedAmount(curr.amount, earlyMoveInDate, leaseStartDate, prorationType) +
          acc,
        0
      );
  }

  return leaseItems
    .filter(
      (leaseItem) =>
        new Date(leaseItem.start_date) >= new Date(earlyMoveInDate) &&
        new Date(leaseItem.start_date) < new Date(leaseStartDate)
    )
    .reduce(
      (acc, curr) =>
        calculateProratedAmount(curr.amount, curr.start_date, leaseStartDate, prorationType) + acc,
      0
    );
}

export function leaseRentalIncentiveProratedAmount(
  early_move_in_date,
  lease_start_date,
  incentives
) {
  if ((incentives && incentives.length === 0) || !early_move_in_date || !lease_start_date) return 0;
  const incentives_to_tally = incentives.filter((incentive) => incentive.frequency !== '1 month');
  if (incentives_to_tally.length === 0) return 0;

  const date = new Date(early_move_in_date);
  const month = date.getMonth();
  const year = date.getFullYear();
  const daysInMonth = getDaysInMonth(year, month + 1);
  const diffDays = DaysBetween(early_move_in_date, lease_start_date);

  const total = incentives_to_tally.reduce((acc, incentive) => incentive.incentive_amount + acc, 0);

  const incentive_sum = (total / daysInMonth) * diffDays;
  return incentive_sum;
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function inputNumberFormatter(value) {
  return value && `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function inputNumberParser(value) {
  return value.replace(/\$\s?|(,*)/g, '');
}
