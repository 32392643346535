import React from 'react';
import { localize } from 'react-localize-redux';
import { onlyNumbers, toDate } from './Formatter';
import { apiPost, apiGet } from './ApiHelper';
import Auth from 'j-toker';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as notificationActions from 'actions/notificationActions';

const Error = ({ errorName }) =>
  React.createElement(localize()(({ translate }) => <div>{translate(errorName)}</div>));

export const asyncValidate = (values, dispatch, props) => {
  let error_object = {};
  return new Promise((resolve, reject) => {
    apiGet(Auth.getApiUrl() + `/landlords/chart_of_account_numbers`).then((response) => {
      const accountNumbers = response.coa_numbers.map(
        (chartOfAccount) => chartOfAccount.account_number
      );
      const possibleChartOfAccountMatch = response.coa_numbers.filter(
        (chart_of_account) =>
          chart_of_account.account_number === parseInt(values.account_number, 10)
      )[0];

      if (
        accountNumbers.includes(parseInt(values.account_number, 10)) &&
        possibleChartOfAccountMatch.id !== values.id
      ) {
        error_object.account_number = 'That number is already taken';
      }

      if (Object.keys(error_object).length > 0) {
        reject(error_object);
      } else {
        resolve();
      }
    });
  });
};

export function validateUniqueEmail(user) {
  // expects user to be an object passed from redux-form's asyncValidate
  const { user_type, email, user_id } = user;
  let error_object = {};
  return new Promise((resolve, reject) => {
    if (!email) {
      error_object.email = 'No email exists';
    } else if (!user_type) {
      error_object.user_type = 'Must select the user type';
    }

    // If there is any issue with the input don't bother making the API call
    if (Object.keys(error_object).length > 0) {
      return reject(error_object);
    }

    apiPost(Auth.getApiUrl() + `/landlords/users/validate_email`, {
      user_type,
      email,
      user_id,
    }).then((response) => {
      if (!response.valid) {
        error_object.email = 'That email is already taken';
      }
      if (Object.keys(error_object).length > 0) {
        reject(error_object);
      } else {
        resolve();
      }
    });
  });
}

export async function validUniqueLeadEmail(email, leadId) {
  try {
    return (await validateUniqueEmail({ user_type: 'Lead', email, user_id: leadId })) === undefined;
  } catch (_e) {
    return false;
  }
}

export const asyncValidateParcelUUID = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    apiGet(Auth.getApiUrl() + `/landlords/parcels/${values.uuid}`)
      .then((response) => {
        if (response.uuid === values.uuid) {
          reject({ uuid: 'That package ID is already taken' });
        } else {
          dispatch(notificationActions.showFailure());
          reject();
        }
      })
      .catch((error) => {
        if (error.status === 404) {
          // package is not found, so we can create one.
          resolve();
        } else {
          dispatch(notificationActions.showFailure(error));
          reject();
        }
      });
  });
};

export function normalizePhone(value, previousValue) {
  if (!value) {
    return '';
  }

  const onlyNums = String(value).replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
}

// CANADIAN
export function normalizeSin(value, _previousValue) {
  if (!value) {
    return '';
  }

  let onlyNums = String(value)
    .replace(/[^\d]/g, '') // remove any characters that are not numbers
    .substring(0, 9) // limit length to 9 characters
    .match(/.{1,3}/g)
    .join('-'); // add a dash after every 3rd character

  return onlyNums;
}

// AMERICAN
export function normalizeSsn(value, _previousValue) {
  if (!value) {
    return '';
  }

  const onlyNums = String(value)
    .replace(/[^\d]/g, '')
    .substring(0, 9) // ssn number is only 9 numbers
    // there are 2 replace functions here so the dashes show up as the user is typing
    .replace(/^(.{3})(.{1,})/, '$1-$2') // add a dash after the first 3
    .replace(/^(.{6})(.{1,})/, '$1-$2'); // since the above adds a dash, we take that character into account and add another after the next 2 numbers

  return onlyNums;
}

// DEPRECATED
export function parsePercent(value, previousValue) {
  if (!value && value !== 0) return '';

  return value.replace(' %', '');
}

export function normalizeMoney(value, option) {
  if (!value && value !== 0) return '';

  if (typeof value !== 'number' && typeof value !== 'string') {
    return '';
  }
  if (option && option.maxValue && typeof value === 'string') {
    if (parseFloat(value.replace('$', '')).toFixed(2) > option.maxValue) {
      value = option.maxValue;
    }
  }

  if (typeof value === 'number') {
    value = value.toString();
  }
  if (value[value.length - 1] === '.') {
    return value;
  }
  const onlyNums = value.replace(/[^\d.]/g, '');
  if (onlyNums.length === 0) {
    return '';
  }

  let dollars = onlyNums.split('.')[0]; // stuff before the first period
  let cents = onlyNums.split('.')[1]; // stuff after the first period
  cents = cents ? `.${cents.slice(0, 2)}` : ''; // first two decimals
  return '$' + parseFloat(dollars, 10).toLocaleString() + cents;
}

export function numeric(value) {
  return isNaN(value) ? <Error errorName="mustBeNumeric" /> : undefined;
}

export function phoneLength(value) {
  if (!value) return <Error errorName="phoneNumberLength" />;
  const onlyNums = String(value).replace(/[^\d]/g, '');

  return onlyNums.length !== 10 ? <Error errorName="phoneNumberLength" /> : undefined;
}

export function phoneLengthNotRequired(value) {
  if (!value) return;
  phoneLength(value);
}

export function validPhoneNumber(value) {
  if (!value) return <Error errorName="phoneNumberLength" />;
  let number = onlyNumbers(value, true);
  return number === '0000000000' ? <Error errorName={'notValidPhoneNumber'} /> : undefined;
}

export function validInternationalPhoneNumber(value) {
  // Check if all 0's with a leading +
  const valueNoSpace = value.replace(/\s/g, '');
  let validRegex = RegExp(
    // eslint-disable-next-line
    /([+]([0]+$))/g
  );
  if (validRegex.test(valueNoSpace)) return undefined;

  if (!value) return <Error errorName="notValidPhoneNumber" />;
  const parseResult = parsePhoneNumberFromString(value);
  return !parseResult || !parseResult.isValid() ? (
    <Error errorName={'notValidPhoneNumber'} />
  ) : undefined;
}

export function passwordLength(value) {
  return value.length < 8 || value.length > 16 ? <Error errorName="passwordLength" /> : undefined;
}

export function passwordStrengthWeak(value) {
  if (value.toString().length > 4) return undefined;

  return <Error errorName="passwordLengthWeak" />;
}

export function passwordStrength(value) {
  if (new RegExp(/(?=.{8,16})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()])/).test(value))
    return undefined;
  return <Error errorName="passwordStrength" />;
}

export function institutionNumber(value) {
  if (!value) return <Error errorName="institutionNumber" />;
  return value.toString().length < 3 ? <Error errorName="institutionNumber" /> : undefined;
}

export function transitNumber(value) {
  if (!value) return <Error errorName="transitNumber" />;
  return value.toString().length < 5 ? <Error errorName="transitNumber" /> : undefined;
}

export function accountNumber(value) {
  if (!value) return <Error errorName="accountNumber" />;
  return value.toString().length < 7 || value.toString().length > 15 ? (
    <Error errorName="accountNumber" />
  ) : undefined;
}

export function accountNumberACH(value) {
  if (!value) return <Error errorName="accountNumberACH" />;
  return value.toString().length < 4 || value.toString().length > 17 ? (
    <Error errorName="accountNumberACH" />
  ) : undefined;
}

export function routingNumber(value) {
  if (!value) return <Error errorName="routingNumber" />;
  return value.toString().length !== 9 ? <Error errorName="routingNumber" /> : undefined;
}

export function passwordConfirmation(value, fields) {
  return value !== fields.password ? <Error errorName="passwordMatching" /> : undefined;
}

export function fullName(value) {
  return value.trim().split(' ').length < 2 ? <Error errorName="fullNameValidation" /> : undefined;
}

export function validEmail(value) {
  if (!value) return undefined;
  const emailValidation =
    // eslint-disable-next-line
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/i.test(
      value
    );
  if (!emailValidation) {
    return <Error errorName="invalidEmail" />;
  }

  if (
    ['abuse@', 'postmaster@', 'noc@', 'noemail@', '@test.com', '@example.com'].some((part) =>
      value.includes(part)
    )
  ) {
    return <Error errorName="invalidEmail" />;
  }

  return undefined;
}

export function isValidEmail(value) {
  return validEmail(value) === undefined;
}

export const validUrlRegex =
  /(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export function validURL(url) {
  if (!url) return undefined;

  let validRegex = RegExp(
    // eslint-disable-next-line
    /(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return !validRegex.test(url) ? <Error errorName="invalidURL" /> : undefined;
}

export function required(value) {
  if (!Array.isArray(value) && (value || value === false || value === 'false' || value === 0)) {
    if (typeof value === 'string' && value.trim().length === 0) {
      return <Error errorName="required" />;
    }
    return undefined;
  } else if (Array.isArray(value)) {
    if (value.length === 0) {
      return <Error errorName="required" />;
    }
    return undefined;
  } else {
    return <Error errorName="required" />;
  }
}

export function acceptance(value) {
  return value === true ? undefined : <Error errorName="acceptance" />;
}

export function showingFormValidation(value, maxDate) {
  const valueDate = toDate(value);
  if (!!valueDate && valueDate < new Date().setDate(new Date().getDate() - 1)) {
    return <Error errorName="cannotBookInPast" />;
  }

  const maximumDate = toDate(maxDate);
  if (!!maximumDate && maximumDate < valueDate) {
    return <Error errorName="cannotBookInFuture" />;
  }

  return required(value);
}

export function minDate(value) {
  const valueDate = toDate(value);
  if (!!valueDate && valueDate < new Date()) {
    return <Error errorName="cannotBookInPast" />;
  }

  return undefined;
}

export const requiredIf = (condition) => (value) => {
  if (condition) return required(value);
  return undefined;
};

export function requiredActiveLease(value, fields) {
  if (fields.property && fields.unit_selected && fields.unit_active_lease === false) {
    return <Error errorName="requiredActiveLease" />;
  }

  return undefined;
}

// Mark a field required if otherFieldName exists
export const requiredIfExists = (otherFieldName) => (value, allValues) => {
  if (allValues[otherFieldName] !== undefined && allValues[otherFieldName] !== null) {
    return required(value);
  }

  return undefined;
};

export function multi_select_required(value) {
  return value ? value.count === 0 : <Error errorName="required" />;
}

export function maxLength(value) {
  return value.split(' ').length < 2 ? <Error errorName="fullNameValidation" /> : undefined;
}

export function dateRange(value, fields) {
  if (fields.is_current_occupation === 'no' && fields.start_date >= fields.end_date) {
    return <Error errorName="endDateValidation" />;
  }
  return undefined;
}

export function concessionDateRange(value, fields, props) {
  let start_date = fields.lease_start_date;
  if (typeof start_date === 'string') {
    start_date = toDate(start_date);
  }
  let end_date = fields.lease_end_date;
  if (typeof end_date === 'string') {
    end_date = toDate(end_date);
  }

  if (!start_date) {
    return <Error errorName="noStartDatePresent" />;
  }

  if (!end_date) {
    return <Error errorName="noEndDatePresent" />;
  }

  start_date.setHours(0, 0, 0, 0);
  end_date.setHours(0, 0, 0, 0);

  if (value < fields.lease_start_date) {
    return <Error errorName="concessionStartsBeforeLease" />;
  }

  if (value > fields.lease_end_date && !fields.month_to_month) {
    return <Error errorName="concessionEndsAfterLease" />;
  }

  return undefined;
}

export function termDateRange(value, fields) {
  let start_date = fields.lease_start_date;
  if (typeof start_date === 'string') {
    start_date = toDate(start_date);
  }
  let end_date = fields.lease_end_date;
  if (typeof end_date === 'string') {
    end_date = toDate(end_date);
  }

  const term = Number(value.replace(' month(s)', ''));

  if (!start_date) {
    return <Error errorName="noStartDatePresent" />;
  }

  if (!end_date) {
    return <Error errorName="noEndDatePresent" />;
  }

  start_date.setHours(0, 0, 0, 0);
  end_date.setHours(0, 0, 0, 0);

  if (Math.round((end_date - start_date) / 2.628e9) < term) {
    return <Error errorName="termInvalid" />;
  }

  return undefined;
}

export function dateLeaseRange(value, fields) {
  let start_date = fields.lease_start_date;
  if (typeof start_date === 'string') {
    start_date = toDate(start_date);
  }
  let end_date = fields.lease_end_date;
  if (typeof end_date === 'string') {
    end_date = toDate(end_date);
  }
  let early_move_in_date = fields.early_move_in_date;
  if (typeof early_move_in_date === 'string') {
    early_move_in_date = toDate(early_move_in_date);
  }
  if (start_date && end_date) {
    start_date.setHours(0, 0, 0, 0);
    end_date.setHours(0, 0, 0, 0);
    if (start_date && end_date && start_date >= end_date) {
      return <Error errorName="endDateValidation" />;
    }
  }
  if (early_move_in_date && start_date && end_date) {
    early_move_in_date.setHours(0, 0, 0, 0);
    start_date.setHours(0, 0, 0, 0);
    end_date.setHours(0, 0, 0, 0);
    if (early_move_in_date && start_date && end_date && early_move_in_date > start_date) {
      return <Error errorName="helpers.validators.earlyMoveInDateValidation" />;
    }
  }
  return undefined;
}

export function dateOfflineRange(value, fields) {
  let start_date = fields.offline_starts_at;
  let end_date = fields.offline_ends_at;
  let now = new Date();
  if (typeof start_date === 'string') {
    start_date = new Date(start_date);
  }
  if (typeof end_date === 'string') {
    end_date = new Date(end_date);
  }
  if (start_date && end_date) {
    start_date.setHours(0, 0, 0, 0);
    end_date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    if (start_date && end_date && start_date > end_date) {
      return <Error errorName="endDateValidation" />;
    } else if (start_date && start_date < now) {
      return <Error errorName="onlyFutureDate" />;
    }
  }
  return undefined;
}

export function futureDate(value, fields) {
  let rightnow = new Date();
  rightnow.setHours(0, 0, 0, 0);
  if (value) {
    value.setHours(0, 0, 0, 0);
    if (value < rightnow) {
      return <Error errorName="futureDateValidation" />;
    }
  }
  return undefined;
}

export function moveInDate(value, fields) {
  let start_date = fields.lease_start_date;
  let move_in_date = fields.early_move_in_date;
  if (start_date && move_in_date) {
    start_date.setHours(0, 0, 0, 0);
    move_in_date.setHours(0, 0, 0, 0);
    if (move_in_date > start_date) {
      return <Error errorName="invalidMoveInDate" />;
    }
  }
  return undefined;
}

export function todaysDate(value) {
  if (new Date().setHours(0, 0, 0, 0) !== value.setHours(0, 0, 0, 0)) {
    return <Error errorName="notTodayDate" />;
  }

  return undefined;
}

export function maxPaymentReport(value, fields) {
  let reportAmount = onlyNumbers(value);
  let outstandingAmount = onlyNumbers(fields.amount_outstanding);

  if (reportAmount > outstandingAmount) {
    return <Error errorName="invalidPaymentReportAmount" />;
  }
  return undefined;
}

export function depositAmount(value, fields) {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }

  let depositAmount = onlyNumbers(value);
  let monthlyRent = onlyNumbers(fields.monthly_rent);

  if (depositAmount && monthlyRent && depositAmount > monthlyRent) {
    return <Error errorName="invalidDepositAmount" />;
  }
  return undefined;
}

export function greaterThanZero(value) {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }

  let amount = onlyNumbers(value);

  if (amount <= 0) {
    return <Error errorName="greaterThanZero" />;
  }
  return undefined;
}

export function doubleUnitBook(value, fields, unit_leases, is_renewal) {
  if (!unit_leases) return undefined;

  const leases = unit_leases.filter((lease) => {
    return (
      (lease.month_to_month === true && !is_renewal && lease.moving_out === false) ||
      (lease.lease_start_date <= value && lease.lease_end_date >= value)
    );
  });

  if (leases.length > 0) {
    return <Error errorName="doubleUnitBookValidation" />;
  }
  return undefined;
}

// Tests that an attribute in a field array is unique. This can be greatly
// improved in redux-form 7.0 as validate gets the field name prop directly
export function unique(value, allValues, fieldName, attributeName) {
  if (!allValues || typeof allValues[fieldName] !== 'object') return undefined;

  const fieldArrayVals = allValues[fieldName] || [];
  const nonUnique = fieldArrayVals.filter((item) => {
    if (!item) return false;

    return value === item[attributeName];
  });

  if (nonUnique.length > 1) {
    return <Error errorName="uniqueValues" />;
  }

  return undefined;
}

export function businessHoursPair(value, fields, index) {
  if (!value || !fields || !fields.contact_cards) {
    return undefined;
  }
  if (
    (fields.contact_cards[index].business_hours_start &&
      !fields.contact_cards[index].business_hours_end) ||
    (fields.contact_cards[index].business_hours_end &&
      !fields.contact_cards[index].business_hours_start)
  ) {
    return <Error errorName="requiredBusinessHours" />;
  }

  if (
    fields.contact_cards[index].business_hours_start &&
    fields.contact_cards[index].business_hours_end
  ) {
    if (
      fields.contact_cards[index].business_hours_start >=
      fields.contact_cards[index].business_hours_end
    ) {
      return <Error errorName="invalidBusinessHours" />;
    }
  }
  return undefined;
}

export function greaterThan(value, allFields, fieldNameToCompare, fieldVerbiage = 'greaterThan') {
  if (!value || !allFields || !allFields[fieldNameToCompare]) return undefined;

  let error = fieldVerbiage;

  if (value <= allFields[fieldNameToCompare]) {
    return <Error errorName={error} />;
  }

  return undefined;
}

export function lessThan(value, allFields, fieldNameToCompare, fieldVerbiage = 'lessThan') {
  if (!value || !allFields || !allFields[fieldNameToCompare]) return undefined;

  let error = fieldVerbiage;

  if (value > allFields[fieldNameToCompare]) {
    return <Error errorName={error} />;
  }

  return undefined;
}

export function atLeastNineDigitSin(value) {
  if (value) {
    if (value.replace(/\D/g, '').length !== 9) {
      return <Error errorName="invalidSinNumberLength" />;
    }
  }
}

export function isValidSsn(value) {
  if (value && !value.match(/^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/g)) {
    // https://stackoverflow.com/a/4087530
    return <Error errorName="invalidSsnNumber" />;
  }
}

export function validIps(value) {
  if (!value) return undefined;
  if (Array.isArray(value)) return undefined;

  // source https://stackoverflow.com/questions/36479169/regular-expression-for-ip-address-separated-by-comma-or
  // eslint-disable-next-line
  return !/^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)|\*)(?:,\s*(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)|\*))*$/g.test(
    value
  ) ? (
    <Error errorName="invalidIps" />
  ) : undefined;
}

export function validateAmenityMin(value, allFields) {
  if (!allFields.min_booking_time || !allFields.max_booking_time) return undefined;
  if (allFields.min_booking_time <= allFields.max_booking_time) return undefined;

  return <Error errorName="amenityMinGreaterThenMax" />;
}

export function validateAmenityMax(value, allFields) {
  if (!allFields.min_booking_time || !allFields.max_booking_time) return undefined;
  if (allFields.max_booking_time >= allFields.min_booking_time) return undefined;

  return <Error errorName="amenityMaxLessThenMin" />;
}

export function flexiblePapPercentage(value) {
  if (value >= 100) return undefined;

  return <Error errorName="flexiblePapPercentageInvalid" />;
}
