import { SAVE_LOGS, CLEAR_LOGS } from './types';

export default function reducer(
  state = {
    logs: [],
  },
  action
) {
  switch (action.type) {
    case CLEAR_LOGS: {
      return { ...state, logs: [] };
    }
    case SAVE_LOGS: {
      return { ...state, logs: action.logs };
    }
    default:
      return state;
  }
}
