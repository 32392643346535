const makeType = (key) => {
  return 'LANDLORD::APPLICATION_MANAGEMENT::' + key;
};

export const RESET_APPLICATION_STATUSES = makeType('RESET_APPLICATION_STATUSES');

export const CREATE_APPLICATION = makeType('CREATE_APPLICATION');
export const CREATE_APPLICATION_SUCCESS = makeType('CREATE_APPLICATION_SUCCESS');
export const CREATE_APPLICATION_FAILURE = makeType('CREATE_APPLICATION_FAILURE');

export const UPDATE_APPLICATION = makeType('UPDATE_APPLICATION');
export const UPDATE_APPLICATION_SUCCESS = makeType('UPDATE_APPLICATION_SUCCESS');
export const UPDATE_APPLICATION_FAILURE = makeType('UPDATE_APPLICATION_FAILURE');

export const DENY_APPLICATION = makeType('DENY_APPLICATION');
export const DENY_APPLICATION_SUCCESS = makeType('DENY_APPLICATION_SUCCESS');

export const ASSIGN_AGENT = makeType('ASSIGN_AGENT');
export const ASSIGN_AGENT_SUCCESS = makeType('ASSIGN_AGENT_SUCCESS');
export const ASSIGN_AGENT_FAILURE = makeType('ASSIGN_AGENT_FAILURE');

export const REMOVE_APPLICANT = makeType('REMOVE_APPLICANT');
export const REMOVE_APPLICANT_SUCCESS = makeType('REMOVE_APPLICANT_SUCCESS');
