import * as validator from '../helpers/Validator';

export default function reducer(
  state = {
    buildingCommunity: {},
    generalDetails: {},
    payments: [],
    building: null,
    amenities: [],
    promotions: [],
    properties: [],
    referralLinks: {},
    ancillaryItems: [],
    ancillaryItemPricing: {},
    tenancyInfo: {},
    visitors: [],
    emergencyContacts: [],
    boardwalkPayments: [],
    paymentDetails: {
      paymentWithFee: {},
      paymentAuthorizeError: null,
      paymentError: null,
      paymentSuccessDetails: null,
    },
  },
  action
) {
  switch (action.type) {
    case 'TENANT_PROFILE::BUILDING_COMMUNITY': {
      return { ...state, buildingCommunity: action.buildingCommunity };
    }
    case 'TENANT_PROFILE::TENANCY_INFO': {
      return { ...state, tenancyInfo: action.tenancyInfo };
    }
    case 'GET_TENANT_PROFILE': {
      return {
        ...state,
        generalDetails: {
          ...action.generalDetails,
          dob: action.birthday,
        },
        emergencyContacts: action.emergencyContacts,
      };
    }
    case 'DISPLAY_TENANT_RENTAL_HISTORY_QUESTIONS': {
      return { ...state, questions: action.questions };
    }
    case 'DISPLAY_LANDLORD_REFERENCES': {
      return { ...state, landlord_references: action.landlord_references };
    }
    case 'EDIT_LANDLORD_REFERENCE': {
      action.reference.phone_number = validator.normalizePhone(action.reference.phone_number);
      return { ...state, referenceToEdit: action.reference };
    }
    case 'DISPLAY_PERSONAL_REFERENCES': {
      return { ...state, personal_references: action.personal_references };
    }
    case 'EDIT_PERSONAL_REFERENCE': {
      action.reference.phone_number = validator.normalizePhone(action.reference.phone_number);
      return { ...state, referenceToEdit: action.reference };
    }
    case 'DISPLAY_PREVIOUS_EMPLOYMENT_INFO': {
      return {
        ...state,
        previous_employment_info: action.previous_employment_info,
      };
    }
    case 'EDIT_PREVIOUS_EMPLOYMENT_INFO': {
      return { ...state, employmentInfoToEdit: action.employmentInfoToEdit };
    }
    case 'TENANT_DASHBOARD::DISPLAY_PAYMENT_WITH_FEE': {
      return {
        ...state,
        boardwalkPayment: {
          ...state.boardwalkPayment,
          paymentWithFee: action.boardwalkPaymentWithFee,
          paymentAuthorizeError: null,
          paymentAuthorizing: false,
          sendingPayment: false,
          paymentError: null,
          paymentSuccessDetails: null,
        },
      };
    }
    case 'TENANT_PAYMENTS::DISPLAY_PAYMENT_WITH_FEE': {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentWithFee: action.paymentWithFee,
          paymentAuthorizeError: null,
          paymentError: null,
          paymentSuccessDetails: null,
        },
      };
    }
    case 'TENANT_PAYMENTS::ERROR_PAYMENT_AUTHORIZE': {
      return {
        ...state,
        paymentDetails: {
          paymentWithFee: {},
          paymentAuthorizeError: action.error,
          paymentError: null,
          paymentSuccessDetails: null,
        },
      };
    }
    case 'TENANT_PAYMENTS::PAYMENT_SUCCESS': {
      return {
        ...state,
        paymentDetails: {
          paymentWithFee: {},
          paymentAuthorizeError: null,
          paymentError: null,
          paymentSuccessDetails: action.paymentSuccessDetails,
        },
      };
    }
    case 'TENANT_PAYMENTS::PAYMENT_ERROR': {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentError: action.error,
          sendingPayment: false,
          paymentSuccessDetails: null,
        },
      };
    }
    case 'TENANT_DASHBOARD::GET_BOARDWALK_TENANT_PAYMENTS': {
      return { ...state, boardwalkPayments: action.boardwalkPayments };
    }
    case 'TENANT_DASHBOARD::GET_TENANT_PAYMENTS': {
      return { ...state, payments: action.payments };
    }
    case 'DISPLAY_TENANT_BUILDING': {
      return { ...state, building: action.building };
    }
    case 'DISPLAY_AMENITIES': {
      return {
        ...state,
        amenities: action.amenities,
        amenityBookingWaiver: action.amenity_booking_waiver,
      };
    }
    case 'DISPLAY_PROMOTIONS': {
      return { ...state, promotions: action.promotions };
    }
    case 'DISPLAY_PROPERTIES': {
      return { ...state, properties: action.properties };
    }
    case 'DISPLAY_VISITORS': {
      return { ...state, visitors: action.visitors };
    }
    case 'DISPLAY_REFERRALS': {
      return { ...state, referrals: action.referrals };
    }
    case 'DISPLAY_REFERRAL_LINK': {
      return {
        ...state,
        referralLinks: {
          ...state.referralLinks,
          [action.propertyId]: action.referralLink,
        },
      };
    }
    case 'SAVE_LEASE_CHARGES': {
      return {
        ...state,
        leaseCharges: action.leaseCharges,
      };
    }
    case 'SAVE_ANCILLARY_ITEMS': {
      return {
        ...state,
        ancillaryItems: action.ancillaryItems,
      };
    }
    case 'SAVE_ANCILLARY_ITEM_PRICING': {
      return {
        ...state,
        ancillaryItemPricing: action.ancillaryItemPricing,
      };
    }
    default:
      return state;
  }
}
