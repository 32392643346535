export default function reducer(
  state = {
    parcels: [],
    signature: null,
    parcelDetails: {},
    fetchingParcels: false,
    parcelsCounts: {},
  },
  action
) {
  switch (action.type) {
    case 'LANDLORD_PARCELS::FETCH_PARCELS_PENDING': {
      return { ...state, fetchingParcels: true };
    }
    case 'LANDLORD_PARCELS::SAVE_PARCEL_DETAILS': {
      return { ...state, parcelDetails: action.parcelDetails };
    }
    case 'LANDLORD_PARCELS::SHOW_PARCELS': {
      return {
        ...state,
        parcels: [...state.parcels.slice(0, action.offset), ...action.parcels],
        fetchingParcels: false,
        offset: action.offset,
      };
    }
    case 'LANDLORD_PARCELS::SAVE_PARCEL_STATS': {
      return { ...state, parcelsCounts: action.payload };
    }
    case 'LANDLORD_PARCELS::SAVE_SIGNATURE': {
      return { ...state, signature: action.signature };
    }
    default:
      return state;
  }
}
