import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import landlordApplicationsReducer from 'reducers/landlordApplicationsReducer';
import CurrentLeasesOfUnit from 'components/shared/CurrentLeasesOfUnit';
import PropertyUnitSelector from 'components/shared/PropertyUnitSelector';
import { RenderError, differenceInMonthsRounded } from 'helpers/Formatter';
import { deepDiver } from 'helpers/utilities';
import { formValueSelector } from 'redux-form';

let PropertyPage = props => {
  const { currentLeasesOfUnit, currentLeaseId, changeFieldValue, leaseDetails } = props;

  const onUnitChange = async event => {
    if (!event.value) return;

    props.getCurrentLeasesOfUnit(event.value, currentLeaseId);

    props.getUnit(event.value).then(response => {
      const lengthInMonths = differenceInMonthsRounded(
        deepDiver(leaseDetails, 'lease_start_date'),
        deepDiver(leaseDetails, 'lease_end_date')
      );

      let depositAmount = response.value.deposit_amount;
      if (depositAmount === '0') {
        depositAmount = null;
      }

      if (lengthInMonths > 11) {
        changeFieldValue('monthly_rent', response.value.absolute_rent);
        changeFieldValue('optimal_rent', response.value.market_rent);
        if (leaseDetails && !leaseDetails.discounted_deposit_used) {
          changeFieldValue('deposit_amount', depositAmount || response.value.market_rent);
        }
        return;
      }

      if (lengthInMonths === null || lengthInMonths <= 5) {
        changeFieldValue('monthly_rent', response.value.market_rent);
        changeFieldValue('optimal_rent', response.value.market_rent);
        if (leaseDetails && !leaseDetails.discounted_deposit_used) {
          changeFieldValue('deposit_amount', depositAmount || response.value.market_rent);
        }
        return;
      }

      if (lengthInMonths <= 11 && lengthInMonths > 5) {
        changeFieldValue('monthly_rent', response.value.absolute_rent_six_month);
        changeFieldValue('optimal_rent', response.value.market_rent);
        if (leaseDetails && !leaseDetails.discounted_deposit_used) {
          changeFieldValue('deposit_amount', depositAmount || response.value.market_rent);
        }
      }
    });
  };

  const getUnits = value => {
    const property_id = value && value.value;
    if (property_id) {
      props.clearCurrentLeasesOfUnit();
      props.changeFieldValue('unit_selected', null);
      props.getUnitTypes(property_id);

      props
        .dispatch(props.getUnits(property_id, { for_multiselect: true, only_rentables: true }))
        .then(props.onGetUnitsSuccess);
    }
  };

  const _renderSelectMessaging = () => {
    let desiredUnitTypeVerbiage = '';
    if (!!leaseDetails.unit_type_name) {
      desiredUnitTypeVerbiage = `The prospect is looking for a ${leaseDetails.unit_type_name}`;
    }

    return (
      <p>
        Select a unit number from the dropdown menu to assign to this lease.{' '}
        <b>{desiredUnitTypeVerbiage}</b>
      </p>
    );
  };

  return (
    <div>
      <form onSubmit={props.handleSubmit} id="form_field">
        <h3 style={{ textAlign: 'center' }}>Property and Unit</h3>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>{_renderSelectMessaging()}</div>

        <PropertyUnitSelector
          properties={props.properties}
          units={props.units}
          unitTypes={props.unitTypes}
          onPropertyChange={getUnits}
          onUnitChange={onUnitChange}
          propertyInnerDivStyle={{
            width: '100%',
            fontSize: '14px',
            fontWeight: '100 !important',
          }}
          propertyMenuStyle={{ minWidth: '250px', fontSize: '14px' }}
          propertyStyle={{ textAlign: 'left', fontSize: '14px' }}
          unitMenuStyle={{ fontSize: '14px' }}
          unitStyle={{ textAlign: 'left', fontSize: '14px' }}
          abilityToDelayUnitAssignment={props.abilityToDelayUnitAssignment}
          delayUnitAssignment={props.delayUnitAssignment}
        />
        <Field name="unit_selected" component={RenderError} />

        <CurrentLeasesOfUnit leases={currentLeasesOfUnit} />
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.name);
  return {
    form: ownProps.name,
    abilityToDelayUnitAssignment:
      state.companyDetails.companyPreferences.ability_to_delay_unit_assignment,
    delayUnitAssignment: selector(state, 'delay_unit_assignment'),
  };
};

PropertyPage = compose(
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(PropertyPage);

PropertyPage = connect(
  state => ({
    initialValues: state.landlordApplications.leaseDetails, // pull initial values from account reducer
  }),
  { load: landlordApplicationsReducer } // bind account loading action creator
)(PropertyPage);

export default PropertyPage;
