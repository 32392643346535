//LIBRARIES
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FormSection } from 'redux-form';
import { TextField, SelectField } from 'redux-form-material-ui';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

//HELPERS
import * as validator from 'helpers/Validator';
import { AccentColor } from 'helpers/AccentColorHelper';
import { getTranslatedString } from 'helpers/i18n';

//COMPONENTS
import AddressAutocomplete from 'components/shared/addressAutocomplete';

//CONSTANTS
import { CANADIAN_PROVINCES, USA_STATES } from 'constants/address.constants';

//STYLINGS
const style = {
  button: {
    color: 'white',
    height: '40px',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '10px',
  },
};

class CompanyAddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCountry: getTranslatedString('country.CA'),
    };
  }

  componentDidUpdate() {
    const { formValues } = this.props;
    const { currentCountry } = this.state;

    if (formValues) {
      if (!isEqual(formValues.address.country, currentCountry)) {
        this.setState({ currentCountry: formValues.address.country });
      }
    }
  }

  /**
   * Render province/state dropdown items base on country
   * @function renderDropdownList
   */
  renderDropdownList = () => {
    const { currentCountry } = this.state;
    let dropdownList = CANADIAN_PROVINCES.map((province) => {
      return <MenuItem value={province} primaryText={province} />;
    });

    if (isEqual(currentCountry, 'United States')) {
      dropdownList = USA_STATES.map((state) => {
        return <MenuItem value={state} primaryText={state} />;
      });
    }

    return dropdownList;
  };

  /**
   * update country state when autocomplete is selected
   * @function getAddressFromAutoComplete
   * @param { address } string - passed down from autocomplete
   */
  getAddressFromAutoComplete = (address) => {
    if (!isEmpty(address)) {
      this.setState({ currentCountry: address.country });
    }
  };

  addToAddressFields = (street, city, province, country, post_code) => {
    this.props.change('address.street', street);
    this.props.change('address.city', city);
    this.props.change('address.province', province);
    this.props.change('address.country', country);
    this.props.change('address.postal_code', post_code);
  };

  render() {
    const { handleSubmit } = this.props;
    const { currentCountry } = this.state;

    const showSubmitButton =
      this.props.showSubmitButton === undefined || this.props.showSubmitButton;

    return (
      <form onSubmit={handleSubmit.bind(this)}>
        <FormSection name="address">
          <div className="row" style={{ marginBottom: '30px', textAlign: 'center' }}>
            <div className="row">
              <div className="col-xs-4" style={{ textAlign: 'center', zIndex: '1000' }}>
                <Field
                  name="unit_number"
                  type="text"
                  component={TextField}
                  floatingLabelText={getTranslatedString(
                    'landLordPortal.companyAddressForm.labelText.unit'
                  )}
                  style={{ marginTop: '-15px', fontSize: '14px', width: '100%' }}
                />
              </div>
              <div className="col-xs-8" style={{ textAlign: 'center', zIndex: '1000' }}>
                <AddressAutocomplete
                  validate={[validator.required]}
                  addToRedux={this.addToAddressFields}
                  getAddressFromAutoComplete={this.getAddressFromAutoComplete}
                  floatingLabelText={getTranslatedString(
                    'landLordPortal.companyAddressForm.labelText.address'
                  )}
                  name="street"
                  style={{ marginTop: '-15px', fontSize: '14px', width: '100%' }}
                />
              </div>
              <div className="col-sm-6" style={{ textAlign: 'center' }}>
                <Field
                  name="city"
                  validate={[validator.required]}
                  type="text"
                  component={TextField}
                  floatingLabelText={getTranslatedString(
                    'landLordPortal.companyAddressForm.labelText.city'
                  )}
                  style={{ marginTop: '-15px', fontSize: '14px', width: '100%' }}
                />
              </div>
              <div className="col-sm-6" style={{ textAlign: 'center' }}>
                {isEqual(currentCountry, 'Canada') || isEqual(currentCountry, 'United States') ? (
                  <Field
                    name="province"
                    component={SelectField}
                    floatingLabelText={getTranslatedString('addressForm.division')}
                    style={{
                      marginTop: '-15px',
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    {this.renderDropdownList()}
                  </Field>
                ) : (
                  <Field
                    name="province"
                    type="text"
                    component={TextField}
                    floatingLabelText={getTranslatedString('addressForm.division')}
                    style={{
                      marginTop: '-15px',
                      width: '100%',
                    }}
                    errorStyle={{ fontSize: '14px' }}
                  />
                )}
              </div>
              <div className="col-sm-6" style={{ textAlign: 'center' }}>
                <Field
                  name="postal_code"
                  validate={[validator.required]}
                  type="text"
                  component={TextField}
                  floatingLabelText={getTranslatedString('addressForm.area')}
                  style={{ marginTop: '-15px', fontSize: '14px', width: '100%' }}
                />
              </div>
              <div className="col-sm-6" style={{ textAlign: 'center' }}>
                <Field
                  name="country"
                  validate={[validator.required]}
                  type="text"
                  component={TextField}
                  floatingLabelText={getTranslatedString(
                    'landLordPortal.companyAddressForm.labelText.country'
                  )}
                  style={{ marginTop: '-15px', fontSize: '14px', width: '100%' }}
                />
              </div>
            </div>
            {showSubmitButton && (
              <FlatButton
                label={getTranslatedString('landLordPortal.companyAddressForm.updateAddress')}
                type="submit"
                primary={true}
                backgroundColor={AccentColor()}
                hoverColor="grey"
                style={style.button}
              />
            )}
          </div>
        </FormSection>
      </form>
    );
  }
}

CompanyAddressForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CompanyAddressForm);

CompanyAddressForm = connect((state) => ({
  formValues: state.form.company_address && state.form.company_address.values,
}))(CompanyAddressForm);

export default CompanyAddressForm;
