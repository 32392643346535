import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { TextField } from 'redux-form-material-ui';

import * as validator from 'helpers/Validator';
import { createNumberMask } from 'redux-form-input-masks';

import LeaseItems from 'components/shared/LeaseItems/LeaseItems';

import { formatMoney } from 'helpers/Formatter';
import { hostIsWestbank, hostIsFourStar } from 'helpers/CurrentWindowLocation';
import { getTranslatedString } from 'helpers/i18n';

// One off of the default in Formatter.js to force values to be 0
const currencyMask = createNumberMask({
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
  allowEmpty: false,
});

const yardiStyle = {
  textAlign: 'left',
  color: '#6f6f6f',
  lineHeight: '15px',
  fontSize: '12px',
};

const KeyDetails = props => {
  const { customStyle, key_deposit } = props;

  if (!key_deposit) return null;

  return (
    <div className="row">
      <h4
        style={{
          marginTop: '30px',
          textAlign: 'center',
        }}
      >
        Key Details
      </h4>
      <div id="editable">
        <Field
          name="keys_description"
          component={TextField}
          hintText={getTranslatedString('landlordPortal.leasePricingWizardPage.keysDescription')}
          hintStyle={{ fontSize: '14px' }}
          multiLine={true}
          fullWidth
          style={customStyle}
        />
      </div>
    </div>
  );
};

const RenderDiscounts = props => {
  const { optimalRent, actualRent } = props;

  if (hostIsFourStar()) return null;
  if (optimalRent === 0) return null;
  if (optimalRent <= actualRent) return null;

  return (
    <div className="row" style={{ margin: '15px 0 -5px' }}>
      <h4 style={{ textAlign: 'center' }}>
        {getTranslatedString('landlordPortal.leasePricingWizardPage.concessionBreakdown')}
      </h4>
      <div className="col-sm-4 col-xs-6" style={{ textAlign: 'center' }}>
        <h5>{getTranslatedString('landlordPortal.leasePricingWizardPage.marketRent')}</h5>
        <p>{formatMoney(optimalRent)}</p>
      </div>
      <div className="col-sm-4 col-xs-6" style={{ textAlign: 'center' }}>
        <h5>{getTranslatedString('landlordPortal.leasePricingWizardPage.actualRent')}</h5>
        <p>{formatMoney(actualRent)}</p>
      </div>
      <div
        className="col-sm-4 col-xs-6 col-sm-offset-0 col-xs-offset-3"
        style={{ textAlign: 'center' }}
      >
        <h5>{getTranslatedString('landlordPortal.leasePricingWizardPage.totalConcessions')}</h5>
        <p>{formatMoney(optimalRent - actualRent)}</p>
      </div>
    </div>
  );
};

let LeasePricingPage = props => {
  const {
    handleSubmit,
    name,
    key_deposit,
    monthly_rent,
    disableFieldSelection,
    disableDepositSelection,
    optimal_rent,
    hideDepositDetails,
    leaseDetails,
  } = props;

  const petCount = leaseDetails.pet_count;
  const vehicleCount = leaseDetails.vehicle_count;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h3 style={{ textAlign: 'center' }}>
          {getTranslatedString('landlordPortal.leasePricingWizardPage.leasePricing')}{' '}
        </h3>
        {_renderPetVehicleData(petCount, vehicleCount)}
        <div style={{ marginTop: '40px' }}>
          <h4 style={{ textAlign: 'center' }}>
            {getTranslatedString('landlordPortal.leasePricingWizardPage.monthlyPricing')}{' '}
          </h4>
        </div>
        <div className="row" style={{ marginTop: '15px' }}>
          <div className="col-xs-4">
            <Field
              name="monthly_rent"
              type="text"
              component={TextField}
              floatingLabelText={getTranslatedString(
                'landlordPortal.leasePricingWizardPage.monthlyRent'
              )}
              validate={[validator.required]}
              style={{
                fontSize: '14px',
                marginTop: '-15px',
                width: '150px',
              }}
              disabled={disableFieldSelection}
              {...currencyMask}
            />
          </div>
          {!hideDepositDetails && (
            <div>
              <div className="col-xs-4">
                <Field
                  name="deposit_amount"
                  type="text"
                  component={TextField}
                  floatingLabelText={getTranslatedString(
                    'landlordPortal.leasePricingWizardPage.rentalDeposit'
                  )}
                  validate={[validator.required]}
                  style={{
                    fontSize: '14px',
                    marginTop: '-15px',
                    width: '150px',
                  }}
                  disabled={disableFieldSelection}
                  {...currencyMask}
                />
                {disableDepositSelection && (
                  <div className="row">
                    <p style={yardiStyle}>
                      {getTranslatedString(
                        'landlordPortal.leasePricingWizardPage.rentalDepositYardiDisabled'
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="col-xs-4">
                <Field
                  name="key_deposit"
                  type="text"
                  component={TextField}
                  floatingLabelText={getTranslatedString(
                    'landlordPortal.leasePricingWizardPage.keyDeposit'
                  )}
                  style={{
                    fontSize: '14px',
                    marginTop: '-15px',
                    width: '150px',
                  }}
                  disabled={disableFieldSelection}
                  {...currencyMask}
                />
              </div>

              <KeyDetails
                key_deposit={key_deposit}
                customStyle={{ fontSize: '14px', marginLeft: '70px', width: '73%' }}
              />

              {_renderLiquidationDeposit()}
            </div>
          )}
        </div>

        <RenderDiscounts optimalRent={optimal_rent} actualRent={monthly_rent} />

        <LeaseItems formName={name} />
      </form>
    </div>
  );
};

const _renderPetVehicleData = (petCount, vehicleCount) => {
  if (petCount === 0 && vehicleCount === 0) return;

  return (
    <p
      style={{
        textAlign: 'center',
        fontSize: '15px',
        color: 'red',
        fontFamily: 'Circular-Bold',
      }}
    >
      {getTranslatedString('landlordPortal.leasePricingWizardPage.petAndVehicleBreakdown', {
        petCount,
        vehicleCount,
      })}
    </p>
  );
};

const _renderLiquidationDeposit = () => {
  if (!hostIsWestbank()) return;

  return (
    <div className="col-xs-4 col-xs-offset-4">
      <Field
        name="liquidation_deposit"
        type="text"
        component={TextField}
        floatingLabelText={getTranslatedString(
          'landlordPortal.leasePricingWizardPage.liquidationDeposit'
        )}
        style={{
          fontSize: '14px',
          marginTop: '-15px',
          width: '150px',
        }}
        {...currencyMask}
      />
    </div>
  );
};

LeasePricingPage = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(LeasePricingPage);

LeasePricingPage = connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.name);
  return {
    form: ownProps.name,
    initialValues: state.landlordApplications.leaseDetails,
    key_deposit: selector(state, 'key_deposit'),
    monthly_rent: selector(state, 'monthly_rent'),
    optimal_rent: selector(state, 'optimal_rent'),
  };
})(LeasePricingPage);

export default LeasePricingPage;
