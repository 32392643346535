// THIS IS BEING PHASED OUT IN FAVOR OF Header.js
import React from 'react';
import { colors } from 'helpers/ColorHelper';

const TableHeader = ({ name, onEdit, borderRadius }) => {
  return (
    <div
      className="row"
      style={{
        margin: '30px 0px 10px 0px',
        backgroundColor: colors.LIGHT_GRAY,
        borderRadius: borderRadius,
      }}
    >
      <div style={{ padding: '5px 10px 25px 15px' }}>
        <span style={{ textAlign: 'center', fontSize: '22px', float: 'left' }}>{name}</span>

        {onEdit && (
          <button
            type="button"
            className="link-button"
            onClick={onEdit}
            style={{ cursor: 'pointer', float: 'right' }}
          >
            edit
          </button>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
