export default function reducer(
  state = {
    moveOut: false,
    hasPoll: false,
    moveOutDetails: { moving_elevator_bookable: false, status: 0 },
    pollDetails: {},
    layout: null,
    containerWidth: 1080,
    hasApplication: false,
    currentTenant: false,
    documentContainerExpanded: false,
    maintenanceComponentExpanded: false,
    transactionContainerHeight: 1000,
    documentContainerVisible: true,
    createMaintenanceRequest: false,
    tenantOverview: { month_to_month: false, pad_details: {}, landlordName: '' },
    badgeIconForEvents: 0,
    applicants: [],
    activeApplicationID: null,
    address: null,
    leaseStartDate: null,
  },
  action
) {
  switch (action.type) {
    case 'SET_DASHBOARD_LAYOUT': {
      return {
        ...state,
        hasApplication: action.hasApplication,
        coapplicantsInApplication: action.coapplicantsInApplication,
        currentTenant: action.currentTenant,
        tenantOverview: action.tenantOverview,
        moveOutDetails: action.moveOutDetails,
        moveOut: action.moveOut,
        hasPoll: action.hasPoll,
        pollDetails: action.pollDetails,
        badgeIconForEvents: action.badgeIconForEvents,
        propertyType: action.property_type,
        applicants: action.applicants,
        activeApplicationID: action.activeApplicationID,
        address: action.address,
        leaseStartDate: action.leaseStartDate,
        portalLinks: action.portalLinks,
        bannerMessage: action.bannerMessage,
        landlordMaintenaceRequestLink: action.landlordMaintenaceRequestLink,
      };
    }
    case 'OPEN_MAINTENANCE_DIALOG': {
      return {
        ...state,
        createMaintenanceRequest: true,
      };
    }
    case 'MESSAGES::SET_UNREAD_MESSAGES': {
      return {
        ...state,
        tenantOverview: { ...state.tenantOverview, unread_messages: action.unread_messages },
      };
    }
    case 'CLOSE_MAINTENANCE_DIALOG': {
      return {
        ...state,
        createMaintenanceRequest: false,
      };
    }
    default:
      return state;
  }
}
