export default function reducer(
  state = {
    property_id: null,
    redirectToApplication: false,
    invitation_token: null,
    userType: null,
    onboardUser: {},
  },
  action
) {
  switch (action.type) {
    case 'REDIRECTION::INVITATION_TOKEN': {
      return { ...state, invitation_token: action.invitation_token, userType: action.user_type };
    }
    case 'REDIRECTION::GET_ONBOARD_USER': {
      return { ...state, onboardUser: action.onboardUser };
    }
    case 'REDIRECTION::REDIRECT_TO_APPLICATION': {
      return {
        ...state,
        property_id: action.property_id,
        referrer: action.referrer,
        redirectToApplication: true,
      };
    }
    case 'REDIRECTION::CLEAR_REDIRECT_STORAGE': {
      return {
        ...state,
        property_id: null,
        redirectToApplication: false,
      };
    }
    default:
      return state;
  }
}
