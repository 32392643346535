export default function reducer(
  state = {
    leads: [],
    leadDetails: {},
    showNewLeadDialog: false,
    showEditLeadDialog: false,
    leadDashboard: {},
    trafficSources: [],
    referrals: [],
  },
  action
) {
  switch (action.type) {
    case 'SHOW_LEADS': {
      return { ...state, leads: action.leads };
    }
    case 'SHOW_NEW_LEAD_DIALOG': {
      return { ...state, showNewLeadDialog: action.shouldShow };
    }
    case 'SHOW_EDIT_LEAD_DIALOG': {
      return {
        ...state,
        showEditLeadDialog: action.shouldShow,
        leadDetails: action.leadDetails,
      };
    }
    case 'TRAFFIC_SOURCES': {
      return {
        ...state,
        trafficSources: action.trafficSources,
      };
    }
    case 'LEAD_DASHBOARD': {
      return {
        ...state,
        leadDashboard: action.leadDashboard,
      };
    }
    case 'DISPLAY_REFERRALS': {
      return {
        ...state,
        referrals: action.referrals,
      };
    }
    default:
      return state;
  }
}
