import {
  SHOW_DOCUMENT_TEMPLATE,
  FILTER_DOCUMENT_TEMPLATES,
  FETCH_DOCUMENT_TEMPLATES,
  FETCH_DOCUMENT_TEMPLATES_STATS,
  DUPLICATE_DOCUMENT_TEMPLATES,
} from 'actions/landlordDocumentTemplatesActions';

const selectDocumentTemplateData = payload => {
  const json_api_data = payload.document_templates.data;
  const attributes_data = json_api_data.map(arr => arr.attributes);
  return attributes_data;
};

export default function reducer(
  state = {
    documentTemplates: [],
    documentTemplate: undefined,
    uploadedFiles: [],
    fetchingDocumentTemplates: false,
    isUpsertingDocumentTemplate: false,
    updatingDocumentTemplate: false,
    duplicateDocumentTemplate: undefined,
    duplicateDocumentTemplateSuccess: false,
    fetchedDocumentTemplateStatus: 'pending',
    fetchingConditionalTypes: false,
    availableConditionalTypeMappings: [],
    templateCounts: {
      lease: 0,
      other: 0,
    },
  },
  action
) {
  switch (action.type) {
    case `${FETCH_DOCUMENT_TEMPLATES}_PENDING`: {
      return { ...state, fetchingDocumentTemplates: true };
    }
    case `${FETCH_DOCUMENT_TEMPLATES}_REJECTED`: {
      return { ...state, fetchingDocumentTemplates: false };
    }
    case SHOW_DOCUMENT_TEMPLATE: {
      return {
        ...state,
        fetchingDocumentTemplates: false,
        documentTemplate: action.payload.data.attributes,
      };
    }
    case `${FILTER_DOCUMENT_TEMPLATES}_FULFILLED`: {
      return {
        ...state,
        fetchingDocumentTemplates: false,
        documentTemplates: selectDocumentTemplateData(action.payload),
      };
    }
    case `${FETCH_DOCUMENT_TEMPLATES}_FULFILLED`: {
      return {
        ...state,
        fetchingDocumentTemplates: false,
        documentTemplates: selectDocumentTemplateData(action.payload),
      };
    }
    case `${FETCH_DOCUMENT_TEMPLATES_STATS}_FULFILLED`: {
      return {
        ...state,
        templateCounts: action.payload,
      };
    }
    case `${DUPLICATE_DOCUMENT_TEMPLATES}_PENDING`: {
      return {
        ...state,
        duplicateDocumentTemplate: undefined,
        duplicateDocumentTemplateSuccess: false,
      };
    }
    case `${DUPLICATE_DOCUMENT_TEMPLATES}_REJECTED`: {
      return {
        ...state,
        duplicateDocumentTemplate: undefined,
        duplicateDocumentTemplateSuccess: false,
      };
    }
    case `${DUPLICATE_DOCUMENT_TEMPLATES}_FULFILLED`: {
      return {
        ...state,
        duplicateDocumentTemplate: action.payload.data,
        duplicateDocumentTemplateSuccess: true,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::GET_DOCUMENT_TEMPLATE_PENDING': {
      return {
        ...state,
        fetchedDocumentTemplateStatus: 'pending',
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::GET_DOCUMENT_TEMPLATE_FULFILLED': {
      let uploadedFiles = [];
      if (action.payload.data.attributes.uploaded_files) {
        uploadedFiles = action.payload.data.attributes.uploaded_files.map(file => ({
          id: file[0],
          name: file[1],
          file_type: file[2],
          keep: true,
        }));
      }

      return {
        ...state,
        uploadedFiles: uploadedFiles,
        documentTemplate: action.payload.data.attributes,
        fetchedDocumentTemplateStatus: 'fulfilled',
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::GET_DOCUMENT_TEMPLATE_REJECTED': {
      return {
        ...state,
        fetchedDocumentTemplateStatus: 'rejected',
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::UPDATE_DOCUMENT_TEMPLATE_PENDING': {
      return {
        ...state,
        updatingDocumentTemplate: true,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::UPDATE_DOCUMENT_TEMPLATE_FULFILLED': {
      return {
        ...state,
        documentTemplate: action.payload.data.attributes,
        fetchedDocumentTemplateStatus: 'fulfilled',
        updatingDocumentTemplate: false,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::UPDATE_DOCUMENT_TEMPLATE_REJECTED': {
      return {
        ...state,
        fetchedDocumentTemplateStatus: 'rejected',
        updatingDocumentTemplate: false,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::ARCHIVE_DOCUMENT_TEMPLATE_PENDING': {
      return {
        ...state,
        updatingDocumentTemplate: true,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::ARCHIVE_DOCUMENT_TEMPLATE_FULFILLED': {
      return {
        ...state,
        documentTemplate: action.payload.data.attributes,
        fetchedDocumentTemplateStatus: 'fulfilled',
        updatingDocumentTemplate: false,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::ARCHIVE_DOCUMENT_TEMPLATE_REJECTED': {
      return {
        ...state,
        fetchedDocumentTemplateStatus: 'rejected',
        updatingDocumentTemplate: false,
      };
    }

    case 'LANDLORD_DOCUMENT_TEMPLATES::PUBLISH_DOCUMENT_TEMPLATE_PENDING': {
      return {
        ...state,
        updatingDocumentTemplate: true,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::PUBLISH_DOCUMENT_TEMPLATE_FULFILLED': {
      return {
        ...state,
        documentTemplate: action.payload.data.attributes,
        fetchedDocumentTemplateStatus: 'fulfilled',
        updatingDocumentTemplate: false,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::PUBLISH_DOCUMENT_TEMPLATE_REJECTED': {
      return {
        ...state,
        fetchedDocumentTemplateStatus: 'rejected',
        updatingDocumentTemplate: false,
      };
    }

    case 'LANDLORD_DOCUMENT_TEMPLATES::CREATE_DOCUMENT_TEMPLATE_PENDING': {
      return { ...state, isUpsertingDocumentTemplate: true };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::CREATE_DOCUMENT_TEMPLATE_FULFILLED': {
      return {
        ...state,
        documentTemplate: action.payload.data.attributes,
        isUpsertingDocumentTemplate: false,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::CREATE_DOCUMENT_TEMPLATE_REJECTED': {
      return { ...state, isUpsertingDocumentTemplate: false };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::GET_CONDITIONAL_TYPES_PENDING': {
      return { ...state, fetchingConditionalTypes: true };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::GET_CONDITIONAL_TYPES_FULFILLED': {
      return {
        ...state,
        availableConditionalTypeMappings: action.payload,
        fetchingConditionalTypes: false,
      };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::GET_CONDITIONAL_TYPES_REJECTED': {
      return { ...state, fetchingConditionalTypes: false };
    }
    case 'LANDLORD_DOCUMENT_TEMPLATES::EDIT_DOCUMENT_TEMPLATE_FULFILLED': {
      return {
        ...state,
        documentTemplate: action.payload.data.attributes,
      };
    }

    default:
      return state;
  }
}
