export const showUnit = units => {
  if (!units || !units.length) return false;

  // must be a house or single-unit property
  if (units.length === 1 && !units[0].label) return false;

  // length is greater than 1 so we're okay to show the units
  return true;
};

export const fetchCurrentLeasesForSingleUnits = (
  units,
  getCurrentLeasesOfUnit,
  changeFieldValue,
  getUnitDetails
) => {
  if (units.length === 1 && !units[0].label) {
    // unit must be a house or single-unit property
    getCurrentLeasesOfUnit(units[0].value);
    changeFieldValue('unit_selected', units[0]);
    if (getUnitDetails) {
      getUnitDetails(units[0].value);
    }
  }
};
