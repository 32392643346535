export default function reducer(
  state = {
    maintenanceLayout: null,
    maintenanceTickets: [],
  },
  action
) {
  switch (action.type) {
    case 'MAINTENANCE_GRID_LAYOUT': {
      return { ...state, maintenanceLayout: action.maintenanceLayout };
    }
    case 'SAVE_MAINTENANCE_WORKER_TICKETS': {
      return { ...state, maintenanceTickets: action.maintenanceTickets };
    }
    default:
      return state;
  }
}
