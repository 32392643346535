import * as LDClient from 'launchdarkly-js-client-sdk';

let client = null;

async function initializeClient(user) {
  if (!user.key || !user.name) {
    console.warn('[LaunchDarkly]', 'Tried to initialize client with wrong user', user);
    return null;
  }

  try {
    // Trying to return the already-initialized client if the application tries to initialize a new client with the same user.
    if (client) {
      if (client.getUser && client.getUser() && String(client.getUser().key) === String(user.key)) {
        return client;
      }
    }

    client = LDClient.initialize(window.LAUNCH_DARKLY_KEY, user);

    await client.waitUntilReady();

    return client;
  } catch (error) {
    // We don't want to halt the application flow because Launch Darkly can't be initialized.
    window.Rollbar.error(error);
    return null;
  }
}

function getClient() {
  return client;
}

export { getClient, initializeClient };
