import React, { Component } from 'react';
import { localize } from 'react-localize-redux';

import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from 'helpers/reduxFormMaterialUiNext/Switch';

import { showUnit } from 'helpers/PropertiesHelper';
import { colors } from 'helpers/ColorHelper';
import * as validator from 'helpers/Validator';

import SearchableSelectRedux from 'components/shared/SearchableSelectRedux';
import { getTranslatedString } from 'helpers/i18n';
import { CSSTransitionGroup } from 'react-transition-group';
import Spacer from 'components/ui/Spacer';

/*
 PropertyUnitSelector is built to show/hide unit dropdowns when property is a single unit or has apartments.

 validate: used to validate either a property or a unit whichever is shown last.
 */

class PropertyUnitSelector extends Component {
  getValidate = () => {
    let validate = this.props.validate;
    if (!validate) validate = [];
    if (!Array.isArray(validate)) validate = [validate];
    return validate;
  };

  render() {
    const validate = this.getValidate();
    const validateProperty = showUnit(this.props.units) ? [] : validate;

    return (
      <div className="row" style={{ marginTop: this.props.containerMarginTop }}>
        <div className="col-sm-12" style={{ textAlign: 'center', marginTop: '10px' }}>
          <Field
            name="property"
            component={SearchableSelectRedux}
            placeholder={getTranslatedString('landlordPortal.propertyUnitSelector.property')}
            options={this.props.properties}
            onChange={this.props.onPropertyChange}
            innerDivStyle={this.props.propertyInnerDivStyle}
            menuStyle={this.props.propertyMenuStyle}
            style={this.props.propertyStyle}
            validate={[validator.required, ...validateProperty]}
            fullWidth
            disabled={this.props.propertyDisabled || this.props.disabled}
            label={getTranslatedString('landlordPortal.propertyUnitSelector.property')}
            variant={this.props.variant}
          />
        </div>
        <div className="col-sm-12" style={{ marginTop: '15px' }}>
          {this.renderDelayUnitAssignmentSwitch()}
        </div>

        <CSSTransitionGroup
          transitionName="show_dropdown_61px"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          <div className="col-sm-12">
            <CSSTransitionGroup
              transitionName="fade_out_buttons"
              style={{ width: '100%' }}
              transitionEnterTimeout={200}
              transitionLeaveTimeout={200}
            >
              {this.renderUnitOrUnitType()}
            </CSSTransitionGroup>
          </div>
        </CSSTransitionGroup>
      </div>
    );
  }

  renderDelayUnitAssignmentSwitch = () => {
    if (
      !this.props.abilityToDelayUnitAssignment ||
      this.props.unitDisabled ||
      !this.props.unitTypes ||
      this.props.unitTypes === []
    )
      return;

    return (
      <Spacer ml={-1}>
        <FormControlLabel
          control={
            <Field
              name="delay_unit_assignment"
              component={Switch}
              normalize={v => !!v}
              color="primary"
            />
          }
          label={getTranslatedString('landlordPortal.inviteToApply.assignUnitLater')}
        />
      </Spacer>
    );
  };

  renderUnitOrUnitType = () => {
    const validate = this.getValidate();

    if (this.props.delayUnitAssignment && this.props.unitTypes && this.props.unitTypes !== []) {
      return (
        <Field
          name="unit_type_selected"
          component={SearchableSelectRedux}
          label={getTranslatedString('landlordPortal.propertyUnitSelector.unitType')}
          placeholder={getTranslatedString('landlordPortal.propertyUnitSelector.select')}
          options={this.props.unitTypes || []}
          style={this.props.unitStyle}
          filter={(searchText, key) => key.indexOf(searchText) !== -1}
          fullWidth
          validate={validator.required}
          onChange={this.props.onUnitTypeChange}
          disabled={this.props.disabled || this.props.unitDisabled}
        />
      );
    }

    if (this.props.propertySelected && this.props.units.length === 0) {
      return (
        <p
          style={{
            color: colors.YUHU_RED,
            fontFamily: 'Circular-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          <i className="em em-raised_hands" /> All units are currently occupied. Please select a
          different property. <i className="em em-raised_hands" />
        </p>
      );
    }

    if (showUnit(this.props.units)) {
      const filterWaitUnits = this.props.units.filter(
        unit => !unit.label?.toLowerCase()?.includes('wait')
      );

      return (
        <Field
          name="unit_selected"
          component={SearchableSelectRedux}
          placeholder={getTranslatedString('landlordPortal.propertyUnitSelector.select')}
          options={filterWaitUnits || []}
          style={this.props.unitStyle}
          filter={(searchText, key) => key.indexOf(searchText) !== -1}
          fullWidth
          onChange={this.props.onUnitChange}
          validate={[validator.required, ...validate]}
          disabled={this.props.disabled || this.props.unitDisabled}
          allowNone={this.props.allowNone}
          variant={this.props.variant}
          label={getTranslatedString('landlordPortal.propertyUnitSelector.unitNumber')}
        />
      );
    }
  };
}

export default localize()(PropertyUnitSelector);
