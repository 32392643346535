export default function reducer(
  state = {
    selectedRoute: 'dashboard',
    mobileMenuOpen: false,
  },
  action
) {
  switch (action.type) {
    case 'NAVIGATION_ITEMS::TOGGLE_MOBILE_MENU': {
      return { ...state, mobileMenuOpen: action.isOpen };
    }
    case 'SELECTED_ROUTE': {
      return { ...state, selectedRoute: action.selectedRoute.value };
    }
    default:
      return state;
  }
}
