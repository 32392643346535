import React from 'react';

import IconButton from 'material-ui/IconButton';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { MdEdit as EditIcon } from 'react-icons/md';

import * as formatter from 'helpers/Formatter';
import { getTranslatedString } from 'helpers/i18n';

export const RenderLeaseItem = ({ items, onEdit, onDelete, disabled }) => {
  if (!items || !Array.isArray(items)) return null;

  const renderAmount = (item) => {
    if (item.payment_type === 'recurring' || !item.payment_type) {
      return (
        <div>
          <div className="col-sm-4" style={{ padding: '0px' }}>
            <h5 style={{ fontSize: '12px' }}>Monthly Rent</h5>
            <p>
              {item.amount !== undefined &&
                `${formatter.formatMoney(item.amount)}/${getTranslatedString(
                  'landlordPortal.renderLeaseItem.month'
                )}`}
            </p>
          </div>
          <div className="col-sm-4" style={{ padding: '0px' }}>
            <h5 style={{ fontSize: '12px' }}>
              {' '}
              ${getTranslatedString('landlordPortal.renderLeaseItem.deposit')}{' '}
            </h5>
            <p>{item.deposit_amount !== undefined && formatter.formatMoney(item.deposit_amount)}</p>
          </div>
          <div className="col-sm-4" style={{ padding: '0px' }}>
            <h5 style={{ fontSize: '12px' }}>
              {' '}
              ${getTranslatedString('landlordPortal.renderLeaseItem.keyDeposit')}
            </h5>
            <p>
              {item.key_deposit_amount !== undefined &&
                formatter.formatMoney(item.key_deposit_amount)}
            </p>
          </div>
        </div>
      );
    } else if (item.payment_type === 'one_time') {
      if (item.amount === undefined || !item.charge_type) return null;
      return (
        <p>{`${formatter.formatMoney(item.amount)} ${getTranslatedString(
          'landlordPortal.renderLeaseItem.oneTime',
          {
            feeType: item.charge_type,
          }
        )}`}</p>
      );
    }
  };

  const renderDates = (item) => {
    if (item.payment_type === 'one_time') return null;
    if (!item.start_date) return null;

    const startDate = formatter.toFriendlyDateString(item.start_date);
    if (!item.duration && startDate && item.end_date) {
      return (
        <p style={{ fontSize: '12px' }}>
          From {startDate} until {formatter.toFriendlyDateString(item.end_date)}
        </p>
      );
    }

    if (item.duration === 'monthly') {
      return (
        <p>
          $
          {getTranslatedString('landlordPortal.renderLeaseItem.fromMonthlyRecurring', {
            startDate: startDate,
          })}
        </p>
      );
    }

    return (
      <p>
        $
        {getTranslatedString('landlordPortal.renderLeaseItem.fromForMonths', {
          startDate: startDate,
          duration: item.duration,
        })}
      </p>
    );
  };

  return (
    <div>
      {items.map((item, index) => {
        const name = item.asset_number ? `${item.item_type} ${item.asset_number}` : item.item_name;

        return (
          <div
            id="mobile_card"
            key={index}
            style={{ paddingBottom: '0px', textTransform: 'capitalize' }}
          >
            {!disabled && onDelete && (
              <IconButton
                style={{ float: 'right', padding: '0px', backgroundColor: 'white' }}
                onClick={() => onDelete(index)}
              >
                <DeleteIcon size={24} style={{ padding: '0px' }} />
              </IconButton>
            )}

            {!disabled && onEdit && (
              <IconButton
                style={{
                  float: 'right',
                  padding: '0px',
                  marginRight: '25px',
                  backgroundColor: 'white',
                }}
                onClick={() => onEdit(index)}
              >
                <EditIcon size={24} style={{ padding: '0px' }} />
              </IconButton>
            )}

            <div className="row">
              <div className="row">
                <h5>{name}</h5>
              </div>
              <div className="row">{renderAmount(item)}</div>
              <div className="row">{renderDates(item)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
