import deepDiff from 'return-deep-diff';
import { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

export function deepDiver(obj, query) {
  if (!obj || typeof obj !== 'object') return;
  const nestedAttributes = query.split('.');

  let i = 0;
  let current = obj[nestedAttributes[i]];

  while (i < nestedAttributes.length - 1 && current) {
    i++;
    current = current[nestedAttributes[i]];
  }

  return current;
}

// https://stackoverflow.com/questions/41972652/how-to-sort-mixed-numeric-alphanumeric-array-in-javascript
export function sortAlphaNum(a, b) {
  a = a ? a.toString().replace(/[^a-zA-ZÀ-ÿ0-9  ]/gi, '') : 'a';
  b = b ? b.toString().replace(/[^a-zA-ZÀ-ÿ0-9  ]/gi, '') : 'b';

  const regex = /^([a-zA-ZÀ-ÿ]*)(\d*)/i;
  let _a = a.match(regex);
  let _b = b.match(regex);

  // if the alphabetic part of a is less than that of b => -1
  if (_a[1] < _b[1]) return -1;
  // if the alphabetic part of a is greater than that of b => 1
  if (_a[1] > _b[1]) return 1;

  // if the alphabetic parts are equal, check the number parts
  let _n = (parseInt(_a[2]) || 0) - (parseInt(_b[2]) || 0);

  if (_n !== 0) return _n;

  let new_a = a.substr(_a[0].length).trim();
  let new_b = b.substr(_b[0].length).trim();
  if (new_a.length === 0) return -1;
  if (new_b.length === 0) return 1;

  return sortAlphaNum(new_a, new_b);
}

// https://stackoverflow.com/questions/5072136/javascript-filter-for-objects?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
export const filterObject = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    // eslint-disable-next-line
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

// removing null values
// https://stackoverflow.com/questions/281264/remove-empty-elements-from-an-array-in-javascript
export const compact = (array) => {
  return array.filter((n) => n);
};

export const CANADIAN_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland',
  'NW Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'PEI',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export function objectsAreEqual(a, b) {
  const diff = deepDiff(a, b, true);
  return !diff || !Object.keys(diff).length;
}

// returns the previous state of a component variable
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function toSnakeCase(stringValue) {
  return stringValue
    .replace(/\s+/g, '')
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
}

export function removeFalsyValuesFromObject(object) {
  return Object.entries(object).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
}

export function isPresent(value) {
  if (isNumber(value)) {
    return true;
  }

  return !isEmpty(value);
}
