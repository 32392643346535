const BASE_ACTION_TYPE = 'LANDLORD_TENANTS::RESIDENT_DRAWER';

export const OPEN_RESIDENT_DRAWER = `${BASE_ACTION_TYPE}::OPEN_RESIDENT_DRAWER`;
export const CLOSE_RESIDENT_DRAWER = `${BASE_ACTION_TYPE}::CLOSE_RESIDENT_DRAWER`;

export const LOADING = `${BASE_ACTION_TYPE}::LOADING`;

export const SHOW_INVITED_DRAWER = `${BASE_ACTION_TYPE}::SHOW_INVITED_DRAWER`;
export const SHOW_OPT_OUT_RESIDENT = `${BASE_ACTION_TYPE}::SHOW_OPT_OUT_RESIDENT`;
export const SHOW_PASSWORD_RESET = `${BASE_ACTION_TYPE}::SHOW_PASSWORD_RESET`;
export const SHOW_DELETE_DRAWER = `${BASE_ACTION_TYPE}::SHOW_DELETE_DRAWER`;
