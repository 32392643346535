// @flow
import Auth from 'j-toker';

import { logOutLandlordUser, logOutLeadUser, logOutMaintenanceUser } from 'helpers/Authentication';
import { getTranslatedString } from 'helpers/i18n';

export function explosionCelebration() {
  return {
    type: 'NOTIFICATIONS::CELEBRATION_EXPLOSION',
  };
}

export function showSecondaryLoader(showLoader: boolean) {
  return {
    type: 'NOTIFICATIONS::SHOW_SECONDARY_LOADER',
    showLoader,
  };
}

export function showSuccess(message: string) {
  return {
    type: 'UPDATE_SUCCESS',
    updateSuccessNotice: true,
    message: message,
  };
}
export function showFailureFromUrlQuery(query) {
  let message;
  if (query === 'saml') {
    message = getTranslatedString('weWereNotAbleToLogYouInSaml');
  } else {
    message = getTranslatedString('unknownError');
  }

  return {
    type: 'UPDATE_FAIL',
    updateFailureNotice: true,
    message,
  };
}

export function showFailure(error: Object | string, ignoreUserLoggedInCheck: false) {
  if ((!Auth.user || !Auth.user.signedIn) && !ignoreUserLoggedInCheck) {
    // don't show failures if not logged in
    return {
      type: 'IGNORE',
    };
  }

  const UNAUTHORIZED = 401;
  const THROTTLE_IN_MS = 7000;
  let action = {
    type: 'UPDATE_FAIL',
    updateFailureNotice: true,
    message: getTranslatedString('unknownError'),
    meta: { throttle: THROTTLE_IN_MS },
  };

  if (typeof error === 'object') {
    if (error.status === UNAUTHORIZED) {
      if (Auth.user.type === 'agent') {
        logOutLandlordUser({ showDialog: true });
        return;
      }

      if (Auth.user.type === 'lead') {
        logOutLeadUser({ showDialog: true });
        return;
      }

      if (Auth.user.type === 'maintenance') {
        logOutMaintenanceUser();
      }
    } else if (
      error.reason === 'Cannot validate token; no token found.' ||
      error.reason === 'Cannot validate token; token rejected by server.'
    ) {
      action.message = getTranslatedString('pleaseRelogin');
    } else if (error.responseJSON && error.responseJSON.message) {
      // custom back-end message
      action.message = error.responseJSON.message;
    }
  } else if (typeof error === 'string') {
    //  custom front-end message
    action.message = error;
  }

  return action;
}

export function hideSuccess() {
  return {
    type: 'CLEAR_UPDATE_SUCCESS',
    updateSuccessNotice: false,
  };
}

export function hideFailure() {
  return {
    type: 'CLEAR_UPDATE_FAILURE',
    updateFailureNotice: false,
  };
}

export function displayLoader(verbiage: string) {
  return {
    type: 'SHOW_FULL_SCREEN_LOADER',
    shouldShow: true,
    verbiage,
  };
}

export function hideLoader() {
  return {
    type: 'SHOW_FULL_SCREEN_LOADER',
    shouldShow: false,
    verbiage: null,
  };
}
